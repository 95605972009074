export function sendProductDetailsImpression(titleId, productName) {
  const eventData = {
    items: [
      {
        item_id: titleId,
        item_name: titleId,
      }
    ],
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: eventData,
  });
}

export function sendAddToCartEvent({ productInfo, currency, totalPrice }) {
  const eventData = {
    currency,
    value: totalPrice,
    items: [
      {
        item_id: productInfo.id,
        item_name: productInfo.id,
        item_category: productInfo.category,
        quantity: productInfo.quantity,
        price: productInfo.price,
      }
    ],
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: eventData,
  });
}

export function sendPurchaseConfirmedEvent({ titleId, productInfo, currency, totalPrice, orderId }) {
  const eventData = {
    currency,
    value: totalPrice,
    transaction_id: orderId,
    items: [
      {
        item_id: productInfo.id,
        item_name: productInfo.id,
        item_category: productInfo.category,
        quantity: productInfo.quantity,
        price: productInfo.price,
      }
    ],
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: eventData,
  });
}

export function sendInitiatePurchaseEvent({ titleId, productInfo, currency, totalPrice }) {
  const eventData = {
    currency,
    value: totalPrice,
    items: [
      {
        item_id: productInfo.id,
        item_name: productInfo.id,
        item_category: productInfo.category,
        quantity: productInfo.quantity,
        price: productInfo.price,
      }
    ],
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: eventData,
  });
}

export function sendCreateAccountEvent() {
  window.dataLayer.push({ event: 'create_account' });
}
