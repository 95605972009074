import styled from "styled-components";

export default (component) => {
  return styled(component)`
    width: ${ props => {
      const { width } = props;
      if (!width) {
        return '280px';
      }
  
      if (typeof width === 'number') {
        return `${width}px`;
      }
  
      if (typeof width === 'string') {
        return width;
      }
  
      // If none of the above; default
      return '280px';
    }};
    height: 15px;
    border: 1px solid ${ props => props.theme.ds_bright_blue_50 };
    padding: 14px;
    transition: ${ props => props.theme.transition };
    border-radius: 3px;
    outline: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    
    &::placeholder {
      color: ${ props => props.dark ? props.theme.white_70 : props.theme.black_30 };
    }
    
    &:hover {
      border: 3px solid ${ props => props.theme.ds_bright_blue };
      padding: 12px;
    }
    
    &:focus {
      border: 1px solid ${ props => props.theme.ds_bright_blue };
      padding: 14px;
    }
  `;
}
