import React from 'react';
import styled from 'styled-components';

import { Desktop, Mobile } from '../../components/responsive/Breakpoints';

const AboutUsDetailsSectionContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: black;
  display: flex;
  justify-content: center;
`;

const AboutUsDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${ props => props.isMobile ? 'column' : 'row' };
  max-width: 1170px;
  padding: ${ props => props.isMobile ? '36px 22px' : '55px 98px' };
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${ props => props.isMobile ? undefined : '1' };
`;

const Paragraph = styled.p`
  font-size: ${ props => props.isMobile ? '0.875rem' : '1.25rem' };
  color: ${ props => props.theme.white_70 };
  margin-top: 0;
  margin-bottom: ${ props => props.isMobile ? '22px' : '30px' };
  line-height: ${ props => props.isMobile ? '1.33' : '1.55' };
`;

const COPY = {
  firstParagraph: 'In 2016, Swiss computer scientists Caecilia Charbonnier and Sylvain Chagué, along with entrepreneur Ronald Menzel, premiered a groundbreaking new approach to virtual reality. By merging the technique of "motion capture" used in blockbuster Hollywood movies with sophisticated programming and a deep understanding of body mechanics, they created a platform able to track six people simultaneously, in real-time, with full body presence, and render them as characters inside a computer generated world.',
  secondParagraph: 'Suddenly the limits of VR were gone. You were no longer a lone observer, you became a participant. Immersive storytelling and shared experiences were a real possibility. VR was more than a device, it was a destination.',
  thirdParagraph: 'The platform soon caught the eyes of a group of global entertainment creators: producer, screenwriter, and former head of Dreamworks Motion Pictures, Walter Parkes; global music events producer and serial entrepreneur Kevin Wall, along with his partner Aaron Grosky; and former Chief Creative Officer of Disney Imagineering, Bruce Vaughn.',
  fourthParagraph: 'The producers and technologists brought their talents together, and Dreamscape Immersive was born. The vision: combine the emotional power of Hollywood storytelling, the visceral excitement of great theme-park rides, and this extraordinary new technology to create stories and worlds that push the limits of virtual reality.',
  fifthParagraph: 'Dreamscape is truly the birth of a new storytelling medium, giving people the opportunity to step into a story and watch it unfold around them as they explore cinematic worlds, characters and creatures never before thought possible. And each Dreamscape adventure is a shared experience, embracing the human desire to explore, learn and enjoy together.',
  sixthParagraph: 'Dreamscape venues began rolling out in December 2018.'
};

const renderDetailsContainer = (isMobile) => {
  return (
    <AboutUsDetailsContainer isMobile={isMobile}>
      <DetailsColumn isMobile={isMobile}>
        <Paragraph isMobile={isMobile}>
          { COPY.firstParagraph }
        </Paragraph>
        <Paragraph isMobile={isMobile}>
          { COPY.secondParagraph }
        </Paragraph>
        <Paragraph isMobile={isMobile}>
          { COPY.thirdParagraph }
        </Paragraph>
        <Paragraph isMobile={isMobile}>
          { COPY.fourthParagraph }
        </Paragraph>
        <Paragraph isMobile={isMobile}>
          { COPY.fifthParagraph }
        </Paragraph>
        <Paragraph isMobile={isMobile}>
          { COPY.sixthParagraph }
        </Paragraph>
      </DetailsColumn>
    </AboutUsDetailsContainer>
  );
};

const AboutUsDetailsSection = () => {
  return (
    <AboutUsDetailsSectionContainer id="about-us-details">
      <Desktop>
        { renderDetailsContainer() }
      </Desktop>
      <Mobile>
        { renderDetailsContainer(true) }
      </Mobile>
    </AboutUsDetailsSectionContainer>
  );
};

export default AboutUsDetailsSection;
