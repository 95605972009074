import React, { Component } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe,
} from 'react-stripe-elements';
import styled from 'styled-components';

import StripeInput from './StripeInput';
import { WhiteButton } from '../buttons'
import { BlueButton } from '../buttons';
import theme from '../../util/theme';
import { Large, Phone } from '../responsive/Breakpoints';
import MobileCheckoutFooterBtn from '../MobileCheckoutFooterBtn';
import { BasicInputField as Input } from './';
import CheckoutOrderDetails from '../CheckoutOrderDetails';
import FooterSpacer from '../NavbarSpacer';
import LoadingSpinner from '../LoadingSpinner';
import DisabledInputField from "./DisabledInputField";

const CCNumberInput = StripeInput(CardNumberElement);
const CCExpiryInput = StripeInput(CardExpiryElement);
const CCCVCInput = StripeInput(CardCVCElement);
const CCPostalCodeInput = StripeInput(PostalCodeElement);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${ props => props.isMobile ? '48px' : '42px' };
`;

const PaymentInfoRow = styled.div`
  display: flex;
  flex-direction: ${ props => props.isMobile ? 'column' : 'row' };
  justify-content: ${ props => props.isMobile ? undefined : 'center' };
  align-items: ${ props => props.isMobile ? 'center' : undefined };
  width: ${ props => props.isMobile ? '100%' : 'auto' };
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${ props => props.isMobile ? '100%' : 'auto' };
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: ${ props => props.isMobile ? '100%' : '310px' };
  max-width: ${ props => props.isMobile ? '360px' : undefined };
  padding: ${ props => props.isMobile ? '0 22px' : undefined };
  margin-top: ${ props => props.isMobile ? '42px' : undefined };
  box-sizing: ${ props => props.isMobile ? 'border-box' : undefined };
`;

const InputLabel = styled.label`
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: ${ props => props.flex ? '30px' : undefined };
  flex: ${ props => props.flex ? '1' : undefined };
  color: ${ props => {
    if (props.focused) {
      return props.theme.ds_bright_blue;
    }
  
    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

const InputSpacer = styled.div`
  display: flex;
  width: 14px;
  flex-shrink: 0;
`;

const UserSignoutTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${ props => props.isMobile ? '14px 0 36px 0' : '24px 0 16px 0'};
`;

const TermsText = styled.span`
  font-size: 0.8125rem;
  color: ${ props => props.link ? props.theme.ds_dusk_blue : props.theme.charcoal };
  cursor: ${ props => props.link ? 'pointer' : undefined };
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${ props => props.isMobile ? 'auto' : '100%' };
  height: ${ props => props.isMobile ? '68px' : undefined };
  max-width: ${ props => props.isMobile ? '360px' : undefined };
  padding: ${ props => props.isMobile ? '32px 0 22px 0' : '36px 0 22px 0' };
`;

const PromoButtonRow = styled.div`
  display: flex;
  flex-direction: ${ props => props.isMobile ? 'column' : 'row' };
  justify-content: space-between;
  width: ${ props => props.isMobile ? 'auto' : '100%' };
  height: ${ props => props.isMobile ? 'auto' : undefined };
  max-width: ${ props => props.isMobile ? '360px' : undefined };
  padding: ${ props => props.isMobile ? '32px 0 22px 0' : '12px 0 22px 0' };
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: ${ props => props.isMobile ? '360px' : undefined };
  padding: ${ props => props.isMobile ? '32px 22px 22px 22px' : '36px 0 22px 0' };
  box-sizing: ${ props => props.isMobile ? 'border-box' : undefined };
`;

const SmallText = styled.div`
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-top: ${ props => props.marginTop ? '22px' : undefined };
  text-align: ${ props => props.isMobile ? 'center' : undefined };
  font-weight: ${ props => props.bold ? 'bold' : undefined };
  color: ${ props => props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal };
  cursor: ${ props => props.blue ? 'pointer' : undefined };
`;

const inputConfig = {
  base: {
    // Setting as pixel size instead of rem here since stripe hosted fields use 14px as a base font size using rem relates
    // To stripes root container rather than this projects root element
    fontSize: '13px',
    fontFamily: theme.sans_serif,
    color: theme.charcoal,
    '::placeholder': {
      color: theme.black_30,
    },
  },
};

class PaymentForm extends Component {
  state = {
    loading: false,
    inputFocused: {
      cc: false,
      expire: false,
      cvv: false,
      zip: false,
    },
    email: '',
    name: '',
    promoCode: '',
    promoCodeFocused: false,
    newsletterOptIn: false,
    cardRequired: true,
    loadingPromoCode: false
  };

  componentDidMount () {
    if (this.props.authState && this.props.authState.currentUser && this.props.authState.currentUser.attributes) {
      this.setState({ email: this.props.authState.currentUser.attributes.email });
      if (!this.nameRef || !this.nameRef.focus) {
        return;
      }
      return this.nameRef.focus();
    }
  }

  componentDidUpdate () {
    if (this.state.cardRequired && this.props.orderDetails.total === 0) {
      this.setState({cardRequired: false});
    }
    if (!this.state.email && this.props.authState && this.props.authState.currentUser && this.props.authState.currentUser.attributes) {
      this.setState({ email: this.props.authState.currentUser.attributes.email });
      if (!this.nameRef || !this.nameRef.focus) {
        return;
      }
      return this.nameRef.focus();
    }
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ loading: true });
    const { orderDetails } = this.props;

    if(this.state.email === ''){
      alert('Email is required');
      return this.setState({ loading: false });

    }
    if(this.state.name === ''){
      alert('Name is required');
      return this.setState({ loading: false });
    }
    // allow user to move with cc info if order total is 0
    if(orderDetails.total <= 0) {
      try {
        const success = await this.props.handleSubmitPayment({
          email: this.state.email,
          name: this.state.name,
        });
        if (!success) {
          return this.setState({ loading: false });
        }
      } catch (err) {
        console.error('[PaymentForm] -> (handleSubmit) Error Occurred:', err);
        alert('We couldn\'t process the payment at this time. Please check all fields and try again.');
        return this.setState({ loading: false });
      }
    }
    else{
      try {
        const stripeTokenResponse = await this.props.stripe.createToken({name: this.state.name });
        if (stripeTokenResponse.error) {
          console.error('[PaymentForm] -> (handleSubmit) Stripe Error:', stripeTokenResponse.error);
          this.setState({ loading: false });
          alert(stripeTokenResponse.error.message);
          return;
        }

        const success = await this.props.handleSubmitPayment({
          email: this.state.email, 
          name: this.state.name, 
          cardToken: stripeTokenResponse.token,
        });
        if (!success) {
          return this.setState({ loading: false });
        }
      } catch (err) {
        console.error('[PaymentForm] -> (handleSubmit) Error Occurred:', err);
        alert('We couldn\'t process the payment at this time. Please check all fields and try again.');
        this.setState({ loading: false });
      }
    }
  };

  handleInputFocused (inputFocused) {
    const inputFocusUpdate = {
      [inputFocused]: true,
    };
    this.setState({ inputFocused: { ...this.state.inputFocused, ...inputFocusUpdate} });
  }

  handleInputBlur (inputBlurred) {
    const inputFocusUpdate = {
      [inputBlurred]: false,
    }
    this.setState({ inputFocused: { ...this.state.inputFocused, ...inputFocusUpdate} });
  }

  handleCheckClicked = (e) => {
    e.preventDefault();
    this.setState({ newsletterOptIn: !this.state.newsletterOptIn });
  };
  handlePromoCode = async (e) => {
    e.preventDefault();
    this.setState({ loadingPromoCode: true });
    await this.props.handlePromoCodeSubmit(this.state.promoCode);
    return this.setState({ loadingPromoCode: false, promoCodeFocused:false, promoCode: '' });
  };
  enablePromoCode = () => {
    this.setState({promoCodeFocused: !this.state.promoCodeFocused});
  };
  renderEmailInputArea = () => {
    const { isSignedIn } = this.props.authState;

    if (!isSignedIn) {
      // Return the standard, enabled input to enter email with
      return (
        <Input
          autoFocus
          type="email"
          width="100%"
          placeholder="Email"
          label="Email*"
          required
          value={ this.state.email }
          style={{ marginBottom: 12 }}
          onChange={ (event) => this.setState({ email: event.target.value }) }
        />
      );
    }

    // User is logged in so display a disabled input box with disclaimer at the bottom to allow user to signout of the current
    // user account and login OR continue as guest.
    return (
      <React.Fragment>
        <Input
          disabled
          type="email"
          width="100%"
          placeholder="Email"
          label="Email*"
          required
          value={ this.state.email }
          style={{ marginBottom: 12 }}
        />
        <UserSignoutTextContainer>
          <TermsText style={{ flex: 1 }}>Is this not you?</TermsText>
          <TermsText style={{ flex: 2 }} link onClick={ this.props.handleUserSignoutAndNavigate }>Sign in with a different account or continue as a guest. &gt;</TermsText>
        </UserSignoutTextContainer>
      </React.Fragment>
    );
  };

  renderPayButton (isMobile) {
    const { orderDetails } = this.props;

    const btnContent = this.state.loading ? <LoadingSpinner/> : `PAY ${ orderDetails.totalCost }`;

    return (
      <BlueButton disabled={ this.state.loading } width={ isMobile ? '100%' : '300px' }>
        { btnContent }
      </BlueButton>
    );
  }

  renderGiftPromoSection (isEnabled, isMobile) {
    if(!isEnabled){
      return (
        <Row isMobile={isMobile}>
          <SmallText blue isMobile={isMobile} onClick={ this.enablePromoCode }>ENTER PROMO CODE &gt;</SmallText>
          <SmallText blue marginTop isMobile={isMobile} onClick={ this.enablePromoCode }>ENTER GIFT CARD &gt;</SmallText>
        </Row>
      )
    }
    else{
      const btnContent = this.state.loadingPromoCode ? <LoadingSpinner/> : `APPLY CODE`;
      return (
        <Column isMobile={isMobile}>
          <Input
            autoFocus
            type="text"
            width="100%"
            label="Please be sure to include any dashes in your code"
            placeholder="Gift Code/Promo Code"
            value={ this.state.promoCode }
            style={{ marginBottom: 12 }}
            onChange={ (event) => this.setState({ promoCode: event.target.value }) }>
          </Input>
          <PromoButtonRow isMobile={isMobile}>
            <BlueButton width={isMobile ? '100%' : undefined } onClick = {this.handlePromoCode}>
              {btnContent}
            </BlueButton>
            {
              this.state.loadingPromoCode
                ? null
                : <WhiteButton width={isMobile ? '100%' : undefined } style={{margin: isMobile ? '10px 0 0 0' : '0 0 0 10px'}} onClick={this.enablePromoCode}>
                    CANCEL
                  </WhiteButton>
            }
          </PromoButtonRow>
        </Column>
      );
    }
  }

  render () {
    // const { totalPrice, fees, feePercent } = this.props.checkoutState;
    const { isMobile, orderDetails, titleId } = this.props;

    let ccForm = (
      <React.Fragment>
        <InputRow>
          <InputLabel focused={ this.state.inputFocused.cc } flex>
            Credit Card Number*
            <CCNumberInput width="100%"
                           placeholder="Credit Card Number"
                           style={ inputConfig }
                           onFocus={ () => this.handleInputFocused('cc') }
                           onBlur={ () => this.handleInputBlur('cc') }
            />
          </InputLabel>
          <InputSpacer/>
          <InputLabel focused={ this.state.inputFocused.expire }>
            Exp Date*
            <CCExpiryInput width={65}
                           placeholder="MM/YY"
                           style={ inputConfig }
                           onFocus={ () => this.handleInputFocused('expire') }
                           onBlur={ () => this.handleInputBlur('expire') }
            />
          </InputLabel>
        </InputRow>
        <InputRow>
          <InputLabel focused={ this.state.inputFocused.cvv }>
            CVV*
            <CCCVCInput width={51}
                        placeholder="CVV"
                        style={ inputConfig }
                        onFocus={ () => this.handleInputFocused('cvv') }
                        onBlur={ () => this.handleInputBlur('cvv') }
            />
          </InputLabel>
          <InputSpacer/>
          <InputLabel focused={ this.state.inputFocused.zip } flex>
            Zip Code*
            <CCPostalCodeInput width="100%"
                               placeholder="Zip Code"
                               style={ inputConfig }
                               onFocus={ () => this.handleInputFocused('zip') }
                               onBlur={ () => this.handleInputBlur('zip') }
            />
          </InputLabel>
        </InputRow>
        <Large>
          { this.renderGiftPromoSection(this.state.promoCodeFocused) }
        </Large>
      </React.Fragment>
    );

    if (!this.state.cardRequired) {
      ccForm = (
        <React.Fragment>
          <InputRow>
            <DisabledInputField
              type="text"
              width="100%"
              label="Credit Card Number*"
              placeholder="Credit Card Number"/>
            <InputSpacer/>
            <DisabledInputField
              type="text"
              width={95}
              label="Exp Date*"
              placeholder="MM/YY"/>
          </InputRow>
          <InputRow>
            <DisabledInputField
              type="text"
              width={81}
              label="CVV*"
              placeholder="CVV"/>
            <InputSpacer/>
            <DisabledInputField
              type="text"
              width="100%"
              label="Zip Code*"
              placeholder="Zip Code"/>
          </InputRow>
          <Large>
            { this.renderGiftPromoSection(this.state.promoCodeFocused) }
          </Large>
        </React.Fragment>
      );
    }

    return (
      <StyledForm onSubmit={ this.handleSubmit } isMobile={ isMobile }>
        <PaymentInfoContainer isMobile={ isMobile }>
          <PaymentInfoRow isMobile={ isMobile }>
            <CheckoutOrderDetails orderDetails={orderDetails} isMobile={ isMobile } titleId={titleId} />

            <InputGroup isMobile={ isMobile } name="payment-form-group">
              { this.renderEmailInputArea() }
              <Input
                width="100%"
                placeholder={ this.state.cardRequired ? "Name on Card" : "Full Name" }
                label={ this.state.cardRequired ? "Name on Card*" : "Full Name*" }
                value={ this.state.name }
                onChange={ (event) => this.setState({ name: event.target.value }) }
                ref={ (ref) => this.nameRef = ref ? ref.inputRef : null }
              />
              { ccForm }
            </InputGroup>
          </PaymentInfoRow>

        </PaymentInfoContainer>

        {/* Terms of service text here */}
        <Large>
          <TermsContainer>
            <TermsText><TermsText link onClick={this.props.linkToTerms}>Dreamscape Terms of Service &gt;</TermsText></TermsText>
          </TermsContainer>
        </Large>
        <Phone>
          { this.renderGiftPromoSection(this.state.promoCodeFocused, true) }
          <TermsContainer isMobile>
            <TermsText link onClick={this.props.linkToTerms}>Dreamscape Terms of Service &gt;</TermsText>
          </TermsContainer>
          <FooterSpacer name="footer-spacer" height={92}/>
        </Phone>

        {/* PAYMENT SUBMIT BUTTON */}
        <Large>
          <InputRow>
            { this.renderPayButton(false) }
          </InputRow>
          <FooterSpacer name="footer-spacer" height={57}/>
        </Large>

        <Phone>
          <MobileCheckoutFooterBtn>
            { this.renderPayButton(true) }
          </MobileCheckoutFooterBtn>
        </Phone>
      </StyledForm>
    );
  }
}

export default injectStripe(PaymentForm);
