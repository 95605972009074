import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import styled from 'styled-components';
import {scroller} from "react-scroll/modules";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";
import {AuthContext} from "../components/context/AuthenticatorContext";

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${ props => props.mobile ? '84px 22px' : '164px 20% 86px' };
  max-width: 1170px;
`;

const HeaderBlock = styled.div`
  padding-bottom: ${ props => props.mobile ? '18px' : '40px' };
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '36px' : '44px' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' }1.09;
  color: ${ props => props.theme.charcoal };
`;

const Body = styled.div`
  text-align: left;
`;

const Header = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: 1rem;
  font-weight: bold;
  ${ props => props.blue ? 'text-decoration: underline' : '' }
  color: ${ props => props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal_90 };
  margin-top: 13px;
  ${ props => props.blue ? 'cursor: pointer;' : '' }
`;

const Text = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: 0.81rem;
  color: ${ props => props.theme.charcoal_60 };
`;

const PaddedText = styled(Text)`
  margin-top: 13px;
`;

const Contents = styled.table`
  padding: 0 0 40px;
`;

const ContentsRow = styled.tr`
  vertical-align: top;
`;

const LinkText = styled.span`
  cursor: pointer;
  color: ${ props => props.theme.charcoal_60 };
  text-decoration: none;
`;

const ColoredLink = styled(LinkText)`
  color: ${ props => props.theme.ds_dusk_blue };
`;

class TermsWithContext extends Component {
  componentDidMount () {
    const navbar = document.getElementById('navbar');

    this.setState({ navbar });
  }

  scrollPage = (sectionId, offset) => {
    scroller.scrollTo(sectionId, {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset,
    });
  };

  handleScrollToSection = (sectionId) => {
    const toasterOffset = this.props.parentState.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    const { navbar } = this.state;
    // If viewing the kiosk, navbar-less, version of the page, default the offset to provide some spacing.
    this.scrollPage(sectionId, navbar ? -navbar.offsetHeight -toasterOffset : -30);
  };

  handleNavigation = (path) => {
    if (this.props.disableLinks) {
      return;
    }
    return this.props.history.push(path);
  };

  content = [
    {
      title: '1. YOU ACCEPT THESE TERMS',
      preview: 'Each time you access and/or use the Site, you agree to be bound by these Terms and any additional terms that will apply to you.',
      body: (
        <React.Fragment>
          <span>These terms set forth a legally binding agreement between you and Dreamscape, and govern your use of any part of the Site. If you do not agree with any of these Terms, including the </span>
          <ColoredLink onClick={() => this.handleNavigation('/privacy')}>Privacy Policy</ColoredLink>
          <span> incorporated herein, please do not use this Site. By using this Site you will be deemed to have irrevocably agreed to these Terms, and consent to our collection, use and disclosure practices, and other activities as described in our Privacy Policy. Some areas of this Site may be subject to additional terms and conditions, which you should read carefully before making any use of those areas. Such additional terms are supplemental to these Terms and will not change or replace these Terms regarding the use of the Site, unless otherwise expressly stated.</span>
        </React.Fragment>
      )
    }, {
      title: '2. UPDATES TO TERMS',
      preview: 'These Terms and additional terms posted on this Site at each time of use apply to that use, prospectively. You agree that we may provide notice to you of updated terms by posting them on the Site.',
      body: 'Please note that these Terms may be revised and reissued, prospectively, by posting updated terms on the Site, at any time and in our sole discretion. You consent and agree to receive notices of updates of these Terms through our posting of updated Terms on the Site, and/or to send you an e-mail to the last e-mail address you provided to us. All such updates are effective immediately when we post them, or such later date as may be specified in the notice of updated terms and any applicable Additional Terms. You should visit this page regularly to review the current terms. If you object to any updates to the Terms, your sole recourse is to cease using the Site. Your continued use of the Site will be deemed as irrevocable acceptance of any revisions.'
    }, {
      title: '3. LEGAL CAPACITY',
      preview: 'You represent that you have the legal capacity to enter into the agreement set out in these Terms.',
      body: (
        <React.Fragment>
          <span>You may not use the Site unless you are at least thirteen (13) years old, and may not participate in a Dreamscape VR Experience unless you are at least ten (10) years old and have the consent of a parent or guardian. We will assume (and by using this Site you warrant that) you have legal capacity to enter into the agreement set out in these Terms (i.e., that you are of sufficient age and mental capacity and are otherwise entitled to be legally bound in contract).</span>
          <br/><br/>
          <span style={{fontWeight:"bold"}}>You agree to comply with all local, state, federal, national, foreign, supranational, and international laws, statutes, ordinances, regulations, treaties, directives, and agreements that apply to your use of the Site and/or your participation in a Dreamscape VR Experience.</span>
        </React.Fragment>
      )
    }, {
      title: '4. THE INFORMATION YOU GIVE US MUST BE CORRECT',
      preview: 'The information you submit to the Site must be correct. We can refuse access to the Site if we learn that information you have provided is inaccurate.',
      body: 'To access this Site or some of the resources it has to offer, you may be asked to provide registration details. It is a condition of use of this Site that all the details you provide be correct, current and complete. If we at Dreamscape believe the details are not correct, current, or complete, we have the right to refuse you access to the Site, or any of its resources and to terminate or suspend your account.'
    }, {
      title: '5. ONLINE PRIVACY NOTICE',
      preview: (
        <React.Fragment>
          <span>You agree to the terms of our </span>
          <ColoredLink onClick={() => this.handleNavigation('/privacy')}>Privacy Policy</ColoredLink><span>.</span>
        </React.Fragment>
      ),
      body: (
        <React.Fragment>
          <span>We provide a notice explaining our online information practices and the choices you can make about the way your information is collected and used at this Site. </span>
          <ColoredLink onClick={() => this.handleNavigation('/privacy')}>Click here</ColoredLink>
          <span> to view our Privacy Policy, which forms part of these Terms.</span>
        </React.Fragment>
      )
    }, {
      title: '6. DISCLAIMER OF WARRANTIES',
      preview: 'We disclaim all warranties and provide access to the Site “As Is.”',
      body: 'YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE SITE AND ALL THE MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES AND OTHER CONTENT IN THE SITE ARE PROVIDED “AS IS,” “AS AVAILABLE” AND “WITH ALL FAULTS” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE SITE, DREAMSCAPE, AND ANY SUBSIDIARIES OR AFFILIATED COMPANIES OF DREAMSCAPE (“THE DREAMSCAPE GROUP”) DISCLAIM ALL WARRANTIES OR ENDORSEMENTS OF ANY KIND, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, OR ARISING FROM A COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, USE AND NON-INFRINGEMENT. THE DREAMSCAPE GROUP DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THIS SITE WILL BE AVAILABLE, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE DREAMSCAPE GROUP DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT ON THE SITE OR ANY WEBSITES LINKED TO THE SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. THE DREAMSCAPE GROUP MAKES NO WARRANTIES THAT YOUR USE OF THE MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT IN THE SITE OR ANY WEBSITE WILL NOT INFRINGE THE RIGHTS OF OTHERS AND THE DREAMSCAPE GROUP ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT OF THE SITE OR ANY OTHER WEBSITE. IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF SOME OR ALL OF THE ABOVE IMPLIED WARRANTIES TO APPLY TO YOU, THE ABOVE EXCLUSIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.'
    }, {
      title: '7. LIMITATION OF LIABILITY',
      preview: 'Our liability is limited.',
      body: 'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW THE DREAMSCAPE GROUP DISCLAIMS ALL LIABILITY, WHETHER BASED IN CONTRACT, INDEMNIFICATION, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, AND WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGES (DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY, OR OTHERWISE) INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, RESULTING FROM ANY USE OF, OR INABILITY TO USE, THE SITE OR ANY OTHER WEBSITE, OR THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT ON THE SITE OR ANY OTHER WEBSITE, REGARDLESS OF THE BASIS UPON WHICH LIABILITY IS CLAIMED AND EVEN IF ANY MEMBER OF THE DREAMSCAPE GROUP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU (AND NOT ANY MEMBER OF THE DREAMSCAPE GROUP) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION IN THE EVENT OF ANY SUCH LOSS OR DAMAGE.'
    }, {
      title: '8. BINDING ARBITRATION',
      preview: 'Any disputes under these Terms will be resolved on an individual basis through binding arbitration, with no class relief.',
      body: (
        <React.Fragment>
          <span style={{fontWeight: 'bold'}}>PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR CLAIMS.  IT CONTAINS PROCEDURES FOR MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.</span>
          <br/><br/>
          <span>(a) U.S. Residents: To the fullest extent permissible by law, with the exception of disputes pertaining to Dreamscape’s intellectual property rights and certain statutory claims that, pursuant to applicable law, are not arbitrable, any dispute of any kind between you and Dreamscape arising under these Terms shall be resolved through final and binding arbitration pursuant to the JAMS Streamlined Arbitration Rules and Procedures on an individual basis only and with no entitlement to class relief, unless otherwise prohibited by law. The arbitrator shall be a retired judge or justice of any California state or federal court with substantial experience in the internet industry and shall follow California substantive law in adjudicating the dispute, except that this Section 8(a) shall be construed as a “written agreement to arbitrate” pursuant to the Federal Arbitration Act (“FAA”). You and we agree that we intend that this Section 8(a) satisfies the “writing” requirement of the FAA. The hearing shall be conducted in the county that encompasses the billing address you have provided to Dreamscape. For any claim in which you seek U.S. $10,000.00 or less, you shall have the choice as to whether the hearing is conducted in person, by telephone, or instead the arbitrator may decide the dispute without a hearing. For those claims that the arbitrator determines are not frivolous, Dreamscape shall pay the costs and fees of JAMS and the arbitrator. Dreamscape agrees that it will not seek reimbursement from you for its costs and fees incurred by it in the arbitration. AGREEMENT TO THESE TERMS CONSTITUTES AN AGREEMENT TO PURSUE YOUR CLAIM ONLY ON AN INDIVIDUAL BASIS AND A WAIVER OF THE ABILITY TO PURSUE YOUR CLAIM IN A CLASS ACTION.</span>
          <br/><br/>
          <span>(b) Non-U.S. Residents: If any controversy, allegation, or claim (including any non-contractual claim) arises out of or relates to the Site or Terms of Service, then you and we agree to send a written notice to the other providing a reasonable description of the Section 8(b) Dispute, along with a proposed resolution of it. Our notice to you will be sent to you based on the most recent contact information that you provide us. But if no such information exists or if such information is not current, then we have no obligation under this Section 8(b). Your notice to us must be sent to us at <ColoredLink as={'a'} href={ !this.props.disableLinks ? "mailto:legal@dreamscapevr.com" : undefined }>legal@dreamscapevr.com</ColoredLink>. For a period of sixty (60) days from the date of receipt of notice from the other party, Dreamscape and you will engage in a dialogue in order to attempt to resolve the Section 8(b) Dispute, though nothing will require either you or Dreamscape to resolve the Section 8(b) Dispute on terms either you or Dreamscape, in each of our sole discretion, are uncomfortable. Nothing in this Section will prevent a party from pursuing their claims in Court or another complaint process.</span>
        </React.Fragment>
      )
    }, {
      title: '9. TRADEMARKS AND COPYRIGHTS',
      preview: 'This Site is protected by copyrights, patents, trade secrets, or other proprietary rights.',
      body: 'This Site and materials incorporated by Dreamscape on this Site (“Material”) are protected by copyrights, patents, trade secrets or other proprietary rights (collectively, “Copyrights”). Some of the characters, logos, or other images incorporated by Dreamscape on this Site are also protected as registered or unregistered trademarks, trade names, and/or service marks owned by Dreamscape or others (collectively, “Trademarks”). Dreamscape respects the intellectual property rights of others and asks users of this Site to do the same.'
    }, {
      title: '10. YOUR USE OF MATERIAL',
      preview: 'We only grant you a limited revocable license to use the Site for your own non-commercial use subject to rules and limitations.',
      body: (
        <React.Fragment>
          <span>Your right to make use of this Site and any Material or other content appearing on it is subject to your compliance with these Terms. Modification or use of the Material or any other content on this Site for any purpose not permitted by these Terms may be a violation of the Copyrights and/or Trademarks protected by law and these Terms and is prohibited.</span>
          <br/><br/>
          <span>You may access and display Material and all other content displayed on this Site for non-commercial, non-political, personal use only. The Material and all other content on this Site may not otherwise be copied, reproduced, republished, uploaded, posted, transmitted, distributed, or used in any way unless specifically authorized by Dreamscape. Any authorization to copy Material granted by Dreamscape in any part of this Site for any reason is restricted to making a single copy for non-commercial, non-political, personal use only, and is subject to your keeping intact all copyright and other proprietary notices. Using any Material on any other website or networked computer environment is prohibited. Also, decompiling, reverse engineering, disassembling, or otherwise reducing the code used in any software on this Site into a readable form in order to examine the construction of such software and/or to copy or create other products based (in whole or in part) on such software, is prohibited. Further, you may not harvest any information from the Site; infringe any intellectual property or other right of any third party; or use the Site in a manner that suggests an unauthorized association or is beyond the scope of the limited license granted to you.</span>
        </React.Fragment>
      )
    }, {
      title: '11. FORUMS',
      preview: 'Dreamscape is not responsible for information you post on our Site.',
      body: 'THE MATERIALS, INFORMATION AND OPINIONS INCLUDED AND/OR EXPRESSED IN OR ON BULLETIN BOARDS, COMMENT SECTIONS, COMMUNITY PAGES, OR OTHER FORUMS ON THIS SITE (“FORUMS”) ARE NOT NECESSARILY THOSE OF THE DREAMSCAPE GROUP OR CONTENT PROVIDERS. DREAMSCAPE DOES NOT UNDERTAKE TO MONITOR OR REVIEW FORUMS, AND THE CONTENT OF FORUMS IS NOT THE RESPONSIBILITY OF DREAMSCAPE. DREAMSCAPE MAY REMOVE OR MODIFY ANY CONTENT WITHOUT NOTICE OR LIABILITY AT ANY TIME IN DREAMSCAPE’S SOLE DISCRETION. ANY USE OF THE FORUMS WILL BE AT YOUR OWN RISK AND WILL BE SUBJECT TO THE DISCLAIMERS AND LIMITATIONS ON LIABILITY SET OUT ABOVE.'
    }, {
      title: '12. UNINVITED SUBMISSION',
      preview: 'We do not accept unsolicited ideas through your submissions and will not pay for them.',
      body: 'Dreamscape is interested in hearing from you regarding your questions or comments about our Site. However, However, keep in mind if you submit an idea, (1) your idea will automatically become the property of Dreamscape, without any compensation to you; (2) Dreamscape may use or redistribute the idea and its contents worldwide for any purpose and in any way; (3) there is no obligation for Immersive to review your idea; and (4) there is no obligation to keep any ideas confidential.'
    }, {
      title: '13. OUR USE OF CONTENT',
      preview: 'Dreamscape will consider anything you provide to us as free of any obligations to you.',
      body: 'Dreamscape will consider anything you provide to Dreamscape and/or contribute to this Site as available for our use free of any obligations to you (including any payment), except where Invited Submissions are expressly governed by Additional Terms appearing elsewhere on this Site, in which event those Additional Terms will determine how we will treat your Invited Submissions. Under no circumstances will we pay you for the use of your ideas or submissions. You waive any and all rights and claims in connection with our consideration, use, or development of any product, content, or other materials similar or identical to your submission now or in the future.'
    }, {
      title: '14. USER CONTENT',
      preview: 'You grant Dreamscape a royalty-free, perpetual, non-exclusive, unrestricted license to your User Content.',
      body: (
        <React.Fragment>
          <span>Reviews, responses, profile entries, posts, or questions may not be able to be deleted once uploaded. For some of our features, other members may be able to request email notifications of your new public content or publish their own comments to your comments. We may use this public content to develop aggregate ratings, personalize site views, market products, or identify or feature popular members. By posting or uploading any content to this Site, including posts, responses, and/or providing any communication or material to Dreamscape (“User Content”), you automatically and irrevocably:</span>
          <br/><br/>
          <span>a. Grant and assign to Dreamscape a royalty-free, perpetual, non-exclusive, unrestricted, worldwide license to any and all rights in the User Content including without limitation all copyright, together with all consents (if any) necessary to enable its reproduction, distribution, modification, publishing, and/or other exploitation by Dreamscape and/or by any person authorized by Dreamscape, by any means and in all media now known or hereafter devised, without payment or other reference to you or any other person, and to advertise and promote such exploitation, for the full period of all such rights (together with any extensions and renewals) and insofar as possible in perpetuity;</span>
          <br/><br/>
          <span>b. Waive all moral rights in the User Content which may be available to you in any part of the world and confirm that no such rights have been asserted;</span>
          <br/><br/>
          <span>c. Appoint Dreamscape as your agent with full power to enter into any document and/or do any act Dreamscape may consider appropriate to confirm the grant and assignment, consent and waiver set out above;</span>
          <br/><br/>
          <span>d. Warrant that you are the owner of the User Content and entitled to enter into these Terms, and own or have the necessary rights, licenses, consents, and permissions to grant us the rights granted in this Section;</span>
          <br/><br/>
          <span>e. Confirm that no such User Content will be subject to any obligation, of confidence or otherwise, to you or any other person and that Dreamscape shall not be liable for any use or disclosure of such User Content.</span>
        </React.Fragment>
      )
    }, {
      title: '15. CLAIMS OF INFRINGEMENT',
      preview: (
        <React.Fragment>
          <span>If you believe that any content appearing on this Site infringes on your copyrights or any other intellectual property rights, we want to hear from you. Please email </span>
          <ColoredLink as={'a'} href={ !this.props.disableLinks ? "mailto:copyright@dreamscapevr.com" : undefined } style={{wordWrap: 'break-word'}}>copyright&#8203;@dreamscapevr.com.</ColoredLink>
        </React.Fragment>
      ),
      body: (
        <React.Fragment>
          <span>If you believe that any content appearing on this Site infringes your copyright rights, we at Dreamscape want to hear from you. Please forward the following information in writing to the Copyright Agent at the address listed below:</span>
          <br/><br/>
          <span>(a) Your name, address, telephone number, and email address;</span>
          <br/><br/>
          <span>(b) A description of the copyrighted work that you claim has been infringed;</span>
          <br/><br/>
          <span>(c) The exact URL or a description of each place where alleged infringing material is located;</span>
          <br/><br/>
          <span>(d) A statement by you that you have a good faith belief that the disputed use has not been authorized by you, your agent, or the law;</span>
          <br/><br/>
          <span>(e) Your electronic or physical signature or the electronic or physical signature of the person authorized to act on your behalf; and</span>
          <br/><br/>
          <span>(f) A statement by you that the information in your notice is accurate, and under penalty of perjury, that you are the copyright owner or authorized to act on behalf of the copyright owner.</span>
          <br/><br/>
          <span>Legal Department</span>
          <br/>
          <span>Dreamscape Immersive, Inc.</span>
          <br/>
          <span>8740 Washington Blvd.</span>
          <br/>
          <span>Suite B</span>
          <br/>
          <span>Culver City, CA 90232</span>
          <br/>
          <LinkText as={'a'} href={ !this.props.disableLinks ? 'tel:424-603-2900' : undefined }>424-603-2900</LinkText>
          <br/>
          <span>Email: <ColoredLink as={'a'} href={ !this.props.disableLinks ? "mailto:copyright@dreamscapevr.com" : undefined }>copyright@dreamscapevr.com</ColoredLink></span>
          <br/><br/>
          <span>Dreamscape seeks to preserve any and all exemptions from liability that may be available under the copyright law, but does not necessarily stipulate that it is a service provider as defined in USC section 512(c) or elsewhere.</span>
          <br/><br/>
          <span style={{fontWeight: 'bold'}}>Counter Notification</span>
          <br/><br/>
          <span>If you believe that your material has been removed in error in response to a copyright notification, you may submit a counter notification to our designated agent with the following written information:</span>
          <br/><br/>
          <span>(a) A physical or electronic signature;</span>
          <br/><br/>
          <span>(b) Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;</span>
          <br/><br/>
          <span>(c) A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and</span>
          <br/><br/>
          <span>(d) Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the Federal District Court for the judicial district in which the address is located, or if your address is outside of the U.S., for any judicial district in which Company may be found, and that you will accept service of process from the person who provided notification under subsection (c)(1)(C) or an agent of such person.</span>
          <br/><br/>
          <span>We will respond to counter notifications in accordance with the DMCA.</span>
        </React.Fragment>
      )
    }, {
      title: '16. SITE FEATURES',
      preview: 'The site offers features that are available to you in accordance with our privacy policy.',
      body: (
        <React.Fragment>
          <span>A. Wireless Features.</span>
          <br/><br/>
          <span>The Site may offer features that are available to you via your wireless device including the ability to access the Site’s features and upload content to the Site (collectively, “Wireless Features”).  By using the Site, you agree that Dreamscape may collect information related to your use of the Wireless Features as described in our Privacy Policy, and may change, alter, or modify the settings or configurations on your device in order to allow for or optimize your use of the Site.  Data rates and other carrier fees may apply. </span>
          <br/><br/>
          <span>B. Location-Based Features.</span>
          <br/><br/>
          <span>If you have location-based features on your wireless device, you acknowledge that your device location will be tracked and shared consistent with the Privacy Policy. You can terminate location tracking by us by adjusting the permissions in your device.  Location-based features are used at your own risk and location data may not be accurate.</span>
          <br/><br/>
          <span>C. Communications.</span>
          <br/><br/>
          <span>You can opt-out of receiving certain promotional e-mails from us at any time by following the instructions as provided in e-mails to click on the unsubscribe link, or contacting us here with the word UNSUBSCRIBE in the subject field of the e-mail. Your opt-out will not affect non-promotional e-mails, such as those about your account, transactions, servicing, or Dreamscape’s ongoing business relations.</span>
          <br/><br/>
          <span>Please note that any opt-out by you is limited to the e-mail address, device, or phone number used and will not affect subsequent subscriptions.</span>
        </React.Fragment>
      )
    },
    {
      title: '17. RESPONSIBLE USE OF SITE',
      preview: 'You may only use the Site for lawful purposes.',
      body: 'Please act responsibly when using this Site. You may only use this Site and its contents for lawful purposes and in accordance with applicable law and you are prohibited from storing, distributing, or transmitting any unlawful material through this Site. You may not collect or store personal information regarding other users. You recognize that storing, distributing or transmitting unlawful material could expose you to criminal and/or civil liability. You agree that if a third-party claims that material you have contributed to the Site is unlawful, you will bear the burden of establishing that it is lawful. You understand and agree that all materials publicly posted or privately transmitted on or through this Site are the sole responsibility of the sender, not Dreamscape, and that you are responsible for all material you upload, publicly post or otherwise transmit to or through this Site.'
    }, {
      title: '18. RELEASE',
      preview: 'You release us from any claims relating to third-parties.',
      body: 'If you have a dispute with one or more users, you release us (and our parent companies, affiliates and subsidiaries, and their respective officers, directors, employees, and agents) from claims, demands, liabilities, costs, or expenses and damages (actual and consequential of every kind and nature, known and unknown, arising out of or in any way connected with such disputes). In entering into this release, you expressly waive any protections (whether statutory or otherwise) to the extent permitted by applicable law that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.'
    }, {
      title: '19. INDEMNITY',
      preview: 'You will indemnify and hold Dreamscape harmless for material you place or transmit onto Dreamscape\'s servers.',
      body: (
        <React.Fragment>
          <span>You agree to indemnify and hold harmless Dreamscape and its officers, directors, employees, agents, distributors, and affiliates from and against any and all claims, demands, liabilities, costs or expenses, including reasonable attorney\'s fees, resulting from (i) your breach of these Terms, including any of the foregoing provisions, representations or warranties; (ii) your placement or transmission of any content onto Dreamscape\'s servers; (ii)  any and all use or misuse of your account or the Site; (iii) your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any governmental or quasi-governmental authorities; (v) your violation of the rights of any third party, including any intellectual property right, publicity, confidentiality, property, or privacy right; (vi) your use of any third party service in connection with the Site; or (vii) any misrepresentation made by you. Dreamscape reserves the right to assume, at your expense, the exclusive defense and control of any matter subject to indemnification by you. You agree to cooperate with Dreamscape’s defense of any claim. You will not in any event settle any claim without the prior written consent of Dreamscape.</span>
          <br/><br/>
          <span>This provision does not require you to indemnify us for any unconscionable commercial practice by us or for our fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the Site.</span>
        </React.Fragment>
      )
    }, {
      title: '20. PRODUCT SPECIFICATIONS',
      preview: 'We do not warrant that product specifications, pricing, or other content on the Site is error-free. In the event of any errors relating to the pricing or specifications, Dreamscape reserves the right to cancel orders.',
      body: 'We do our best to describe every product or service offered on this Site as accurately as possible. However, we are human, and therefore we do not warrant that product specifications, pricing, or other content on the Site is complete, accurate, reliable, current, or error-free. In the event of any errors relating to the pricing or specifications, Dreamscape shall have the right to refuse or cancel any orders in its sole discretion. If we charged your credit card or other account prior to our cancelling of your order, we will issue a credit to your account in the amount of the charge. Additional Terms may apply.'
    }, {
      title: '21. SYSTEM ABUSE',
      preview: 'You may not abuse the system.',
      body: 'Without limitation, you agree not to send, create, or reply to so called “email bombs” (i.e., emailing copies of a single message to many users, or sending large or multiple files or messages to a single user with malicious intent) or engage in “spamming” (i.e., unsolicited emailing for business or other purposes) or undertake any activity which may adversely affect the operation or enjoyment of this Site by any other person, including placing malware on the Site. You may not reproduce, sell, resell, or otherwise exploit any resource, or access to any resource, contained on this Site.'
    }, {
      title: '22. SECURITY',
      preview: 'Your usernames and passwords for this Site are for your use only and you are responsible for their security.',
      body: 'Any usernames and passwords used for this Site are for individual use only. You shall be responsible for the security of your username and password. Dreamscape shall be entitled to monitor your username and password and, at its discretion, require you to change it. If you use a username and password that Dreamscape considers insecure, Dreamscape will be entitled to require this to be changed and/or terminate your account. Please note that you are solely responsible for all activities that occur under your account, whether or not you authorized the activity, and we are not liable for any loss or damage to you or any third party arising from your failure to comply with any of the foregoing obligations.'
    }, {
      title: '23. VIOLATION OF SECURITY SYSTEMS',
      preview: 'You may not tamper with the system for this Site or the accounts of others.',
      body: 'You are prohibited from using any services or facilities provided in connection with this Site to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g., password guessing programs, cracking tools, malware, or network probing tools) is strictly prohibited. If you become involved in any violation of system security, Dreamscape reserves the right to release your details to system administrators at other sites, law enforcement, and/or governmental authorities in order to assist them in resolving security incidents.'
    }, {
      title: '24. INVESTIGATIONS',
      preview: 'Dreamscape reserves the right to investigate suspected violations of these Terms.',
      body: 'Dreamscape reserves the right to investigate suspected violations of these Terms, including without limitation, any violation arising from any submission or emails you make or send. Dreamscape may seek to gather information from the user who is suspected of violating these Terms and from any other user. Dreamscape may suspend any users whose conduct or content are under investigation and may remove such material from its servers, without notice, as it deems appropriate. If Dreamscape believes, in its sole discretion, that a violation of these Terms has occurred, it may edit or modify any submission, posting or emails, remove the material permanently, cancel postings, warn users, suspend users and passwords, terminate accounts, or take other corrective action it deems appropriate. Dreamscape will fully cooperate with any law enforcement authorities or court order requesting or directing Dreamscape to disclose the identity of anyone posting any emails, or publishing or otherwise making available any materials that are believed to violate these Terms. BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD HARMLESS ALL MEMBERS OF THE DREAMSCAPE GROUP FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY MEMBER OF THE DREAMSCAPE GROUP DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER A MEMBER OF THE DREAMSCAPE GROUP OR LAW ENFORCEMENT AUTHORITIES.'
    }, {
      title: '25. SERVICE',
      preview: 'In order to use this Site, you must obtain access to the World Wide Web and pay any related fees to access the Internet. We are not responsible for your access to the Site.',
      body: 'In order to use this Site, you must obtain access to the World Wide Web and possibly pay any service fee associated with that access. You will need to provide all equipment necessary to connect to the Site on the World Wide Web (including a computer, modem, mobile device, tablet, and/or other access devices).'
    }, {
      title: '26. RESERVATION OF RIGHTS',
      preview: 'Dreamscape may modify or discontinue this Site at any time.',
      body: 'Dreamscape reserves the right to modify or discontinue, temporarily or permanently, all or any part of this Site and/or any software, facilities, and services on this Site, with or without notice and/or to establish general guidelines and limitations on their use.'
    }, {
      title: '27. LOCAL REGULATIONS',
      preview: 'Dreamscape makes no representation that the Site is available for use outside of the United States.',
      body: (
        <React.Fragment>
          <span>Dreamscape makes no representation that Materials or other content on the Site are appropriate or available for use outside the United States, its territories, possessions, and protectorates. If you choose to access the Site from other locations, you do so on your own initiative and at your own risk.</span>
          <br/><br/>
          <span>You are responsible for complying with local laws, if and to the extent local laws are applicable. You specifically agree to comply with all applicable laws concerning the transmission of technical data exported from the United States or the country you reside in.</span>
        </React.Fragment>
      )
    }, {
      title: '28. THIRD-PARTY SITES',
      preview: 'We are not responsible for third-parties or their content, apps, or sites.',
      body: 'This Site may link you to other sites on the Internet, including our ticketing and payment partners (e.g., xola.com and stripe.com). You acknowledge that (whether or not such sites are affiliated in any way with Dreamscape) Dreamscape is not responsible for the accuracy, copyright compliance, privacy compliance, legality, decency, or any other aspect of the content of such sites. The inclusion of such a link does not imply endorsement of any site by Dreamscape or any association with its operators.'
    }, {
      title: '29. CHOICE OF LAW',
      preview: 'With the exception of Section 8 which will be construed in accordance with the FAA, California law will apply to these Terms.',
      body: 'With the exception of Section 8 which will be construed in accordance with the FAA, these Terms shall be governed by, construed and enforced in accordance with the laws of the State of California, as they are applied to agreements entered into and to be performed entirely within California.'
    }, {
      title: '30. NOTICE FOR CALIFORNIA USERS',
      preview: 'California users of the Site are entitled to consumer rights notice.',
      body: (
        <React.Fragment>
          <span>Under California Civil Code Section 1789.3, California users of the Site are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 400 R Street, Suite 1080, Sacramento, California 95814, or by telephone at <LinkText as={'a'} href={ !this.props.disableLinks ? 'tel:(916) 445-1254' : undefined }>(916) 445-1254</LinkText> or <LinkText as={'a'} href={ !this.props.disableLinks ? 'tel:(800) 952-5210' : undefined }>(800) 952-5210</LinkText>. Their website is located at: </span>
          <ColoredLink as={'a'} href={ !this.props.disableLinks ? "http://www.dca.ca.gov" : undefined }>http://www.dca.ca.gov</ColoredLink>
          <span>.</span>
        </React.Fragment>
      )
    }, {
      title: '31. HOW TO CONTACT US',
      preview: (
        <React.Fragment>
          <span>Dreamscape owns and operates this Site. You can contact us at </span>
          <ColoredLink as={'a'} href={ !this.props.disableLinks ? "mailto:customerservice@dreamscapevr.com" : undefined } style={{wordWrap: 'break-word'}}>customerservice&#8203;@dreamscapevr.com</ColoredLink><span>.</span>
        </React.Fragment>
      ),
      body: (
        <React.Fragment>
          <span>This Site is controlled and operated by Dreamscape located at 8740 Washington Blvd., Suite B, Culver City, CA 90232. Please forward any comments or complaints about the Site to </span>
          <ColoredLink as={'a'} href={ !this.props.disableLinks ? "mailto:customerservice@dreamscapevr.com" : undefined }>customerservice@dreamscapevr.com</ColoredLink>
          <span>. Please forward any questions regarding privacy to </span>
          <ColoredLink as={'a'} href={ !this.props.disableLinks ? "mailto:privacy@dreamscapevr.com" : undefined }>privacy@dreamscapevr.com</ColoredLink>
          <span> and other legal matters to </span>
          <ColoredLink as={'a'} href={ !this.props.disableLinks ? "mailto:legal@dreamscapevr.com" : undefined }>legal@dreamscapevr.com</ColoredLink><span>.</span>
        </React.Fragment>
      )
    }, {
      title: '32. GENERAL',
      preview: 'If any portion of this agreement is deemed unenforceable, the remaining provisions will survive.',
      body: 'If any provision of these Terms are adjudged, by written decision, to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this agreement and shall not affect the validity and enforceability of any remaining provisions. This is the entire agreement between you and Dreamscape relating to the matters contained here and the Site.'
    }
  ];

  renderTerms (mobile) {
    return (
      <Container>
        <TextContainer mobile={mobile}>
          <HeaderBlock mobile={mobile}>DREAMSCAPE TERMS OF USE</HeaderBlock>
          <Body>
            <Header>EFFECTIVE DATE: NOVEMBER 19, 2018</Header>
            <Text>Welcome! Thank you for visiting a Dreamscape Immersive, Inc. (“Dreamscape,” “we” or “us”) offering. To help inform you, we provide this notice explaining our online information practices and the choices you can make about the collection and use of information you submit or we collect through our website www.dreamscapeimmersive.com and our other related mobile websites, mobile applications (“mobile apps”), features, content, services, tools, and other applications (collectively, the “Site”). The Site is owned and operated by Dreamscape.</Text>
            <Header>CONTENTS & SUMMARY</Header>
            <Contents>
              <tbody>
              {this.content.map((row, i) => {
                return (
                  <ContentsRow key={i}>
                    <td><Header blue onClick={() => this.handleScrollToSection('section'+(i+1))}>{row.title}</Header></td>
                    <td><PaddedText>{row.preview}</PaddedText></td>
                  </ContentsRow>
                )
              })}
              </tbody>
            </Contents>
            {this.content.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <Header id={"section"+(i+1)}>{row.title}</Header>
                  <Text>{row.body}</Text>
                </React.Fragment>
              )
            })}
          </Body>
        </TextContainer>
      </Container>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Desktop>
          {this.renderTerms(false)}
        </Desktop>
        <Mobile>
          {this.renderTerms(true)}
        </Mobile>
      </React.Fragment>
    );
  }
}

const Terms = props => (
  <AuthContext.Consumer>
    { parentState => <TermsWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(Terms);
