import React from 'react';

export default () => (
  <svg width="127" height="13" viewBox="0 0 127 13" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <g fill="#FFFFFE">
            <path d="M123.856.584c.197 0 .39.05.578.152.188.1.334.246.439.434a1.19 1.19 0 0 1 .002 1.173 1.092 1.092 0 0 1-.434.435 1.19 1.19 0 0 1-1.17 0 1.097 1.097 0 0 1-.435-.435 1.186 1.186 0 0 1-.155-.584c0-.204.052-.4.158-.589.105-.188.252-.333.44-.434.187-.102.38-.152.577-.152zm0 .194a1 1 0 0 0-.482.127.99.99 0 0 0-.499.854.984.984 0 0 0 .981.98.988.988 0 0 0 .85-.493.989.989 0 0 0-.002-.978.904.904 0 0 0-.367-.363 1.002 1.002 0 0 0-.48-.127zm-.515 1.63V1.145h.434c.149 0 .256.012.322.035.067.024.12.064.16.123a.337.337 0 0 1-.04.427.39.39 0 0 1-.263.114c.045.019.08.041.108.067.05.05.113.134.186.251l.155.248h-.25l-.112-.2c-.088-.157-.159-.255-.213-.294a.262.262 0 0 0-.163-.044h-.12v.538h-.204zm.204-.711h.247c.119 0 .2-.018.242-.053a.172.172 0 0 0 .065-.14.17.17 0 0 0-.031-.1.186.186 0 0 0-.087-.067.641.641 0 0 0-.204-.021h-.232v.381z" fillRule="nonzero"/>
            <path d="M7.704 3.456v6.086h.841c2.157 0 3.31-1.389 3.31-3.043 0-1.655-1.153-3.043-3.324-3.043h-.827zM5 11.817V1.18h3.56c3.723 0 6.028 2.246 6.028 5.319 0 3.087-2.305 5.318-6.013 5.318H5zM20.93 3.456v2.703h1.359c.812 0 1.432-.384 1.432-1.36 0-.93-.635-1.343-1.447-1.343H20.93zm1.226-2.276c2.54 0 4.299 1.227 4.299 3.62 0 1.536-.784 2.555-1.95 3.072l2.142 3.945h-2.822L22.11 8.316H20.93v3.501h-2.645V1.18h3.87zM30.914 11.817V1.18h6.974v2.276h-4.27v1.89h3.65v2.157h-3.65v2.039h4.491v2.275h-7.195M47.538 8.375c-.369-.842-.797-1.891-1.004-2.541-.148.354-.724 1.861-1.02 2.54h2.024zM46.46 1.033h.133l5.289 10.784H49.06l-.753-1.625h-3.575l-.739 1.625h-2.822l5.29-10.784zM57.704 6.986l-.694-.576v5.407h-2.57V1.033h.058l5.185 4.712 5.186-4.712h.074v10.784h-2.6V6.41l-.68.576c-.665.635-1.315 1.27-1.98 1.891-.664-.62-1.314-1.256-1.98-1.891M96.146 8.375c-.37-.842-.798-1.891-1.005-2.541-.147.354-.724 1.861-1.019 2.54h2.024zm-1.079-7.342h.133l5.29 10.784h-2.823l-.753-1.625H93.34l-.739 1.625H89.78l5.288-10.784zM105.526 3.456v2.703h.975c.827 0 1.462-.4 1.462-1.36 0-.93-.65-1.343-1.477-1.343h-.96zm0 4.978v3.383h-2.704V1.18h3.546c2.556 0 4.329 1.212 4.329 3.62 0 2.437-1.773 3.634-4.33 3.634h-.841zM113.532 11.817V1.18h6.973v2.276h-4.27v1.89h3.65v2.157h-3.65v2.039h4.492v2.275h-7.195M74.807 3.947c-.298-.242-.949-.708-1.757-.712-.777-.004-1.25.457-1.2.907.074.688.583.774 1.897 1.214 1.45.485 2.582 1.319 2.581 3.139 0 1.506-.798 2.848-2.35 3.314-1.885.566-3.882.163-5.421-.928l1.172-2.204c.516.421 1.213 1.032 2.4 1.074 1.266.044 1.56-1.056 1.137-1.57-.535-.652-2.361-.779-3.39-1.625-1.007-.828-1.087-2.42-.629-3.551.481-1.188 1.607-2.052 3.384-2.044 1.568.006 2.744.572 3.265.85l-1.089 2.136M85.12 9.41c-1.591 0-2.881-1.17-2.881-2.899 0-1.728 1.29-2.9 2.881-2.9.946 0 1.782.46 2.307 1.168V1.512a5.824 5.824 0 0 0-2.494-.551 5.554 5.554 0 1 0 0 11.108c.898 0 1.744-.197 2.494-.551V8.243a2.869 2.869 0 0 1-2.307 1.168"/>
        </g>
    </g>
</svg>

);
