import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="117" height="140" viewBox="0 0 117 140">
    <g fill="none" fill-rule="evenodd">
      <g>
        <rect width="114" height="137" x="1.5" y="1.5" stroke="#2E3191" stroke-width="3" rx="5"/>
        <g fill="#2E3191">
          <path d="M4.486 0c2.209 0 4 1.79 4 4v.714h6.371c2.21 0 4 1.791 4 4v5.429h3.912c1.429 0 2.75.762 3.464 2l1.622 2.81c.291-.063.593-.096.902-.096 2.343 0 4.243 1.9 4.243 4.243 0 2.343-1.9 4.243-4.243 4.243-2.343 0-4.243-1.9-4.243-4.243 0-1.006.35-1.93.935-2.657l-1.715-2.972c-.179-.309-.509-.5-.866-.5h-4.116c.07.462.105.934.105 1.415 0 5.207-4.221 9.428-9.428 9.428C4.22 27.814 0 23.593 0 18.386c0-3.867 2.327-7.19 5.657-8.644V3.829c0-.553-.448-1-1-1H0V0h4.486zm4.943 11.786c-3.646 0-6.6 2.955-6.6 6.6 0 3.645 2.954 6.6 6.6 6.6 3.645 0 6.6-2.955 6.6-6.6 0-3.645-2.955-6.6-6.6-6.6zm19.328 9.9c-.78 0-1.414.633-1.414 1.414 0 .781.633 1.414 1.414 1.414.781 0 1.414-.633 1.414-1.414 0-.781-.633-1.414-1.414-1.414zM9.44 16.029c1.302 0 2.357 1.055 2.357 2.357 0 1.302-1.055 2.357-2.357 2.357-1.302 0-2.357-1.055-2.357-2.357 0-1.302 1.055-2.357 2.357-2.357zm5.59-8.486H8.486v1.46c.31-.03.624-.046.943-.046 2.57 0 4.899 1.028 6.6 2.695v-3.11c0-.551-.448-1-1-1z" transform="translate(25.000000, 9.000000)"/>
        </g>
        <g fill="#2E3191">
          <path d="M-3.151 10.435L28.021 10.435 28.021 14.435 -3.151 14.435z" transform="translate(78.500000, 22.500000) rotate(-90.000000) translate(-78.500000, -22.500000) translate(66.000000, 10.000000) translate(12.435029, 12.435029) rotate(-315.000000) translate(-12.435029, -12.435029)"/>
          <path d="M15 15L31 15 31 19 15 19z" transform="translate(78.500000, 22.500000) rotate(-90.000000) translate(-78.500000, -22.500000) translate(66.000000, 10.000000) translate(23.000000, 17.000000) rotate(-270.000000) translate(-23.000000, -17.000000)"/>
          <path d="M9 21L25 21 25 25 9 25z" transform="translate(78.500000, 22.500000) rotate(-90.000000) translate(-78.500000, -22.500000) translate(66.000000, 10.000000) translate(17.000000, 23.000000) rotate(-360.000000) translate(-17.000000, -23.000000)"/>
        </g>
        <text fill="#1B1B1B" fill-opacity=".9" font-family="Brother-1816-Bold, Brother 1816" font-size="16" font-weight="bold">
          <tspan x="8.248" y="127">ASSIGNMENT</tspan>
        </text>
        <text fill="#1B1B1B" font-family="Brother-1816-Bold, Brother 1816" font-size="45" font-weight="bold" letter-spacing="-1">
          <tspan x="17.677" y="91">MIB</tspan>
        </text>
        <path fill="#1B1B1B" fill-opacity=".25" fill-rule="nonzero" d="M6 106L6 105 111 105 111 106zM6 44L6 43 111 43 111 44z"/>
      </g>
    </g>
  </svg>
);