import React from "react";
import styled from "styled-components";

const MobileButtonFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
`;

const MobileSubmitBtnContainer = styled.div`
  padding: 22px;
  background-color: ${ props => props.theme.white_80 };
  border-top: 1px solid ${ props => props.theme.ds_dusk_blue }
`;

export default (props) => {
  return (
    <MobileButtonFooter>
      <MobileSubmitBtnContainer name="mobile-proceed-btn">
        {props.children}
      </MobileSubmitBtnContainer>
    </MobileButtonFooter>
  );
}
