import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton } from '../components/buttons';
import { BasicInputField as Input } from '../components/forms';
import CheckoutOrderDetails from '../components/CheckoutOrderDetails';
import { DIApi } from "../util/api";
import { AuthContext } from "../components/context/AuthenticatorContext";
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import LoadingSpinner from '../components/LoadingSpinner';

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${ props => props.mobile ? '20px' : '38px' };
`;

const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${ props => props.mobile ? '16px 22px 0 22px' : undefined };
  max-width: ${ props => props.mobile ? '360px' : '300px' };
  box-sizing: border-box;
  width: 100%;
 `;

const SectionTitle = styled.div`
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' };
  align-self: center;
  color: ${ props => props.theme.charcoal_90 };
  padding: ${ props => props.mobile ? '46px 22px 0 22px' : '60px 22px 0 22px' };
  text-align: ${ props => props.mobile ? 'center' : undefined };
  letter-spacing: -1px;
`;

const SectionSubTitle = styled.div`
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  align-self: center;
  max-width: ${ props => props.mobile ? '360px' : '428px' };
  padding: 4px 22px 0 22px;
  text-align: center;
  color: ${ props => props.theme.charcoal_60 };
  box-sizing: border-box;
`;

const StyledInput = styled(Input)`
  margin-bottom: 12px;
`;

const SmallText = styled.div`
  font-size: 0.8125rem;
  color: ${ props => props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal };
  cursor: ${ props => props.blue ? 'pointer' : undefined };
`;

const SmallTextEnd = styled(SmallText)`
  align-self: flex-end;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  box-sizing: border-box;
  max-width: ${ props => props.mobile ? '360px' : '300px' };
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 21px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21px 0 31px 0;
`;

const BtnDividerText = styled.div`
  font-size: 1rem;
  color: ${ props => props.theme.charcoal_60 };
  padding: 3px 12px;
`;

const BtnDividerLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${ props => props.theme.charcoal_60 };
`;

class CheckoutSignIn extends Component {
  state = {
    email: '',
    password: '',
    orderDetails: null,
    isSigningIn: false,
  };

  componentDidMount () {
    document.body.style.background = 'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';
    // This will return the header information back to its default if it has been changed.
    this.props.updateHeaderText({});
    this.handleRedirect();
  }

  componentDidUpdate () {
    this.handleRedirect();
  }

  componentWillUnmount () {
    document.body.style.background = 'black';
  }

  handleRedirect () {
    if (this.props.authState.isSignedIn) {
      this.navToPayment(true);
    }
  }

  handleInputChange = (element) => {
    const { name, value } = element.target;
    this.setState({
      [name]: value,
    });
  };

  handleUserSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    console.log('[CheckoutSignIn] -> (handleUserSignIn) Sign In Clicked');

    await this.setState({ isSigningIn: true });
    const signedIn = await DIApi.userSignIn(email, password);
    if (!signedIn) {
      // TODO: Handle displaying error message here
      await this.setState({ isSigningIn: false });
      return;
    }

    this.props.authState.handleAuthStateChange('signedIn');
    this.setState({ email: '', password: '' });

    this.navToPayment(true);
  };

  navToPayment = (shouldReplace) => {
    const locationState = this.props.location.state;

    if (shouldReplace) {
      return this.props.history.replace('/checkout/payment', locationState);
    }

    return this.props.history.push('/checkout/payment', locationState);
  };

  handleNavToPayment = (e) => {
    e.preventDefault();
    this.navToPayment();
  }

  handleNavToForgotPassword = () => {
    this.props.history.push('/checkout/signin/forgot-password/request', this.props.location.state);
  };

  navToSignup = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/checkout/signup', locationState);
  };

  renderSignInButton (isMobile) {
    const buttonContent = this.state.isSigningIn ? <LoadingSpinner/> : 'SIGN IN';

    return (
      <BlueButton width={isMobile ? '100%' : 300} type="submit" disabled={this.state.isSigningIn}>
        { buttonContent }
      </BlueButton>
    );
  }

  render () {
    const { orderDetails, adventure } = this.props.location.state;
    const pageDetails = {
      title: 'ACCOUNT SIGN IN',
      subTitle: 'Signing in or creating an account will make it easier when checking in for your adventure',
    }

    return (
      <Page>
        <Desktop>
          <SectionTitle>{pageDetails.title}</SectionTitle>
          <SectionSubTitle>{pageDetails.subTitle}</SectionSubTitle>
        </Desktop>
        <Mobile>
          <SectionTitle mobile>ACCOUNT<br/>SIGN IN</SectionTitle>
          <SectionSubTitle mobile>{pageDetails.subTitle}</SectionSubTitle>
        </Mobile>

        <Desktop>
          <Container>
            <CheckoutOrderDetails orderDetails={orderDetails} titleId={adventure.title_id} />

            <SignInForm onSubmit={this.handleUserSignIn}>
              <StyledInput
                autoFocus
                name="email"
                placeholder="Email"
                type="email"
                value={ this.state.email }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <StyledInput
                name="password"
                placeholder="Password"
                type="password"
                value={ this.state.password }
                onChange={ this.handleInputChange }
                width={'100%'}
              />

              <SmallTextEnd blue onClick={ this.handleNavToForgotPassword }>Forgot your password?</SmallTextEnd>

              <ButtonGroup>
                { this.renderSignInButton() }

                <Row>
                  <BtnDividerLine/>
                  <BtnDividerText>OR</BtnDividerText>
                  <BtnDividerLine/>
                </Row>

                <BlueButton width={300} onClick={ this.handleNavToPayment }>CONTINUE AS GUEST</BlueButton>
              </ButtonGroup>

              <Column>
                <SmallText blue onClick={ this.navToSignup }>Create an account &gt;</SmallText>
                <SmallText>Faster Check-out. Faster Check-in.</SmallText>
              </Column>
            </SignInForm>

          </Container>
        </Desktop>

        {/* MOBILE STYLING */}
        <Mobile>
          <Container mobile>

            <SignInForm mobile onSubmit={this.handleUserSignIn}>
              <StyledInput
                autoFocus
                name="email"
                placeholder="Email"
                type="email"
                value={ this.state.email }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <StyledInput
                name="password"
                placeholder="Password"
                type="password"
                value={ this.state.password }
                onChange={ this.handleInputChange }
                width={'100%'}
              />

              <SmallTextEnd blue onClick={ this.handleNavToForgotPassword }>Forgot your password?</SmallTextEnd>

              <ButtonGroup mobile>
                { this.renderSignInButton(true) }

                <Row>
                  <BtnDividerLine/>
                  <BtnDividerText>OR</BtnDividerText>
                  <BtnDividerLine/>
                </Row>

                <BlueButton width={'100%'} onClick={ this.handleNavToPayment }>CONTINUE AS GUEST</BlueButton>
              </ButtonGroup>

              <Column>
                <SmallText blue onClick={ this.navToSignup }>Create an account &gt;</SmallText>
                <SmallText>Faster Check-out. Faster Check-in.</SmallText>
              </Column>
            </SignInForm>
          </Container>
        </Mobile>
      </Page>
    );
  }
}

const SignIn = props => (
  <AuthContext.Consumer>
    { authState => {
      return (<CheckoutSignIn {...props} authState={authState} />);
    }}
  </AuthContext.Consumer>
);

export default withRouter(SignIn);
