import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile, Large, Phone } from './responsive/Breakpoints';
import styled from 'styled-components';
import ImgMission from '../assets/images/img-mission.svg';
import ImgCulture from '../assets/images/img-culture.svg';
import ImgInnovation from '../assets/images/img-innovation.svg';
import BlueButton from "./buttons/BlueButton";

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
  width: 100%;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${props => props.mobile ? '44px 22px 47px' : '61px 98px'};
  max-width: 1170px;
`;

const HeaderBlock = styled.div`
  margin: ${props => props.mobile ? '0 auto' : '18px auto 0'};
  font-family: ${props => props.theme.sans_serif};
  font-size: 1rem;
  font-weight: bold;
  line-height: ${props => props.mobile ? '1.33' : '1.09'};
  color: ${props => props.theme.charcoal};
  padding-bottom: 29px;
`;

const SubHeaderBlock = styled.div`
  max-width: 995px;
  margin: 0 auto ${props => props.mobile ? '' : '61px'};
  font-family: ${props => props.theme.serif};
  font-size: ${props => props.mobile ? '1.25rem' : '2.25rem'};
  line-height: ${props => props.mobile ? '1.29' : '1.25'};
  color: ${props => props.theme.charcoal_90};
`;

const WhyWorkReasons = styled.div`
  text-align: left;
  display: flex;
  flex-direction: ${props => props.mobile ? 'column' : 'row'};
  ${props => props.mobile ? 'padding-top: 48px; padding-bottom: 8px;' : ''}
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background: ${props => props.theme.charcoal_25};
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  background: url(${props => props.background});
  background-repeat: no-repeat;
  background-position-y: 50%;
`;

const Reason = styled.div`
  padding-bottom: ${props => props.mobile ? '40' : '50'}px;
  margin: ${props => !props.mobile && props.middle ? '0 60px' : undefined};
  vertical-align: top;
  flex: 1;
`;

const ReasonHeader = styled.div`
  margin: 6px 0 26px 0;
  font-family: ${props => props.theme.serif};
  font-size: ${props => props.mobile ? '1.375' : '2.25'}rem;
  line-height: ${props => props.mobile ? '1.29' : '1.19'};
  color: ${props => props.theme.charcoal_90};
`;

const ReasonBody = styled.div`
  font-family: ${props => props.theme.sans_serif};
  font-size: ${props => props.mobile ? '0.875rem' : '1rem'};
  line-height: ${props => props.mobile ? '1.33' : '1.33'};
  color: ${props => props.theme.charcoal_60};
`;

const JobOpeningButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
  z-index: 0;
`;

class CareersWhyWorkBlock extends Component {
  state = {};

  reasonBlock (mobile, header, body, image, isMiddle) {
    return (
      <Reason middle={isMiddle} mobile={mobile} key={header}>
        <ImageContainer background={image}/>
        <Divider/>
        <ReasonHeader mobile={mobile}>{header}</ReasonHeader>
        <ReasonBody mobile={mobile}>{body}</ReasonBody>
      </Reason>
    )
  }

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  renderWhyWorkBlock (mobile) {
    return (
      <React.Fragment>
        <HeaderBlock mobile={mobile}>WHY WORK AT DREAMSCAPE?</HeaderBlock>
        <SubHeaderBlock mobile={mobile}>Calling all bright minds, futurists and avatar-friendly people. If you’re ready
          to make an adventurous career move, Dreamscape wants to hear from you.</SubHeaderBlock>
        <WhyWorkReasons mobile={mobile}>
          {this.reasonBlock(mobile,
            'Mission',
            'Dreamscape brings together the emotional power of Hollywood storytelling, the visceral excitement of great theme-park rides, and extraordinary new technology. Join us in creating stories and worlds that push the limits of virtual reality.',
            ImgMission)}
          {this.reasonBlock(mobile,
            'Culture',
            'Each Dreamscape adventure is a shared experience, embracing the human desire to explore, learn and enjoy together. Our work culture champions the same values. We work hard as a team so that people can experience the awe and wonder that VR technology allows us to create. ',
            ImgCulture,
            true)}
          {this.reasonBlock(mobile,
            'Innovation',
            'Dreamscape is truly the birth of a new storytelling medium, giving people the opportunity to step into a story and watch it unfold around them as they explore cinematic worlds, characters and creatures never before thought possible. Bring your curiosity and innovation, and help us write the future.',
            ImgInnovation)}
        </WhyWorkReasons>
        <JobOpeningButtonContainer>
          <BlueButton
            width={'100%'}
            onClick={() => this.handleOpenLink('https://dreamscape-immersive.breezy.hr')}>JOB OPENINGS</BlueButton>
          {/*<BlueButton*/}
          {/*  style={{ marginTop: 24}}*/}
          {/*  width={'100%'}*/}
          {/*  onClick={() => this.handleOpenLink('https://sjobs.brassring.com/TGnewUI/Search/home/HomeWithPreLoad?PageType=JobDetails&partnerid=25572&siteid=5197&Areq=20752BR#jobDetails=1476086_5197')}>DALLAS AREA OPENINGS</BlueButton>*/}
        </JobOpeningButtonContainer>
      </React.Fragment>
    )
  }

  renderContainer (phone) {
    return (
      <TextContainer mobile={phone}>
        <Desktop>
          {this.renderWhyWorkBlock(false)}
        </Desktop>
        <Mobile>
          {this.renderWhyWorkBlock(true)}
        </Mobile>
      </TextContainer>
    )
  }

  render () {
    return (
      <Container id='why-work-here'>
        <Large>
          {this.renderContainer(false)}
        </Large>
        <Phone>
          {this.renderContainer(true)}
        </Phone>
      </Container>
    );
  }
}

export default withRouter(CareersWhyWorkBlock);
