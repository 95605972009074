import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="117" height="140" viewBox="0 0 117 140">
    <g fill="none" fill-rule="evenodd">
        <rect width="114" height="137" x="1.5" y="1.5" stroke="#2E3191" stroke-width="3" rx="5"/>
        <g fill="#2E3191">
            <path d="M66 32.172L88.042 10.13l2.828 2.828L68.828 35z"/>
            <path d="M75 10h16v4H75z"/>
            <path d="M87 26V10h4v16z"/>
        </g>
        <text fill="#1B1B1B" fill-opacity=".9" font-family="Brother-1816-Bold, Brother 1816" font-size="16" font-weight="bold">
            <tspan x="19.592" y="127">DRAGONS</tspan>
        </text>
        <text fill="#1B1B1B" font-family="Brother-1816-Bold, Brother 1816" font-size="45" font-weight="bold" letter-spacing="-1">
            <tspan x="10.86" y="91">DRG</tspan>
        </text>
        <path fill="#1B1B1B" fill-opacity=".25" fill-rule="nonzero" d="M6 106v-1h105v1zM6 44v-1h105v1z"/>
        <g fill="#2E3191">
            <path d="M30.714 11a4 4 0 0 1 4 4v.286h5.429a4 4 0 0 1 4 4v4.571h3.346a4 4 0 0 1 3.464 2l1.37 2.373a3.857 3.857 0 1 1-2.187 1.354l-1.534-2.655a1 1 0 0 0-.866-.5h-3.689a8.571 8.571 0 1 1-11.904-6.572V14.57a1 1 0 0 0-1-1H27V11h3.714zm4.857 10.714a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm17.572 9a1.286 1.286 0 1 0 0 2.572 1.286 1.286 0 0 0 0-2.572zM35.58 25.571a2.143 2.143 0 1 1 0 4.286 2.143 2.143 0 0 1 0-4.286zm4.99-7.714h-5.857v1.328a8.544 8.544 0 0 1 6.857 2.408v-2.736a1 1 0 0 0-1-1z"/>
        </g>
      </g>
  </svg>
);
