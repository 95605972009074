import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Route,
  withRouter
} from 'react-router-dom';

import CheckoutTicketSelector from './pages/checkout/amc/CheckoutTicketSelector';
import { AuthContext } from './components/context/AuthenticatorContext';
import CheckoutNavBar from './components/CheckoutNavbar';
import NavbarSpacer from './components/NavbarSpacer';

const CheckoutContainer = styled.div`
  width: 100%;
`;

class Checkout extends Component {
  state = {
    headerText: '',
  };

  updateHeaderText = (headerInfo) => {
    const { title } = headerInfo;
    const headerTitle = title || '';
    this.setState({
      headerText: headerTitle,
    });
  };

  render() {
    return (
      <CheckoutContainer>
        <CheckoutNavBar title={this.state.headerText} disableCancel onBack={ this.state.handleOnBackClicked }/>
        <NavbarSpacer height={48} />

        <Route exact path="/checkout/amc/tickets" render={() => (
          <CheckoutTicketSelector updateHeaderText={this.updateHeaderText}/>
        )}/>
      </CheckoutContainer>
    );
  }
}

const CheckoutController = props => (
  <AuthContext.Consumer>
    { parentState => <Checkout {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(CheckoutController);
