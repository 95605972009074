import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile, Large, Phone } from './responsive/Breakpoints';
import styled from 'styled-components';
import ImgShoes from '../assets/images/img-attire.svg';
import ImgAttire from '../assets/images/img-attire-clothes.svg';
import ImgPhotography from '../assets/images/img-photography.svg';
import ImgWheelchair from '../assets/images/img-wheelchair.svg';
import ImgLegal from '../assets/images/img-legal.png';
import imgKeepsakes from '../assets/images/img-keepsakes.svg';
import BlueButton from "./buttons/BlueButton";

const Container = styled.div`
  background: #fff;
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${ props => props.mobile ? '30px 22px' : '45px 98px 20px' };
  max-width: 1170px;
`;

const HeaderBlock = styled.div`
  padding-bottom: ${ props => props.mobile ? '27px' : '36px' };
  font-family: ${ props => props.theme.sans_serif };
  font-size: 1rem;
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' };
  color: ${ props => props.theme.charcoal };
`;

const SubHeaderBlock = styled.div`
  max-width: 700px;
  margin: 0 auto;
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.mobile ? '1.25rem' : '2.25rem' };
  line-height: ${ props => props.mobile ? '1.37' : '1.25' };
  color: ${ props => props.theme.charcoal_90 };
`;

const RulesTable = styled.table`
  width: 100%;
  text-align: left;
  table-layout: fixed;
  margin-top: ${ props => props.mobile ? '38' : '48' }px;
`;

const SpacerTd = styled.td`
  width: 22px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${ props => props.theme.charcoal_25 };
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  background: url(${props => props.background});
  background-repeat: no-repeat;
  background-position-y: 50%;
`;

const Rule = styled.td`
  padding-bottom: ${ props => props.mobile ? '22px' : undefined };
  vertical-align: top;
`;

const RuleHeader = styled.div`
  margin: 18px 0 18px 0;
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.mobile ? '1.375rem' : '2.25rem' };
  line-height: ${ props => props.mobile ? '1.64' : '1.19' };
  color: ${ props => props.theme.charcoal_90 };
`;

const RuleBody = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  line-height: 1.33;
  color: ${ props => props.theme.charcoal_60 };
`;

const FAQButton = styled(BlueButton)`
  margin: ${ props => props.mobile ? '57px 0 20px' : '76px 0 59px' };
  ${ props => props.mobile ? 'width: 100%;' : '' }
`;

class PlanAheadRulesBlock extends Component {
  state = {};

  ruleBlock(mobile, header, body, image) {
    return (
      <Rule key={header} mobile={mobile}>
        <ImageContainer background={image}/>
        <Divider/>
        <RuleHeader mobile={mobile}>{header}</RuleHeader>
        <RuleBody mobile={mobile}>{body}</RuleBody>
      </Rule>
    )
  }

  allocateBlocks(blocks, blocksPerRow, mobile) {
    let blockGroups = blocks;

    if (!mobile) {
      blockGroups = [];
      let temp = [];
      for (let i=0; i<blocks.length; i++) {
        if (temp.length > 0) {
          temp.push(<SpacerTd key={i}/>);
        }
        temp.push(blocks[i]);
        if ((i+1) % blocksPerRow === 0) {
          blockGroups.push(temp);
          temp = [];
        }
        if (i === blocks.length-1 && temp.length > 0) {
          for (let j=temp.length; j<blocksPerRow; j++) {
            temp.push(<SpacerTd key={'spacer'+i}/>);
            temp.push(<td key={'block'+i} style={{flex: '1'}}/>);
          }
          blockGroups.push(temp);
        }
      }
    }

    return blockGroups;
  }

  tableContent (mobile) {
    const blocks = [
      this.ruleBlock(mobile, 'Keepsakes & Provisions', 'Snag souvenirs from your journey at our Departure Lounge Mercantile. Non-alcoholic drinks are available in the Departure Lounge before and after your adventure (but not during your trip).', imgKeepsakes),
      this.ruleBlock(mobile, 'Shoes', 'You should wear comfortable shoes. High heels and flip-flops don\'t mix well with alternate realities.', ImgShoes),
      this.ruleBlock(mobile, 'Clothing', 'Dress for adventure. Long skirts or flared pants may get tangled up and/or interfere with sensors. ', ImgAttire),
      this.ruleBlock(mobile, 'Photography', (
        <div>
          <span>We encourage you to take lots of selfies in the Departure Lounge, however no photography, videography or recording is allowed inside the adventure. Don't forget to share with #DreamscapeVR!</span>
          {/*<br/><br/>*/}
          {/*<span>Photos and videos are not allowed inside the experience, but we encourage you to snap photos in the Lobby area. Don’t forget to share on social with #dreamscape!</span>*/}
        </div>
      ), ImgPhotography),
      this.ruleBlock(mobile, 'Accessibility', 'Guests who require a wheelchair must be able to self-transfer to one of Dreamscape\'s specially equipped wheelchairs.', ImgWheelchair),
      this.ruleBlock(mobile, 'Legal', (
        <div>
          <span>For DreamWorks Dragons: Flight Academy, you must be at least 8 years old and at least 44 inches tall. For all other adventures, travelers must be at least 48 inches tall and at least 10 years old.</span>
          <br/><br/>
          <span>Travelers under 13 years old must be accompanied by an adult with a paid ticket and must have their parent or guardian sign their waiver at check-in.</span>
        </div>
      ), ImgLegal),
    ];

    const blockGroups = this.allocateBlocks(blocks, 4, mobile);

    return (
      <React.Fragment>
        {blockGroups.map((blockGroup, i) => {
          return (
            <tr key={i}>
              {blockGroup}
            </tr>
          )
        })}
      </React.Fragment>
    )
  }

  renderRulesBlock (mobile) {
    return (
      <React.Fragment>
        <HeaderBlock mobile={mobile}>ADVENTURE GUIDELINES</HeaderBlock>
        <SubHeaderBlock mobile={mobile}>For your safety, and to ensure a fun and successful adventure, here are a few essential Dreamscape Guidelines:</SubHeaderBlock>
        <RulesTable mobile={mobile}>
          <tbody>
          {this.tableContent(mobile)}
          </tbody>
        </RulesTable>
        <FAQButton mobile={mobile} onClick={() => this.props.history.push('/faq')}>LEARN MORE IN OUR FAQS</FAQButton>
      </React.Fragment>
    )
  }

  renderContainer (phone) {
    return (
      <TextContainer mobile={phone}>
        <Desktop>
          {this.renderRulesBlock(false)}
        </Desktop>
        <Mobile>
          {this.renderRulesBlock(true)}
        </Mobile>
      </TextContainer>
    )
  }

  render () {
    return (
      <Container>
        <Large>
          {this.renderContainer(false)}
        </Large>
        <Phone>
          {this.renderContainer(true)}
        </Phone>
      </Container>
    );
  }
}

export default withRouter(PlanAheadRulesBlock);
