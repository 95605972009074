import Amplify, { Auth, API } from 'aws-amplify';
import { withOAuth, Authenticator } from 'aws-amplify-react';

import { RIYADH_LOCATION, GENEVA_LOCATION } from './constants';

const SERVICE_ENDPOINTS = {
  COMMON: '/common',
  TICKETS: '/tickets',
  ACCOUNT: '/account',
  ASSETS: '/assets',
};

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_COGNITO_ID_POOL,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_APP_CLIENT,

    // oauth: oauth
  },
  API: {
    endpoints: [
      {
        name: "DevCRMLinkAPI",
        endpoint: process.env.REACT_APP_API_DOMAIN
      }
    ]
  }
});

const apiGet = async ({path, headers, queryStringParameters}) => {
  let apiName = 'DevCRMLinkAPI';
  let myInit = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
      ...headers,
    },
    response: true,
  };

  if (queryStringParameters) {
    myInit.queryStringParameters = queryStringParameters;
  }

  try {
    const response = await API.get(apiName, path, myInit);
    return response;
  } catch (error) {
    console.debug('[api] -> (apiGet) Error Occurred:', error);
    throw error;
  }
};

const apiPost = async ({path, headers, queryStringParameters, body}) => {
  let apiName = 'DevCRMLinkAPI';
  let myInit = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
      ...headers,
    },
    body: {},
    response: true,
  };

  if (queryStringParameters) {
    myInit.queryStringParameters = queryStringParameters;
  }

  if (body) {
    myInit.body = body;
  }

  try {
    const response = await API.post(apiName, path, myInit);
    return response;
  } catch(error) {
    console.debug(error);
    throw error;
  }
};

class DIApi {
  static async getTitleAssets () {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.ASSETS}/type/titles`,
    };

    try {
      const assetsRes = await apiGet(apiConfig);
      return assetsRes.data.titles;
    } catch (e) {
      return console.log('[api] -> (getTitleAssets) Error Occurred:', e);
    }
  }

  static async getHomeAssets () {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.ASSETS}/type/home`,
    };

    try {
      const assetsRes = await apiGet(apiConfig);
      return assetsRes.data.home;
    } catch (e) {
      return console.log('[api] -> (getHomeAssets) Error Occurred:', e);
    }
  }

  static async getSiteAssets () {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.ASSETS}/type/sites`,
    };

    try {
      const assetsRes = await apiGet(apiConfig);
      return assetsRes.data.sites;
    } catch (e) {
      return console.log('[api] -> (getSiteAssets) Error Occurred:', e);
    }
  }

  static async getSites () {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.COMMON}/sites`,
    };

    try {
      const res = await apiGet(apiConfig);
      // combine sites from API with locations from constants, ensuring
      // the api locations are at the top of the list
      return [
        ...res.data,
        RIYADH_LOCATION,
        GENEVA_LOCATION,
      ];
    } catch(e) {
      console.debug('[api] -> (getSites) Error Occurred:', e);
      return;
    }
  }

  static async getTitles () {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.COMMON}/titles`,
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch(e) {
      console.debug('[api] -> (getTitles) Error Occurred:', e);
      return;
    }
  }

  static async getExperiencesBySite (siteId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/experiences/${siteId}`,
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getExperiencesBySite) Error Occurred:', e);
      return;
    }
  }

  static async resendReceipt(siteId, orderId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/order/email/xola/${siteId}/${orderId}`,
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (resendReceipt) Error Occurred:', e);
      return;
    }
  }

  static async holdTickets(siteId, expId, expStart, totalQuantity, adaQuantity) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/order/hold/xola`,
    };

    apiConfig.body = {
      "partner_site_id": siteId,
      "partner_experience_id": expId,
      "partner_experience_start": expStart,
      "total_quantity": totalQuantity,
      "ada_quantity": adaQuantity
    };

    try {
      const res = await apiPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('Found an error: ', e);
      return;
    }
  }

  static async releaseHeldTickets (partnerSiteId, orderId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/order/release/xola/${partnerSiteId}/${orderId}`,
    };

    try {
      const res = await apiPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (releaseHeldTickets) Error Occurred:', e);
      return;
    }
  }
  static async applyGiftCode(siteId, expId, expStart, totalQuantity, adaQuantity, codes){
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/order/pricing/xola/`,
    };
    apiConfig.body = {
      "partner_site_id": siteId,
      "partner_experience_id": expId,
      "partner_experience_start": expStart,
      "total_quantity": totalQuantity,
      "ada_quantity": adaQuantity,
      "codes": codes,
    };
    try {
      const res = await apiPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (giftCode) Error Occurred:', e);
      throw e;
    }
  }

  static async purchaseTickets(purchaseData) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/order/purchase/xola`,
      body: purchaseData,
    };

    try {
      const res = await apiPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (purchaseTickets) Error occurred:', e);
      throw e;
    }
  }

  static async checkOrderStatus (partnerSiteId, orderId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/order/purchase/xola/status/${partnerSiteId}/${orderId}`,
    };

    try {
      const res = await apiGet(apiConfig);
      // just return the axios response instead of parsing out the data so we have access to the http status code
      return res;
    } catch (e) {
      console.debug('[api] -> (checkOrderStatus) Error occurred:', e);
      throw e;
    }
  }

  static async getAvailableShowtimesForExperience (siteId, expId, startDate, endDate, ada=false) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/experiences/availability/${siteId}/${expId}?ada=${ada}&show_private=true`,
    };

    if (startDate && endDate) {
      apiConfig.queryStringParameters = {
        startdate: startDate,
        enddate: endDate,
      };
    }

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getAvailableShowtimesForExperience) Error Occurred:', e);
      return;
    }
  }

  static async getEstimatedPriceForExperience(siteId, expId, expStart){
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/event/pricing/xola`,
    };
    apiConfig.body = {
      "partner_site_id": siteId,
      "partner_experience_id": expId,
      "partner_experience_start": expStart,
    };
    try {
      const res = await apiPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (pricing) Error occurred:', e);
      return;
    }
  }

  static async getEstimatedPriceForAMCExperience(siteId, expId, expStart){
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.TICKETS}/event/pricing/amc/${expId}`,
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    };
    apiConfig.body = {
      tickets: 1,
    };
    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (pricing) Error occurred:', e);
      return;
    }
  }

  static async changePassword(user, oldPassword, newPassword) {
    try {
      return await Auth.changePassword(user, oldPassword, newPassword);
    } catch (e) {
      console.debug('[api] -> (changePassword) Error Occurred:', e);
      throw e;
    }
  }

  static async getUserOrders () {
    const jwtToken = (await Auth.currentSession()).idToken.jwtToken;
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.ACCOUNT}/history/orders`,
      headers: {
        Authorization: jwtToken,
      },
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getUserOrders) Error Occurred:', e);
      return;
    }
  }

  static async getUserWaivers () {
    const jwtToken = (await Auth.currentSession()).idToken.jwtToken;
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.ACCOUNT}/waivers/valid`,
      headers: {
        Authorization: jwtToken,
      },
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getUserOrders) Error Occurred:', e);
      return;
    }
  }

  static async userSignIn (username, password) {
    try {
      const signinData = await Auth.signIn(username, password);
      return signinData;
    } catch (e) {
      console.debug('[api] -> (userSignIn) Error Occurred:', e);
      return;
    }
  }

  static async userSignOut () {
    try {
      const signoutData = await Auth.signOut();
      return signoutData;
    } catch (e) {
      console.debug('[api] -> (userSignOut) Error Occurred:', e);
      return;
    }
  }

  static async userSignUp (username, password, firstName, lastName, birthdate, phone_number=null) {
    try {
      const attributesData = {
        given_name: firstName,
        family_name: lastName,
        birthdate,
      };
      if (phone_number) {
        attributesData['phone_number'] = phone_number;
      }
      const signupData = await Auth.signUp({
        username,
        password,
        attributes: attributesData,
      });
      return signupData;
    } catch (e) {
      console.debug('[api] -> (userSignIn) Error Occurred:', e);
      throw e;
    }
  }

  static async userEdit (user, firstName, lastName, birthdate, phone_number="") {
    try {
      const editData = await Auth.updateUserAttributes(user, {
        given_name: firstName,
        family_name: lastName,
        birthdate,
        phone_number
      });
      return editData;
    } catch (e) {
      console.debug('[api] -> (userEdit) Error Occurred:', e);
      throw e;
    }
  }

  static async getCurrentUser () {
    try {
      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      return currentUser;
    } catch (e) {
      console.debug('[api] -> (getCurrentUser) Error Occurred:', e);
      return;
    }
  }

  static async requestForgotPasswordVerification (username) {
    try {
      const forgotPasswordResponse = await Auth.forgotPassword(username);
      return forgotPasswordResponse;
    } catch (e) {
      console.debug('[api] -> (requestForgotPasswordVerification) Error Occurred:', e);
      throw e;
    }
  }

  static async submitForgotPasswordRequest (username, code, newPassword) {
    try {
      const resetPasswordResponse = await Auth.forgotPasswordSubmit(username, code, newPassword);
      return resetPasswordResponse;
    } catch (e) {
      console.debug('[api] -> (submitForgotPasswordRequest) Error Occurred:', e);
      throw e;
    }
  }
}

export {
  DIApi,
  Authenticator,
  Auth,
  withOAuth,
}
