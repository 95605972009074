import React from 'react';
import theme from '../../util/theme';

export default (props) => {
  let color = theme.white_70;

  if (props.active) {
    color = theme.ds_bright_blue;
  } else if (props.color) {
    color = props.color;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="36" viewBox="0 0 26 36">
      <path fill="#FFF" fillRule="evenodd" d="M13 0c7.18 0 13 5.556 13 12.41 0 4.426-4.067 12.03-12.202 22.813a1 1 0 0 1-1.596 0l-.385-.51C3.94 24.2 0 16.765 0 12.41 0 5.556 5.82 0 13 0zm0 7.222a4.333 4.333 0 1 0 0 8.667 4.333 4.333 0 0 0 0-8.667z"/>
    </svg>

  );
};
