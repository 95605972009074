import React, { Component } from 'react';
import styled from 'styled-components';

const BasicInputFieldContainer = styled.div`
  display: flex;
  width: ${ props => {
    const { width } = props;
    if (!width) {
      return '280px';
    }
  
    if (typeof width === 'number') {
      return `${width}px`;
    }
    
    if (typeof width === 'string') {
      return width;
    }
    
    // If none of the above; default
    return '280px';
  }};
  padding: ${ props => {
    const { padding } = props;
    if (!padding) {
      return;
    }

    if (typeof padding === 'string') {
      return padding;
    }
  }};
  margin: ${ props => {
    const { margin } = props;
    if (!margin) {
      return;
    }

    if (typeof margin === 'string') {
      return margin;
    }
  }};
  flex: ${ props => props.flex };
  box-sizing: border-box;
`;

const BasicInputField = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 43px;
  // height: 15px;
  border: solid 1px ${ props => props.theme.ds_bright_blue_50 };
  padding: 14px;
  font-family: ${ props => props.theme.sans_serif };
  font-size: 0.8125rem;
  color: ${ props => props.dark ? 'white' : props.theme.charcoal };
  transition: ${ props => props.theme.transition };
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  
  &::placeholder {
    color: ${ props => props.dark ? props.theme.white_70 : props.theme.black_30 };
  }
  
  &:hover {
    border: 3px solid ${ props => props.theme.ds_bright_blue };
    padding: 12px;
  }
  
  &:focus {
    border: 1px solid ${ props => props.theme.ds_bright_blue };
    padding: 14px;
  }
  
  &:disabled, :read-only {
    border: 1px solid ${ props => props.theme.charcoal_25 };
    padding: 14px;
  }
`;

const BasicInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ props => {
    if (props.focused) {
      return props.theme.ds_bright_blue;
    }
    
    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

export default class InputField extends Component {
  state = {
    focused: false,
  };

  handleFocusChange = (isFocused) => {
    this.setState({ focused: isFocused });
  };

  render () {
    const {
      width,
      height,
      flex,
      margin,
      padding,
      ...inputProps
    } = this.props;

    const containerProps = {
      width,
      height,
      flex,
      margin,
      padding,
    }

    return (
      <BasicInputFieldContainer {...containerProps}>
        <BasicInputLabel focused={this.state.focused} dark={this.props.dark}>
          {this.props.label || this.props.placeholder}
          <BasicInputField onBlur={() => {
                             this.handleFocusChange(false);
                             if (this.props.onBlur) {
                               this.props.onBlur();
                             }
                           }}
                           onFocus={() => {
                             this.handleFocusChange(true);
                             if (this.props.onFocus) {
                               this.props.onFocus();
                             }
                           }}
                           ref={ ref => this.inputRef = ref }
                           {...inputProps}
          />
        </BasicInputLabel>
      </BasicInputFieldContainer>
    );
  }
}
