import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import BlueButton from '../../components/buttons/BlueButton';
import { Desktop, Mobile } from '../../components/responsive/Breakpoints';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 22px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  font-size: ${ props => props.isMobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: ${ props => props.isMobile ? '1.33' : '1.09' };
  color: ${ props => props.theme.charcoal };
  text-align: center;
  margin-top: 76px;
`;

const HeaderSubTitle = styled.div`
  display: flex;
  font-size: ${ props => props.isMobile ? '0.875' : '1' }rem;
  color: ${ props => props.theme.charcoal_60 };
  max-width: 326px;
  padding: 10px 22px 0px 22px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  align-self: center;
  align-items: center;
  margin: ${ props => props.isMobile ? '95px 0 0 0' : '30px 0' };
`;

class ForgotPasswordConfirmation extends Component {
  renderContent (isMobile) {
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle isMobile={isMobile}>NEW PASSWORD SAVED!</HeaderTitle>
          <HeaderSubTitle isMobile={isMobile}>You can use this new password to sign into your account.</HeaderSubTitle>
        </HeaderContainer>

        <ContentContainer isMobile={isMobile}>
          <BlueButton width="100%" onClick={ () => this.props.history.replace(`${this.props.path}`, this.props.location.state) }>PROCEED TO SIGN IN</BlueButton>
        </ContentContainer>
      </React.Fragment>
    );
  }

  render () {
    return (
      <Container name="forgot-password-confirmation-container">
        <Column>
          <Desktop>{ this.renderContent() }</Desktop>
          <Mobile>{ this.renderContent(true) }</Mobile>
        </Column>
      </Container>
    );
  }
}

export default withRouter(ForgotPasswordConfirmation);
