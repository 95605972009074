import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { scroller } from "react-scroll/modules";
import { Desktop, Mobile } from "./responsive/Breakpoints";
import CareersBackground_2x from "../assets/images/bg-careers@2x.jpg";
import CareersBackgroundSmall_2x from "../assets/images/img-careersbg-s@2x.jpg";
import CareersBackgroundSmall_3x from "../assets/images/img-careersbg-s@3x.jpg";

import DownArrowIcon from "./svgs/DownArrowIcon";
import theme from "../util/theme";
import WhiteButton from "./buttons/WhiteButton";
import { AuthContext } from "./context/AuthenticatorContext";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";

const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  text-align: center;
  padding: 0 21px;
`;

const Header = styled.div`
  color: #fff;
  font-family: ${props => props.theme.sans_serif};
  font-size: ${props => props.mobile ? '1.625' : '3.5'}rem;
  line-height: ${props => props.mobile ? '1.33' : 'normal'};
  font-weight: bold;
  letter-spacing: ${props => props.mobile ? 'normal' : '2px'};
  max-width: 1170px;
  box-sizing: border-box;
  padding: ${props => props.mobile ? '0px' : '0 22px'};
  line-height: ${props => props.mobile ? 'normal' : '1.25'};
  margin-bottom: ${props => props.mobile ? '6vh' : '10vh'};
`;

const Container = styled.div`
  height: calc(100vh - ${props => props.toasterOffset}px);
  width: 100%;
  position: relative;
`;

const AnchorNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 10;
  pointer-events: none;
`;

const Anchor = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.white_25};
  border-radius: 3px;
  color: white;
  pointer-events: all; 
  cursor: pointer; 
  margin-bottom: ${props => props.marginTop || '10vh'};
  padding: 10px;
`;

const JobOpeningButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 220px;
  z-index: 0;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const BackgroundImage = styled.img`
  height: auto;
  width: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
`;

class CareersHeroBlockWithContext extends Component {
  scrollPage = (id, val, offset) => {
    scroller.scrollTo(id, {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset,
    });
  };

  handleScrollToNextSection = (toasterOffset) => {
    this.scrollPage('why-work-here',
      document.getElementById('home').offsetHeight,
      -document.getElementById('navbar').offsetHeight - toasterOffset);
  };

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  render () {
    const toasterOffset = this.props.parentState.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    const laJobOpeningsButtonText = "JOB OPENINGS";
    // const dallasJobOpeningsButtonText = "DALLAS AREA OPENINGS";

    return (
      <Container id="home" toasterOffset={toasterOffset}>
        <Desktop>
          <ContentContainer>
            <ImageContainer>
              <BackgroundImage src={CareersBackground_2x}
                               srcSet={`${CareersBackground_2x} 2x`} alt="Careers Hero BG"/>
            </ImageContainer>
            <Header mobile={false}>JOIN US IN MAKING VIRTUAL DREAMS A REALITY.</Header>
            <JobOpeningButtonContainer>
              <WhiteButton
                width={'100%'}
                onClick={() => this.handleOpenLink('https://dreamscape-immersive.breezy.hr')}>{laJobOpeningsButtonText}</WhiteButton>
              {/*<WhiteButton*/}
              {/*  style={{ marginTop: 24 }}*/}
              {/*  width={'100%'}*/}
              {/*  onClick={() => this.handleOpenLink('https://sjobs.brassring.com/TGnewUI/Search/home/HomeWithPreLoad?PageType=JobDetails&partnerid=25572&siteid=5197&Areq=20752BR#jobDetails=1476086_5197')}>{dallasJobOpeningsButtonText}</WhiteButton>*/}
            </JobOpeningButtonContainer>
          </ContentContainer>
        </Desktop>
        <Mobile>
          <ContentContainer>
            <ImageContainer>
              <BackgroundImage src={CareersBackgroundSmall_2x}
                               srcSet={`${CareersBackgroundSmall_2x} 2x, ${CareersBackgroundSmall_3x} 3x`}
                               alt="Careers Hero BG"/>
            </ImageContainer>
            <Header mobile={true}>JOIN US IN MAKING VIRTUAL DREAMS A REALITY.</Header>
            <JobOpeningButtonContainer mobile={true}>
              <WhiteButton
                width={'100%'}
                onClick={() => this.handleOpenLink('https://dreamscape-immersive.breezy.hr')}>{laJobOpeningsButtonText}</WhiteButton>
              {/*<WhiteButton*/}
              {/*  style={{ marginTop: 24 }}*/}
              {/*  width={'100%'}*/}
              {/*  onClick={() => this.handleOpenLink('https://sjobs.brassring.com/TGnewUI/Search/home/HomeWithPreLoad?PageType=JobDetails&partnerid=25572&siteid=5197&Areq=20752BR#jobDetails=1476086_5197')}>{dallasJobOpeningsButtonText}</WhiteButton>*/}
            </JobOpeningButtonContainer>
          </ContentContainer>
        </Mobile>

        <AnchorNav onClick={() => this.handleScrollToNextSection(toasterOffset)}>
          <Anchor>
            <DownArrowIcon color={theme.white_80}/>
          </Anchor>
        </AnchorNav>
      </Container>
    )
  }
}

const CareersHeroBlock = props => (
  <AuthContext.Consumer>
    {parentState => <CareersHeroBlockWithContext {...props} parentState={parentState}/>}
  </AuthContext.Consumer>
);

export default withRouter(CareersHeroBlock);
