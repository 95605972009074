import React from 'react';
import Helmet from 'react-helmet';

const METADATA_MAP = {
  HOME: {
    title: 'Dreamscape - A Virtual Reality Experience Like No Other',
    description: 'Experience an exciting lineup of fully immersive virtual reality adventures. Step into virtual worlds and transform into the hero of your own VR experience.',
    keywords: 'Dreamscape,Virtual Reality,VR,dreamscape,Dreamscape Immersive,virtual reality near me,virtual reality movie,alien zoo,the blu,lavan magic projector',
  },
  ADVENTURES: {
    title: 'Dreamscape - Virtual Worlds Beyond Imagination | VR Experience',
    description: 'Book a virtual reality adventure today and be transported to fantastical places you couldn\'t imagine. Explore virtual worlds, interact with characters and creatures, and get ready for a breathtaking ride.',
    keywords: 'Dreamscape,Virtual Worlds,VR Experience,dreamscape,Dreamscape Immersive,virtual reality near me,virtual reality movie,alien zoo,the blu,lavan magic projector',
  },
  PRESS: {
    title: 'Dreamscape - Press',
    description: 'Exciting things are happening at Dreamscape. Contact our press team with any inquiries and check out our latest press coverage',
    keywords: 'Dreamscape,Virtual Reality,VR,dreamscape,Dreamscape Immersive,virtual reality near me,virtual reality movie,alien zoo,the blu,lavan magic projector',
  },
  ABOUT_US: {
    title: 'Dreamscape - An Immersive Virtual Reality Experience',
    description: 'Dreamscape adventures are a shared VR experience, embracing the human desire to explore, learn and enjoy together.',
    keywords: 'Dreamscape,Virtual Reality,Immersive,Immersive Experience,dreamscape,Dreamscape Immersive,virtual reality near me,virtual reality movie,alien zoo,the blu,lavan magic projector',
  },
  FIND_US: {
    title: 'Dreamscape - A Virtual Reality Experience Like No Other',
    description: 'Experience an exciting lineup of fully immersive virtual reality adventures. Step into virtual worlds and transform into the hero of your own VR experience.',
    keywords: 'Dreamscape,Virtual Reality,VR,dreamscape,Dreamscape Immersive,virtual reality near me,virtual reality movie,alien zoo,the blu,lavan magic projector',
  },
  PLAN_AHEAD: {
    title: 'Dreamscape - Get Ready for your VR Experience',
    description: 'Welcome to Dreamscape. If you\'ve never adventured with us before, here are a few things you might want to know about your upcoming virtual reality experience.',
    keywords: 'Dreamscape,VR Experience,VR,dreamscape,Dreamscape Immersive,virtual reality near me,virtual reality movie,alien zoo,the blu,lavan magic projector',
  },
  ALIENZOO01: {
    title: 'Alien Zoo - Visit Virtual Worlds | Dreamscape',
    description: 'For the first time ever, the Intergalactic Wildlife Federation is inviting humans to visit the Alien Zoo. Come face-to-face with endangered species from across the galaxy on a breathtaking virtual reality tour.',
    keywords: 'Alien Zoo,Virtual Worlds,Dreamscape,VR,Dreamscape Immersive,virtual reality near me,virtual reality movie',
  },
  MAGICPROJECTOR01: {
    title: 'Curse of the Lost Pearl - Visit Virtual Worlds | Dreamscape',
    description: 'Leap into a daring Virtual Reality adventure. Enter a jungle, where a powerful stone lies hidden inside a perilous ancient temple. Do you have the wits, luck and courage to complete your mission and make it out alive?',
    keywords: 'Curse of the lost Pearl,Virtual Worlds,Dreamscape,VR,Dreamscape Immersive,virtual reality near me,virtual reality movie',
  },
  DEEPBLURESCUE01: {
    title: 'The Blu: Deep Rescue - Visit Virtual Worlds | Dreamscape',
    description: 'Climb into your virtual reality divers suit, power up your AquaScooter, and propel through the wonders of the ocean. Encounter astonishing marine life as you search for the most magnificent living creatures: blue whales.',
    keywords: 'The Blu Deep Rescue, Virtual Worlds, Dreamscape,VR,Dreamscape Immersive,virtual reality near me,virtual reality movie',
  },
  DRAGONS01: {
    title: 'DreamWorks Dragons Flight Academy - Visit Virtual Worlds | Dreamscape',
    description: 'Enlist in Dragons Flight Academy and earn your wings on a free-flying rescue mission to the Hidden World.',
    keywords: 'DreamWorks, Dragons Flight Academy, Virtual Worlds, Dreamscape,VR,Dreamscape Immersive,virtual reality near me,virtual reality movie',
  },
  MENINBLACK01: {
    title: 'MIB First Assignment - Visit Virtual Worlds | Dreamscape',
    description: 'Suit up, agent. First assignment: Save the Universe.',
    keywords: 'MIB First Assignment, Virtual Worlds, Dreamscape,VR,Dreamscape Immersive,virtual reality near me,virtual reality movie',
  },
  // Los Angeles | Westfield Century City
  US000001: {
    title: 'Dreamscape - Virtual Reality | Los Angeles, CA',
    description: 'Now Boarding - Book a virtual reality adventure today and be transported to fantasy worlds you couldn\'t imagine at the Westfield Century City mall.',
    keywords: 'Dreamscape, Virtual Reality, Los Angeles,VR,Dreamscape Immersive,virtual reality near me,virtual reality movie',
  },
};

const HeaderMeta = (props) => {
  if (!props.page) {
    return null;
  }

  // If no data is found with supplied "page" prop, default to the home pages info
  const metaData = METADATA_MAP[props.page.toUpperCase()] || METADATA_MAP.HOME;

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description}/>
      <meta name="keywords" content={metaData.keywords}/>
    </Helmet>
  );
};

export default HeaderMeta;
