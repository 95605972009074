import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module';

import 'react-dynamic-swiper/lib/styles.css';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import registerServiceWorker from './registerServiceWorker';

import Root from './Root';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_CONTAINER_ID,
});

ReactDOM.render(
  <CookiesProvider>
    <Root />
  </CookiesProvider>,
  document.getElementById('root')
);
registerServiceWorker();
