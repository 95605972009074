import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Large, Phone } from '../components/responsive/Breakpoints';
import styled from 'styled-components';
import NavSpacer from '../components/NavbarSpacer';
import ArrowDown from '../assets/images/btn-arrow-down.svg';
import { scroller } from "react-scroll/modules";
import { AuthContext } from "../components/context/AuthenticatorContext";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";

const Container = styled.div`
  background: #fff;
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  padding: ${props => props.mobile ? '6px 22px 56px 22px' : '77px 22px 119px 22px'};
  max-width: 1170px;
  margin: 0 auto;
`;

const HeaderText = styled.div`
  font-family: ${props => props.theme.sans_serif};
  font-size: ${props => props.mobile ? '1.625rem' : '2.75rem'};
  font-weight: bold;
  line-height: ${props => props.mobile ? '1.31' : '1.09'};
  color: ${props => props.theme.charcoal};
  ${props => props.mobile ? 'text-align: center;' : ''}
  margin-bottom: 86px;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: ${props => props.mobile ? 'column' : 'row'};
  text-align: left;
  padding-top: ${props => props.mobile ? '30' : '20'}px;
`;

const QuestionsSection = styled.div`
  flex: ${props => props.mobile ? '1' : '3'};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.charcoal_25};
  margin: 20px 0;
`;

const UnstyledLink = styled.a`
  color: ${props => props.theme.ds_dusk_blue};
  cursor: pointer;
  text-decoration: none;
`;

const Question = styled.div`
  font-size: ${props => props.mobile ? '0.875' : '1'}rem;
  font-weight: bold;
  color: ${props => props.theme.charcoal_90};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

const Answer = styled.div`
  font-size: ${props => props.mobile ? '0.875' : '1'}rem;
  padding: ${props => props.mobile ? '20px 0 0 16px' : '20px 0 0 40px'};
  color: ${props => props.theme.charcoal_60};
  height: ${props => props.displayBool ? 'auto' : '0'};
  overflow: hidden;
  padding-top: ${props => props.displayBool ? '20px' : '0'};
`;

const ArrowImage = styled.img`
  transform: ${props => props.displayBool ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

class FAQWithContext extends Component {
  DREAMSCAPE_CLOSING = [
    {
      question: 'Questions About an Outstanding Gift Card Balance',
      answer: (
        <React.Fragment>
          <div>If you have a gift card with an outstanding balance to our store at Westfield Century City, you
          can mail the card to the following address:</div>
          <br/>
          <div>Dreamscape Immersive. Inc.</div>
          <div>c/o Legal Department</div>
          <div>5730 Buckingham Parkway</div>
          <div>Culver City, CA 90230</div>
          <br/>
          <div>We will check the balance and mail a check to the address and individual designated in your
          mail communication. You can send the gift card and check information by standard postage or
          via Certified Mail. Dreamscape is not responsible for any gift cards that are lost in transit.
          Dreamscape will not be providing any reimbursements for the cost of mailing the gift cards.</div>
        </React.Fragment>
      ),
    },
    {
      question: 'Media and Press Inquiries',
      answer: (
        <React.Fragment>
          Please contact <UnstyledLink href='mailto:info@dreamscapelearn.com'>info@dreamscapelearn.com</UnstyledLink>
        </React.Fragment>
      )
    },
    {
      question: 'Ticketing for Dreamscape Geneva',
      answer: (
        <React.Fragment>
          Tickets and Information at <UnstyledLink href='https://dreamscapegeneva.com/'>https://dreamscapegeneva.com/</UnstyledLink>
        </React.Fragment>
      )
    },
    {
      question: 'Ticketing for Dreamscape Riyadh',
      answer: (
        <React.Fragment>
          Tickets and Information at <UnstyledLink href='https://www.dreamscapemena.com/'>https://www.dreamscapemena.com/</UnstyledLink>
        </React.Fragment>
      )
    },
    {
      question: 'General Questions and Business Inquiries',
      answer: (
        <React.Fragment>
          Please contact <UnstyledLink href='mailto:info@dreamscapelearn.com'>info@dreamscapelearn.com</UnstyledLink>
        </React.Fragment>
      )
    },
  ];

  state = {
    displayQuestions: {},
    shouldResetPage: false,
  };

  componentDidMount() {
    const navbar = document.getElementById('navbar');
    const shouldScrollHealth = this.props.location.state ? this.props.location.state.fromHealthFooter : false;

    this.setState({ navbar, shouldScrollHealth });
  }

  componentDidUpdate(prevProps) {
    const currentStamp = this.props.location.state ? this.props.location.state.stamp : null;
    const prevStamp = prevProps.location.state ? prevProps.location.state.stamp : null;
    const shouldScrollHealth = this.props.location.state ? this.props.location.state.fromHealthFooter : false;
    const shouldResetPage = this.props.location.state ? this.props.location.state.resetPage : false;

    if ((this.state.shouldScrollHealth !== shouldScrollHealth || this.state.resetPage !== shouldResetPage) && currentStamp > prevStamp) {
      this.setState({ shouldScrollHealth, shouldResetPage });
    }

    if (this.state.shouldScrollHealth) {
      this.handleSelectHealthAndSafetyFromNav();
    }

    if (this.state.shouldResetPage) {
      this.handleResetPage();
    }
  }

  handleResetPage() {
    this.setState({
      displayQuestions: {},
      shouldScrollHealth: false,
      shouldResetPage: false,
    });
  }

  toggleQuestion(questionId) {
    const displayQuestions = this.state.displayQuestions;
    displayQuestions[questionId] = !displayQuestions[questionId];
    this.setState({ displayQuestions });
  }

  scrollPage = (sectionId, offset) => {
    scroller.scrollTo(sectionId, {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset,
    });
  };

  handleScrollToSection = (sectionId) => {
    const toasterOffset = this.props.parentState.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    this.scrollPage(sectionId, -this.state.navbar.offsetHeight - toasterOffset);
  };

  selectSection = (event) => {
    const section = event.target.value;
    this.handleScrollToSection(section);
  };

  renderContentBlock(mobile) {

    let questionsSection = this.DREAMSCAPE_CLOSING.map((question, index) => {
      const questionKey = question.question + index;
      return (
        <React.Fragment key={questionKey}>
          <Question mobile={mobile} onClick={() => this.toggleQuestion(questionKey)}>{question.question}
            <ArrowImage displayBool={this.state.displayQuestions[questionKey]} src={ArrowDown} alt="Caret" />
          </Question>
          <Answer mobile={mobile} displayBool={this.state.displayQuestions[questionKey]}>{question.answer}</Answer>
          <Divider />
        </React.Fragment>
      )
    });

    return (
      <React.Fragment>
        <QuestionsSection mobile={mobile}>
          {questionsSection}
        </QuestionsSection>
      </React.Fragment>
    )
  }

  renderWelcomeBlock(mobile) {
    return (
      <Container>
        <NavSpacer />
        <TextContainer mobile={mobile}>
          <HeaderText mobile={mobile}>FREQUENTLY ASKED QUESTIONS</HeaderText>
          <ContentBlock mobile={mobile}>
            {this.renderContentBlock(mobile)}
          </ContentBlock>
        </TextContainer>
      </Container>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Large>
          {this.renderWelcomeBlock(false)}
        </Large>
        <Phone>
          {this.renderWelcomeBlock(true)}
        </Phone>
      </React.Fragment>
    );
  }
}

const FAQ = props => (
  <AuthContext.Consumer>
    {parentState => <FAQWithContext {...props} parentState={parentState} />}
  </AuthContext.Consumer>
);

export default withRouter(FAQ);
