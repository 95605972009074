import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton } from '../components/buttons';
import { BasicInputField as Input } from '../components/forms';
import { DIApi } from "../util/api";
import { AuthContext } from "../components/context/AuthenticatorContext";
import {Desktop, Mobile} from "../components/responsive/Breakpoints";
import Checkbox from "../components/forms/Checkbox";
import LoadingSpinner from "../components/LoadingSpinner";
import Moment from "moment";
import {parsePhoneNumber} from "libphonenumber-js";

import imgBenefit1 from "../assets/images/img-benefit-1.svg";
import imgBenefit2 from "../assets/images/img-benefit-2.svg";
import imgBenefit3 from "../assets/images/img-benefit-3.svg";
import imgBenefit4 from "../assets/images/img-benefit-4.svg";
import PhoneInputField from "../components/forms/PhoneInputField";
import { sendCreateAccountEvent } from '../util/analytics';

const Container = styled.div`
  padding: 0 ${ props => props.mobile ? '' : '85px' };
  max-width: 1170px;
  margin: 0 auto;
`;

const HalfInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  > label {
    display: inline-block;
    flex: unset;
  };
`;

const StyledHalfInput = styled(Input)`
  margin-bottom: 15px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '1.875rem' : '2.75rem' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.2' : '1.09' };
  color: ${ props => props.theme.charcoal };
`;

const PasswordRules = styled.span`
  padding: 0 0 15px;
  font-family: ${ props => props.theme.sans_serif };
  font-size: 0.81rem;
  font-style: italic;
  color: ${ props => props.theme.charcoal_60 };
`;

const CheckFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0 27px;
`;

const NonShrinkingFlex = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const CheckBoxText = styled.div`
  flex: 1;
  margin: auto 0;
  font-family: ${ props => props.theme.sans_serif };
  font-size: 0.75rem;
  color: ${ props => props.theme.charcoal_60 };
`;

const PrivacyTermsLink = styled.span`
  cursor: pointer;
  text-decoration: none;
  color: ${ props => props.theme.ds_dusk_blue };
`;

const SmallText = styled.div`
  font-size: 0.8125rem;
  color: ${ props => props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal };
  align-self: flex-end;
  cursor: ${ props => props.blue ? 'pointer' : undefined };
`;

const Row = styled.div`
  left: 0;
  display: flex;
  flex-direction: row;
  padding-top: ${ props => props.mobile ? '33' : '42' }px;
`;

const Divider = styled.div`
  margin-top: 33px;
  height: ${ props => props.mobile ? '1' : '2' }px;
  background-color: ${ props => props.theme.charcoal_25 };
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: ${ props => props.mobile ? 'column' : 'row' };
  padding-top: ${ props => props.mobile ? '13' : '39' }px;
  padding-bottom: ${ props => props.mobile ? '90' : '43' }px;
`;

const HorizontalSpacer = styled.div`
  width: 110px;
`;

const FormContainer = styled.div`
  ${ props => props.mobile ? 'padding-top: 33px;' : 'width: 0;' }
  flex: 1;
`;

const ReasonsContainer = styled.div`
   flex: 1;
`;

const Reason = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
`;

const ReasonImgContainer = styled.div`
  min-width: 50px;
  padding-right: 20px;
  text-align: center;
`;

const ReasonText = styled.div`
  font-size: ${ props => props.mobile ? '0.875' : '1' }rem;
  font-family: ${ props => props.theme.sans_serif };
  color: ${ props => props.theme.charcoal_60 };
`;

const StyledBlueButton = styled(BlueButton)`
  &:disabled {
    background-color: ${ props => props.theme.charcoal_25 };
    color: ${ props => props.theme.white_25 };
    cursor: initial;
    box-shadow: none;
    border: 0;
  }
`;

const FinePrint = styled.div`
  font-size: .75rem;
  color: ${ props => props.theme.charcoal_60 };
`;

class AccountSignUp extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    readTermsOfUse: false,
    subscribedToDreamscape: false,
    unblock: () => {},
    isSigningUp: false,
  };

  handleInputChange = (element) => {
    const { name, value } = element.target;
    this.setState({
      [name]: value,
    });
  };

  handleUserSignUp = async () => {
    if (!this.state.readTermsOfUse) {
      alert("You must read the Terms of Use and the Privacy Policy before signing up.");
      return;
    }
    const { email, password, confirmPassword, firstName, lastName, dob, phone } = this.state;

    if(firstName.length <= 0){
      alert("First name is required to create an account.");
      return;
    }
    if(lastName.length <= 0){
      alert("Last name is required to create an account.");
      return;
    }

    if(!dob || dob.length <= 0){
      alert("Date of birth is required to create an account.");
      return;
    }
    if (Moment().diff(dob, 'years') < 13) {
      alert("You must be at least 13 years of age to create an account.");
      return;
    }

    if (Moment().diff(dob, 'years') > 100) {
      alert('An invalid date of birth was entered. Please check the date and try again.');
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    if (password.length < 8) {
      alert("Password is too short. Passwords must be at least 8 characters long.");
      return;
    }
    if (password === password.toLowerCase()) {
      alert("Password must contain an uppercase letter.");
      return;
    }
    if (password === password.toUpperCase()) {
      alert("Password must contain a lowercase letter.");
      return;
    }
    if (password.match(/\d+/) === null) {
      alert("Password must contain a number.");
      return;
    }

    this.setState({isSigningUp: true});
    try {
      const apiArgs = [email.toLowerCase(), password, firstName, lastName, dob];
      if (phone) {
        const phoneNumber = parsePhoneNumber(phone, 'US');
        if (!phoneNumber.isValid()) {
          throw new Error("Invalid phone number entered.");
        }
        const phoneFormatted = phoneNumber.format('E.164');
        apiArgs.push(phoneFormatted);
      }
      await DIApi.userSignUp(...apiArgs);
      // Send analytics event
      sendCreateAccountEvent();
    } catch (e) {
      if (e.message === "NOT_A_NUMBER" || e.message === "TOO_LONG") {
        alert("You must enter a valid phone number format.");
      } else if (typeof e === 'string') {
        alert(e);
      } else {
        alert(e.message);
      }
      this.setState({isSigningUp: false});
      return;
    }

    this.setState({ email: '', password: '' });
    this.navToSignIn(true);
  };

  toggleTerms = () => {
    this.setState({readTermsOfUse: !this.state.readTermsOfUse})
  };

  toggleSubscribe = () => {
    this.setState({subscribedToDreamscape: !this.state.subscribedToDreamscape})
  };

  renderCreateAccountButton = () => {
    const buttonContent = this.state.isSigningUp ? <LoadingSpinner/> : 'CREATE ACCOUNT';

    return (
      <StyledBlueButton style={{margin: '12px 0 0'}} width={'100%'} disabled={this.state.isSigningUp || !this.state.readTermsOfUse} onClick={ this.handleUserSignUp }>
        {buttonContent}
      </StyledBlueButton>
    )
  };

  navToSignIn = (newAccount) => {
    const locationState = this.props.location.state;
    this.props.history.push('/account/signin', {...locationState, newAccount: newAccount});
  };

  renderSignUpPage (mobile) {
    const accountTitle = 'CREATE YOUR FREE DREAMSCAPE ACCOUNT TO CHECK IN FASTER';

    return (
      <Container mobile={mobile}>
        <Title mobile={mobile}>{accountTitle}</Title>
        <Row mobile={mobile}>
          <SmallText>Already have an account?&nbsp;</SmallText>
          <SmallText blue onClick={ () => this.navToSignIn(false) }>Sign in here &gt;</SmallText>
        </Row>
        <Divider mobile={mobile}/>
        <BottomSection mobile={mobile}>
          <ReasonsContainer>
            <Reason>
              <ReasonImgContainer>
                <img
                  style={{width: '34px', height: '35px'}}
                  src={imgBenefit4} alt=""/>
              </ReasonImgContainer>
              <ReasonText mobile={mobile}>
                <b>Check in faster.</b> Save your info, preferences and waiver agreement to jumpstart your next adventure.
              </ReasonText>
            </Reason>
            <Reason>
              <ReasonImgContainer>
                <img
                  style={{width: '50px', height: '23px'}}
                  src={imgBenefit2} alt=""/>
              </ReasonImgContainer>
              <ReasonText mobile={mobile}>
                <b>Be the first to know.</b> Stay in the loop on upcoming adventures, special events and promotions.
              </ReasonText>
            </Reason>
            <Reason>
              <ReasonImgContainer>
                <img
                  style={{width: '32px', height: '39px'}}
                  src={imgBenefit3} alt=""/>
              </ReasonImgContainer>
              <ReasonText mobile={mobile}>
                <b>Log your adventures.</b> Become a frequent traveler and view your account history.
                <FinePrint>
                  *not available at all locations
                </FinePrint>
              </ReasonText>
            </Reason>
            <Reason>
              <ReasonImgContainer>
                <img
                  style={{width: '37px', height: '40px'}}
                  src={imgBenefit1} alt=""/>
              </ReasonImgContainer>
              <ReasonText mobile={mobile}>
                <b>Join the Dreamscape Community.</b> Be the first to test out new experiences, get access to special events and score exclusive merch.
              </ReasonText>
            </Reason>
          </ReasonsContainer>
          <HorizontalSpacer/>
          <FormContainer mobile={mobile}>
            <HalfInputContainer>
              <StyledHalfInput
                name="firstName"
                placeholder="First Name"
                value={ this.state.firstName }
                width={'100%'}
                onChange={ this.handleInputChange }
              />
              <HorizontalSpacer style={{width: '8%'}}/>
              <StyledHalfInput
                name="lastName"
                placeholder="Last Name"
                value={ this.state.lastName }
                width={'100%'}
                onChange={ this.handleInputChange }
              />
            </HalfInputContainer>
            <StyledInput
              width={'100%'}
              name="email"
              placeholder="Email"
              type="email"
              value={ this.state.email }
              onChange={ this.handleInputChange }
            />
            <StyledInput
              width={'100%'}
              name="dob"
              placeholder="Date of Birth"
              type="date"
              value={ this.state.dob }
              onChange={ this.handleInputChange }
            />
            <PhoneInputField
              width={'100%'}
              style={{marginBottom: '15px'}}
              placeholder="Phone Number (Optional)"
              value={ this.state.phone }
              changeCallback={ (newValue) => this.setState({ phone: newValue }) }/>
            <StyledInput
              width={'100%'}
              name="password"
              placeholder="Password"
              type="password"
              value={ this.state.password }
              onChange={ this.handleInputChange }
              style={{marginBottom: '0'}}
            />
            <PasswordRules>Use 8 or more characters with an uppercase letter, a lowercase letter, and a number.</PasswordRules>
            <StyledInput
              width={'100%'}
              padding={'30px 0'}
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              value={ this.state.confirmPassword }
              onChange={ this.handleInputChange }
              style={{marginBottom: '0'}}
            />
            <CheckFlex>
              <NonShrinkingFlex>
                <Checkbox checked={ this.state.readTermsOfUse }
                          onClick={ this.toggleTerms }
                />
              </NonShrinkingFlex>
              <CheckBoxText>I have read and agree to Dreamscape Immersive's&nbsp;
                <PrivacyTermsLink onClick={() => this.props.history.push('/terms')}>Terms of Use</PrivacyTermsLink>
                &nbsp;and&nbsp;
                <PrivacyTermsLink onClick={() => this.props.history.push('/privacy')}>Privacy Policy</PrivacyTermsLink>.
              </CheckBoxText>
            </CheckFlex>

            {this.renderCreateAccountButton()}
          </FormContainer>
        </BottomSection>

      </Container>
    );
  }

  render () {
    return (
      <React.Fragment>
        <Desktop>{this.renderSignUpPage(false)}</Desktop>
        <Mobile>{this.renderSignUpPage(true)}</Mobile>
      </React.Fragment>
    )
  }
}

const SignUp = props => (
  <AuthContext.Consumer>
    { authState => <AccountSignUp {...props} authState={authState} /> }
  </AuthContext.Consumer>
);

export default withRouter(SignUp);
