import React from 'react';

export default (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g transform="rotate(180 16 16)">
          <path stroke="#1B1B1B" strokeWidth="2" d="M20.255 25.824l-9.412-9.412L20.255 7"/>
        </g>
      </g>
    </svg>
  );
};
