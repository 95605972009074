import React from "react";

const LoadingSpinner = (props) => (
  <div className="spinner" id={props.dark ? undefined : "inContinueButton"}>
    <div className="rect1"/>
    <div className="rect2"/>
    <div className="rect3"/>
    <div className="rect4"/>
    <div className="rect5"/>
  </div>
);

export default LoadingSpinner;
