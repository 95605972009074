import React, { Component } from 'react';
import styled from 'styled-components';
import {
  withRouter
} from 'react-router-dom';
import Moment from 'moment';

import { DIApi } from '../util/api';
import DSLogo from '../assets/images/global-assets-logo-logo-stacked-black.svg';
import NowBoarding from '../components/svgs/NowBoarding';
import CornerArrowSvg from '../assets/images/img-corner-arrow.svg';
import BottomBarSvg from '../assets/images/img-bars.svg';
import DSHorizontalLogo_2x from '../assets/images/img-logo-horizontal@2x.jpg';
import DSHorizontalLogo_3x from '../assets/images/img-logo-horizontal@3x.jpg';
import { CURRENCY_SYMBOL } from '../util/constants';

const ReceiptPage = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: scroll;
  font-weight: 300;
`;

const ReceiptContainer = styled.div`
  width: 20cm;
  height: 26.3cm;
  margin: 0 auto;
  border-collapse: collapse;
`;

const TopRow = styled.div`
  height: 50%; //13.2cm;
  width: 100%;
  border-bottom: 1px solid lightgray;
`;

const BottomRow = styled.div`
  height: 50%; //13.2cm;
  width: 100%;
`;

const TopLeftBox = styled.div`
  position: relative;
  height: 100%;
  width: 50%;
  border-right: 1px solid lightgray;
  border-bottom: 0;
  float: left;
`;

const CornerArrowContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.75cm;
`;

const TopRightBox = styled.div`
  height: 100%;
  width: 50%;
  margin-left: 50%;
`;

const BottomLeftBox = styled.div`
  width: 50%;
  height: 100%;
  float: left;
  border-right: 1px solid lightgray;
`;

const BottomRightBox = styled.div`
  height: 100%;
  width: 50%;
  margin-left: 50%;
`;

const ReceiptDetailsContainer = styled.div`
  width: 3in;
  margin: 0 auto;
  height: 100%;
`;

const ReceiptDetailsSectionContainer = styled.div`
  height: 50%;
`;

const QRCodeImg = styled.img`
  width: 2.2cm;
  height: 2.2cm;
`;

const TopRightTitle = styled.div`
  color: ${ props => props.theme.ds_dusk_blue };
  font-weight: lighter;
  font-size: 16pt;
  text-align: center;
  letter-spacing: 2.5px;
  padding-top: 1.25in;
`;

const TopRightDetails = styled.div`
  color: ${ props => props.theme.ds_dusk_blue };
  font-size: 10pt;
  letter-spacing: -0.5px;
  text-align: center;
  margin: 0.3in 0;
`;

const SmallFadedText = styled.div`
  font-size: 12px;
  color: lightgray;
  padding-top: 0.3cm;
`;

const InstructionsTextContainer = styled.div`
  color: ${ props => props.theme.charcoal };
  text-align: center;
  padding: 0 1.25cm;
`;

const InstructionsTitle = styled.div`
  font-size: 16pt;
  padding-top: 0.6cm;
  color: ${ props => props.theme.charcoal };
`;

const InstructionsText = styled.div`
  font-size: 10pt;
  padding-top: 0.4cm;
  line-height: 1.33;
  color: ${ props => props.theme.charcoal_60 };
`;

const InstructionsSmallTitle = styled.div`
  font-size: 12pt;
  padding-top: 0.4cm;
  color: ${ props => props.theme.charcoal };
`;

/*
 *  Starting new Table styling for the "form" look for ticket details
 */
const ReceiptDetailsTable = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const DetailsTableColumnLabel = styled.td`
  font-size: 7pt;
  font-weight: 900;
  color: ${ props => props.theme.ds_dusk_blue };
  vertical-align: bottom;
  line-height: 6pt;
  height: 100%;
  width: 1.55cm;
  padding-right: 0.05cm;
  ${ props => props.includesADA ? 'padding-bottom: 0.45cm;' : '' }
`;

const DetailsTableColumnValue = styled.td`
  font-size: 8pt;
  padding: 0.55cm 0.1cm 0.15cm 0.1cm;
  margin-left: 0.2cm;
  border-bottom: 1px solid ${ props => props.theme.ds_dusk_blue };
  color: ${ props => props.theme.ds_dusk_blue };
  vertical-align: ${ props => props.includesADA ? 'top' : 'bottom' };
`;

const CustomDetailsTableColumnValue = styled.td`
  font-size: 8pt;
  margin-left: 0.2cm;
  vertical-align: bottom;
  color: ${ props => props.theme.ds_dusk_blue };
`;

const CustomDetailsTableLabelContainer = styled.div`
  border-bottom: 1px solid ${ props => props.theme.ds_dusk_blue };
  padding: 0.55cm 0.1cm 0.15cm 0.1cm;
  margin-bottom: 0.50cm;
`;

const DuskBlueTextSpan = styled.span`
  color: ${ props => props.theme.ds_dusk_blue };
`;

const CustomerTextDiv = styled.div`
  padding: 1.7cm 0.5cm 0 0.5cm;
  font-size: 9pt; 
  text-align: center;
  line-height: 1.4;
  color: ${ props => props.theme.charcoal_60 };
`;

const CopyrightText = styled.div`
  padding: 0.2cm 0 0 0;
  font-size: 6pt;
  text-align: center;
  color: ${ props => props.theme.charcoal_60 };
`;

class PrintReceipt extends Component {
  state = {
    printableReceiptData: null,
  };

  async componentWillMount () {
    const { siteId, orderId } = this.props.match.params;
    try {
      const purchaseRes = await DIApi.checkOrderStatus(siteId, orderId);
      const purchaseData = purchaseRes.data;

      if (!purchaseData || !Object.keys(purchaseData).length) {
        return console.log('[PrintReceipt] -> (componentWillMount) No data received', purchaseData);
      }

      const arrivalDateMoment = Moment.parseZone(purchaseData.arrival.arrival_datetime);
      const boardingTimeFormat = arrivalDateMoment.format('h:mmA').replace('M', '');
      const boardingDateFormat = arrivalDateMoment.format('ddd, MMM DD, YYYY').toUpperCase();

      const generalTickets = purchaseData.breakdown.filter(x => x.type==='general-admission');
      const adaTickets = purchaseData.breakdown.filter(x => x.type==='ada');
      const curSymbol = purchaseData.currency ? CURRENCY_SYMBOL[purchaseData.currency] || '$' : '$';

      const printableReceiptData = {
        location: purchaseData.ticketed_site.site_name.toUpperCase(),
        boardingDate: boardingDateFormat,
        time: boardingTimeFormat,
        pod: purchaseData.ticketed_pod.pod_number,
        ticketNum: generalTickets.length,
        ticketNumADA: adaTickets.length,
        totalCost: `${curSymbol}${purchaseData.pricing['general-admission'].toFixed(2)}`,
        totalCostADA: `${curSymbol}${purchaseData.pricing['ada'].toFixed(2)}`,
        bookingNum: purchaseData.partner_short_code,
        customerEmail: purchaseData.email,
        qrCodeUri: `data:image/png;base64, ${purchaseData.partner_order_qrcode_image_b64}`,
        experienceName: purchaseData.ticketed_title.shortname.toUpperCase(),
        experienceAbrv: purchaseData.ticketed_title.tlc,
      };

      this.setState({
        printableReceiptData,
      });
    } catch (err) {
      console.log('[PrintReceipt] -> (componentWillMount) error', err);
    }
  }

  render () {
    if (!this.state.printableReceiptData) {
      return <ReceiptPage name="receipt-page"></ReceiptPage>;
    }

    const {
      location,
      boardingDate,
      time,
      pod,
      ticketNum,
      ticketNumADA,
      totalCost,
      totalCostADA,
      bookingNum,
      customerEmail,
      qrCodeUri,
      experienceAbrv,
      experienceName,
    } = this.state.printableReceiptData;

    const includesADA = ticketNumADA > 0 && ticketNum > 0;

    let ticketBreakdown = null;

    if (ticketNumADA > 0 && ticketNum > 0) {
      ticketBreakdown = (
        <React.Fragment>
          {ticketNum} X GA {totalCost}
          <br/>
          {ticketNumADA} X ACC {totalCostADA}
        </React.Fragment>
      );
    } else if (ticketNum > 0) {
      ticketBreakdown = (
        <React.Fragment>
          { ticketNum } X GA { totalCost }
        </React.Fragment>
      )
    } else if (ticketNumADA > 0) {
      ticketBreakdown = (
        <React.Fragment>
          { ticketNumADA } X ACC { totalCostADA }
        </React.Fragment>
      )
    }

    return (
      <ReceiptPage name="receipt-page">
        <ReceiptContainer name="receipt-container">
          <TopRow name="top-row">
            <TopLeftBox name="top-left-box">
              <CornerArrowContainer>
                <img style={{ width: '0.4cm' }} src={CornerArrowSvg} alt={'Corner arrow'} />
              </CornerArrowContainer>
              <ReceiptDetailsContainer name="top-left-box-details-container">
                <ReceiptDetailsSectionContainer name="top-left-box-details-section-container-top">
                  <div style={{ textAlign: 'center' }}>
                    <img width="110" src={DSLogo} alt={'Dreamscape Immersive logo'}/>
                  </div>
                  <div style={{ textAlign: 'center', margin: '0.5cm 0 0.4cm 0' }}>
                    <NowBoarding/>
                  </div>

                  <div style={{ textAlign: 'center', fontSize: '56pt', fontWeight: '900', lineHeight: '0.8', height: 68 }}>{ experienceAbrv }</div>
                  <div style={{ textAlign: 'center', fontSize: '14pt' }}>{ experienceName }</div>
                </ReceiptDetailsSectionContainer>

                <ReceiptDetailsSectionContainer name="top-left-box-details-section-container-bottom">
                  <ReceiptDetailsTable>
                    <tbody>
                      {/* Location row */}
                      <tr>
                        <DetailsTableColumnLabel>LOCATION</DetailsTableColumnLabel>
                        <DetailsTableColumnValue>{ location }</DetailsTableColumnValue>
                      </tr>
                    </tbody>
                  </ReceiptDetailsTable>

                  {/* Boarding Details row */}
                  <ReceiptDetailsTable>
                    <tbody>
                      <tr>
                        <DetailsTableColumnLabel>BOARDING</DetailsTableColumnLabel>
                        <DetailsTableColumnValue style={{ width: 'auto' }}>{ boardingDate }</DetailsTableColumnValue>
                        <DetailsTableColumnLabel style={{ width: 'auto', padding: '0 0.1cm' }}>TIME</DetailsTableColumnLabel>
                        <DetailsTableColumnValue>{ time }</DetailsTableColumnValue>
                        <DetailsTableColumnLabel style={{ padding: '0 0.1cm', width: 'auto' }}>POD</DetailsTableColumnLabel>
                        <DetailsTableColumnValue>{ pod }</DetailsTableColumnValue>
                      </tr>
                    </tbody>
                  </ReceiptDetailsTable>

                  {/* Ticket Details Row */}
                  <ReceiptDetailsTable>
                    <tbody>
                      <tr>
                        <DetailsTableColumnLabel includesADA={includesADA}>TICKET(S)</DetailsTableColumnLabel>
                        <DetailsTableColumnValue>
                          {ticketBreakdown}
                        </DetailsTableColumnValue>
                        {/* We are using the `width: 1` property here to ensure the width of this column shrinks down to the size of the image defined within. */}
                        <DetailsTableColumnValue includesADA={includesADA} style={{ width: 1, border: 0, paddingRight: 0, paddingLeft: '0.5cm', paddingBottom: 0, textAlign: 'right' }} rowSpan="2">
                          <QRCodeImg src={ qrCodeUri }/>
                          {/*<QRCodeImg src="https://sandbox.xola.com/api/orders/5bef550107876cae078b4580/items/5bef550107876cae078b4578/qrcode"/>*/}
                        </DetailsTableColumnValue>
                      </tr>
                      <tr>
                        {/*
                          We are using some unorthodox padding on the bottom of this label and a different ColumnValue container
                          to help "push" the "Tickets" column up to where it would sit naturally if the "QRImage" wasn't in the mix.
                        */}
                        <DetailsTableColumnLabel style={{ paddingBottom: '0.58cm' }}>BOOKING#</DetailsTableColumnLabel>
                        <CustomDetailsTableColumnValue style={{ width: 'auto' }}>
                          <CustomDetailsTableLabelContainer>{ bookingNum }</CustomDetailsTableLabelContainer>
                        </CustomDetailsTableColumnValue>
                      </tr>
                    </tbody>

                  </ReceiptDetailsTable>
                  <div style={{ marginTop: includesADA ? '0' : '0.4cm' }}>
                    <img width="100%" src={BottomBarSvg} alt={'Page separator'}/>
                  </div>
                </ReceiptDetailsSectionContainer>
              </ReceiptDetailsContainer>
            </TopLeftBox>

            <TopRightBox name="top-right-box">
              <TopRightTitle>A <span style={{ fontWeight: 900 }}>VR</span> EXPERIENCE<br/>LIKE NO OTHER</TopRightTitle>
              <TopRightDetails>
                <div style={{ fontWeight: 900 }}>DREAMSCAPEIMMERSIVE.COM</div>
                @VISITDREAMSCAPE &nbsp;|&nbsp; #DREAMSCAPE
              </TopRightDetails>

              <div style={{ textAlign: 'center', paddingTop: '3.5cm' }}>
                <img width="180" src={DSHorizontalLogo_2x} srcSet={`${DSHorizontalLogo_2x} 2x, ${DSHorizontalLogo_3x} 3x`} alt={'Dark Dreamscape Immersive logo'} />
              </div>
              <CopyrightText>©DREAMSCAPE IMMERSIVE, INC.</CopyrightText>
            </TopRightBox>
          </TopRow>

          <BottomRow name="bottom-row">
            <BottomLeftBox name="bottom-left-box">
              <SmallFadedText>Fold</SmallFadedText>
              <InstructionsTextContainer>
                <InstructionsTitle>NOW WHAT?</InstructionsTitle>

                <InstructionsText>
                  This is your digital ticket. The QR code above includes all tickets from your purchase.
                </InstructionsText>

                <InstructionsText>
                  You can scan your ticket more than once during your check-in at Dreamscape.
                </InstructionsText>

                <InstructionsSmallTitle>TELL YOUR FRIENDS</InstructionsSmallTitle>
                <InstructionsText style={{ margin: 0, padding: '0' }}>
                  Make sure you tell your friends about your adventure. Take a photo inside or outside of our departure lounge and tag us
                  <br/>
                  <DuskBlueTextSpan>@VISITDREAMSCAPE | #DREAMSCAPE</DuskBlueTextSpan>
                </InstructionsText>

                <InstructionsSmallTitle>QUESTIONS?</InstructionsSmallTitle>
                <InstructionsText style={{ margin: 0, padding: '0' }}>
                  Please visit <DuskBlueTextSpan>dreamscapeimmersive.com/faq</DuskBlueTextSpan>
                </InstructionsText>
              </InstructionsTextContainer>
            </BottomLeftBox>

            <BottomRightBox name="bottom-right-box">

              <InstructionsTextContainer>
                <InstructionsTitle style={{ paddingTop: '1.35cm' }}>PLAN AHEAD</InstructionsTitle>

                <InstructionsSmallTitle>WHAT TO BRING</InstructionsSmallTitle>
                <InstructionsText style={{ margin: 0, padding: '0' }}>
                  Keep your confirmation email handy during check-in. Personal items and shopping bags can be stowed in our storage area during
                  your adventure.
                </InstructionsText>

                <InstructionsSmallTitle>WHAT TO WEAR</InstructionsSmallTitle>
                <InstructionsText style={{ margin: 0, padding: '0' }}>
                  Dress for adventure and wear comfortable shoes. Avoid heels, flip-flops, long skirts and oversized eyeglasses.
                </InstructionsText>

                <InstructionsSmallTitle>WHAT TO EXPECT</InstructionsSmallTitle>
                <InstructionsText style={{ margin: 0, padding: '0' }}>
                  Please check in 15 minutes prior to your departure time. Your full trip, including gear up/down, and your adventure will take between 30 to 40 minutes.
                </InstructionsText>
              </InstructionsTextContainer>

              <CustomerTextDiv>
                This ticket issued to: <br/> { customerEmail }
              </CustomerTextDiv>
            </BottomRightBox>
          </BottomRow>
        </ReceiptContainer>
      </ReceiptPage>
    );
  }
}

export default withRouter(PrintReceipt);
