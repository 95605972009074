import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { Authenticator, withOAuth, DIApi } from './util/api';
import Navigation from './components/Navigation';
import AppNavbar from './components/AppNavbar';
import Footer from './components/Footer';
import theme from "./util/theme";
import {AuthContext} from "./components/context/AuthenticatorContext";

const defaultState = {
  isSignedIn: false,
  currentUser: null,
  isLoadingUser: true,
};

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const AppBody = styled.div`
  margin-top: ${ props => props.toasterOffset }px;
`;

class AppWithContext extends Component {
  state = {
    ...defaultState,
  };

  handleAuthStateChange = async (state) => {
    switch (state) {
      case 'signedIn':
        const user = await DIApi.getCurrentUser();
        if (user.attributes.email) {
          // this.trackUser(user.attributes.email)
        }
        this.setState({
          isSignedIn: true,
          currentUser: user,
          isLoadingUser: false,
        });
        break;
      case 'signedOut':
        this.setState({
          isSignedIn: defaultState.isSignedIn,
          currentUser: defaultState.currentUser,
          isLoadingUser: defaultState.isLoadingUser,
        });
        break;
      default:
        this.setState({
          isLoadingUser: false,
        });
        break;
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppContainer>
          <AppBody>
            <AppNavbar />
            <Authenticator hideDefault={true} onStateChange={this.handleAuthStateChange}/>
            <Navigation
              currentUser={this.state.currentUser}
              handleAuthStateChange={this.handleAuthStateChange}
            />
            <Footer/>
          </AppBody>
        </AppContainer>
      </ThemeProvider>
    );
  }
}

const App = props => (
  <AuthContext.Consumer>
    { parentState => <AppWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withOAuth(App);
