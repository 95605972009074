import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Import sections
import PlanAheadHeroSection from '../components/PlanAheadHeroBlock';
import PlanAheadWelcomeBlock from '../components/PlanAheadWelcomeBlock';
import PlanAheadRulesBlock from '../components/PlanAheadRulesBlock';
import Recommendations from '../components/NearbyRecommendations';
import FindUsSection from '../components/FindUsSection';

import { AuthContext } from '../components/context/AuthenticatorContext';

import HeaderMeta from "../components/HeaderMeta";

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

class PlanAheadWithContext extends Component {
  state = {
    hasSelectedSite: false,
    selectedSite: {},
  };

  componentDidMount () {
    const { selectedSite } = this.props.parentState;
    this.setSiteInfo(selectedSite);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { selectedSite } = this.props.parentState;
    if (
      (!prevProps.parentState.selectedSite.site_id && selectedSite.site_id) ||
      (prevProps.parentState.selectedSite.site_id !== selectedSite.site_id)
    ) {
      return this.setSiteInfo(selectedSite);
    }
  }

  setSiteInfo (site) {
    const state = {
      hasSelectedSite: false,
      selectedSite: {},
    };

    if (!site || !site.site_id) {
      return this.setState(state);
    }

    return this.setState({
      hasSelectedSite: true,
      selectedSite: site,
    });
  }

  render () {
    const selectedSite = this.state.selectedSite;
    const locationData = this.props.parentState.cms.getSiteAssets(selectedSite.site_id);

    return (
      <Container id="plan-ahead">
        <HeaderMeta page={'plan_ahead'}/>
        <PlanAheadHeroSection hasStoredSite={this.props.parentState.hasStoredSite}/>
        <PlanAheadWelcomeBlock/>
        <PlanAheadRulesBlock/>
        { this.state.hasSelectedSite ?
          <Recommendations locationData={locationData} site={selectedSite}/> :
          <FindUsSection/>
        }
      </Container>
    );
  }
}

const PlanAhead = props => (
  <AuthContext.Consumer>
    { parentState => <PlanAheadWithContext {...props} parentState={parentState}/> }
  </AuthContext.Consumer>
);

export default withRouter(PlanAhead);
