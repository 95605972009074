import React, { Component } from 'react';
import styled from 'styled-components';

import PaymentForm from '../../../components/forms/TestCyberSourcePaymentForm';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  & td, th {
    border: 1px solid black;
    text-align: left;
    padding: 6px 12px;
    word-break: break-all;
  }

  & thead {
    background-color: #778899;
    color: white;
  }

  & tr {
    width: 100%;
  }

  & tr > td:first-of-type {
    width: 350px;
    background-color: lightgray;
    font-weight: bold;
  }
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  flex: 1;
  width: 100%;
`;

const InnerContainer = styled.div`
  flex: 1;
  padding: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class TestPurchase extends Component {
  render() {
    return (
      <Container name="test-cybersource-page">
        <InnerContainer>
          <PaymentForm />
        </InnerContainer>
      </Container>
    );
  }
}

export default TestPurchase;
