import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import AdventureItem from '../components/content/AdventureItem';
import FindUsSection from '../components/FindUsSection';
import { AuthContext } from '../components/context/AuthenticatorContext';
import HeaderMeta from "../components/HeaderMeta";
import AnnouncementModal from '../components/AnnouncementModal';

const PageContainer = styled.div`
  height: auto;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageTitle = styled.div`
  height: auto;
  max-width: ${ props => props.theme.content_max_width };
  text-align: center;
  font-size: ${ props => props.fontSize || '3.5rem' };
  line-height: ${ props => props.lineHeight || 'initial' };
  font-weight: bold;
  letter-spacing: ${ props => props.letterSpacing || '2px'};
  padding: ${ props => props.padding || '56px 22px 48px 22px'};
`;

const AdventuresSection = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: ${ props => props.theme.ds_blue };
`;

const AdventuresListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${ props => props.theme.content_max_width };
  padding: ${ props => props.mobile ? '48px 0 32px 0' : '88px 0 0' };
`;

const AdventureCardRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: ${ props => props.theme.content_max_width };
  margin: ${ props => props.margin || '0 22px 55px' };
  /* on desktop set justify-content start so the last row wraps left justified. we are assuming there will be at least 3 adventures (1 full row) */
  justify-content: ${ props => props.mobile ? 'center' : 'start'};
`;

class AdventuresWithContext extends Component {
  state = {};

  navDetails (adventure, toAvailability, toQuoteBlock) {
    this.props.history.push(`/adventures/details/${adventure.title_id}`, { toAvailability, toQuoteBlock });
  }

  renderAdventures (cols, platform) {
    const rows = [];
    let row = [];
    const isMobile = platform === 'mobile';
    const rowMargin = isMobile ? '16px 0px' : null;
    const itemMargin = isMobile ? null : '0 8px';

    const { adventures, cms, selectedSite } = this.props.parentState;

    if (!adventures || !adventures.length) {
      return;
    }
    let availableAdventures = adventures;
    if (selectedSite.site_id) {
      availableAdventures = adventures.filter((adventure) => {
        return adventure.isAvailableAtCurrentLocation;
      });
    }
    availableAdventures.forEach((adventure, itr) => {
      const assets = cms ? cms.getAdventureAssets(adventure.title_id, selectedSite.site_id) : {};

      const isAvailable = !!adventure.isAvailableAtCurrentLocation;
      if (itr !== 0 && (itr % cols === 0)) {
        rows.push(
          <AdventureCardRow
            key={adventure.title_id + '_' + itr}
            name="adventure-card-row"
            margin={rowMargin}
            mobile={isMobile}>
            { row }
          </AdventureCardRow>
        );
        row = [];
      }

      row.push(
        <AdventureItem
          isMobile={isMobile}
          key={ adventure.title_id + '_final' }
          adventure={adventure}
          assets={assets}
          site={selectedSite}
          onClick={ () => this.navDetails(adventure, isAvailable, !isAvailable) }
          posterOnClick={ () => this.navDetails(adventure) }
          margin={itemMargin}
          buttonText={isAvailable ? null : 'LEARN MORE'}
        />
      );
    });

    if (row.length) {
      rows.push(
        <AdventureCardRow margin={rowMargin} mobile={isMobile} key="final" name="adventure-card-row">
          { row }
        </AdventureCardRow>
      );
    }

    return rows;
  }

  render () {
    const pageTitleText = 'BOOK YOUR NEXT ADVENTURE';
    const DesktopPageTitle = <PageTitle>{pageTitleText}</PageTitle>;
    const MobilePageTitle = (
      <PageTitle
        fontSize="1.625rem"
        lineHeight={1.33}
        letterSpacing="normal"
        padding="28px 22px 10px 22px"
      >
        {pageTitleText}
      </PageTitle>
    );

    return (
      <PageContainer name="page-container">
        <Desktop>
          <AnnouncementModal />
        </Desktop>
        <Mobile>
          <AnnouncementModal isMobile={true} />
        </Mobile>

        <HeaderMeta page={'adventures'}/>
        <Desktop>
          <AdventuresSection name="adventure-section">
            <AdventuresListContainer>
              { DesktopPageTitle }
              { this.renderAdventures(3, 'desktop') }
            </AdventuresListContainer>
          </AdventuresSection>
        </Desktop>
        <Mobile>
          <AdventuresSection name="adventure-section">
            <AdventuresListContainer mobile>
              { MobilePageTitle }
              { this.renderAdventures(1, 'mobile') }
            </AdventuresListContainer>
          </AdventuresSection>
        </Mobile>

        <FindUsSection/>
      </PageContainer>
    );
  }
}

const Adventures = props => (
  <AuthContext.Consumer>
    { parentState => <AdventuresWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(Adventures);
