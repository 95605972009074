import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import crypto from 'crypto';
import axios from 'axios';
import cardValid from 'card-validator';

import { BlueButton } from '../buttons';
import TestCyberSourceAuthorizeResponse from '../TestCyberSourceAuthorizeResponse';
import theme from '../../util/theme';
import { BasicInputField as Input } from '.';
import { getCybersourceCredentials } from '../../util/common';

const CARD_TYPE_MAP = {
  'visa': '001',
  'mastercard': '002',
  'american-express': '003',
  'discover': '004',
  'diners-club': '005',
  'jcb': '007',
  'maestro': '024',
  'hipercard': '050',
  'elo': '054',
};

const DEFAULT_CARD_LENGTHS = [16];
const DEFAULT_CARD_GAPS = [4, 8, 12];

const CCInput = styled(Input)`
  border: ${ props => props.isInvalid ? '1px solid red !important' : 'parent' };
`;

class CyberSourcePaymentForm extends Component {
  state = {
    signedDataValues: {
      access_key: process.env.REACT_APP_CYBS_ACCESS_KEY,
      profile_id: process.env.REACT_APP_CYBS_PROFILE_ID,
      // signed_field_names: "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email",
      signed_field_names: "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_city,bill_to_address_country",
      unsigned_field_names: "card_type,card_number,card_expiry_date,card_cvn",
      locale: "en",
      transaction_type: "authorization",
      currency: "AED",
      payment_method: "card",
      bill_to_address_line1: "1 Card Lane",
      bill_to_address_city: "My City",
      // bill_to_address_state: "CA",
      bill_to_address_country: "AE",
      // ignore_avs: "true",
    },
    // Auto generated values
    transaction_uuid: "",   //crypto.randomBytes(8).toString('hex'),
    signed_date_time: "",   //moment().utc().format(),
    reference_number: "",   //Date.now(),
    signature: "",
    // User supplied fields
    amount: "22.00",
    bill_to_email: "null@cybersource.com",
    bill_to_forename: "John",
    bill_to_surname: "Doe",
    // bill_to_address_postal_code: "94043",
    ccNum: '',
    ccCVV: '',
    ccExp: '',
    ccType: '',
    // CC input validation
    ccNumLengths: [16],
    ccNumGaps: [4, 8, 12],
    isCCNumInvalid: false,
    isCCExpInvalid: false,
    displayOverlayPopup: false,
    responseData: {},
  };

  handlePopupResponse = (data) => {
    console.log('Successfully called:', data);
    this.setState({ responseData: data, displayOverlayPopup: false });
  }

  componentDidMount() {
    const hostname = window.location.hostname.replace('www.', '');
    const { profile_id, access_key } = getCybersourceCredentials(hostname);
    window.handlePopupReturn = this.handlePopupResponse;
    this.setState((state, props) => {
      return {
        signedDataValues: {
          ...state.signedDataValues,
          profile_id,
          access_key,
        },
      };
    });
  }

  componentWillUnmount() {
    window.handlePopupReturn = undefined;
  }

  async fetchSignedData() {
    const host = process.env.REACT_APP_CYBS_SIGN_HOST || '';
    const {
      bill_to_forename,
      bill_to_surname,
      bill_to_email,
      // bill_to_address_postal_code,
    } = this.state;

    this.setState({
      transaction_uuid: crypto.randomBytes(8).toString('hex') + '_' + Date.now(),
      signed_date_time: moment().utc().format(),
      reference_number: Date.now(),
    }, async () => {
        const {
          transaction_uuid,
          signed_date_time,
          reference_number,
          amount,
        } = this.state;

        const dataToSign = {
          ...this.state.signedDataValues,
          transaction_uuid,
          signed_date_time,
          reference_number,
          bill_to_forename,
          bill_to_surname,
          bill_to_email,
          // bill_to_address_postal_code,
          amount,
        };

        const response = await axios.post(`${host}/cybersource/sign`, dataToSign);

        this.setState({ signature: response.data.signedData }, () => {
          this.launchOverlay(this.form);
          this.form.submit();
        });
    });
  }

  insertHiddenInputFields() {
    const hiddenFields = Object.keys(this.state.signedDataValues).map((field, index) => {
      return (
        <input
          key={index}
          id={field}
          name={field}
          value={this.state.signedDataValues[field]}
          type="hidden" />
      );
    });

    return hiddenFields;
  }

  launchOverlay(form) {
    form.target = 'cybersource_form';
    this.setState({ displayOverlayPopup: true });
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    // validate data first
    const expireDate = this.state.ccExp.replace(/\-/g, '');
    const { val, isValid } = this.validateCCExpirationDate(expireDate);
    this.setState({ ccExp: val, isCCExpInvalid: !isValid }, () => {
      if (!this.checkCanSubmit()) {
        alert('There are invalid entries in the form. Please check the form and try again.');
        return;
      }
      this.fetchSignedData();
    });
  };

  checkCanSubmit () {
    const {
      bill_to_email,
      bill_to_forename,
      bill_to_surname,
      ccNum,
      ccCVV,
      ccExp,
      ccType,
      isCCNumInvalid,
      isCCExpInvalid,
    } = this.state;

    if (!bill_to_email ||
      !bill_to_forename ||
      !bill_to_surname ||
      !ccNum ||
      !ccCVV ||
      !ccExp ||
      !ccType ||
      isCCNumInvalid ||
      isCCExpInvalid
    ) {
      return false;
    }

    return true;
  }

  onCardNumberChange = (event) => {
    // Only allow numbers to be entered
    if (!RegExp('^(?:[0-9]+$|)$').test(event.target.value.slice(this.state.ccNum.length))) {
      return;
    }

    // Validate card number and get card formatting info
    const valid = cardValid.number(event.target.value)

    const stateUpdates = {
      ccNumLengths: valid.card ? valid.card.lengths : DEFAULT_CARD_LENGTHS,
      ccNumGaps: valid.card ? valid.card.gaps : DEFAULT_CARD_GAPS,
      isCCNumInvalid: !valid.isPotentiallyValid,
    }

    // Strip away formatted spaces for easier length validation
    const strippedCCNum = event.target.value.replace(/\s/g, '');
    // If the current cc number length exceeds all expected lengths, prevent any more characters
    if (stateUpdates.ccNumLengths.every(value => strippedCCNum.length > value)) {
      return;
    }
    stateUpdates.ccNum = strippedCCNum;
    
    if (valid.card) {
      const ccType = CARD_TYPE_MAP[valid.card.type];
      // Record the card type if we have a possible valid card.
      stateUpdates.ccType = ccType;
    }

    this.setState(stateUpdates);
  };

  onCardExpireChange = (event) => {
    const currentVal = event.target.value.replace(/\-/g, '');
    if (!RegExp('^(?:[0-9]+$|)$').test(currentVal)) {
      return;
    }

    if (currentVal.length > 6) {
      return;
    }

    let val = currentVal;
    if (currentVal.length === 1 && parseInt(currentVal, 10) > 1) {
      val = '0' + val;
    }

    const valid = cardValid.expirationDate(val);

    if (val.length > 2) {
      const splitVal = val.split('');
      splitVal.splice(2, 0, '-');
      val = splitVal.join('');
    }

    this.setState({ ccExp: val, isCCExpInvalid: !valid.isPotentiallyValid });
  };

  onCardExpireBlur = (event) => {
    const currentVal = event.target.value.replace(/\-/g, '');
    const {val, isValid} = this.validateCCExpirationDate(currentVal);

    this.setState({ ccExp: val, isCCExpInvalid: !isValid });
  };

  onCardCVVChange = (event) => {
    if (RegExp('^(?:[0-9]+$|)$').test(event.target.value)) {
      this.setState({ ccCVV: event.target.value });
    }
  }

  validateCCExpirationDate (currentVal) {
    const valid = cardValid.expirationDate(currentVal);

    let val = currentVal;
    if (valid.month && valid.year) {
      const normalizedYear = valid.year.length > 2 ? valid.year : "20" + valid.year;
      val = valid.month + normalizedYear;
    }

    if (val.length > 2) {
      const splitVal = val.split('');
      splitVal.splice(2, 0, '-');
      val = splitVal.join('');
    }

    return { val, isValid: valid.isValid };
  }

  renderOverlay() {
    return (
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 4000000,
        backgroundColor: theme.black_30,
        display: this.state.displayOverlayPopup ? 'block' : 'none',
      }}>
        <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{
            width: 640,
            height: 480,
            backgroundColor: 'white',
          }}>
            <iframe id="cybersource_form" name="cybersource_form" style={{
              width: '100%',
              height: '100%',
            }} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <TestCyberSourceAuthorizeResponse responseData={this.state.responseData} />
        {this.renderOverlay()}
        <form ref={ref => this.form = ref} id="payment_confirmation" action="https://testsecureacceptance.cybersource.com/silent/pay" method="post" onSubmit={this.handleSubmit}>
          {/* Static Hidden Fields */}
          {this.insertHiddenInputFields()}
          <input
            id="reference_number"
            name="reference_number"
            value={this.state.reference_number}
            type="hidden" />
          <input
            id="transaction_uuid"
            name="transaction_uuid"
            value={this.state.transaction_uuid}
            type="hidden" />
          <input
            id="signed_date_time"
            name="signed_date_time"
            value={this.state.signed_date_time}
            type="hidden" />
          <input
            id="signature"
            name="signature"
            value={this.state.signature}
            type="hidden" />
          {/* Figure out some method for determining the card type. (Some kind of selector?) */}
          <input
            id="card_type"
            name="card_type"
            value={this.state.ccType}
            type="hidden" />
          <Input
            name="bill_to_email"
            autoFocus
            type="email"
            placeholder="Email"
            label="Email*"
            required
            value={this.state.bill_to_email}
            style={{ marginBottom: 12 }}
            onChange={(event) => this.setState({ bill_to_email: event.target.value })}
          />
          <Input
            name="bill_to_forename"
            type="text"
            placeholder="First Name"
            label="First Name*"
            required
            value={this.state.bill_to_forename}
            style={{ marginBottom: 12 }}
            onChange={(event) => this.setState({ bill_to_forename: event.target.value })}
          />
          <Input
            name="bill_to_surname"
            type="text"
            placeholder="Last Name"
            label="Last Name*"
            required
            value={this.state.bill_to_surname}
            style={{ marginBottom: 12 }}
            onChange={(event) => this.setState({ bill_to_surname: event.target.value })}
          />
          <CCInput
            name="card_number"
            type="text"
            placeholder="Credit Card Number"
            label="Credit Card Number*"
            required
            value={this.state.ccNum}
            style={{ marginBottom: 12 }}
            isInvalid={this.state.isCCNumInvalid}
            onChange={this.onCardNumberChange}
          />
          <CCInput
            name="card_expiry_date"
            type="text"
            placeholder="MM-YYYY"
            label="Exp Date*"
            required
            value={this.state.ccExp}
            style={{ marginBottom: 12 }}
            isInvalid={this.state.isCCExpInvalid}
            onChange={this.onCardExpireChange}
            onBlur={this.onCardExpireBlur}
          />
          <Input
            name="card_cvn"
            type="text"
            placeholder="CVV"
            label="CVV*"
            required
            maxLength={4}
            value={this.state.ccCVV}
            style={{ marginBottom: 12 }}
            onChange={this.onCardCVVChange}
          />
          {/* <Input
            name="bill_to_address_postal_code"
            type="text"
            placeholder="Zip Code"
            label="Zip Code*"
            required
            value={this.state.bill_to_address_postal_code}
            style={{ marginBottom: 12 }}
            onChange={(event) => this.setState({ bill_to_address_postal_code: event.target.value })}
          /> */}
          <Input
            id="amount"
            name="amount"
            type="text"
            placeholder="0.00"
            label="Amount*"
            required
            value={this.state.amount}
            style={{ marginBottom: 12 }}
            onChange={(event) => this.setState({ amount: event.target.value })}
          />
          <BlueButton id="btn-submit" type="submit" width={'280px'}>
            SUBMIT
        </BlueButton>
        </form>
      </React.Fragment>
    );
  }
}

export default CyberSourcePaymentForm;
