import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { BlueButton } from '../components/buttons';
import { AuthContext } from "../components/context/AuthenticatorContext";
import { DIApi } from "../util/api";
import Moment from 'moment-timezone';
import {Large, Phone} from "../components/responsive/Breakpoints";
import AdventureThumbnail from "../components/AdventureThumbnail";

const Container = styled.div`
  height: auto;
  width: auto;
  margin: 0 auto;
`;

const BoundingContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const BoundingContentContainer = styled.div`
  max-width: 800px;
  margin: ${ props => props.mobile ? '61' : '54' }px auto 0;
  padding-bottom: 7px;
`;

const DetailsButton = styled(BlueButton)`
  color: #fff;
  display: block;
  margin: 25px ${ props => props.mobile ? '0' : 'auto' };
  border-radius: 3px;
  width: ${ props => props.mobile ? '100%' : '191px' };
  height: 47px;
`;

const OrderSubtypeSection = styled.div`
  ${ props => props.mobile ? 'padding-bottom: 23px;' : '' }
`;

const OrderSubtypeHeader = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: 1.875rem;
  font-weight: bold;
  padding-bottom: 29px;
  color: ${ props => props.theme.charcoal };
`;

const PageBreak = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background-color: ${ props => props.theme.charcoal_25 };
`;

const TicketContentTable = styled.table`
  display: inline-block;
  padding-bottom: 83px;
  font-family: ${ props => props.theme.sans_serif };
  font-size: 1rem;
`;

const TicketContentTr = styled.tr`
  vertical-align: top;
`;

const KeyTd = styled.td`
  padding-right: 10px;
  color: ${ props => props.theme.charcoal_60 };
`;

const ValueTd = styled.td`
  font-weight: bold;
  color: ${ props => props.theme.charcoal_90 };
`;

const TicketPreview = styled.div`
  display: ${ props => props.mobile ? 'block' : 'inline-block' };
  ${ props => props.mobile ? '' : 'float: right;' }
  margin-bottom: ${ props => props.mobile ? '16px' : '0px' };
`;


class AccountViewTickets extends Component {
  state = {
    data: null,
    loadedTitle: false,
  };

  isActive = false;

  componentDidMount () {
    this.isActive = true;
    this.componentDidUpdate();
  }

  componentWillUnmount () {
    this.isActive = false;
  }

  componentDidUpdate () {
    this.handleRedirect();
    if (!this.state.loadedTitle && this.props.authState.isSignedIn && this.props.authState.currentUser) {
      this.props.updateHeaderText({
        title: (
          <React.Fragment>
            <Large>
              {this.props.authState.currentUser.attributes.given_name.toUpperCase()} {this.props.authState.currentUser.attributes.family_name.toUpperCase()}
            </Large>
            <Phone>
              <span style={{fontSize: '1.875rem'}}>{this.props.authState.currentUser.attributes.given_name.toUpperCase()} {this.props.authState.currentUser.attributes.family_name.toUpperCase()}</span>
            </Phone>
          </React.Fragment>
        ),
        subTitle: (
          <React.Fragment>
            <Large>
              {this.props.authState.currentUser.attributes.email}
            </Large>
            <Phone>
              <span style={{fontSize: '0.875rem'}}>{this.props.authState.currentUser.attributes.email}</span>
            </Phone>
          </React.Fragment>
        )
      });
      if (this.isActive) {
        this.setState({loadedTitle: true});
      }
    }
    this.setDataOrRedirect();
  }

  async setDataOrRedirect () {
    if (this.state.data !== null || this.props.authState.isLoadingUser) {
      return;
    }
    if (this.props.authState.isSignedIn) {
      try {
        const data = await DIApi.getUserOrders();
        if (this.isActive) {
          this.setState({data});
        }
      } catch (e) {
        alert('Could not load data. Try reloading the page.');
        return;
      }
    } else {
      this.navToSignIn();
    }
  }

  handleRedirect () {
    if (!this.props.authState.isLoadingUser && !this.props.authState.isSignedIn) {
      this.navToSignIn();
    }
  }

  navToDetails = () => {
    this.props.history.push('/account/details');
  };

  navToSignIn = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account/signin', locationState);
  };

  ticketPreview (mobile, ada, titleId) {
    return (
      <TicketPreview mobile={mobile}>
        <AdventureThumbnail ada={ada} titleId={titleId} />
      </TicketPreview>
    )
  }

  ticketSection (key, mobile, header, bodyArray) {
    return (
      <React.Fragment key={key}>
        <OrderSubtypeSection mobile={mobile}>
          <OrderSubtypeHeader>{header}</OrderSubtypeHeader>
          {bodyArray.map((body, i) => (
            <React.Fragment key={i}>
              <Phone>
                {this.ticketPreview(mobile, body.ada, body.titleId)}
              </Phone>
              <PageBreak/>
              <TicketContentTable>
                <tbody>
                  <TicketContentTr>
                    <KeyTd>ORDER</KeyTd>
                    <ValueTd>{body.order}</ValueTd>
                  </TicketContentTr>
                  <TicketContentTr>
                    <KeyTd>LOCATION</KeyTd>
                    <ValueTd>{body.location.toUpperCase()}</ValueTd>
                  </TicketContentTr>
                  <TicketContentTr>
                    <KeyTd>TIME</KeyTd>
                    <ValueTd>{body.time.toUpperCase()}</ValueTd>
                  </TicketContentTr>
                  <TicketContentTr>
                    <KeyTd>TICKETS</KeyTd>
                    <ValueTd>{body.tickets}</ValueTd>
                  </TicketContentTr>
                  {body.ada &&
                  <TicketContentTr>
                    <KeyTd>ACCESSIBLE</KeyTd>
                    <ValueTd>YES</ValueTd>
                  </TicketContentTr>
                  }
                  <TicketContentTr>
                    <KeyTd>TOTAL COST</KeyTd>
                    <ValueTd>{body.totalCost}</ValueTd>
                  </TicketContentTr>
                </tbody>
              </TicketContentTable>
              <Large>
                {this.ticketPreview(mobile, body.ada, body.titleId)}
              </Large>
            </React.Fragment>
          ))}
        </OrderSubtypeSection>
      </React.Fragment>
    )
  }

  ticketBodyObject (row) {
    return {
      order: row.transaction_short_code,
      location: row.site_name,
      time: Moment(row.ticketed_start_time).tz(this.props.authState.site.tz).format("ddd, MM/DD/YYYY [at] h:mmA"),
      tickets: row.total_ticket_count,
      ada: row.ada_ticket_count > 0,
      totalCost: row.transaction_total,
      titleId: row.ticketed_title_id,
    }
  }

  renderAccountTicketsPage (mobile) {
    let content = null;
    if (!this.state.data) {
      content = (
        <div className="spinner">
          <div className="rect1"/>
          <div className="rect2"/>
          <div className="rect3"/>
          <div className="rect4"/>
          <div className="rect5"/>
        </div>
      )
    } else {
      content = [];
      const today = Moment();
      const pastTickets = this.state.data.filter(row => Moment(row.ticketed_start_time).isBefore(today, 'day'));
      const todayTickets = this.state.data.filter(row => Moment(row.ticketed_start_time).isSame(today, 'day'));
      const upcomingTickets = this.state.data.filter(row => Moment(row.ticketed_start_time).isAfter(today, 'day'));

      if (todayTickets.length > 0) {
        content.push(this.ticketSection(
          1, mobile,
          "READY FOR CHECK IN",
          todayTickets.map(row => this.ticketBodyObject(row))
        ))
      }
      if (upcomingTickets.length > 0) {
        content.push(this.ticketSection(
          2, mobile,
          "UPCOMING",
          upcomingTickets.map(row => this.ticketBodyObject(row))
        ))
      }
      if (pastTickets.length > 0) {
        content.push(this.ticketSection(
          3, mobile,
          "PAST",
          pastTickets.map(row => this.ticketBodyObject(row))
        ))
      }
    }
    return (
      <Container>
        <BoundingContainer>
          <DetailsButton style={{display: this.state.loadedTitle ? 'block' : 'none'}} mobile={mobile} invert onClick={ this.navToDetails }>ACCOUNT DETAILS</DetailsButton>
          <BoundingContentContainer mobile={mobile}>
            {content}
          </BoundingContentContainer>
        </BoundingContainer>
      </Container>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Large>
          {this.renderAccountTicketsPage(false)}
        </Large>
        <Phone>
          {this.renderAccountTicketsPage(true)}
        </Phone>
      </React.Fragment>
    )
  }
}

const SignIn = props => (
  <AuthContext.Consumer>
    { authState => {
      return (<AccountViewTickets {...props} authState={authState} />)
    }}
  </AuthContext.Consumer>
);

export default withRouter(SignIn);
