import {Component} from 'react';
import {DIApi} from '../util/api'
import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import TimeSelector from '../components/TimeSelector'
import { BlueOutlineButton, BlueButton, WhiteButton } from '../components/buttons'
import Moment from 'moment-timezone';
import LabelDropDown from "./LabelDropDown";
import downArrow from '../assets/images/arrow_down.svg';
import debounce from 'lodash/debounce';
import ComingSoonHubspotForm from "./hubspot/ComingSoonHubspotForm";
import LoadingSpinner from "./LoadingSpinner";

const DateButton = styled(BlueOutlineButton)`
  width: 145px;
  height: 47px;
  vertical-align: middle;
  margin: 0 12px;
  background-color: ${ props => props.isSelected ? props.theme.ds_bright_blue : 'transparent' };
  color: ${ props => props.isSelected ? 'white' : 'black' };
  
  &:focus {
    background-color: ${ props => props.theme.ds_bright_blue };
    color: #fff;
  }
`;

const LabelDropDownContainer = styled.div`
  display: block;
  margin: 0 auto;
`;

const OutlineButton = styled(BlueOutlineButton)`
  height: 47px;
  
  &:disabled {
    opacity: 0.25;
    cursor: initial;
    
    &:hover {
      border: 1px solid ${ props => props.theme.ds_bright_blue };
    }
    
    &:focus {
      background-color: transparent;
      color: black;
    }
    
    &:active {
      background-color: transparent;
      color: black;
    }
  }
`;

const ArrowButton = styled(OutlineButton)`
  width: 47px;
  vertical-align: middle;
  margin: 0 12px;
`;

const Arrow = styled.img`
  display: flex;
  margin: auto;
  transform: ${ props => props.rotate ? `rotate(${props.rotate}deg)` : undefined };
`;

const NoClick = styled.div`
  pointer-events: none;
`;

const ComingSoonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // min-height: ${ props => props.mobile ? '233px' : '394px'};
  padding-bottom: 42px;
`;

const ComingSoonBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: ${ props => props.mobile ? '768px' : '1166px'};
  min-height: ${ props => props.mobile ? '302px' : '394px'};
  height: 100%;
  border: 1px solid ${ props => props.theme.charcoal_25 };
  border-radius: 6px;
  margin: 0 22px;
  padding: ${ props => props.mobile ? '40px 28px' : '104px 22px'};
`;

const ComingSoonTitle = styled.div`
  font-size: ${ props => props.mobile ? '1rem' : '1.625rem'};
  font-weight: bold;
  color: ${ props => props.theme.charcoal };
`;

const ComingSoonDescription = styled.div`
  margin-top: ${ props => props.mobile ? '8px' : undefined };
  line-height: ${ props => props.mobile ? '1.25': undefined };
  font-size: 0.875rem;
  max-width: ${ props => props.mobile ? '328px' : '698px' };
  color: ${ props => props.theme.charcoal_60 };
`;

const HubspotFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: start;
  max-width: ${ props => props.mobile ? '360px' : '500px' };
  margin-top: ${ props => props.mobile ? '20px' : '40px' };
  width: 100%;
`;

class Scheduler extends Component {
  state = {
    startDate: null,
    startDateMoment: null,
    endDate: null,
    maxCountdownTime: 1000*60,
    selectedDate: null,
    todaysDate: null,
    maxDaysToDisplay: null,
    daysInDropdown: null,
    availabilityData: {},
    refreshing: true,
    isAvailabilityEnded: false,
  };

  componentDidMount () {
    this.startInterval();
    this.setupRefresh();
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      (!prevProps.adventure && this.props.adventure) ||
      (prevProps.adventure && this.props.adventure && prevProps.adventure.title_id !== this.props.adventure.title_id) ||
      (prevProps.site.site_id !== this.props.site.site_id) ||
      (!this.state.startDate && this.props.adventure.licensed_site && this.props.site)
    ) {
      await this.setupDateControls();
      this.refreshAvailability(this.state.startDate, this.state.endDate);
    }
  }

  setupDateControls () {
    const { site, maxDaysToDisplay, adventure } = this.props;

    const nowDate = Moment();
    const foundActiveDate = adventure.licensed_site ? adventure.licensed_site.active_date : site.active_date;
    const nowDateString = this.standardizeDate(nowDate);
    let startDate = Moment().tz(site.tz).startOf('day');
    const activeDate = Moment(foundActiveDate).startOf('day');
    const selectedDate = 0;
    if (activeDate > startDate) {
      startDate = activeDate;
    }
    const startDateString = this.standardizeDate(startDate);
    const weekAfterNow = Moment(startDate).add(maxDaysToDisplay, 'days');

    return this.setState({
      startDate: startDateString,
      startDateMoment: startDate,
      endDate: this.standardizeDate(weekAfterNow),
      todaysDate: nowDateString,
      daysInDropdown: maxDaysToDisplay,
      availabilityData: {},
      selectedDate,
      maxDaysToDisplay,
    });
  }

  setupRefresh = () => {
    const refreshInterval = setInterval(() => {
      if (!this.props.mobile || this.state.refreshing) {
        this.refreshAvailability(this.state.startDate, this.state.endDate);
      }
    }, this.state.maxCountdownTime);
    this.setState({refreshInterval});
  };

  startInterval = () => {
    this.setState({refreshing: true});
    this.refreshAvailability(this.state.startDate, this.state.endDate);
  };

  stopInterval = () => {
    this.setState({refreshing: false});
  };

  refreshAvailabilityUnthrottled = async (startDate, endDate) => {
    // adventure and site will always be an object so check if they have any properties
    if (!Object.keys(this.props.adventure).length || !Object.keys(this.props.site).length || !startDate) {
      return;
    }

    this.setState({ isAvailabilityEnded: false });
    // Only add an additional "maxDaysToDisplay" if we are displaying the desktop availability picker.
    let adjustedEndDate = this.props.mobile ? endDate : Moment(endDate).add(this.state.maxDaysToDisplay, 'days').format('YYYY-MM-DD');
    const dateRangeInDays = Moment(adjustedEndDate).diff(Moment(startDate), 'days');
    // Ensure we don't exceed the APIs limitation of requesting a max of 31 days worth of availability.
    if (dateRangeInDays > 31) {
      adjustedEndDate = Moment(startDate).add(31, 'days').format('YYYY-MM-DD');
    }
    const data = await DIApi.getAvailableShowtimesForExperience(this.props.site.site_id, this.props.adventure.title_id, startDate, adjustedEndDate, true);
    if (!data) {
      return;
    }

    if (this.state.availabilityData && Object.keys(this.state.availabilityData).length > 0 && this.props.mobile) {
      const newStartDate = Moment(startDate).format("YYYY-MM-DD");
      const whereToCutOff = Object.keys(this.state.availabilityData)[this.state.selectedDate];
      if(newStartDate !== whereToCutOff) {
        return;
      }
    }

    let availData = data[this.props.adventure.title_id];
    const lastAvailDateFound = Object.keys(availData).pop();
    // this.state.endDate is always one day ahead of the last date that is displayed in desktop mode.
    const displayEndMoment = Moment(this.state.endDate).subtract(1, 'day');
    if (displayEndMoment.isSameOrAfter(Moment(lastAvailDateFound))) {
      this.setState({ isAvailabilityEnded: true });
    }

    let sorted = {};
    if (Object.keys(this.state.availabilityData).length && !Object.keys(availData).length) {
      // return this.setState({availabilityData: sorted});
      return;
    }

    for (let i = 0; i < this.state.maxDaysToDisplay; i++) {
      const dateStr = Moment(startDate).add(i, 'days').format("YYYY-MM-DD");
      if (dateStr in availData) {
        const slotArray = availData[dateStr];
        const slotDict = slotArray.reduce(function(map, obj) {
          map[obj.time] = obj;
          return map
        }, {});
        sorted[dateStr] = slotDict;
      } else {
        sorted[dateStr] = {};
      }
    }

    let newData = sorted;

    if (this.props.mobile) {
      // const leadingAvailabilityData = this.state.availabilityData.slice(0, this.state.selectedDate);
      // newData = leadingAvailabilityData.concat(sorted);
      newData = {...this.state.availabilityData, ...sorted};
    }

    this.setState({
      availabilityData: newData,
      daysInDropdown: Object.keys(newData).length
    });
  };

  refreshAvailability = debounce(this.refreshAvailabilityUnthrottled, 3000, {
    'leading': true,
  });

  standardizeDate = (date) => {
    return date.format('YYYY-MM-DD');
  };

  componentWillUnmount() {
    clearInterval(this.state.refreshInterval);
    this.refreshAvailability.cancel();
  }

  maxDate = (date = this.state.todaysDate) => {
    const d = Moment(date).add(this.state.maxDaysToDisplay, 'days');
    return this.standardizeDate(d);
  };

  oneAfterMaxDate = () => {
    const d = Moment(this.maxDate(this.state.startDate)).add(1, 'day');
    return this.standardizeDate(d);
  };

  weekBeforeMinDate = () => {
    const adjustedStartDate = Moment(this.state.startDate).subtract(this.state.maxDaysToDisplay, 'days');
    if (adjustedStartDate.startOf('day').isBefore(Moment().startOf('day'))) {
      return [this.standardizeDate(Moment()), false];
    }
    return [this.standardizeDate(adjustedStartDate), true];
  };

  selectDate = (event) => {
    const dateToSelect = parseInt(event.target.id, 10);
    if (0 <= dateToSelect && dateToSelect < this.state.maxDaysToDisplay) {
      this.setState({'selectedDate': dateToSelect})
    }
  };

  selectDateDropdown = (event) => {
    const dateToSelect = parseInt(event.target.value, 10);
    if (0 <= dateToSelect) {
      this.setState({'selectedDate': dateToSelect});
      if (this.props.mobile) {
        this.appendAvailability(dateToSelect);
      }
    }
  };

  appendAvailability = (dateToSelect) => {
    this.stopInterval();
    const startDate = Object.keys(this.state.availabilityData)[dateToSelect];
    this.setState({
      startDate: startDate,
      endDate: this.standardizeDate(Moment(startDate).add(this.state.maxDaysToDisplay, 'days'))
    }, this.startInterval);
  };

  decrementDate = () => {
    if (this.state.selectedDate < 1) {
      if (this.state.startDate === this.state.todaysDate) {
        this.setState({'selectedDate': 0})
      } else {
        const [newStartDate, didLoadFullWeek] = this.weekBeforeMinDate();
        const newEndDate = this.maxDate(newStartDate);
        this.setState({
          startDate: newStartDate,
          endDate: newEndDate,
          selectedDate: didLoadFullWeek ? this.state.maxDaysToDisplay - 1 : 0,
          availabilityData: {}
        });
        this.refreshAvailability(newStartDate, newEndDate);
      }
    } else {
      this.setState({'selectedDate': this.state.selectedDate - 1})
    }
  };

  incrementDate = () => {
    if (this.state.selectedDate >= this.state.maxDaysToDisplay - 1) {
      const newStartDate = this.maxDate(this.state.startDate);
      const newEndDate = this.maxDate(newStartDate);
      this.setState({
        startDate: newStartDate,
        endDate: newEndDate,
        selectedDate: 0,
        availabilityData: {}
      });
      this.refreshAvailability(newStartDate, newEndDate);
    } else {
      this.setState({'selectedDate': this.state.selectedDate + 1})
    }
  };

  formatDate = (date) => {
    return Moment(date).format('ddd, MMM D').toUpperCase();
  };

  formatDatePieces = (date) => {
    return [Moment(date).format('ddd').toUpperCase(), Moment(date).format('MMM D').toUpperCase()]
  };

  render () {
    if (!this.props.site.site_id) {
      return (
        <ComingSoonContainer mobile={this.props.mobile}>
          <ComingSoonBox mobile={this.props.mobile}>
            <ComingSoonTitle mobile={this.props.mobile}>LOCATION REQUIRED</ComingSoonTitle>
            <ComingSoonDescription mobile={this.props.mobile}>Please choose the nearest dreamscape location to see an accurate list of departure times.</ComingSoonDescription>
            <div style={{ marginTop: this.props.mobile ? 52 : 40 }}>
              { this.props.canChangeLocations
                ? <BlueButton
                    onClick={this.props.handleShowLocationDialog}
                    width={this.props.mobile ? '201px' : '292px'}
                  >
                    CHANGE LOCATION
                  </BlueButton>
                : <LoadingSpinner dark/>
              }

            </div>
          </ComingSoonBox>
        </ComingSoonContainer>
      );
    }

    if (!this.props.isTicketingAvailable) {
      const comingSoonTitle = this.props.mobile ? <span>SORRY, NO DEPARTURE TIMES<br/>CURRENTLY AT THIS LOCATION</span> : 'SORRY, NO DEPARTURE TIMES CURRENTLY AT THIS LOCATION';
      return (
        <ComingSoonContainer mobile={this.props.mobile}>
          <ComingSoonBox mobile={this.props.mobile}>
            <ComingSoonTitle mobile={this.props.mobile}>{comingSoonTitle}</ComingSoonTitle>
            <ComingSoonDescription mobile={this.props.mobile}>This adventure will be departing soon. Please continue to check availability or submit your email address below and we’ll be sure to let you know when the adventures begin departing from this location.</ComingSoonDescription>
            <HubspotFormContainer mobile={this.props.mobile}>
              <ComingSoonHubspotForm
                mobile={this.props.mobile}
                portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                formId={process.env.REACT_APP_HUBSPOT_COMING_SOON_SUBSCRIPTION_FORM_ID}/>
            </HubspotFormContainer>
          </ComingSoonBox>
        </ComingSoonContainer>
      );
    }

    if (!this.state.availabilityData || Object.keys(this.state.availabilityData).length === 0) {
      return (
        <div className="spinner">
          <div className="rect1"/>
          <div className="rect2"/>
          <div className="rect3"/>
          <div className="rect4"/>
          <div className="rect5"/>
        </div>
      )
    }

    const currentSelectedDay = Object.keys(this.state.availabilityData)[this.state.selectedDate];
    const currentSelectedMoment = new Moment(currentSelectedDay);
    const rightArrowDisabled = this.state.isAvailabilityEnded && currentSelectedMoment.isSameOrAfter(Moment(this.state.endDate, 'YYYY-MM-DD').subtract(1, 'day'));

    let dateContent = (
      <React.Fragment>
        <ArrowButton onClick={this.decrementDate}
                     disabled={currentSelectedMoment.isSameOrBefore(this.state.startDateMoment)}>
          <Arrow rotate={90} src={downArrow} alt="Left Arrow"/>
        </ArrowButton>
        {
          Object.keys(this.state.availabilityData).map((date, i) => {
            const isSelected = this.state.selectedDate === i;
            const datePieces = this.formatDatePieces(date);
            if (date === Moment().format('YYYY-MM-DD')) {
              datePieces[0] = "TODAY";
            }
            return (
              <DateButton disabled={isSelected} isSelected={isSelected} onClick={this.selectDate} id={i} key={i}>
                <NoClick style={{fontSize: '0.625rem', fontWeight: 'normal'}}>{datePieces[0]}</NoClick>
                <NoClick>{datePieces[1]}</NoClick>
              </DateButton>
            )
          })
        }
        <ArrowButton onClick={this.incrementDate} disabled={rightArrowDisabled}>
          <Arrow rotate={270} src={downArrow} alt="Right Arrow"/>
        </ArrowButton>
      </React.Fragment>
    );

    if (this.props.mobile) {
      dateContent = (
        <LabelDropDownContainer>
          <LabelDropDown
            daysInDropdown={this.state.daysInDropdown}
            availabilityData={this.state.availabilityData}
            selectDateDropdown={this.selectDateDropdown}/>
        </LabelDropDownContainer>
      )
    }

    return (
      <div>
        {dateContent}

        <TimeSelector
          adventure={this.props.adventure}
          site={this.props.site}
          maxSeats={this.props.adventure.max_player_count}
          availabilityData={this.state.availabilityData}
          selectedDate={this.state.selectedDate}/>
      </div>
    );
  }
}

export default withRouter(Scheduler);
