import styled from 'styled-components';

import BaseButton from './BaseButton';

const BlueOutlineButton = styled(BaseButton)`
  background-color: transparent;
  border: 1px solid ${ props => props.theme.ds_bright_blue };
  color: ${ props => props.invert ? 'black' : 'white' };
  transition: ${ props => props.theme.transition };
  
  &:focus {
    background-color: ${ props => props.invert ? props.theme.charcoal_25 : 'white' };
    color: black;
  }
  
  &:active {
    background-color: ${ props => props.theme.ds_bright_blue };
    color: white;
  }
  
  &:hover {
    border: 3px solid ${ props => props.theme.ds_bright_blue };
  }
`;

export default BlueOutlineButton;
