import React, { Component } from 'react';
import styled from 'styled-components';

import DubaiHubspotForm from "../components/hubspot/DubaiHubspotForm";
import { Desktop, Mobile } from "../components/responsive/Breakpoints";
import AdventureItem from "../components/content/AdventureItem";
import { AuthContext } from "../components/context/AuthenticatorContext";

import Logo from '../components/svgs/TitleLogo';
import ImgLogoLarge from '../assets/images/img-logo-l.png';
import MobileLogo from '../components/svgs/TitleLogoMobile';
import LocationIcon from '../components/svgs/WhiteLocationIcon';
import LocationIconMobile from '../components/svgs/WhiteLocationIconMobile';
import background from '../assets/images/img-background-l.jpg';
import CityScape from '../assets/images/img-bottom-l@3x.png';
import CityScapeSmall from '../assets/images/img-bottom-s@3x.png';
import facebook from '../assets/images/img-facebook.svg';
import instagram from '../assets/images/img-instagram.svg';
import twitter from '../assets/images/img-twitter.svg';
import vidPlayButton from '../assets/images/img-play-l@2x.png';
import vidGif from '../assets/videos/dubai_vid_thumb.gif';
import vid from '../assets/videos/dubai_vid.mp4';
import { MOBILE_ADVENTURE_CARD_WIDTH } from "../util/constants";
import HomeAdventuresCarousel from "../components/carousels/HomeAdventuresCarousel";

const RootContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position-x: center;
  color: white;
`;

const Header = styled.header`
  display: flex;
  // height: ${ props => props.isMobile ? '104px' : '140px'};
  margin-top: ${ props => props.isMobile ? '28px' : '46px'};
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${ props => props.isMobile ? '28px' : '44px'};
  align-items: center;
`;

const Title = styled.div`
  font-size: ${ props => props.isMobile ? '1.625rem' : '3.5rem'};
  font-weight: bold;
  letter-spacing: ${ props => props.isMobile ? '-1px' : '2px'};
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${ props => props.isMobile ? '1.25rem' : '2.75rem'};
  font-weight: 300;
`;

const SubTitle = styled.div`
  margin-left: ${ props => props.isMobile ? '5px' : '12px'};
  line-height: ${ props => props.isMobile ? 'normal' : '46px'};
  letter-spacing: ${ props => props.isMobile ? '-0.5px' : '-1px'};
`;

const FormContainer = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${ props => props.isMobile ? '28px' : '40px' };
`;

const FormTitle = styled.div`
  font-size: ${ props => props.isMobile ? '1.125rem' : '1.875rem' };
  padding: 0 22px;
  font-style: italic;
  text-align: center;
  max-width: 520px;
  letter-spacing: -0.6px;
  line-height: ${ props => props.isMobile ? '1.44' : '1.2' };
`;

const DescriptionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${ props => props.isMobile ? '40px' : '52px' };
  text-align: center;
  padding: 0 22px;
  font-size: ${ props => props.isMobile ? '0.875rem' : '1.25rem' };
`;

const VideoContainerSection = styled.section`
  display: flex;
  justify-content: center;
  margin-top: ${ props => props.isMobile ? '40px' : '56px' };
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 652px;
`;

const Media = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const AdventureLineupContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${ props => props.isMobile ? '36px' : '80px' };
`;

const AdventureLineupTitle = styled.div`
  text-align: center;
  padding: 0 32px;
  font-size: ${ props => props.isMobile ? '1.625rem' : '1.875rem' };
  line-height: ${ props => props.isMobile ? '1.31' : 'normal' };
  letter-spacing: ${ props => props.isMobile ? '-0.75px' : '1px' }
  font-weight: bold;
`;

const AdventureCardRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: ${ props => props.theme.content_max_width };
  margin: ${ props => props.isMobile ? '' : '0 22px' };
`;

const CityScapeContainer = styled.section`
  margin-top: ${ props => props.isMobile ? '14px' : '40px' };
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 148px;
  background-color: ${ props => props.theme.charcoal };
  color: ${ props => props.theme.white_80 };
  font-size: ${ props => props.isMobile ? '0.8125rem' : '0.8125rem' };
  text-align: center;
  letter-spacing: ${ props => props.isMobile ? '0.3px' : '0.5px' };
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ADVENTURES_TO_DISPLAY = ['alienzoo01', 'deepblurescue01', 'magicprojector01'];

class DubaiLandingPage extends Component {
  videoRef = null;

  state = {
    hasStartedVideo: false,
  };

  renderAdventureItems (isMobile) {
    const rowMargin = isMobile ? '16px 0px' : null;
    const itemMargin = isMobile ? null : '0 8px';

    const { adventures, cms, selectedSite } = this.props.parentState;

    if (!adventures || !adventures.length) {
      return;
    }

    const items = adventures
      .filter(adventure => ADVENTURES_TO_DISPLAY.includes(adventure.title_id))
      .map((adventure) => {
      const assets = cms ? cms.getAdventureAssets(adventure.title_id, selectedSite.site_id) : {};

      const isAvailable = !!adventure.isAvailableAtCurrentLocation;
      return (
        <AdventureItem
          minimal
          isMobile={isMobile}
          key={ adventure.title_id }
          adventure={adventure}
          assets={assets}
          site={selectedSite}
          margin={itemMargin}
          buttonText={isAvailable ? null : 'LEARN MORE'}
        />
      );
    });

    if (isMobile) {
      return (
        <HomeAdventuresCarousel
          minimal
          isMobile={true}
          items={items}
          itemWidth={MOBILE_ADVENTURE_CARD_WIDTH}/>
      );
    }

    return (
      <AdventureCardRow
        name="adventure-card-row"
        margin={rowMargin}>
        { items }
      </AdventureCardRow>
    );
  }

  renderAdventureLineup (isMobile) {
    return (
      <AdventureLineupContainer isMobile={isMobile}>
        <AdventureLineupTitle isMobile={isMobile}>AN EXCITING LINE UP OF VR ADVENTURES</AdventureLineupTitle>
        { this.renderAdventureItems(isMobile) }
      </AdventureLineupContainer>
    );
  }

  renderPlayButton (isMobile) {
    if (this.state.hasStartedVideo) {
      return;
    }

    return (
        <img
          width={ isMobile ? 75 : 138}
          src={vidPlayButton}
          style={{ position: "absolute" }}
          alt={'Dreamscape preview play button'} />
    );
  }

  handleMediaAreaPressed = (e) => {
    if (!this.state.hasStartedVideo) {
      e.preventDefault();
      this.videoRef.play();
    }

    this.videoRef.setAttribute('controls', 'controls');
    this.setState({ hasStartedVideo: true });
  };

  renderContent (isMobile) {
    const copyrightText = isMobile
                         ? <span>COPYRIGHT © 2019 DREAMSCAPE IMMERSIVE.<br/>ALL RIGHTS RESERVED.</span>
                         : 'COPYRIGHT © 2019 DREAMSCAPE IMMERSIVE. ALL RIGHTS RESERVED.';

    const headline = isMobile
                         ? <span>Become the hero of your own<br/>adventure at Dreamscape.</span>
                         : 'Become the hero of your own adventure at Dreamscape.';
    return (
      <RootContainer id={'dubai-coming-soon'}>
        <Header isMobile={isMobile}>
          <img src={ImgLogoLarge} width={isMobile ? 232 : 300}/>
        </Header>

        <TitleContainer isMobile={isMobile}>
          <Title isMobile={isMobile}>COMING SOON TO DUBAI</Title>
          <SubTitleContainer isMobile={isMobile}>
            { isMobile ? <LocationIconMobile/> : <LocationIcon/> }
            <SubTitle isMobile={isMobile}>AT MALL OF THE EMIRATES</SubTitle>
          </SubTitleContainer>
        </TitleContainer>

        <FormContainer isMobile={isMobile}>
          <FormTitle isMobile={isMobile}>BE THE FIRST TO FIND OUT WHEN TICKETS ARE AVAILABLE</FormTitle>
          <DubaiHubspotForm
            dark
            id={'dubaiSubscribeForm'}
            mobile={isMobile}
            widthPercent={'100'}
            portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
            formId={process.env.REACT_APP_HUBSPOT_DUBAI_FORM_ID}/>
        </FormContainer>

        <DescriptionContainer isMobile={isMobile}>
          <div style={{ fontWeight: 'bold' }}>{headline}</div>
          <span style={{maxWidth: 886, lineHeight: isMobile ? 1.44 : 1.35, marginTop: 10 }}>Dreamscape combines the emotional power of cinematic storytelling with the thrill of theme-park rides, creating the ultimate destination for virtual reality entertainment. Share the wonder of Dreamscape with your family and friends, and together you'll be transported to fantastic worlds beyond imagination.</span>
        </DescriptionContainer>

        <VideoContainerSection isMobile={isMobile}>
          <MediaContainer onClick={this.handleMediaAreaPressed}>
            {/* This image is here to help the MediaContainer respond to the size of our absolute positioned elements */}
            <img style={{ maxWidth: 652, width: '100%' }} src={vidGif} alt={'Dreamscape preview'}/>
            <Media ref={ref => this.videoRef = ref} poster={vidGif}>
              <source src={vid} type="video/mp4" />
            </Media>
            { this.renderPlayButton(isMobile) }
          </MediaContainer>
        </VideoContainerSection>

        { this.renderAdventureLineup(isMobile) }

        <CityScapeContainer isMobile={isMobile}>
          <img style={{display: 'block'}} width={'100%'} src={ isMobile ? CityScapeSmall : CityScape } alt={'Dreamscape\'s Megaraffe visiting Dubai'}/>
        </CityScapeContainer>

        <Footer isMobile={isMobile}>
          <SocialContainer>
            <a href={'https://www.facebook.com/DreamscapeMENA'} target={'_blank'}>
              <img src={facebook} alt={'Facebook link'}/>
            </a>
            <a href={'https://www.instagram.com/dreamscapeMENA'} target={'_blank'} style={{ marginLeft: 18 }}>
              <img src={instagram} alt={'Instagram link'} />
            </a>
            <a href={'https://twitter.com/DreamscapeMENA'} target={'_blank'} style={{ marginLeft: 18 }}>
              <img src={twitter} alt={'Twitter link'}/>
            </a>
          </SocialContainer>
          <div style={{ marginTop: isMobile ? 18 : 24 }}>{copyrightText}</div>
        </Footer>
      </RootContainer>
    );
  }

  render () {
    return (
      <React.Fragment>
        <Desktop>
          {this.renderContent(false)}
        </Desktop>
        <Mobile>
          {this.renderContent(true)}
        </Mobile>
      </React.Fragment>
    );
  }
}


const LandingPage = props => (
  <AuthContext.Consumer>
    { parentState => <DubaiLandingPage {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default LandingPage;
