import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll'

import AdventureHeroBlock from '../components/AdventureHeroBlock';
import ContentBlock from '../components/ContentBlock';
import QuoteBlock from '../components/QuoteBlock';
import ItineraryBlock from '../components/ItineraryBlock';
import SchedulerContainer from '../components/SchedulerContainer';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { checkIsTicketingAvailableForSiteAdventure, getAdventureStatus } from '../util/common';
import { sendProductDetailsImpression } from "../util/analytics";
import HeaderMeta from "../components/HeaderMeta";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";

class SingleAdventureWithContext extends Component {
  state = {
    adventure: {},
    site: {},
  };

  scrollTo = (id, offset) => {
    scroller.scrollTo(id, {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset,
    });
  };

  autoScroll = (id) => {
    const toasterOffset = this.props.parentState.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    if (this.props.location.state) {
      if (this.props.location.state.toAvailability) {
        this.scrollTo(id, -document.getElementById('navbar').offsetHeight -toasterOffset);
      } else if (this.props.location.state.toQuoteBlock) {
        this.scrollTo('quoteBlock', -document.getElementById('navbar').offsetHeight -toasterOffset);
      }
    }
  };

  componentDidMount () {
    this.checkLoadingData();
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.checkLoadingData();

    if (!Object.keys(prevState.adventure).length && Object.keys(this.state.adventure).length) {
      // We now have the adventure details loaded into state for the first time
      const titleId = this.props.match.params.title_id;
      // google tag manager
      sendProductDetailsImpression(titleId, this.state.adventure.shortname);
    }
  }

  checkLoadingData = () => {
    if (!this.props.parentState.adventures.length || !this.props.parentState.site) {
      return;
    }

    const titleId = this.props.match.params.title_id;

    const adventuresMap = this.props.parentState.adventures.reduce( (acc, adventure) => {
      return {
        ...acc,
        [adventure.title_id]: adventure,
      };
    }, {});
    const adventure = adventuresMap[titleId];

    // This check is when this component is updated to show to details of a different adventure. This will
    // update the currently selected "state.adventure" to the adventure data from the id in the query string only
    // if the current selected "this.state.adventure.title_id" does not equal the "title_id" from the query string
    if (adventuresMap && (!this.state.adventure.title_id || this.state.adventure.title_id !== titleId)) {
      this.setState({ adventure });
    }

    // Update site and adventure information if the site has been changed
    if (this.state.site.site_id !== this.props.parentState.selectedSite.site_id) {
      this.setState({ site: this.props.parentState.selectedSite, adventure });
    }
  };

  //TODO: Might need to check for more cases where "Coming Soon" may not make sense, such as an adventure that will never be available to a site.
  checkIsTicketingAvailable () {
    // If no site has been selected, default to showing 'GET TICKETS'
    if (!this.state.site.site_id) {
      return false;
    }

    if (!this.state.adventure || !this.state.adventure.licensed_site || !this.state.adventure.licensed_site.ticketing_active_time) {
      return false;
    }

    return checkIsTicketingAvailableForSiteAdventure(this.state.adventure, this.state.site);
  }

  render () {
    const { cms, selectedSite, hasStoredSite } = this.props.parentState;
    const detailsAssets = cms.getAdventureDetailsAssets(this.state.adventure.title_id, selectedSite.site_id);
    const adventureAssets = cms.getAdventureAssets(this.state.adventure.title_id, selectedSite.site_id);
    const siteAssets = cms.getSiteAssets(selectedSite.site_id);
    const adventureStatus = adventureAssets.status ? adventureAssets.status[getAdventureStatus(this.state.adventure, selectedSite)] : null;
    const isTicketingAvailable = this.checkIsTicketingAvailable();

    // Prevent displaying layouts until we have the info we need.
    if (!this.state.adventure.title_id || !detailsAssets || !siteAssets) {
      return (<div style={{ height: '100%' }}></div>);
    }

    return (
      <div>
        <HeaderMeta page={this.props.match.params.title_id}/>
        <AdventureHeroBlock
          contentData={detailsAssets}
          adventure={this.state.adventure}
          isTicketingAvailable={isTicketingAvailable}
          statusMessage={adventureStatus}
          hasStoredSite={hasStoredSite}/>
        <QuoteBlock autoScroll={this.autoScroll} contentData={detailsAssets} adventure={this.state.adventure}/>
        <ContentBlock contentData={detailsAssets} adventure={this.state.adventure} isTicketingAvailable={isTicketingAvailable} hasStoredSite={hasStoredSite}/>
        <ItineraryBlock contentData={detailsAssets} adventure={this.state.adventure} siteCopy={siteAssets.copy}/>
        <SchedulerContainer
          siteCopyAssets={siteAssets.copy}
          adventure={this.state.adventure}
          site={this.state.site}
          autoScroll={this.autoScroll}
          handleShowLocationDialog={this.props.parentState.handleShowLocationDialog}
          isTicketingAvailable={isTicketingAvailable}
          canChangeLocations={this.props.parentState.sites.length > 1}
        />
      </div>
    );
  }
}

const SingleAdventure = props => (
  <AuthContext.Consumer>
    { parentState => <SingleAdventureWithContext {...props} parentState={parentState}/> }
  </AuthContext.Consumer>
);

export default withRouter(SingleAdventure);
