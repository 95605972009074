import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Route,
  withRouter
} from 'react-router-dom';
import Moment from 'moment';

import CheckoutSignIn from './pages/CheckoutSignIn';
import CheckoutSignUp from './pages/CheckoutSignUp';
import CheckoutPaymentConfirm from './pages/CheckoutPurchaseConfirm';
import CheckoutReceipt from './pages/CheckoutReceipt';
import CheckoutTicketSelector from './pages/CheckoutTicketSelector';
import { AuthContext } from './components/context/AuthenticatorContext';
import { DIApi } from './util/api';
import { CLOSING_MESSAGE } from './util/constants';
import CheckoutNavBar from './components/CheckoutNavbar';
import NavbarSpacer from './components/NavbarSpacer';
import ForgotPassword from "./ForgotPassword";

const CheckoutContainer = styled.div`
  width: 100%;
`;

class Checkout extends Component {
  state = {
    ticketHold: null,
    holdTimeString: null,
    headerText: null,
    cancelClicked: false,
    handleOnBackClicked: null,
    isProcessingPurchase: false,
    unblock: () => {},
  };

  setupTimer = () => {
    // If we already have an intervalId registered OR we don't have the ticketHold object yet,
    // OR we don't have the setReleaseTickets function from the parentState yet
    // don't bother setting up the timer
    if (this.state.intervalId ||
      !this.props.location ||
      !this.props.location.state ||
      !this.props.location.state.ticketHold ||
      !this.props.parentState.setReleaseTickets ||
      this.props.location.pathname.match(/reserve|receipt/)
    ) {
      return;
    }

    // From here, we should not have an interval setup yet AND we should have ticketHold Data
    const ticketHold = this.props.location.state.ticketHold;
    this.props.parentState.setReleaseTickets(() => {
      return DIApi.releaseHeldTickets(ticketHold.partner_site_id, ticketHold.partner_order_id);
    });

    this.setState({ ticketHold });

    const intervalId = setInterval(() => {
      if (this.state.isProcessingPurchase) {
        return this.setState({ headerText: 'SPOTS RESERVED FOR --:--' });
      }

      const releaseTime = new Moment(this.state.ticketHold.release_hold_at);
      const now = new Moment();
      const diff = Moment.duration(releaseTime.diff(now));

      if (diff.asSeconds() <= 0) {
        this.clearTimer();
        // TODO: Show messaging to the user that time has run out and will need to re-select their tickets.
        alert('We\'re sorry but the hold on your tickets has expired.');
        this.state.unblock();
        this.handleCancelClicked();
        return;
      }

      const minutes = diff.minutes();
      const seconds = diff.seconds() < 10 ? '0' + diff.seconds().toString() : diff.seconds();
      const timerText = `SPOTS RESERVED FOR ${minutes}:${seconds}`;
      this.setState({ headerText: timerText });
    }, 1000);

    this.setState({ intervalId });
  };

  handleProcessingPurchase = (isProcessingPurchase) => {
    this.setState({ isProcessingPurchase });
  };

  resetCancelClicked = () => {
    this.setState({ cancelClicked: false });
  };

  clearTimer = () => {
    clearInterval(this.state.intervalId);
  };

  updateHeaderText = (headerInfo) => {
    const { title, handleOnBackClicked } = headerInfo;
    const headerTitle = title || 'SPOTS RESERVED FOR --:--';
    this.setState({
      headerText: headerTitle,
      handleOnBackClicked: handleOnBackClicked ? () => handleOnBackClicked() : null,
    });
  };

  handleCancelClicked = () => {
    this.setState({ cancelClicked: true }, () => {
      this.props.history.replace(`/adventures/details/${this.props.location.state.adventure.title_id}`, {
        site: this.props.location.state.site,
        adventure: this.props.location.state.adventure,
      });
    });
  };

  componentDidMount () {
    // This is our conditional navigation block that will help protect the checkout flow from unintentional outside
    // navigation events. A dialog will be presented to the user to ask for confirmation before leaving the page. This
    // provides use the opportunity to release the tickets that the user may have held.
    const unblock = this.props.history.block((location) => {
      // If the cancel button is pressed on the /checkout/reserve page, then DO NOT present closing dialog since
      // Tickets have not actually been held yet.
      if (this.state.cancelClicked && this.props.location.pathname.match(/reserve|receipt/)) {
        return;
      }

      // If we are beyond the /checkout/reserve page, then any detection of cancelClicked should trigger the dialog
      if (this.state.cancelClicked) {
        this.resetCancelClicked();
        return CLOSING_MESSAGE;
      }

      // If cancel is not clicked and the user is still attempting to navigate away from the checkout flow, present
      // the dialog warning them that confirming will release their hold on the tickets.
      if (!location.pathname.match(/checkout/) && !this.props.location.pathname.match(/reserve|receipt/)) {
        return CLOSING_MESSAGE;
      }
    });
    this.setState({ unblock });
    this.setupTimer();
  }

  componentDidUpdate () {
    this.setupTimer();
  }

  componentWillUnmount () {
    this.state.unblock();
    this.clearTimer();
  }

  //TODO: THIS SHOULD BE TEMPORARY UNTIL WE REFACTOR CYBERSOURCE PAYMENT FORM AWAY FROM USING POPUPS
  renderPopupBlockerNotification () {
    if (this.props.location.state &&
      this.props.location.state.site &&
      this.props.location.state.site.site_id === 'AE000001') {
        return (
          <div style={{
            backgroundColor: 'coral',
            padding: '4px 0',
            textAlign: 'center',
            fontWeight: 'bold'
          }}>
            Please disable your popup blocker before submitting your payment
          </div>
        );
    }

    return null;
  }

  render() {
    return (
      <CheckoutContainer>
        {/* {this.renderPopupBlockerNotification()} */}
        <CheckoutNavBar title={this.state.headerText} onCancel={ this.handleCancelClicked } onBack={ this.state.handleOnBackClicked }/>
        <NavbarSpacer height={48} />

        <ForgotPassword path="/checkout/signin" updateHeaderText={this.updateHeaderText} bgOn isCheckout />

        <Route exact path="/checkout/reserve" render={() => (
          <CheckoutTicketSelector updateHeaderText={this.updateHeaderText} />
        )}/>
        <Route exact path="/checkout/signin" render={ () => (
          <CheckoutSignIn updateHeaderText={this.updateHeaderText} />
        ) }/>
        <Route exact path="/checkout/signup" render={ () => (
          <CheckoutSignUp updateHeaderText={this.updateHeaderText} />
        )} />
        <Route exact path="/checkout/payment" render={ () => (
          <CheckoutPaymentConfirm updateHeaderText={this.updateHeaderText} handleProcessingPurchase={ this.handleProcessingPurchase }/>
        )} />
        <Route exact path="/checkout/receipt" render={() => (
          <CheckoutReceipt updateHeaderText={this.updateHeaderText} unblock={this.state.unblock} clearTimer={this.clearTimer} />
        )}/>
      </CheckoutContainer>
    );
  }
}

const CheckoutController = props => (
  <AuthContext.Consumer>
    { parentState => <Checkout {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(CheckoutController);
