import React from 'react';

export default () => (
  <svg width="117" height="140" viewBox="0 0 117 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_207_27755)">
      <rect x="1.5" y="1.5" width="114" height="137" rx="3.5" stroke="#2E3191" strokeWidth="3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M66 32.1716L88.0416 10.13L90.8701 12.9584L68.8284 35.0001L66 32.1716Z" fill="#2E3191"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M75 10L91 10V14L75 14L75 10Z" fill="#2E3191"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M87 26V10L91 10V26H87Z" fill="#2E3191"/>
      <path d="M35.7877 119.544H31.2277V122.296H35.0677V124.328H31.2277V129H28.7637V117.48H35.7877V119.544Z" fill="#1B1B1B" fillOpacity="0.9"/>
      <path d="M37.3714 123.24C37.3714 119.928 39.9154 117.256 43.4034 117.256C46.8754 117.256 49.4354 119.912 49.4354 123.24C49.4354 126.6 46.8914 129.224 43.4034 129.224C39.9154 129.224 37.3714 126.6 37.3714 123.24ZM39.8514 123.24C39.8514 125.304 41.3234 126.968 43.4034 126.968C45.4834 126.968 46.9554 125.304 46.9554 123.24C46.9554 121.192 45.4834 119.512 43.4034 119.512C41.3234 119.512 39.8514 121.192 39.8514 123.24Z" fill="#1B1B1B" fillOpacity="0.9"/>
      <path d="M51.8574 117.48H55.9054C58.5774 117.48 60.3534 118.808 60.3534 121.272C60.3534 122.952 59.4094 124.056 58.1614 124.552L60.5774 129H57.9694L55.9374 124.968H54.2734V129H51.8574V117.48ZM55.9694 119.544H54.2734V122.984H56.0334C57.1214 122.984 57.8734 122.44 57.8734 121.272C57.8734 120.12 57.0894 119.544 55.9694 119.544Z" fill="#1B1B1B" fillOpacity="0.9"/>
      <path d="M70.5992 129H62.9512V117.48H70.3592V119.544H65.4152V122.168H69.6072V124.152H65.4152V126.936H70.5992V129Z" fill="#1B1B1B" fillOpacity="0.9"/>
      <path d="M75.894 129.224C74.582 129.224 73.302 128.936 72.47 128.568V126.2C73.27 126.616 74.326 126.968 75.366 127.032C75.734 127.048 76.086 127.032 76.39 126.968C77.27 126.84 77.782 126.504 77.782 125.944C77.782 125.176 77.094 124.776 75.51 124.2C73.494 123.464 72.31 122.616 72.31 120.616C72.31 118.568 74.006 117.256 76.486 117.256C77.638 117.256 78.774 117.512 79.494 117.832V120.104C78.822 119.704 77.734 119.4 76.678 119.4C75.494 119.4 74.726 119.864 74.726 120.6C74.726 121.272 75.35 121.736 76.918 122.328C78.934 123.112 80.182 123.96 80.182 125.896C80.182 127.976 78.614 129.224 75.894 129.224Z" fill="#1B1B1B" fill-opacity="0.9"/>
      <path d="M90.322 119.544H87.09V129H84.626V119.544H81.41V117.48H90.322V119.544Z" fill="#1B1B1B" fillOpacity="0.9"/>
      <path d="M40.7727 64.22V70.565C38.8377 69.575 35.7777 68.315 31.8177 68.315C25.2477 68.315 21.1077 73.04 21.1077 78.8C21.1077 84.605 25.2027 89.285 31.5927 89.285C35.7777 89.285 38.9727 88.07 40.9977 87.035V93.47C39.3327 94.37 35.6427 95.63 31.0977 95.63C21.3327 95.63 14.1777 88.295 14.1777 78.8C14.1777 69.35 21.3327 61.97 31.3227 61.97C35.8677 61.97 39.1077 63.32 40.7727 64.22Z" fill="#1B1B1B"/>
      <path d="M66.4226 68.405H53.5976V76.145H64.3976V81.86H53.5976V95H46.6676V62.6H66.4226V68.405Z" fill="#1B1B1B"/>
      <path d="M69.8769 78.8C69.8769 69.485 77.0319 61.97 86.8419 61.97C96.6069 61.97 103.807 69.44 103.807 78.8C103.807 88.25 96.6519 95.63 86.8419 95.63C77.0319 95.63 69.8769 88.25 69.8769 78.8ZM76.8519 78.8C76.8519 84.605 80.9919 89.285 86.8419 89.285C92.6919 89.285 96.8319 84.605 96.8319 78.8C96.8319 73.04 92.6919 68.315 86.8419 68.315C80.9919 68.315 76.8519 73.04 76.8519 78.8Z" fill="#1B1B1B"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.0471 26.4286C44.1102 26.848 44.1429 27.2773 44.1429 27.7143C44.1429 32.4482 40.3053 36.2857 35.5714 36.2857C30.8376 36.2857 27 32.4482 27 27.7143C27 24.1995 29.1156 21.1787 32.1429 19.8561V14.5714C32.1429 14.0191 31.6951 13.5714 31.1429 13.5714H27V11H30.7143C32.9234 11 34.7143 12.7909 34.7143 15V15.2857H40.1429C42.352 15.2857 44.1429 17.0766 44.1429 19.2857V23.8571H47.4889C48.918 23.8571 50.2385 24.6195 50.9531 25.8571L52.3231 28.2301C52.5873 28.173 52.8616 28.1429 53.1429 28.1429C55.2731 28.1429 57 29.8698 57 32C57 34.1302 55.2731 35.8571 53.1429 35.8571C51.0126 35.8571 49.2857 34.1302 49.2857 32C49.2857 31.0855 49.6039 30.2454 50.1357 29.5843L48.6024 26.9286C48.4238 26.6192 48.0937 26.4286 47.7364 26.4286H44.0471ZM41.5714 21.5931V18.8571C41.5714 18.3049 41.1237 17.8571 40.5714 17.8571H34.7143V19.1852C34.9962 19.1572 35.2821 19.1429 35.5714 19.1429C37.9075 19.1429 40.0254 20.0774 41.5714 21.5931ZM35.5714 33.7143C38.8851 33.7143 41.5714 31.028 41.5714 27.7143C41.5714 24.4006 38.8851 21.7143 35.5714 21.7143C32.2577 21.7143 29.5714 24.4006 29.5714 27.7143C29.5714 31.028 32.2577 33.7143 35.5714 33.7143ZM54.4286 32C54.4286 32.7101 53.8529 33.2857 53.1429 33.2857C52.4328 33.2857 51.8571 32.7101 51.8571 32C51.8571 31.2899 52.4328 30.7143 53.1429 30.7143C53.8529 30.7143 54.4286 31.2899 54.4286 32ZM35.5807 29.8571C36.7642 29.8571 37.7236 28.8978 37.7236 27.7143C37.7236 26.5308 36.7642 25.5714 35.5807 25.5714C34.3973 25.5714 33.4379 26.5308 33.4379 27.7143C33.4379 28.8978 34.3973 29.8571 35.5807 29.8571Z" fill="#2E3191"/>
      <path d="M6 106V105H111V106H6Z" fill="#1B1B1B" fillOpacity="0.25"/>
      <path d="M6 44V43H111V44H6Z" fill="#1B1B1B" fillOpacity="0.25"/>
    </g>
    <defs>
      <clipPath id="clip0_207_27755">
      <rect width="117" height="140" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
