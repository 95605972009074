import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import DownArrowIcon from '../svgs/DownArrowIcon';
import theme from '../../util/theme';

const CarouselPosInd = styled.div`
  background-color: ${ props => props.dark ? props.theme.charcoal_25 : props.theme.white_25 };
  height: 10px !important;
  width: 10px;
  border-radius: 5px;
`;

const WrapSlider = styled(Slider)`
  display: flex;
  justify-content: center;
  height: 100%;
  
  & li {
    flex: 1 1;
    height: auto;
    margin: 6px 4px;
    width: 10px;
    border-radius: 5px;
  }
  
  & .slick-dots {
    display: flex;
    bottom: 0;
    align-items: center;
    height: 10%;
    position: absolute;
    left: 0;
  }
  
  & .slick-dots > ul {
    width: min-content;
    margin: 16px auto;
  }
  
  & .slick-list, .slick-track, .slick-slide div {
    height: 100% !important;
  }
  
  & .slick-dots .slick-active {
    background-color: #00b8f1;
  }
  
  @media (max-width: 767px) {
    .slick-dots {
      display: flex;
      bottom: 0;
      width: 100%;
      justify-content: center;
    }
  }
`;

const StyledSlickDots = styled.ul`
  display: flex;
  bottom: 0;
  width: 100%;
  padding: 0;
  align-self: flex-end;
`;

const CarouselContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: ${ props => props.mobile && props.top ? '48px' : undefined };
  box-sizing: border-box;
`;

const PageArrow = styled.div`
  color: ${ props => props.theme.white_70 };
  margin: ${ props => props.left ? '12px 0 12px 25px' : '12px 25px 12px 0' };
  cursor: pointer;
  height: 81px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ props => props.theme.charcoal_90 };
  border-radius: 3px;
  position: absolute;
  ${ props => props.left ? 'left' : 'right' }: 0;
`;

const DotsContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
`;

export default class StandardCarousel extends Component {
  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  render () {
    const sliderSettings = {
      adaptiveHeight: true,
      dots: true,
      draggable: true,
      fade: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 20000,
      pauseOnHover: false,
      beforeChange: (oldIndex, newIndex) => {
        if (this.props.captureIndex) {
          this.props.captureIndex(newIndex);
        }
      },
      customPaging: () => {
        return (
          <CarouselPosInd dark={this.props.dark}/>
        );
      },
      appendDots: dots => {
        let leftArrow = null;
        let rightArrow = null;
        if (this.props.displayLeftArrow) {
          leftArrow = (
            <PageArrow onClick={this.previous} left>
              <DownArrowIcon color={theme.white_70} rotate={90}/>
            </PageArrow>
          )
        }
        if (this.props.displayRightArrow) {
          rightArrow = (
            <PageArrow onClick={this.next}>
              <DownArrowIcon color={theme.white_70} rotate={-90}/>
            </PageArrow>
          )
        }
        return (
          <div>
            {leftArrow}
            <DotsContainer>
              <StyledSlickDots>{dots}</StyledSlickDots>
            </DotsContainer>
            {rightArrow}
          </div>
        )
      }
    };

    return (
      <CarouselContainer mobile={this.props.mobile} top={this.props.top}>
        <WrapSlider mobile={this.props.mobile} ref={ ref => this.slider = ref } { ...sliderSettings }>
          { this.props.items }
        </WrapSlider>

        {/* These would likely be elements that should sit within and "above" the carousel */}
        { this.props.children }
      </CarouselContainer>
    );
  }
};
