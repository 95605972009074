import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile } from "../components/responsive/Breakpoints";
import styled from 'styled-components';

import ContentItems from '../components/content/AdventureContentSlides';
import StandardCarousel from '../components/carousels/StandardCarousel';
import WhiteButton from "./buttons/WhiteButton";
import BlueButton from "./buttons/BlueButton";
import {scroller} from "react-scroll/modules";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";

const Container = styled.div`
  background-color: gray;
  height: 768px;
  
  & .slick-track {
    height: unset;
  }
  
  @media (max-width: 767px) {
    .slick-dots {
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      justify-content: center;
    }
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 67px;
  max-width: 1170px;
  ${ props => props.mobile ? 'width: 100%;' : '' }
  ${ props => props.mobile ? 'text-align: center;' : 'margin: 0 auto; left: 0; right: 0;' }
  ${ props => props.mobile ? '' : 'padding: 0 101px;' }
  justify-content: ${ props => props.mobile ? 'center' : undefined };
`;

const GetTicketsButton = styled(WhiteButton)`
  ${ props => props.mobile ? 'flex: 1;' : '' }
  ${ props => props.mobile ? 'max-width: 100%;' : '' }
  ${ props => props.mobile ? 'margin: 0 22px;' : '' }
  max-width: 360px;
`;

const DarkGetTicketsButton = styled(BlueButton)`
  ${ props => props.mobile ? 'flex: 1;' : '' }
  ${ props => props.mobile ? 'max-width: 100%;' : '' }
  ${ props => props.mobile ? 'margin: 0 22px;' : '' }
  max-width: 360px;
`;

class ContentBlock extends Component {
  scrollPage = (val, offset) => {
    scroller.scrollTo('timeSelector', {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset,
    });
  };

  handleScrollToAdvSection = () => {
    const toasterOffset = this.props.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    this.scrollPage(document.getElementById('home').offsetHeight, -document.getElementById('navbar').offsetHeight -toasterOffset);
  };

  render () {
    const ticketButtonLabel = 'GET TICKETS';
    const slidesArr = this.props.contentData.slides.config.slideList.map(slide => this.props.contentData.slides[slide]);
    const textDark = this.props.contentData.slides.config.textDark;
    const adventureId = this.props.adventure.title_id;

    return (
      <div style={{position: 'relative'}}>
        <Desktop>
          <Container id="home">
            <StandardCarousel dark={textDark} items={ContentItems(slidesArr, textDark, this.props.contentData.slides.config.contain, adventureId)}/>
          </Container>
          <ButtonContainer>
            { textDark
              ? <DarkGetTicketsButton mobile={false} onClick={ this.handleScrollToAdvSection }>{ticketButtonLabel}</DarkGetTicketsButton>
              : <GetTicketsButton mobile={false} onClick={ this.handleScrollToAdvSection }>{ticketButtonLabel}</GetTicketsButton>
            }
          </ButtonContainer>
        </Desktop>

        <Mobile>
          <Container id="home">
            <StandardCarousel mobile dark={textDark} items={ContentItems(slidesArr, textDark, this.props.contentData.slides.config.contain, adventureId)}/>
          </Container>
          <ButtonContainer mobile>
            { textDark
              ? <DarkGetTicketsButton mobile={false} onClick={ this.handleScrollToAdvSection }>{ticketButtonLabel}</DarkGetTicketsButton>
              : <GetTicketsButton mobile={false} onClick={ this.handleScrollToAdvSection }>{ticketButtonLabel}</GetTicketsButton>
            }
          </ButtonContainer>
        </Mobile>
      </div>
    );
  }
}

export default withRouter(ContentBlock);
