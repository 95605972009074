import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Import sections
import HeroSection from '../components/home/HeroSection';
import { AuthContext } from "../components/context/AuthenticatorContext";
import HeaderMeta from '../components/HeaderMeta';
import AnnouncementModal from "../components/AnnouncementModal";
import { Desktop, Mobile} from "../components/responsive/Breakpoints";

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

class HomeWithContext extends Component {
  render () {
    const { cms, selectedSite, hasStoredSite } = this.props.parentState;

    return (
      <Container id="home">
        <Desktop>
          <AnnouncementModal />
        </Desktop>
        <Mobile>
          <AnnouncementModal isMobile={true} />
        </Mobile>

        <HeaderMeta page={'home'}/>
        <HeroSection
          cms={cms}
          selectedSite={selectedSite}
          hasStoredSite={hasStoredSite}
        />
      </Container>
    );
  }
}

const Home = props => (
  <AuthContext.Consumer>
    { parentState => <HomeWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(Home);
