import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Large, Phone } from '../components/responsive/Breakpoints';
import styled from 'styled-components';
import NavSpacer from '../components/NavbarSpacer';
import dsLogo from '../assets/images/global-assets-logo-logo-stacked-black.svg';
import HeaderMeta from "../components/HeaderMeta";

const Container = styled.div`
  background: #fff;
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${ props => props.mobile ? '6px 22px 56px' : '77px 98px 119px' };
  max-width: 1170px;
  margin: 0 auto;
`;

const HeaderTextContainer = styled.div`
  text-align: left;
  margin-right: ${ props => props.mobile ? undefined : '36px'};
`;

const HeaderText = styled.div`
  padding-bottom: ${ props => props.mobile ? '28px' : '20px' };
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.2' : '1.09' };
  color: ${ props => props.theme.charcoal };
`;

const SubHeaderText = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '0.875' : '1' }rem;
  color: ${ props => props.theme.charcoal_60 };
  margin: 0 auto;
  max-width: 713px;
`;

const ThingsToKnowTable = styled.div`
  width: 100%;
  text-align: left;
`;

const FlexTr = styled.div`
  display: flex;
  flex-direction: ${ props => props.mobile ? 'column-reverse' : 'row' };
  margin-top: ${ props => props.mobile ? '55' : '48' }px;
`;

const SpacerTd = styled.div`
  width: 40px;
`;

const ThingToKnow = styled.div`
  flex: 1;
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
`;

const TTKHeader = styled.div`
  margin: 11px 0 ${ props => props.press ? '16' : '12' }px 0;
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.mobile ? '1.375' : '2.25' }rem;
  line-height: ${ props => props.mobile ? '1.29' : '1.19' };
  color: ${ props => props.theme.charcoal_90 };
`;

const TTKBody = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: 1rem;
  color: ${ props => props.theme.charcoal_60 };
`;

const TextAndImageHolder = styled.div`
  display: flex;
  flex-direction: ${ props => props.mobile ? 'column-reverse' : 'row' };
  justify-content: space-between
`;

const ImageHolder = styled.div`
  padding-bottom: 53px;
`;

const EmailLink = styled.a`
  cursor: pointer;
  color: ${ props => props.theme.ds_dusk_blue };
  text-decoration: none;
`;

const BoldLinkText = styled.a`
  font-weight: bold;
  cursor: pointer;
  color: ${ props => props.theme.charcoal_90 };
  text-decoration: underline;
`;

const PressBlock = styled.div`
  padding-top: ${ props => props.mobile ? '20' : '11' }px;
`;

class PressKit extends Component {
  pressBlock (mobile, order, newsSource, date, articleTitle, articleLink) {
    return (
      <PressBlock key={order} mobile={mobile}>
        {newsSource} - {date}
        <br/>
        <BoldLinkText href={articleLink}>{articleTitle}</BoldLinkText>
        <br/>
        <br/>
      </PressBlock>
    )
  }

  renderWelcomeBlock (mobile) {
    const pressBlockContent = [
      [
        "Buzzfeed",
        "Krista Torres 1/7/2019",
        "There Is Now A Virtual Reality Movie Experience And Here's What It's Like",
        "https://www.buzzfeed.com/kristatorres/there-is-now-a-virtual-reality-movie-experience-and-heres"
      ],
      [
        "Forbes",
        "David Bloom 12/14/2018",
        "Spielberg-Backed Dreamscape Opens First Permanent VR Storefront",
        "https://www.forbes.com/sites/dbloom/2018/12/14/dreamscape-immersive-los-angeles-opening-stephen-spielberg-hans-zimmer-amc/#620a158a696a"
      ],
      [
        "Fortune",
        "Aric Jenkins 6/20/2019",
        "The Fall and Rise of VR: The Struggle to Make Virtual Reality Get Real",
        "https://fortune.com/longform/virtual-reality-struggle-hope-vr/"
      ],
      [
        "KTLA",
        "Rich Demuro 12/18/2018",
        "Dreamscape Looks to Reinvent the Movie Night with Virtual Reality",
        "https://ktla.com/2018/12/19/dreamscape-immersive-century-city-review/"
      ],
      [
        "Los Angeles Daily News",
        "Sandra Barrera 12/14/2018",
        "Swim with whales at a mall as Dreamscape Immersive opens its first VR storefront",
        "https://www.dailynews.com/2018/12/14/swim-with-whales-at-a-mall-as-dreamscape-immersive-opens-its-first-vr-storefront/"
      ],
      [
        "The Verge",
        "Bryan Bishop 1/15/2019",
        "Dreamscape Immersive wants to bring location-based VR to the masses, starting with a shopping mall",
        "https://www.theverge.com/2019/1/15/18156854/dreamscape-immersive-virtual-reality-los-angeles-walter-parkes-bruce-vaughn"
      ],
      [
        "UploadVR",
        "Tatjana Vejnovic 3/5/2019",
        "Dreamscape’s The Blu: Deep Rescue Is a Tranquil VR Experience",
        "https://uploadvr.com/dreamscape-immersive-blu-deep-rescue/"
      ],
      [
        "VOA",
        "Elizabeth Lee 4/24/2019",
        "Multisensory VR Allows Users to Step Into a Movie and Interact with Objects",
        "https://www.voanews.com/silicon-valley-technology/multisensory-vr-allows-users-step-movie-and-interact-objects"
      ],
      [
        "We Like L.A.",
        "Juliet Bennett Rylah 1/10/2019",
        "Century City’s Dreamscape Immersive is VR You Have to Experience to Believe",
        "https://www.welikela.com/century-citys-dreamscape-immersive-is-vr-you-have-to-experience-to-believe/"
      ],
      [
        "Westside Mommy",
        "Josh Hunt 4/6/2019",
        "Dreamscape VR Experience at Westfield Century City",
        "https://www.westsidemommy.com/site/dreamscape-vr-experience-at-westfield-century-city/"
      ]
    ];

    return (
      <Container>
        <HeaderMeta page={'press'}/>
        <NavSpacer/>
        <TextContainer mobile={mobile}>
          <TextAndImageHolder mobile={mobile}>
            <HeaderTextContainer mobile={mobile}>
              <HeaderText mobile={mobile}>PRESS</HeaderText>
              <SubHeaderText mobile={mobile}>
                <span>Exciting things are happening at Dreamscape. For press inquiries, please contact: Jeanne Zacarias at </span>
                <EmailLink href="mailto:jzacarias@dreamscapevr.com">jzacarias@dreamscapevr.com</EmailLink>
                <br/><br/>
                <span>Curious what people are saying about Dreamscape? Check out our latest press coverage below.</span>
              </SubHeaderText>
            </HeaderTextContainer>
            <ImageHolder mobile={mobile}>
              <img src={dsLogo} alt="DI Logo"/>
            </ImageHolder>
          </TextAndImageHolder>
          <ThingsToKnowTable>
            <FlexTr mobile={mobile}>
              <ThingToKnow>
                <TTKHeader mobile={mobile} press>Press</TTKHeader>
                <TTKBody>
                  {pressBlockContent.map((blockContent, index) => {
                    return this.pressBlock(mobile, index, ...blockContent);
                  })}
                </TTKBody>
              </ThingToKnow>
              {!mobile &&
              <SpacerTd/>
              }
            </FlexTr>
          </ThingsToKnowTable>
        </TextContainer>
      </Container>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Large>
          {this.renderWelcomeBlock(false)}
        </Large>
        <Phone>
          {this.renderWelcomeBlock(true)}
        </Phone>
      </React.Fragment>
    );
  }
}

export default withRouter(PressKit);
