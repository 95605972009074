import React, { Component } from 'react';
import styled from 'styled-components';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  & td, th {
    border: 1px solid black;
    text-align: left;
    padding: 6px 12px;
    word-break: break-all;
  }

  & thead {
    background-color: #778899;
    color: white;
  }

  & tr {
    width: 100%;
  }

  & tr > td:first-of-type {
    width: 350px;
    background-color: lightgray;
    font-weight: bold;
  }
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  flex: 1;
  width: 100%;
`;

const InnerContainer = styled.div`
  flex: 1;
  padding: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class TestCyberSourceAuthorizeResponse extends Component {
  renderCybsResponse() {
    if (!Object.keys(this.props.responseData).length) {
      return;
    }

    const rows = Object.keys(this.props.responseData).map((key, index) => {
      return (
        <tr key={key + index}>
          <td>{key}</td>
          <td>{this.props.responseData[key]}</td>
        </tr>
      );
    });

    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h1>CYBERSOURCE</h1>
        {this.renderCybsResponse()}
      </div>
    );
  }
}

export default TestCyberSourceAuthorizeResponse;
