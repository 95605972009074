import qs from 'querystring';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import styled, { ThemeProvider } from 'styled-components';
import moment from 'moment';

import { DIApi } from './util/api';
import { checkIsTicketingAvailableForSiteAdventure } from './util/common';
import LocationSelectionDialog from './components/dialogs/LocationSelectionDialog';
import theme from './util/theme';
import { AuthContext } from './components/context/AuthenticatorContext';
import ScrollToTop from './components/ScrollToTop';
import Account from './Account';
import CheckoutAMC from './CheckoutAMC';
import Checkout from './Checkout';
import PrintReceipt from './pages/PrintReceipt';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import App from './App';
import CMS from "./util/cms";
import CacheBuster from "./CacheBuster";
import DubaiLandingPage from "./pages/DubaiLandingPage";
import CyberSourceAuth from './pages/checkout/CyberSourceAuth';
import TestPurchase from './pages/checkout/cybersource/TestPurchase';

const COOKIE_NAME = 'di_site_id';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

class Root extends Component {
  state = {
    isSignedIn: false,
    currentUser: null,
    isLoadingUser: true,
    stripe: null,
    setReleaseTickets: null,
    adventures: [],
    allTitles: [],
    sites: [],
    site: {},
    selectedSite: {},
    hasStoredSite: false,
    showLocationDialog: false,
    dismissedAnnouncementDialog: false,
    announcementLastViewed: null,
    cms: new CMS({}),
    handleAuthStateChange: () => {},
    releaseTickets: () => {},
    handleShowLocationDialog: () => {},
    handleDismissAnnouncementDialog: () => {},
    handleSaveLocation: () => {},
  };

  handleAuthStateChange = async (state) => {
    switch (state) {
      case 'signedIn':
        const user = await DIApi.getCurrentUser();

        this.setState({
          isSignedIn: true,
          currentUser: user,
          isLoadingUser: false,
        });
        break;
      case 'signedOut':
        this.setState({
          isSignedIn: false,
          currentUser: null,
          isLoadingUser: false,
        });
        break;
      default:
        this.setState({
          isLoadingUser: false,
        });
        break;
    }
  };

  handleShowLocationDialog = (show) => {
    this.setState({ showLocationDialog: show });
  };

  handleDismissAnnouncementDialog = (show) => {
    const cookieConfig = {
      path: '/',
      expires: moment().add(1, 'days').toDate(),
    };
    const cookieName = `${this.state.selectedSite.site_id}_dialog`;
    const now = moment().format();

    this.props.cookies.set(cookieName, now, cookieConfig);
    this.setState({ dismissedAnnouncementDialog: show, announcementLastViewed: now });
  };

  handleSaveLocation = async (site) => {
    this.props.cookies.set(COOKIE_NAME, site.site_id, { path: '/', expires: moment().add(3, 'months').toDate() });
    this.setState({ hasStoredSite: true });
    await this.setSelectedSiteAndAdventures(site);
  };

  async setSelectedSiteAndAdventures (site) {
    const adventures = await this.reconcileAdventures(site, this.state.allTitles);
    const announcementLastViewed = this.getLastAnnouncementDate(site.site_id);
    this.setState({
      selectedSite: site,
      adventures,
      dismissedAnnouncementDialog: false,
      announcementLastViewed,
    });
  }

  setReleaseTickets = (releaseTicketFunc) => {
    this.setState({ releaseTickets: releaseTicketFunc });
  };

  findLocationBySiteId (siteId) {
    let foundSite = {};
    if (!siteId) {
      return foundSite;
    }

    this.state.sites.some(site => {
      if (site.site_id.toUpperCase() === siteId.toUpperCase()) {
        foundSite = site;
        return true;
      }
      return false;
    });

    return foundSite;
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.state.sites.length && !prevState.sites.length) {
      // The first time sites are populated in state, check if a query param exists for auto-setting location
      // If there is only one site, bypass multiple location logic
      if (this.state.sites.length === 1) {
        const siteId = this.state.sites[0].site_id;
        const location = this.findLocationBySiteId(siteId);
        return this.handleSaveLocation(location);
      }
      const { site_id } = qs.parse(window.location.search.replace('?', ''));
      const querySelectedSite = this.findLocationBySiteId(site_id);
      // Prioritize the site specified in the query parameter
      if (querySelectedSite.site_id) {
        return this.handleSaveLocation(querySelectedSite);
      } else {
        // Otherwise check our cookie for the locally set location.
        const localSelectedSite = this.getStoredLocation();
        this.setSelectedSiteAndAdventures(localSelectedSite);
      }
    }
  }

  getLastAnnouncementDate (siteId = 'default') {
    return this.props.cookies.get(`${siteId}_dialog`);
  }

  getStoredLocation () {
    const storedSiteId = this.props.cookies.get(COOKIE_NAME);
    if (storedSiteId) {
      this.setState({ hasStoredSite: true });
    }
    return this.findLocationBySiteId(storedSiteId);
  }

  async getCMSAssets () {
    const titleAssets = await DIApi.getTitleAssets();
    const homeAssets = await DIApi.getHomeAssets();
    const siteAssets = await DIApi.getSiteAssets();

    const cms = new CMS({ titleAssets, homeAssets, siteAssets });
    this.setState({ cms });
  }

  async componentDidMount () {
    this.getCMSAssets();
    const localSelectedSite = this.getStoredLocation();
    const state = {
      handleAuthStateChange: this.handleAuthStateChange,
      setReleaseTickets: this.setReleaseTickets,
      handleShowLocationDialog: this.handleShowLocationDialog,
      handleDismissAnnouncementDialog: this.handleDismissAnnouncementDialog,
      handleSaveLocation: this.handleSaveLocation,
      selectedSite: localSelectedSite || {}
    };

    this.setState(state);

    const currentUser = await DIApi.getCurrentUser();

    state.isLoadingUser = false;

    if (currentUser) {
      state.isSignedIn = true;
      state.currentUser = currentUser;
    }

    if (window.Stripe) {
      state.stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY) });
      });
    }

    const sites = await DIApi.getSites();

    if (sites && sites.length) {
      state.site = sites[0];
      state.sites = sites;
    }

    const allTitles = await DIApi.getTitles();

    state.adventures = await this.reconcileAdventures(state.selectedSite, allTitles);
    state.allTitles = allTitles;

    this.setState(state);
  }

  async reconcileAdventures (site, titles) {
    let adventures = [];

    if (!titles || !titles.length) {
      return adventures;
    }

    if (!site || !site.site_id) {
      adventures = titles;
    } else {
      const siteAdventures = await DIApi.getExperiencesBySite(site.site_id);
      const siteAdventuresMap = siteAdventures.reduce((acc, adventure) => {
        return {
          ...acc,
          [adventure.title_id]: adventure,
        };
      }, {});

      adventures = titles.map(title => {
        const adventure = {...title};
        const siteAdventure = siteAdventuresMap[adventure.title_id];
        if (siteAdventure) {
          adventure.licensed_site = siteAdventure.licensed_site;

          adventure.isAvailableAtCurrentLocation = checkIsTicketingAvailableForSiteAdventure(adventure, site);
          return adventure;
        }

        adventure.isAvailableAtCurrentLocation = false;
        return adventure;
      });
    }

    return adventures;
  }

  renderLocationDialog = () => {
    return (
      <LocationSelectionDialog
        show={this.state.showLocationDialog}
        sites={this.state.sites}
        selectedSite={this.state.selectedSite}
        onDismiss={() => this.handleShowLocationDialog(false)}
        onSave={this.handleSaveLocation}
      />
    );
  };

  render () {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload}) => {
          if (loading) {
            return null;
          }

          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <ThemeProvider theme={theme}>
              <AuthContext.Provider value={this.state}>
                <StripeProvider stripe={this.state.stripe}>
                  <StyledDiv>
                    <BrowserRouter getUserConfirmation={(message, cb) => {
                      const allowTransition = window.confirm(message);
                      // Intercept the user's decision an if true, release any held tickets the user may have had.
                      if (allowTransition) {
                        this.state.releaseTickets();
                      }
                      cb(allowTransition);
                    }}>
                      <ScrollToTop>
                        { this.renderLocationDialog() }
                        <Switch>
                          {/* List routes here that should not render like the main site */}
                          {/* <Route exact path='/' component={Landing}/> */}
                          {/* We otherwise leave the rest to the main application */}
                          <Route path='/account' component={Account}/>
                          <Route path='/checkout/amc' component={CheckoutAMC}/>
                          <Route path='/checkout' component={Checkout}/>
                          <Route path='/DubaiComingSoon' component={DubaiLandingPage}/>
                          <Route path='/purchase/:siteId/:orderId/print-receipt' component={PrintReceipt} />
                          <Route path='/terms-kiosk' render={ () => <Terms disableLinks={true}/> } />
                          <Route path='/privacy-kiosk' render={ () => <Privacy disableLinks={true} /> } />
                          <Route path='/cybs*' component={CyberSourceAuth} />
                          <Route path='/test/cybersource' component={TestPurchase} />
                          <Route path='/' component={App}/>
                        </Switch>
                      </ScrollToTop>
                    </BrowserRouter>
                  </StyledDiv>
                </StripeProvider>
              </AuthContext.Provider>
            </ThemeProvider>
          );
        }}
      </CacheBuster>
    );
  }
}

export default withCookies(Root);
