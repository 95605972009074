import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Desktop, Mobile } from './responsive/Breakpoints';
import theme from '../util/theme';
import LeftNavDrawer from "./LeftNavDrawer";
import LogoText from '../components/svgs/LogoText';
import LogoTextStacked from '../components/svgs/LogoTextStacked';
import LogoTextStackedWHover from '../components/svgs/LogoTextStackedWHover';
import MenuAnimatedButton from '../components/buttons/MenuAnimatedButton';
import {AuthContext} from "./context/AuthenticatorContext";
import {DIApi} from "../util/api";

const MyNavLink = props => <NavLink activeStyle={{ color: theme.ds_bright_blue }} {...props} />;

const NavRow = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: ${ props => props.maxHeight ? props.maxHeight : 62 }px;
  z-index: 400000000;
`;

const NavContainer = styled.div`
  max-width: ${ props => props.maxWidth ? props.maxWidth : '1110px' };
  height: 100%;
  background-color: ${ props => props.backgroundColor || props.theme.charcoal_90 };
  box-shadow: 0 10px 15px 0 ${ props => props.theme.black_30 };
  color: white;
  margin: 0 auto;
  padding: ${ props => props.padding ? props.padding : '13px 30px' };
  font-family: ${props => props.theme.sans_serif};
  display: flex;
  flex-direction: row;
`;

const baseLinkStyles = css`
  height: 100%;
  margin: 0 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: ${ props => props.theme.white_80 };
  text-decoration: none;

  &:hover {
    color: ${ props => props.disable ? props.theme.white_80 : '#fff' };
    cursor: ${ props => props.disable ? 'auto' : 'pointer' };
  }
`;

const StyledNavLink = styled(MyNavLink)`
  ${baseLinkStyles}
`;

const StyledExternalLink = styled.a`
  ${baseLinkStyles}
`;

const FlexSpacer = styled.div`
  flex: 1;
`;

const MobileTitleWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 120px;
  align-items: center;
`;

const MobileTitle = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  font-size: 1.125rem;
`;

const MenuToggle = styled.div`
  margin: 0;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 4px;
`;

NavContainer.defaultProps = {
  theme: {
    ...theme,
  },
};

class AppNavBar extends Component {
  state = {
    isDrawerOpen: false,
    linkMouseOver: false,
    dropDownMouseOver: false,
    navBarHeight: null,
    navBarPaddingBottom: null,
    DILogoHovering: false,
  };

  toggleLeftDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  handleDrawerOnChange = (open) => {
    this.setState({ isDrawerOpen: open });
  };

  linkMouseOut = () => {
    this.setState({linkMouseOver: false})
  };

  linkMouseOver = () => {
    this.setState({linkMouseOver: true})
  };

  dropDownMouseOut = () => {
    this.setState({dropDownMouseOver: false})
  };

  dropDownMouseOver = () => {
    this.setState({dropDownMouseOver: true})
  };

  componentDidUpdate = () => {
    if (this.state.navBarHeight || this.state.navBarPaddingBottom) {
      return;
    }
    const navBar = document.getElementById('navbar');
    const navBarHeight = navBar.offsetHeight;
    const paddingBottomString = getComputedStyle(navBar).paddingBottom;
    const navBarPaddingBottom = parseInt(paddingBottomString.slice(0,-2), 10);
    this.setState({
      navBarHeight,
      navBarPaddingBottom
    })
  };

  handleUserSignOut = async () => {
    await DIApi.userSignOut();
    this.props.authState.handleAuthStateChange('signedOut');
  };

  handleSetLocationPressed = (event) => {
    // prevent the NavLink "navigation" effect so we can use the same styling component but handle different functionality
    event.preventDefault();

    // Prevent opening the dialog if we only have one site to select
    if (this.props.authState.sites.length <= 1) {
      return;
    }

    this.props.authState.handleShowLocationDialog(true);
  };

  hoverDILogo = () => {
    this.setState({DILogoHovering: true});
  };

  regularDILogo = () => {
    this.setState({DILogoHovering: false});
  };

  render () {
    let DILogo = <LogoTextStacked/>;

    if (this.state.DILogoHovering) {
      DILogo = <LogoTextStackedWHover/>;
    }

    return (
      <React.Fragment>

        {/* Desktop Experience */}
        <Desktop>
          <NavRow>
            <NavContainer id="navbar">

              <StyledNavLink onMouseEnter={this.hoverDILogo} onMouseLeave={this.regularDILogo}
                             style={{ marginLeft: 0, color: 'white' }} activeStyle={{ color: 'inherit' }} exact to="/">
                <div>
                  {DILogo}
                </div>
              </StyledNavLink>

              <FlexSpacer>{/* Spacer */}</FlexSpacer>

              <StyledNavLink to={{ pathname: '/faq', state: { resetPage: true, fromHealthFooter: false, stamp: Date.now() } }}>
                <span>FAQs</span>
              </StyledNavLink>
              <StyledExternalLink href="https://dreamscapegeneva.com/">
                <span>GENEVA</span>
              </StyledExternalLink>
              <StyledExternalLink href="https://www.dreamscapemena.com/en-sa/">
                <span>RIYADH</span>
              </StyledExternalLink>
            </NavContainer>
          </NavRow>
        </Desktop>

        {/* Mobile Experience */}
        <Mobile>
          <NavRow maxHeight={16}>
            <NavContainer
              maxWidth="auto"
              padding="16px"
              id="navbar"
              backgroundColor={theme.charcoal}
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <MenuToggle style={{ zIndex: 10 }}>
                <MenuAnimatedButton
                  isOpen={this.state.isDrawerOpen}
                  menuClicked={this.toggleLeftDrawer}
                  height={18}
                  width={24}
                  strokeWidth={2}
                  color={theme.white_70}
                  borderRadius={0}
                />
              </MenuToggle>

              <MobileTitleWrapper>
                <MobileTitle onClick={ () => this.props.history.push('/') }>
                  <LogoText/>
                </MobileTitle>
              </MobileTitleWrapper>
            </NavContainer>
          </NavRow>

          {/* Left Nav Drawer */}
          <LeftNavDrawer fadeOut width={300} signOut={this.handleUserSignOut} open={this.state.isDrawerOpen} onChange={ this.handleDrawerOnChange }/>
        </Mobile>

      </React.Fragment>
    );
  }
}

const AuthenticatedNavBar = props => (
  <AuthContext.Consumer>
    { authState => {
      return (<AppNavBar {...props} authState={authState} />)
    }}
  </AuthContext.Consumer>
);

export default withRouter(AuthenticatedNavBar);
