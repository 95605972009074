import styled from 'styled-components';

import BaseButton from './BaseButton';

const BlueButton = styled(BaseButton)`
  background-color: ${ props => props.theme.ds_dusk_blue };
  color: white;
  box-shadow: 0 10px 15px 0 ${ props => props.theme.black_30 };
  border: none;
  transition: ${ props => props.theme.transition };
  border: 1px solid transparent;
  
  &:focus {
    border: 1px solid  ${ props => props.theme.ds_bright_blue };
    box-shadow: 0 5px 5px 0 ${ props => props.theme.black_30 };
    color: white;
  }
  
  &:hover {
    box-shadow: 0 5px 5px 0 ${ props => props.theme.black_30 };
    color: ${ props => props.theme.ds_bright_blue };
    background-color: #1e205e
  }
  
  &:active {
    color: ${ props => props.theme.ds_dusk_blue };
    border: none;
    background-color: white;
  }
`;

export default BlueButton;
