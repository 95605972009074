import WhiteOutlineButton from "./buttons/WhiteOutlineButton";
import React, { Component } from "react";
import {AuthContext} from "./context/AuthenticatorContext";
import styled from "styled-components";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";
import { Phone, Large } from './responsive/Breakpoints';

const ToasterNotif = styled.div`
  position: fixed;
  height: ${ TOASTER_NOTIFICATION_HEIGHT }px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: ${ props => props.theme.ds_dusk_blue };
  display: ${ props => props.siteSelected ? 'none' : 'flex' };
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 400000000;
`;

const ToasterMessage = styled.div`
  color: ${ props => props.theme.white_80 };
  font-size: ${ props => props.mobile ? '.875' : '.9375' }rem;
  letter-spacing: .47px;
  line-height: 1.4;
  margin-right: 16px;
  ${ props => props.mobile ? 'flex: 1;' : ''} // makes message text respect button sibling's width and then fill remaining space
`;

class ToasterNotificationWithContext extends Component {
  handleSetLocationPressed = (event) => {
    // prevent the NavLink "navigation" effect so we can use the same styling component but handle different functionality
    event.preventDefault();

    // Prevent opening the dialog if we only have one site to select
    if (this.props.parentState.sites.length <= 1) {
      return;
    }

    this.props.parentState.handleShowLocationDialog(true);
  };

  render() {
    const siteSelected = this.props.parentState.hasStoredSite;

    return (
      <ToasterNotif id={'toasterNotification'} siteSelected={siteSelected}>
        <Large>
          <ToasterMessage>
            See the availability for adventures near you.
          </ToasterMessage>
          <WhiteOutlineButton style={{width: '144px', height: '40px'}} onClick={ (e) => this.handleSetLocationPressed(e) } >
            SET LOCATION
          </WhiteOutlineButton>
        </Large>
        <Phone>
          <ToasterMessage mobile={true}>
            See the availability for adventures near you.
          </ToasterMessage>
          <WhiteOutlineButton style={{width: '144px', height: '40px'}} onClick={ (e) => this.handleSetLocationPressed(e) } >
            SET LOCATION
          </WhiteOutlineButton>
        </Phone>
      </ToasterNotif>
    )
  }
}

const ToasterNotification = props => (
  <AuthContext.Consumer>
    { parentState => {
      return (<ToasterNotificationWithContext {...props} parentState={parentState} />);
    }}
  </AuthContext.Consumer>
);

export default ToasterNotification;