import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AuthContext } from "../components/context/AuthenticatorContext";
import { DIApi } from "../util/api";
import {Large, Phone} from "../components/responsive/Breakpoints";
import BlueButton from "../components/buttons/BlueButton";
import {BasicInputField as Input} from "../components/forms";
import LoadingSpinner from "../components/LoadingSpinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${ props => props.mobile ? '45' : '79' }px;
  align-items: center;
`;

const PasswordInput = styled(Input)`
  margin-bottom: ${ props => props.smallMargin ? '6px' : '13px' };
`;

const ChangePasswordButton = styled(BlueButton)`
  width: ${ props => props.mobile ? '100%' : '300px' };
  margin-top: 2px;
  margin-bottom: ${ props => props.mobile ? '47' : '82' }px;
`;

const FieldContainer = styled.div`
  ${ props => props.mobile ? 'width: 100%;' : '' }
  
  > div {
    width: ${ props => props.mobile ? '100%' : '300px' };
  }
`;

const PasswordRules = styled.span`
  padding: 0 0 13px;
  font-family: ${ props => props.theme.sans_serif };
  font-size: 0.81rem;
  font-style: italic;
  max-width: ${ props => props.mobile ? '100%' : '300px' };
  color: ${ props => props.theme.charcoal_60 };
`;

class ChangePassword extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    isSaving: false,
  };

  componentDidMount () {
    this.handleRedirect();
  }

  componentWillMount () {
    this.handleRedirect();
    this.props.updateHeaderText({
      title: 'ENTER YOUR NEW PASSWORD',
      subTitle: ''
    });
  }

  componentDidUpdate () {
    this.handleRedirect();
  }

  handleRedirect () {
    if (!this.props.authState.isLoadingUser && !this.props.authState.isSignedIn) {
      this.navToSignIn();
    }
  }

  renderChangePasswordButton = (mobile) => {
    const buttonContent = this.state.isSaving ? <LoadingSpinner/> : 'CHANGE PASSWORD';

    return (
      <ChangePasswordButton
        disabled={this.state.isSaving}
        mobile={mobile}
        onClick={ this.handleChangePassword }>{buttonContent}</ChangePasswordButton>
    )
  };

  navToSignIn = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account/signin', locationState);
  };

  handleChangePassword = async () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;

    const oldPass = oldPassword.replace(/\s/g,'');
    const newPass = newPassword.replace(/\s/g,'');
    const confirmPass = confirmPassword.replace(/\s/g,'');

    if (oldPass.length < 6) {
      alert("Incorrect username or password.");
      return;
    }
    if (newPass !== confirmPass) {
      alert("Passwords do not match.");
      return;
    }
    if (newPass.length < 8) {
      alert("Password is too short. Passwords must be at least 8 characters long.");
      return;
    }
    if (newPass === newPass.toLowerCase()) {
      alert("Password must contain an uppercase letter.");
      return;
    }
    if (newPass === newPass.toUpperCase()) {
      alert("Password must contain a lowercase letter.");
      return;
    }
    if (newPass.match(/\d+/) === null) {
      alert("Password must contain a number.");
      return;
    }

    this.setState({isSaving: true});
    try {
      await DIApi.changePassword(this.props.authState.currentUser, oldPass, newPass);
      this.props.history.replace('/account/details');
    } catch (e) {
      alert(e.message);
      this.setState({isSaving: false});
    }
  };

  renderChangePassword (mobile) {
    return (
      <Container mobile={mobile}>
        <FieldContainer mobile={mobile}>
          <PasswordInput
            autoFocus
            placeholder="Old Password"
            type="password"
            value={ this.state.oldPassword }
            onChange={ (event) => this.setState({ oldPassword: event.target.value }) }/>
        </FieldContainer>
        <FieldContainer mobile={mobile} >
          <PasswordInput
            smallMargin
            placeholder="New Password"
            type="password"
            value={ this.state.newPassword }
            onChange={ (event) => this.setState({ newPassword: event.target.value }) }/>
        </FieldContainer>
        <PasswordRules mobile={mobile}>Use 8 or more characters with an uppercase letter, a lowercase letter, and a number.</PasswordRules>
        <FieldContainer mobile={mobile}>
          <PasswordInput
            placeholder="Confirm New Password"
            type="password"
            value={ this.state.confirmPassword }
            onChange={ (event) => this.setState({ confirmPassword: event.target.value }) }/>
        </FieldContainer>
        {this.renderChangePasswordButton(mobile)}
      </Container>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Large>
          {this.renderChangePassword(false)}
        </Large>
        <Phone>
          {this.renderChangePassword(true)}
        </Phone>
      </React.Fragment>
    )
  }
}

const ChangePass = props => (
  <AuthContext.Consumer>
    { authState => {
      return (<ChangePassword {...props} authState={authState} />)
    }}
  </AuthContext.Consumer>
);

export default withRouter(ChangePass);
