import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { SchedulerNatural, SchedulerUndersized } from './responsive/Breakpoints';
import Scheduler from './Scheduler';
import AdventureThumbnail from './AdventureThumbnail';
import LocationIcon from './svgs/LocationIcon';
import { CURRENCY_SYMBOL } from '../util/constants';

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  height: ${ props => props.mobile ? 'unset' : '100%' };
  padding-bottom: ${ props => props.mobile ? '60px' : 'unset' };
  width: 100%;
  text-align: center;
  min-height: ${ props => props.mobile ? '650px' : '750px' };
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 24px 0;
`;

const LocationTitle = styled.div`
  margin-top: 26px;
  font-weight: bold;
  font-size: ${ props => props.platform === "mobile" ? "1.625rem" : "2.75rem" };
  padding: 0 22px;
  line-height: ${ props => props.platform === "mobile" ? '1.33' : '1.09' };
  text-align: center;
  color: ${ props => props.theme.charcoal };
`;

const NowBoardingStatic = styled.div`
  padding: ${ props => props.platform === "mobile" ? "6px 20px" : "4px 10vh 0" };
  font-size: ${ props => props.platform === "mobile" ? '0.875rem' : '1rem' };
  text-align: center;
  color: ${ props => props.theme.charcoal_60 };
`;

const LocationSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: ${ props => props.platform === 'mobile' ? '16px' : '22px' };
  font-size: 0.8125rem;
  font-weight: bold;
`;

const LocationSelectionAction = styled.span`
  color: ${ props => props.theme.ds_dusk_blue};
  font-weight: normal;
  cursor: pointer;
`;

class SchedulerContainer extends Component {
  componentDidMount () {
    this.props.autoScroll('timeSelector')
  }

  renderPricing = (isMobile) => {
    const {adventure, site, siteCopyAssets} = this.props;
    const minAge = adventure.licensed_site ? adventure.licensed_site.min_age : 10;
    const basePrice = adventure.licensed_site ? adventure.licensed_site.base_price.toFixed(2) : '20.00';

    const travelerInfo =
      site.site_id === "US000001"
        ? siteCopyAssets ?. travelerAgeCopy ?? `(Travelers must be Age ${minAge}+. Travelers under Age 13 must be accompanied by an adult with a paid ticket.)`
        : siteCopyAssets ?. travelerAgeCopy ?? `(Travelers must be Age ${minAge}+)`;


    // If CMS did not provide a customPricing scheme, default to standard API information
    if (!siteCopyAssets ||
        !siteCopyAssets.customPricingLines ||
        !Array.isArray(siteCopyAssets.customPricingLines) ||
        !siteCopyAssets.customPricingLines.length) {
      return isMobile
        ? <span>General Admission ${basePrice}<br />{travelerInfo}</span>
        : `General Admission $${basePrice} ${travelerInfo}`;
    }

    // Ruled out that siteCopyAssets exists and contains the data we are looking for so we can
    // safely pluck the cms copy data we want.
    const {customPricingLines, ticketingMinimumDescription} = siteCopyAssets;
    // CMS contains a custom pricing scheme to render
    if (isMobile) {
      const ageDeclaration = [
        <div key={'price_age_declaraion'}>{travelerInfo}</div>
      ];

      return customPricingLines
        .map((priceString, index) => {
          return (<div key={`pricing_${index}`}>{priceString}</div>);
        })
        .concat(ageDeclaration, ticketingMinimumDescription);
    }

    return (
      <React.Fragment>
        <div>{customPricingLines.join(', ')}</div>
        <div>{travelerInfo}</div>
        <div>{ticketingMinimumDescription}</div>
      </React.Fragment>
    );
  };

  renderTextContent = (platform) => {
    const {adventure, site, canChangeLocations } = this.props;
    const isMobile = platform === 'mobile';
    const minAge = adventure.licensed_site ? adventure.licensed_site.min_age : 10;
    const currencySymbol = adventure.licensed_site ? CURRENCY_SYMBOL[adventure.licensed_site.currency] || '$' : '$';
    const basePrice = adventure.licensed_site ? adventure.licensed_site.base_price.toFixed(2) : '20.00';
    const departureTitle = isMobile ? <span>SELECT <br/> DEPARTURE TIME</span> : 'SELECT DEPARTURE TIME';
    const ticketingInfo = this.renderPricing(isMobile);
    const location = site.site_legalname ? `${site.site_legalname}, ${site.city} ${site.state}` : 'NO LOCATION';

    return (
      <HeaderContainer mobile={isMobile}>
        <AdventureThumbnail titleId={adventure.title_id}/>
        <LocationTitle platform={platform}>{departureTitle}</LocationTitle>
        <NowBoardingStatic platform={platform}>{ticketingInfo}</NowBoardingStatic>
        <LocationSelectionContainer platform={platform}>
          <span>{location.toUpperCase()}</span>
          <LocationIcon color={'#000000'} />
          { canChangeLocations
              ? <LocationSelectionAction
                  onClick={ () => this.props.handleShowLocationDialog(true) }
                >
                  (Change)
                </LocationSelectionAction>
              : null
          }

        </LocationSelectionContainer>
      </HeaderContainer>
    )
  };

  render () {
    return (
      <React.Fragment>
        <SchedulerNatural>
          <Container id={'timeSelector'}>
            {this.renderTextContent("desktop")}
            <Scheduler
              key={this.props.adventure.title_id}
              mobile={false}
              maxDaysToDisplay={6}
              site={this.props.site}
              adventure={this.props.adventure}
              isTicketingAvailable={this.props.isTicketingAvailable}
              handleShowLocationDialog={this.props.handleShowLocationDialog}
              canChangeLocations={this.props.canChangeLocations}
            />
          </Container>
        </SchedulerNatural>
        <SchedulerUndersized>
          <Container id={'timeSelector'} mobile={true}>
            {this.renderTextContent("mobile")}
            <Scheduler
              key={this.props.adventure.title_id}
              mobile={true}
              maxDaysToDisplay={20}
              site={this.props.site}
              adventure={this.props.adventure}
              isTicketingAvailable={this.props.isTicketingAvailable}
              handleShowLocationDialog={this.props.handleShowLocationDialog}
              canChangeLocations={this.props.canChangeLocations}
            />
          </Container>
        </SchedulerUndersized>
      </React.Fragment>
    );
  }
}

export default withRouter(SchedulerContainer);
