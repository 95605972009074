import genevaStaticLocationImgMobile from '../assets/images/img-find-us-locale-1-s@3x.jpg';
import genevaStaticLocationImg from '../assets/images/img-find-us-locale-1-l@3x.jpg';
import riyadhStaticLocationImgMobile from '../assets/images/find-us/riyadh/img-find-us-riyadh-s@3x.png';
import riyadhStaticLocationImg from '../assets/images/find-us/riyadh/img-find-us-riyadh-l@3x.png';

export const CLOSING_MESSAGE = 'Leaving this page will cancel the hold on your tickets. Are you sure you want to cancel?';
export const MOBILE_ADVENTURE_CARD_WIDTH = 317;
export const TOASTER_NOTIFICATION_HEIGHT = 70;
export const LOCATION_RESPONSE_DATA = {
  'westfield-century-city': 'US000001',
  // 'dallas-northpark': 'US000002',
  'dubai-malloftheemirates': 'AE000001',
  'los-angeles-vr-westfield-century-city': 'US000001',
  // 'dallas-vr-northpark-center': 'US000002',
  'dubai-vr-malloftheemirates': 'AE000001',
  'columbus-vr-easton-town-center': 'US000003',
  'paramus-vr-garden-state-plaza': 'US000004',
};
export const CURRENCY_SYMBOL = {
  USD: '$',
  AED: 'AED '
}
export const STATIC_GENEVA_SITE_ID = 'CH000001';
export const STATIC_RIYADH_SITE_ID = 'SA000001';
export const GENEVA_LOCATION = {
  site_id: 'CH000001',
  site_legalname: 'Confédération Centre',
  street_address: 'Rue de la Confédération 6-10, 1204 Geneva',
  directions: 'We are located in the entertainment hub, on level -1.',
  city: 'Geneva',
  state: 'Switzerland',
  site_url_path: 'https://dreamscapegeneva.com/',
  hours: [
    {
      day: 'Wednesday',
      time: '12pm - 7pm',
    },
    {
      day: 'Thursday',
      time: '12pm - 9pm',
    },
    {
      day: 'Friday',
      time: '12pm - 11pm',
    },
    {
      day: 'Saturday',
      time: '10am - 11pm',
    },
    {
      day: 'Sunday',
      time: '10am - 7pm',
    },
  ],
  images: {
    mobile: {
      x3: genevaStaticLocationImgMobile,
    },
    desktop: {
      x3: genevaStaticLocationImg,
    },
  },
};
export const RIYADH_LOCATION = {
  site_id: 'SA000001',
  site_legalname: 'Riyadh Park Mall',
  street_address: 'Northern Ring Branch Road, Riyadh, SA',
  directions: 'We are located on Level 1 next to VOX Cinemas',
  city: 'Riyadh',
  state: 'Saudi Arabia',
  site_url_path: 'https://dreamscapemena.com',
  hours: [
    {
      day: 'Monday - Wednesday',
      time: '11am - 12:30am',
    },
    {
      day: 'Thursday',
      time: '11am - 2:30am',
    },
    {
      day: 'Friday',
      time: '1pm - 2:30am',
    },
    {
      day: 'Saturday - Sunday',
      time: '11am - 12:30am',
    }
  ],
  images: {
    mobile: {
      x3: riyadhStaticLocationImgMobile,
    },
    desktop: {
      x3: riyadhStaticLocationImg,
    },
  },
};

/* Static Home Page Hero Content */
export const heroSlideTitles = [
    'closingSlide',
    'slidefullopenmib',
    'slidefullopendrg',
    'slidefullopenazo',
    'slidefullopenmpr'
];

export const staticHeroSlides = {
    'slidempdefault': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidempdefault/images/default/mobile/x1/Mobile-MP_Working_V7.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidempdefault/images/default/mobile/x2/Mobile-MP_Working_V7.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidempdefault/images/default/mobile/x3/Mobile-MP_Working_V7.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidempdefault/images/default/desktop/x1/Desktop-MP_Working_V7.png',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidempdefault/images/default/desktop/x2/Desktop-MP_Working_V7.png',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidempdefault/images/default/desktop/x3/Desktop-MP_Working_V7.png'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'slide5': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide5/images/default/mobile/x1/img-home-slide-1-s.png',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide5/images/default/mobile/x2/img-home-slide-1-s@2x.png',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide5/images/default/mobile/x3/img-home-slide-1-s@3x.png'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide5/images/default/desktop/x1/img-home-slide-1-l.png',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide5/images/default/desktop/x2/img-home-slide-1-l@2x.png',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide5/images/default/desktop/x3/img-home-slide-1-l@3x.png'
                }
            }
        },
        'copy': {
            'default': {
                'headline': 'DREAMWORKS DRAGONS FLIGHT ACADEMY',
                'subCopy': "DREAMSCAPE'S FIRST FREE-FLYING VR ADVENTURE",
                'subCopyFontSize': '2.25rem',
                'subCopyMaxWidth': '920px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures/details/dragons01'
            }
        }
    },
    'slide2': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide2/images/default/mobile/x1/img-home-slide-2-s.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide2/images/default/mobile/x2/img-home-slide-2-s@2x.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide2/images/default/mobile/x3/img-home-slide-2-s@3x.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide2/images/default/desktop/x1/img-home-slide-2.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide2/images/default/desktop/x2/img-home-slide-2@2x.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide2/images/default/desktop/x3/img-home-slide-2@2x.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': 'ALIEN ZOO',
                'subCopy': "COME FACE TO FACE WITH THE GALAXY'S MOST ENDANGERED SPECIES",
                'subCopyFontSize': '2.25rem',
                'subCopyMaxWidth': '945px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures/details/alienzoo01'
            }
        }
    },
    'slidecovid19': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidecovid19/images/default/mobile/x1/Mobile Come Travel with Us.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidecovid19/images/default/mobile/x2/Mobile Come Travel with Us.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidecovid19/images/default/mobile/x3/Mobile Come Travel with Us.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidecovid19/images/default/desktop/x1/Desktop Come Travel with Us.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidecovid19/images/default/desktop/x2/Desktop Come Travel with Us.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slidecovid19/images/default/desktop/x3/Desktop Come Travel with Us.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'slide3': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide3/images/default/mobile/x1/img-home-slide-3-s.png',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide3/images/default/mobile/x2/img-home-slide-3-s@2x.png',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide3/images/default/mobile/x3/img-home-slide-3-s@3x.png'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide3/images/default/desktop/x1/img-home-slide-3-l.png',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide3/images/default/desktop/x2/img-home-slide-3-l@2x.png',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide3/images/default/desktop/x3/img-home-slide-3-l@3x.png'
                }
            }
        },
        'copy': {
            'default': {
                'headline': 'DREAMWORKS DRAGONS FLIGHT ACADEMY',
                'subCopy': 'FLY TO THE HIDDEN WORLD AND EARN YOUR DRAGON WINGS',
                'subCopyFontSize': '2.25rem',
                'subCopyMaxWidth': '920px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures/details/dragons01'
            }
        }
    },
    'slide1': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide1/images/default/mobile/x1/img-home-slide-1-s.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide1/images/default/mobile/x2/img-home-slide-1-s@2x.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide1/images/default/mobile/x3/img-home-slide-1-s@3x.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide1/images/default/desktop/x1/img-home-slide-1.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide1/images/default/desktop/x2/img-home-slide-1@2x.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide1/images/default/desktop/x3/img-home-slide-1@2x.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': 'CURSE OF THE LOST PEARL',
                'subCopy': 'LEAP INTO A DARING ADVENTURE AND BE THE HERO OF THE STORY',
                'subCopyFontSize': '2.25rem',
                'subCopyMaxWidth': '920px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures/details/magicprojector01'
            }
        }
    },
    'slide4': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide4/images/default/mobile/x1/img-home-slide-4-s.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide4/images/default/mobile/x2/img-home-slide-4-s@2x.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide4/images/default/mobile/x3/img-home-slide-4-s@3x.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide4/images/default/desktop/x1/img-home-slide-4.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide4/images/default/desktop/x2/img-home-slide-4@2x.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/default/slide4/images/default/desktop/x3/img-home-slide-4@3x.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': 'THE BLU: DEEP RESCUE',
                'subCopy': 'DIVE INTO THE OCEAN ON AN URGENT RESCUE MISSION',
                'subCopyFontSize': '2.25rem',
                'subCopyMaxWidth': '920px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures/details/deepblurescue01'
            }
        }
    },
    'slidefullopenmpr': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmpr/images/default/mobile/x1/Mobile-MP.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmpr/images/default/mobile/x2/Mobile-MP.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmpr/images/default/mobile/x3/Mobile-MP.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmpr/images/default/desktop/x1/Desktop-MP.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmpr/images/default/desktop/x2/Desktop-MP.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmpr/images/default/desktop/x3/Desktop-MP.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'slidefullopencfo': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopencfo/images/default/mobile/x1/img-home-slide-6-s.webp',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopencfo/images/default/mobile/x2/img-home-slide-6-s@2x.webp',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopencfo/images/default/mobile/x3/img-home-slide-6-s@3x.webp'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopencfo/images/default/desktop/x1/img-home-slide-6-l.webp',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopencfo/images/default/desktop/x2/img-home-slide-6-l@2x.webp',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopencfo/images/default/desktop/x3/img-home-slide-6-l@3x.webp'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'slidefullopenblu': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenblu/images/default/mobile/x1/Mobile-BLU.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenblu/images/default/mobile/x2/Mobile-BLU.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenblu/images/default/mobile/x3/Mobile-BLU.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenblu/images/default/desktop/x1/Desktop-BLU_Working.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenblu/images/default/desktop/x2/Desktop-BLU_Working.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenblu/images/default/desktop/x3/Desktop-BLU_Working.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'slidefullopenazo': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenazo/images/default/mobile/x1/Mobile-AZ_Working_TEXT_V2.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenazo/images/default/mobile/x2/Mobile-AZ_Working_TEXT_V2.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenazo/images/default/mobile/x3/Mobile-AZ_Working_TEXT_V2.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenazo/images/default/desktop/x1/Desktop-AZ_Working_TEXT_V2.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenazo/images/default/desktop/x2/Desktop-AZ_Working_TEXT_V2.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenazo/images/default/desktop/x3/Desktop-AZ_Working_TEXT_V2.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'slidefullopendrg': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopendrg/images/default/mobile/x1/Mobile-DRG.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopendrg/images/default/mobile/x2/Mobile-DRG.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopendrg/images/default/mobile/x3/Mobile-DRG.jpg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopendrg/images/default/desktop/x1/Desktop-DRG.jpg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopendrg/images/default/desktop/x2/Desktop-DRG.jpg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopendrg/images/default/desktop/x3/Desktop-DRG.jpg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'slidefullopenmib': {
        'images': {
            'default': {
                'mobile': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmib/images/default/mobile/x1/Mobile-MIB.jpeg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmib/images/default/mobile/x2/Mobile-MIB.jpeg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmib/images/default/mobile/x3/Mobile-MIB.jpeg'
                },
                'desktop': {
                    'x1': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmib/images/default/desktop/x1/Desktop-MIB.jpeg',
                    'x2': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmib/images/default/desktop/x2/Desktop-MIB.jpeg',
                    'x3': 'https://assets.hq.dev.techscapevr.com/home/hero/slides/US000001/slidefullopenmib/images/default/desktop/x3/Desktop-MIB.jpeg'
                }
            }
        },
        'copy': {
            'default': {
                'headline': '',
                'subCopy': '',
                'subCopyFontSize': '3.5rem',
                'subCopyMaxWidth': '1170px',
                'callToAction': 'BOOK YOUR ADVENTURE',
                'callToActionPath': '/adventures'
            }
        }
    },
    'closingSlide': {
        'images': {
            'default': {
                'desktop': {
                    'x3': '/static/media/imghomeslidesimple.9fbe86ab.webp'
                },
                'mobile': {
                    'x3': '/static/media/imghomeslidesimplemobile.98a3df9f.webp'
                }
            }
        },
        'copy': {}
    }
}
