import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Desktop, Mobile } from "../components/responsive/Breakpoints";
import HubspotForm from "../components/hubspot/ContactUsHubspotForm";
import { BlueButton, BlueOutlineButton } from '../components/buttons';
import { AuthContext } from "../components/context/AuthenticatorContext";

const PageContainer = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: ${ props => props.mobile ? 'column' : 'row' };
  max-width: ${ props => props.mobile ? '424px': '1214px' };
  width: 100%;
  padding: ${ props => props.mobile ? '54px 0 27px 0' : '88px 22px 27px 22px' };
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  padding: ${ props => props.mobile ? '0 22px' : '0' };
  flex: 1;
  display: flex;
  flex-direction: ${ props => props.mobile ? 'column-reverse' : 'column' };
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${ props => props.mobile ? 'center' : 'flex-end' };
  margin: ${ props => props.mobile ? '16px 22px 0 22px' : '0 0 0 33px' };
`;

const Header = styled.h1`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '1.625' : '2.75' }rem;
  text-align: ${ props => props.mobile ? 'center' : undefined };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : undefined };
  color: ${ props => props.theme.charcoal };
  padding-top: ${ props => props.mobile ? '6' : '98' }px;
  margin: 0 0 5px;
`;

const LocationContactHeader = styled.h2`
  padding-top: ${ props => props.mobile ? undefined : '0px'};
  margin: ${ props => props.mobile ? '22px 0 0 0' : '0'};
  color: ${ props => props.theme.charcoal_90 };
  font-size: ${ props => props.mobile ? '1rem' : '1.375rem' };
  font-weight: bold;
`;

const RegularText = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '0.875' : '1' }rem;
  text-align: ${ props => props.center && props.mobile ? 'center' : undefined };
  color: ${ props => props.theme.charcoal_60 };
`;

const StyledLink = styled.a`
  color: ${props => props.theme.ds_dusk_blue };
  cursor: pointer;
  text-decoration: none;
`;

const SiteLinksSection = styled.div`
  display: flex;
  flex-direction: column
  width: 100%;
  background-color: #FFF;
  align-items: center;
`;

const SiteLinksSectionContainer = styled.div`
  display: flex;
  flex-direction: ${ props => props.mobile ? 'column' : 'row' };
  max-width: 1214px;
  width: ${ props => props.mobile ? 'auto' : '100%' };
  margin: ${ props => props.mobile ? '0 22px' : undefined };
  padding: ${ props => props.mobile ? '55px 0' : '75px 22px' };
  box-sizing: border-box;
`;

const SiteLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${ props => {
    if (props.middle && props.mobile) {
      return '60px 0';
    }
    
    if (props.middle) {
      return '0 60px';
    }
  }};
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
`;

const SiteLinkTitle = styled.div`
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.mobile ? '1.375rem' : '2.25rem' };
  line-height: ${ props => props.mobile ? '1.64' : '1.19' };
  color: ${ props => props.theme.charcoal_90 };
  padding-top: ${ props => props.mobile ? '12px' : '20px' };
`;

const SiteLinkText = styled(RegularText)`
  padding-top: 12px;
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  flex: 1;
`;

const SiteLinkButtonItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${ props => props.mobile ? 'center' : undefined };
`;

const SiteLinkButtonContainer = styled.div`
  flex: 1;
  padding-top: 30px;
  max-width: ${ props => props.mobile ? '360px' : '325px' };
`;

const LeftContactSeparator = styled.div`
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
  width: 100%;
  margin: ${ props => props.mobile ? '22px 0' : '60px 0' };
`;

const LocationInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${ props => props.mobile ? 'center' : undefined };
`;

const LocationButtonContainer = styled.div`
  width: 100%;
  max-width: 360px;
  margin-top: ${ props => props.mobile ? '15px' : '26px' };
`;

const PHONE_NUMBERS = {
  US: '(800) 899-0473',
  AE: '+971 04-2200995'
};

class ContactUsWithContext extends Component {
  handleNavLink = (path) => {
    return this.props.history.push(path);
  };

  renderStoreInfoButtons (isMobile) {
    const { sites, cms } = this.props.parentState;

    const contactList = sites
      .filter(site => {
        const siteAssets = cms.getSiteAssets(site.site_id);
        return !!Object.keys(siteAssets).length;
      })
      .map(site => {
        const siteAssets = cms.getSiteAssets(site.site_id);
        return {
          text: site.city.toUpperCase(),
          path: siteAssets.copy.site_url_path,
        }
      });

    return contactList.map((site,index) =>
      <LocationButtonContainer key={index} mobile={isMobile}>
        <BlueOutlineButton
          invert
          width={isMobile ? '100%' : undefined}
          onClick={() => {
            this.props.history.push(site.path);
          }}
        >
          {site.text}
        </BlueOutlineButton>
      </LocationButtonContainer>
    )
  }

  renderContactPage (mobile) {
    const usCopy = 'For immediate US needs, give us a call at';
    const aeCopy = 'To reach our Dubai location, please call';
    const phoneContactContent = mobile
      ? (<span>{usCopy}<br /><StyledLink href={`tel:${PHONE_NUMBERS.US}`}>{PHONE_NUMBERS.US}</StyledLink>.</span>)
      : (<span>{usCopy} <StyledLink href={`tel:${PHONE_NUMBERS.US}`}>{PHONE_NUMBERS.US}</StyledLink>.</span>);
    
    const dubaiPhoneContactContent = mobile 
      ? (<span>{aeCopy}<br /><StyledLink href={`tel:${PHONE_NUMBERS.AE}`}>{PHONE_NUMBERS.AE}</StyledLink>.</span>)
      : (<span>{aeCopy} <StyledLink href={`tel:${PHONE_NUMBERS.AE}`}>{PHONE_NUMBERS.AE}</StyledLink>.</span>);
    return (
      <PageContainer mobile={mobile}>
        {/* Contact section containing Business hours text and hubspot form */}
        <ContactSection mobile={mobile}>

          <TextContainer mobile={mobile}>
            <div>
              <Header mobile={mobile}>GOT A QUESTION?</Header>
              <RegularText mobile={mobile} center>
                Be sure to take a look at our <StyledLink href={'/faq'}>Frequently Asked Questions</StyledLink>
                , or you can drop us a line via the form — we’ll do our best to get back to you within one business day.
              </RegularText>
            </div>
            <LeftContactSeparator mobile={mobile}/>
            <LocationInformationContainer>
              <LocationContactHeader mobile={mobile}>
                Looking for specific location information?<br/>Choose one below.
              </LocationContactHeader>

              { this.renderStoreInfoButtons(mobile) }
              <RegularText style={{ marginTop: 25 }} mobile={mobile}>{phoneContactContent}</RegularText>
              <RegularText style={{ marginTop: mobile ? 4 : 14 }} mobile={mobile}>{dubaiPhoneContactContent}</RegularText>
            </LocationInformationContainer>
          </TextContainer>

          <FormContainer mobile={mobile}>
            <HubspotForm
              id={'contactUsForm'}
              mobile={mobile}
              widthPercent={'100'}
              portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
              formId={process.env.REACT_APP_HUBSPOT_CONTACT_US_FORM_ID}/>
          </FormContainer>

        </ContactSection>

        {/* Site links section */}
        <SiteLinksSection>
          <SiteLinksSectionContainer mobile={mobile}>
            <SiteLinkContainer mobile={mobile}>
              <SiteLinkTitle mobile={mobile}>Still Need Help?</SiteLinkTitle>
              <SiteLinkText mobile={mobile}>From purchasing to checking in, you’ll find the answer here.</SiteLinkText>
              <SiteLinkButtonItem mobile={mobile}>
                <SiteLinkButtonContainer>
                  <BlueButton width={"100%"} onClick={() => this.handleNavLink('/faq')}>VISIT FAQ</BlueButton>
                </SiteLinkButtonContainer>
              </SiteLinkButtonItem>
            </SiteLinkContainer>
            <SiteLinkContainer middle mobile={mobile}>
              <SiteLinkTitle mobile={mobile}>Find Us</SiteLinkTitle>
              <SiteLinkText mobile={mobile}>If you’re looking to have a fun interactive night out, come visit us!</SiteLinkText>
              <SiteLinkButtonItem mobile={mobile}>
                <SiteLinkButtonContainer>
                  <BlueButton width={"100%"} onClick={() => this.handleNavLink('/find-us')}>LOCATIONS</BlueButton>
                </SiteLinkButtonContainer>
              </SiteLinkButtonItem>
            </SiteLinkContainer>
            <SiteLinkContainer mobile={mobile}>
              <SiteLinkTitle mobile={mobile}>Learn More</SiteLinkTitle>
              <SiteLinkText mobile={mobile}>Want to know more about Dreamscape?</SiteLinkText>
              <SiteLinkButtonItem mobile={mobile}>
                <SiteLinkButtonContainer>
                  <BlueButton width={"100%"} onClick={() => this.handleNavLink('/about-us')}>ABOUT US</BlueButton>
                </SiteLinkButtonContainer>
              </SiteLinkButtonItem>
            </SiteLinkContainer>
          </SiteLinksSectionContainer>
        </SiteLinksSection>
      </PageContainer>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Desktop>
          {this.renderContactPage(false)}
        </Desktop>
        <Mobile>
          {this.renderContactPage(true)}
        </Mobile>
      </React.Fragment>
    )
  }
}

const ContactUs = props => (
  <AuthContext.Consumer>
    { parentState => <ContactUsWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(ContactUs);
