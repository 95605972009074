import React from "react";
import styled from 'styled-components';

const SVGContainer = styled.span`
  transform: ${ props => props.rotate ? `rotate(${ props.rotate }deg)` : undefined };
  display: inline-flex;
`;

export default (props) => (
  <SVGContainer rotate={props.rotate}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox="0 0 24 24" enableBackground="new 0 0 24 24">
      <path fill={props.color ? props.color : "none"} d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z"/>
      <path fill="none" d="M0,0h24v24H0V0z"/>
    </svg>
  </SVGContainer>
);
