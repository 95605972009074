import React from 'react';
import styled from 'styled-components';

import AdventureThumbnail from './AdventureThumbnail';

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${ props => props.isMobile ? '100%': '317px' };
  max-width: ${ props => props.isMobile ? '360px': undefined };
  margin-right: ${ props => props.isMobile ? undefined : '70px' };
  padding: ${ props => props.isMobile ? '0 22px' : undefined };
  box-sizing: ${ props => props.isMobile ? 'border-box' : undefined };
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${ props => props.theme.charcoal_25 };
  margin: 16px 0;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsRow = styled.div`
  display: flex;
`;

const DetailLabel = styled.div`
  flex: 1;
  font-size: 1rem;
  letter-spacing: -0.5px;
  color: ${ props => props.theme.charcoal_60 }
`;

const DetailValue = styled.div`
  flex: 1;
  letter-spacing: -0.5px;
  font-size: 1rem;
  margin-left: 12px;
  font-weight: bold;
  color: ${ props => props.theme.charcoal_90 }
`;

function renderTicketFees (ticketFees) {
  if (!ticketFees) {
    return null;
  }

  return ticketFees.map((fee, index) => {
    return (
      <DetailsRow key={`fee-type-${index}`}>
        <DetailLabel>{fee.name}</DetailLabel>
        <DetailValue>{fee.amountLabel}</DetailValue>
      </DetailsRow>
    );
  });
}

const CheckoutOrderDetails = (props) => {
  if (!props.orderDetails) {
    return null;
  }

  const {
    location,
    time,
    ada,
    totalCost,
    ticketCostBreakdown,
    fees,
    giftDeduction,
    promoDeduction,
    ticketFees,
  } = props.orderDetails;

  return (
    <OrderDetailsContainer isMobile={ props.isMobile }>
      <AdventureThumbnail ada={ada} titleId={props.titleId} />

      <Divider/>

      <DetailsContainer>

        <DetailsRow>
          <DetailLabel>BOOKING NUMBER</DetailLabel>
          <DetailValue>{props.shortCode || '-'}</DetailValue>
        </DetailsRow>
        <DetailsRow>
          <DetailLabel>LOCATION</DetailLabel>
          <DetailValue>{location}</DetailValue>
        </DetailsRow>
        <DetailsRow>
          <DetailLabel>TIME</DetailLabel>
          <DetailValue>{time}</DetailValue>
        </DetailsRow>
        { ada
          ? <DetailsRow>
            <DetailLabel>ACCESSIBLE</DetailLabel>
            <DetailValue>YES</DetailValue>
          </DetailsRow>
          : null
        }
        <DetailsRow>
          <DetailLabel>TICKETS</DetailLabel>
          <DetailValue>{ticketCostBreakdown}</DetailValue>
        </DetailsRow>
        { renderTicketFees(ticketFees) }
        {
          giftDeduction
            ? <DetailsRow>
                <DetailLabel>GIFT CARD</DetailLabel>
                <DetailValue>{giftDeduction}</DetailValue>
              </DetailsRow>
            : null
        }

        {
          promoDeduction
            ? <DetailsRow>
              <DetailLabel>PROMO CODE</DetailLabel>
              <DetailValue>{promoDeduction}</DetailValue>
            </DetailsRow>
            : null
        }
        <DetailsRow>
          <DetailLabel>TOTAL</DetailLabel>
          <DetailValue>{totalCost}</DetailValue>
        </DetailsRow>

      </DetailsContainer>
    </OrderDetailsContainer>
  );
}

export default CheckoutOrderDetails;
