import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="117" height="140" viewBox="0 0 117 140">
    <g fill="none" fill-rule="evenodd">
      <g>
        <rect width="114" height="137" x="1.5" y="1.5" stroke="#2E3191" stroke-width="3" rx="5"/>
        <g fill="#2E3191">
          <path d="M-3.151 10.435L28.021 10.435 28.021 14.435 -3.151 14.435z" transform="translate(58.500000, 22.500000) rotate(-90.000000) translate(-58.500000, -22.500000) translate(46.000000, 10.000000) translate(12.435029, 12.435029) rotate(-315.000000) translate(-12.435029, -12.435029)"/>
          <path d="M15 15L31 15 31 19 15 19z" transform="translate(58.500000, 22.500000) rotate(-90.000000) translate(-58.500000, -22.500000) translate(46.000000, 10.000000) translate(23.000000, 17.000000) rotate(-270.000000) translate(-23.000000, -17.000000)"/>
          <path d="M9 21L25 21 25 25 9 25z" transform="translate(58.500000, 22.500000) rotate(-90.000000) translate(-58.500000, -22.500000) translate(46.000000, 10.000000) translate(17.000000, 23.000000) rotate(-360.000000) translate(-17.000000, -23.000000)"/>
        </g>
        <text fill="#1B1B1B" fill-opacity=".9" font-family="Brother-1816-Bold, Brother 1816" font-size="16" font-weight="bold">
          <tspan x="5.248" y="127">ASSIGNMENT</tspan>
        </text>
        <text fill="#1B1B1B" font-family="Brother-1816-Bold, Brother 1816" font-size="45" font-weight="bold" letter-spacing="-1">
          <tspan x="17.677" y="91">MIB</tspan>
        </text>
        <path fill="#1B1B1B" fill-opacity=".25" fill-rule="nonzero" d="M6 106L6 105 111 105 111 106zM6 44L6 43 111 43 111 44z"/>
      </g>
    </g>
  </svg>
);