import React, { Component } from 'react';
import styled from 'styled-components';

import {
  Route,
  withRouter
} from 'react-router-dom';

import {Large, Phone} from './components/responsive/Breakpoints';
import { AuthContext } from "./components/context/AuthenticatorContext";
import AccountViewTickets from "./pages/AccountViewTickets";
import AppNavbar from "./components/AppNavbar";
import Footer from "./components/Footer";
import AccountDetails from "./pages/AccountDetails";
import AccountDetailsEdit from "./pages/AccountDetailsEdit";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from './ForgotPassword';
import AccountSignIn from "./pages/AccountSignIn";
import AccountSignUp from "./pages/AccountSignUp";
import ToasterNotification from "./components/ToasterNotification";

const PageContainer = styled.div`
  margin-top: ${ props => props.toasterOffset }px;
  width: 100%;
  height: 100%;
`;

const AccountContainer = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  padding: ${ props => props.mobile ? '94px 20px 0' : '164px 120px 0' };
  flex-grow: 1;
`;

const Title = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.31' : '1.09' };
  text-align: center;
  color: ${ props => props.theme.charcoal };
`;

const Text = styled.div`
  max-width: 830px;
  font-size: 1rem;
  font-weight: ${ props => props.dark ? 'bold' : 'normal' };
  font-family: ${ props => props.theme.sans_serif };
  color: ${ props => props.dark ? props.theme.charcoal_90 : props.theme.charcoal_60 };
  text-align: center;
  padding-top: 5px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: ${ props => props.mobile ? 'center' : undefined };
`;

class Account extends Component {
  state = {
    accountTitle: '',
    accountSubTitle: '',
    toasterNotif: null,
  };

  componentDidMount() {
    const toasterNotif = document.getElementById('toasterNotification');
    this.setState({ toasterNotif });
  }

  updateHeaderText = (headerInfo) => {
    const { title, subTitle } = headerInfo;
    this.setState({
      accountTitle: title || '',
      accountSubTitle: subTitle || '',
    });
  };

  renderAccountSection (mobile) {
    const toasterNotif = this.state.toasterNotif;
    const toasterOffset = toasterNotif ? toasterNotif.offsetHeight : 0;

    let headerContent = null;
    if (this.state.accountTitle || this.state.accountSubTitle) {
      headerContent = (
        <React.Fragment>
          <Large>
            <HeaderContainer>
              <Title>{this.state.accountTitle}</Title>
              <Text>{this.state.accountSubTitle}</Text>
            </HeaderContainer>
          </Large>
          <Phone>
            <HeaderContainer mobile>
              <Title mobile>{this.state.accountTitle}</Title>
              <Text>{this.state.accountSubTitle}</Text>
            </HeaderContainer>
          </Phone>
        </React.Fragment>
      );
    }
    return (
      <PageContainer toasterOffset={toasterOffset}>
        <AppNavbar />
        <AccountContainer mobile={mobile}>
          {headerContent}

          {/* Account Navigation Routes */}
          <Route exact path="/account" render={() => (
            <AccountViewTickets updateHeaderText={this.updateHeaderText} />
          )}/>
          <Route exact path="/account/signin" render={() => (
            <AccountSignIn updateHeaderText={this.updateHeaderText} />
          )}/>
          <Route exact path="/account/signup" render={() => (
            <AccountSignUp updateHeaderText={this.updateHeaderText} />
          )}/>
          <Route exact path="/account/details" render={() => (
            <AccountDetails updateHeaderText={this.updateHeaderText} />
          )}/>
          <Route exact path="/account/details/edit" render={() => (
            <AccountDetailsEdit updateHeaderText={this.updateHeaderText} />
          )}/>
          <Route exact path="/account/changepassword" render={() => (
            <ChangePassword updateHeaderText={this.updateHeaderText} />
          )}/>

          <ForgotPassword path="/account" />
        </AccountContainer>
        <Footer/>
      </PageContainer>
    )
  }

  render() {
    return (
      <React.Fragment>
        <ToasterNotification/>
        <Large>
          {this.renderAccountSection(false)}
        </Large>
        <Phone>
          {this.renderAccountSection(true)}
        </Phone>
      </React.Fragment>
    );
  }
}

const AccountController = props => (
  <AuthContext.Consumer>
    { authState => <Account {...props} authState={authState} /> }
  </AuthContext.Consumer>
);

export default withRouter(AccountController);
