import React, { Component } from 'react';
import { withCookies } from "react-cookie";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AuthContext } from '../components/context/AuthenticatorContext';
import AdventuresSection from "../components/home/AdventuresSection";
import LocationDetails from '../components/LocationDetails';
import Recommendations from '../components/NearbyRecommendations';
import PrivateEvents from '../components/PrivateEvents';
import { WhiteOutlineButton } from "../components/buttons";
import HeaderMeta from "../components/HeaderMeta";

import { LOCATION_RESPONSE_DATA } from '../util/constants';
import { checkIsTicketingAvailableForSiteAdventure } from '../util/common';
import { DIApi } from '../util/api';

import SetLocationImg from '../assets/images/img-legal.svg';
import { Desktop, Mobile } from "../components/responsive/Breakpoints";

const PageContainer = styled.div`
  height: auto;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OverlayBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500000000;
  background-color: ${ props => props.theme.white_25 };
  backdrop-filter: blur(10px);
`;

const DialogContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 22px;
`;

const LocationDialog = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${ props => props.isMobile ? '320px' : '364px' };
  font-size: ${ props => props.isMobile ? '0.875rem' : '1rem' };
  padding: 22px;
  border-radius: 6px;
  background-color: ${ props => props.theme.charcoal };
`;

const DialogTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 10px 0;
  letter-spacing: 2px;
`;

const DialogTitle = styled.div`
  flex: 1;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
`;

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  color: ${ props => props.theme.white_70};
`;

const DialogSiteInfoContainer = styled.div`
  padding: 8px 0;
  margin: 20px 0;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;

const DialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 41px;
`;

const SiteItemName = styled.div`
  letter-spacing: 2px;
  color: ${ props => props.theme.white_80 };
  font-weight: bold;
  font-size: 0.75rem;
`;

const SiteItemAddress = styled.div`
  font-size: 0.875rem;
`;

const DialogImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

class LocationLandingWithContext extends Component {
  state = {
    viewing_site: {},
    site_adventures: [],
    show_dialog: false,
    dismissed_dialog: false,
  };

  async componentDidMount () {
    const locationProp = this.props.match.params.location;
    const viewing_site_id = LOCATION_RESPONSE_DATA[locationProp];

    const site_adventures = await this.getFilteredAdventuresForLocation(viewing_site_id);
    const viewing_site = this.getLocationInfo(viewing_site_id);

    this.setState({ viewing_site, site_adventures });
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    const locationProp = this.props.match.params.location;
    const viewing_site_id = LOCATION_RESPONSE_DATA[locationProp];

    // Check first if our site_id differs from the current viewed site_id
    if (this.props.parentState.selectedSite.site_id !== viewing_site_id) {
      // We may want to present the dialog.
      // Check first if the user has already dismissed the dialog before and if the current location differs
      // from the previous viewed location from props
      if (this.state.dismissed_dialog && prevProps.match.params.location !== locationProp) {
        // Reset the dismissed_dialog property to allow this dialog to be displayed again
        this.setState({ dismissed_dialog: false });
      } else if (!this.state.dismissed_dialog && !this.state.show_dialog) {
        // If the dialog hasn't been dismissed before and is not currently being shown, then show the dialog
        this.setState({ show_dialog: true });
      }
    }

    if (prevProps.parentState.adventures.length !== this.props.parentState.adventures.length) {
      const site_adventures = await this.getFilteredAdventuresForLocation(viewing_site_id);
      this.setState({ site_adventures });
    }

    if (prevProps.parentState.sites.length !== this.props.parentState.sites.length) {
      const viewing_site = this.getLocationInfo(viewing_site_id);
      this.setState({ viewing_site });
    }

    // Detect if the viewing site has changed. This can happen here if the browser "back" or "forward" buttons are used.
    if (this.state.viewing_site.site_id  && this.state.viewing_site.site_id !== viewing_site_id) {
      const viewing_site = this.getLocationInfo(viewing_site_id);
      const site_adventures = await this.getFilteredAdventuresForLocation(viewing_site_id);
      this.setState({ viewing_site, site_adventures });
    }
  }

  async getFilteredAdventuresForLocation (siteId) {
    const { allTitles, sites } = this.props.parentState;
    let site = {};
    sites.some(location => {
      if (location.site_id === siteId) {
        site = location;
        return true;
      }
      return false;
    });
    const filteredAdventures = allTitles.filter(adventure => {
      const licensedSiteIds = adventure.licensed_sites.map(licensedSite => licensedSite.site_id);
      return licensedSiteIds.includes(siteId);
    });

    const siteAdventures = await DIApi.getExperiencesBySite(siteId);
    const siteAdventuresMap = siteAdventures.reduce((acc, adventure) => {
      return {
        ...acc,
        [adventure.title_id]: adventure,
      };
    }, {});

    const augmentedAdventures = filteredAdventures.map(adventure => {
      const newAdventure = {...adventure};
      const siteAdventure = siteAdventuresMap[adventure.title_id];
      if (siteAdventure) {
        newAdventure.licensed_site = siteAdventure.licensed_site;
      }

      newAdventure.isAvailableAtCurrentLocation = checkIsTicketingAvailableForSiteAdventure(siteAdventure, site);
      return newAdventure;
    });

    return augmentedAdventures;
  }

  getLocationInfo (siteId) {
    const { sites } = this.props.parentState;

    let site = {};
    sites.some(location => {
      if (location.site_id === siteId) {
        site = location;
        return true;
      }
      return false;
    });

    return site;
  }

  handleConfirmLocation = () => {
    this.setState({ show_dialog: false, dismissed_dialog: true });
    this.props.parentState.handleSaveLocation(this.state.viewing_site);
  };

  renderConfirmLocationDialog (isMobile) {
    const locationProp = this.props.match.params.location;
    const viewing_site_id = LOCATION_RESPONSE_DATA[locationProp];

    // Only display the dialog if show_dialog is true AND the viewing_site's site id matches
    // the site_id derived from url path. This will prevent the dialog from temporarily showing
    // a previously viewed site's information before the new viewing_site data comes in.
    if (!this.state.show_dialog || this.state.viewing_site.site_id !== viewing_site_id) {
      return;
    }

    const {street_address, city, state, postal_code, site_legalname} = this.state.viewing_site;
    let siteAddress = `${street_address}, ${city}, ${state}`;
    if (postal_code) {
      siteAddress += ` ${postal_code}`;
    }
    return (
      <OverlayBackground>
        <DialogContainer>
          <LocationDialog isMobile={isMobile}>
            <DialogImgContainer>
              <img style={{ marginTop: 6 }} src={SetLocationImg}/>
            </DialogImgContainer>

            <DialogTitleRow>
              <DialogTitle>SET LOCATION</DialogTitle>
            </DialogTitleRow>

            <DialogBody style={{ overflowY: 'unset' }}>
              <div>Do you want to change your location to:</div>
              <DialogSiteInfoContainer>
                <SiteItemName>{site_legalname.toUpperCase()}</SiteItemName>
                <SiteItemAddress>{siteAddress}</SiteItemAddress>
              </DialogSiteInfoContainer>
              <div>Confirm the location below and tap 'YES'.</div>
            </DialogBody>

            <DialogFooter>
              <WhiteOutlineButton
                style={{ marginRight: 20 }}
                onClick={this.handleConfirmLocation}
              >
                YES
              </WhiteOutlineButton>
              <WhiteOutlineButton
                onClick={() => this.setState({ show_dialog: false, dismissed_dialog: true })}
              >
                NO
              </WhiteOutlineButton>
            </DialogFooter>
          </LocationDialog>
        </DialogContainer>
      </OverlayBackground>
    );
  }

  render () {
    const locationProp = this.props.match.params.location;
    if (!Object.keys(this.state.viewing_site).length) {
      return null;
    }

    const viewingSiteId = LOCATION_RESPONSE_DATA[locationProp];
    const locationData = this.props.parentState.cms.getSiteAssets(viewingSiteId);

    if (!locationData || !Object.keys(locationData).length) {
      return (
        <PageContainer name="page-container">
          <HeaderMeta page={this.state.viewing_site.site_id}/>
        </PageContainer>
      );
    }

    return (
      <PageContainer id={"location-home"} name="page-container">
        <Desktop>
          {this.renderConfirmLocationDialog()}
        </Desktop>
        <Mobile>
          {this.renderConfirmLocationDialog(true)}
        </Mobile>
        <HeaderMeta page={this.state.viewing_site.site_id}/>
        <LocationDetails locationData={locationData} site={this.state.viewing_site} hasStoredSite={this.props.parentState.hasStoredSite}/>
        <AdventuresSection
          adventures={this.state.site_adventures}
          cms={this.props.parentState.cms}
          site={this.state.viewing_site}
          heading={(<span>NOW AVAILABLE AT {locationData.copy.display_name.toUpperCase()}</span>)}
        />
        <Recommendations locationData={locationData} site={this.state.viewing_site}/>
        <PrivateEvents locationData={locationData}/>
      </PageContainer>
    );
  }
}

const LocationLanding = props => (
  <AuthContext.Consumer>
    { parentState => <LocationLandingWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withCookies(withRouter(LocationLanding));
