import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import crypto from 'crypto';
import cardValid from 'card-validator';

import { WhiteButton } from '../buttons'
import { BlueButton } from '../buttons';
import theme from '../../util/theme';
import { Large, Phone } from '../responsive/Breakpoints';
import MobileCheckoutFooterBtn from '../MobileCheckoutFooterBtn';
import { BasicInputField as Input } from './';
import CheckoutOrderDetails from '../CheckoutOrderDetails';
import FooterSpacer from '../NavbarSpacer';
import LoadingSpinner from '../LoadingSpinner';
import DisabledInputField from "./DisabledInputField";
import { getCybersourceCredentials } from '../../util/common';

const CARD_TYPE_MAP = {
  'visa': '001',
  'mastercard': '002',
  'american-express': '003',
  'discover': '004',
  'diners-club': '005',
  'jcb': '007',
  'maestro': '024',
  'hipercard': '050',
  'elo': '054',
};

const DEFAULT_CARD_LENGTHS = [16];
const DEFAULT_CARD_GAPS = [4, 8, 12];

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${ props => props.isMobile ? '48px' : '42px'};
`;

const PaymentInfoRow = styled.div`
  display: flex;
  flex-direction: ${ props => props.isMobile ? 'column' : 'row'};
  justify-content: ${ props => props.isMobile ? undefined : 'center'};
  align-items: ${ props => props.isMobile ? 'center' : undefined};
  width: ${ props => props.isMobile ? '100%' : 'auto'};
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${ props => props.isMobile ? '100%' : 'auto'};
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: ${ props => props.isMobile ? '100%' : '310px'};
  max-width: ${ props => props.isMobile ? '360px' : undefined};
  padding: ${ props => props.isMobile ? '0 22px' : undefined};
  margin-top: ${ props => props.isMobile ? '42px' : undefined};
  box-sizing: ${ props => props.isMobile ? 'border-box' : undefined};
`;

const InputLabel = styled.label`
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: ${ props => props.flex ? '30px' : undefined};
  flex: ${ props => props.flex ? '1' : undefined};
  color: ${ props => {
    if (props.focused) {
      return props.theme.ds_bright_blue;
    }

    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

const InputSpacer = styled.div`
  display: flex;
  width: 14px;
  flex-shrink: 0;
`;

const UserSignoutTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${ props => props.isMobile ? '14px 0 36px 0' : '24px 0 16px 0'};
`;

const TermsText = styled.span`
  font-size: 0.8125rem;
  color: ${ props => props.link ? props.theme.ds_dusk_blue : props.theme.charcoal};
  cursor: ${ props => props.link ? 'pointer' : undefined};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${ props => props.isMobile ? 'auto' : '100%'};
  height: ${ props => props.isMobile ? '68px' : undefined};
  max-width: ${ props => props.isMobile ? '360px' : undefined};
  padding: ${ props => props.isMobile ? '32px 0 22px 0' : '36px 0 22px 0'};
`;

const PromoButtonRow = styled.div`
  display: flex;
  flex-direction: ${ props => props.isMobile ? 'column' : 'row'};
  justify-content: space-between;
  width: ${ props => props.isMobile ? 'auto' : '100%'};
  height: ${ props => props.isMobile ? 'auto' : undefined};
  max-width: ${ props => props.isMobile ? '360px' : undefined};
  padding: ${ props => props.isMobile ? '32px 0 22px 0' : '12px 0 22px 0'};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: ${ props => props.isMobile ? '360px' : undefined};
  padding: ${ props => props.isMobile ? '32px 22px 22px 22px' : '36px 0 22px 0'};
  box-sizing: ${ props => props.isMobile ? 'border-box' : undefined};
`;

const SmallText = styled.div`
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-top: ${ props => props.marginTop ? '22px' : undefined};
  text-align: ${ props => props.isMobile ? 'center' : undefined};
  font-weight: ${ props => props.bold ? 'bold' : undefined};
  color: ${ props => props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal};
  cursor: ${ props => props.blue ? 'pointer' : undefined};
`;

const CCInput = styled(Input)`
  border: ${ props => props.isInvalid ? '1px solid red !important' : 'parent'};
`;

class CybsPaymentForm extends Component {
  state = {
    signedDataValues: {
      access_key: process.env.REACT_APP_CYBS_ACCESS_KEY,
      profile_id: process.env.REACT_APP_CYBS_PROFILE_ID,
      signed_field_names: "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_city,bill_to_address_country,ignore_avs",
      // signed_field_names: 'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email',
      unsigned_field_names: 'card_type,card_number,card_expiry_date,card_cvn',
      locale: 'en',
      transaction_type: 'authorization',
      payment_method: 'card',
      currency: 'AED',
      amount: '',
      bill_to_address_line1: "1 Card Lane",
      bill_to_address_city: "My City",
      bill_to_address_country: "AE",
      ignore_avs: 'true',
    },
    // Auto generated values
    transaction_uuid: '',   //crypto.randomBytes(8).toString('hex'),
    signed_date_time: '',   //moment().utc().format(),
    reference_number: '',   //Date.now(),
    signature: '',
    // User supplied fields
    bill_to_email: '',
    bill_to_forename: '',
    bill_to_surname: '',
    // bill_to_address_postal_code: '',
    ccNum: '',
    ccCVV: '',
    ccExp: '',
    ccType: '',
    // Unrelated to CC Form
    loading: false,
    inputFocused: {
      cc: false,
      expire: false,
      cvv: false,
      zip: false,
    },
    promoCode: '',
    promoCodeFocused: false,
    newsletterOptIn: false,
    cardRequired: true,
    loadingPromoCode: false,
    // CC input validation
    ccNumLengths: [16],
    ccNumGaps: [4, 8, 12],
    isCCNumInvalid: false,
    isCCExpInvalid: false,
    displayOverlayPopup: false,
  };

  componentDidMount() {
    const hostname = window.location.hostname.replace('www.', '');
    const { profile_id, access_key } = getCybersourceCredentials(hostname);
    window.handlePopupReturn = this.handlePopupResponse;
    this.setState((state, props) => {
      return {
        signedDataValues: {
          ...state.signedDataValues,
          amount: props.orderDetails.total.toString(),
          profile_id,
          access_key,
        },
      };
    });

    if (this.props.authState && this.props.authState.currentUser && this.props.authState.currentUser.attributes) {
      this.setState({ bill_to_email: this.props.authState.currentUser.attributes.email });
      if (!this.nameRef || !this.nameRef.focus) {
        return;
      }
      return this.nameRef.focus();
    }
  }

  componentWillUnmount() {
    window.handlePopupReturn = undefined;
  }

  componentDidUpdate() {
    if (this.state.cardRequired && this.props.orderDetails.total === 0) {
      this.setState({ cardRequired: false });
    }
    if (!this.state.bill_to_email && this.props.authState && this.props.authState.currentUser && this.props.authState.currentUser.attributes) {
      this.setState({ bill_to_email: this.props.authState.currentUser.attributes.email });
      if (!this.nameRef || !this.nameRef.focus) {
        return;
      }
      return this.nameRef.focus();
    }

    if (this.state.signedDataValues.amount !== this.props.orderDetails.total.toString()) {
      this.setState((state, props) => {
        return {
          signedDataValues: {
            ...state.signedDataValues,
            amount: props.orderDetails.total.toString(),
          },
        };
      });
    }
  }

  handlePopupResponse = async (data) => {
    const { transaction_id: authRequestId, decision } = data;
    if (decision !== 'ACCEPT') {
      alert('We couldn\'t process the payment at this time. Please check all fields and try again.');
      return this.setState({ loading: false, displayOverlayPopup: false });
    }

    this.setState({ displayOverlayPopup: false });

    console.log(`Transaction Id is: ${authRequestId}`);
    const fullName = `${this.state.bill_to_forename} ${this.state.bill_to_surname}`;
    try {
      const success = await this.props.handleSubmitPayment({
        email: this.state.bill_to_email,
        name: fullName,
        transRef: authRequestId,
      });
      if (!success) {
        return this.setState({ loading: false });
      }
    } catch (err) {
      console.log('[CybsPaymentForm] -> (handlePopupResponse) Error Occurred:', err);
      alert('We couldn\'t process the payment at this time. Please check all fields and try again.');
      return this.setState({ loading: false });
    }
  }

  async fetchSignedData() {
    const host = process.env.REACT_APP_CYBS_SIGN_HOST || '';
    const {
      bill_to_forename,
      bill_to_surname,
      bill_to_email,
      // bill_to_address_postal_code,
    } = this.state;

    this.setState({
      transaction_uuid: this.props.checkoutState.ticketHold.partner_order_id + '_' + Date.now(),
      signed_date_time: moment().utc().format(),
      reference_number: Date.now(),
    }, async () => {
      const {
        transaction_uuid,
        signed_date_time,
        reference_number,
      } = this.state;

      const dataToSign = {
        ...this.state.signedDataValues,
        transaction_uuid,
        signed_date_time,
        reference_number,
        bill_to_forename,
        bill_to_surname,
        bill_to_email,
        // bill_to_address_postal_code,
      };

      const response = await axios.post(`${host}/cybersource/sign`, dataToSign);

      this.setState({ signature: response.data.signedData }, () => {
        this.launchOverlay(this.form);
        this.form.submit();
      });
    });
  }

  launchOverlay(form) {
    form.target = 'cybersource_form';
    this.setState({ displayOverlayPopup: true });
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ loading: true });
    const { orderDetails } = this.props;

    if (this.state.bill_to_email === '') {
      alert('Email is required');
      return this.setState({ loading: false });

    }
    if (this.state.bill_to_forename === '' || this.state.bill_to_surname === '') {
      alert('First and Last names are required');
      return this.setState({ loading: false });
    }
    // allow user to move with cc info if order total is 0
    if (orderDetails.total <= 0) {
      const fullName = `${this.state.bill_to_forename} ${this.state.bill_to_surname}`;
      try {
        const success = await this.props.handleSubmitPayment({
          email: this.state.bill_to_email,
          name: fullName,
        });
        if (!success) {
          return this.setState({ loading: false });
        }
      } catch (err) {
        console.log('[PaymentForm] -> (handleSubmit) Error Occurred:', err);
        alert('We couldn\'t process the payment at this time. Please check all fields and try again.');
        this.setState({ loading: false });
      }
    }
    else {
      try {
        const expireDate = this.state.ccExp.replace(/\-/g, '');
        const { val, isValid } = this.validateCCExpirationDate(expireDate);
        this.setState({ ccExp: val, isCCExpInvalid: !isValid }, () => {
          if (!this.checkCanSubmit()) {
            this.setState({ loading: false });
            alert('There are invalid entries in the form. Please check the form and try again.');
            return;
          }
          this.fetchSignedData();
        });
      } catch (err) {
        console.log('[PaymentForm] -> (handleSubmit) Error Occurred:', err);
        this.setState({ loading: false });
      }
    }
  };

  checkCanSubmit() {
    const {
      bill_to_email,
      bill_to_forename,
      bill_to_surname,
      ccNum,
      ccCVV,
      ccExp,
      ccType,
      isCCNumInvalid,
      isCCExpInvalid,
    } = this.state;

    if (!bill_to_email ||
      !bill_to_forename ||
      !bill_to_surname ||
      !ccNum ||
      !ccCVV ||
      !ccExp ||
      !ccType ||
      isCCNumInvalid ||
      isCCExpInvalid
    ) {
      return false;
    }

    return true;
  }

  handleInputFocused(inputFocused) {
    const inputFocusUpdate = {
      [inputFocused]: true,
    };
    this.setState({ inputFocused: { ...this.state.inputFocused, ...inputFocusUpdate } });
  }

  handleInputBlur(inputBlurred) {
    const inputFocusUpdate = {
      [inputBlurred]: false,
    }
    this.setState({ inputFocused: { ...this.state.inputFocused, ...inputFocusUpdate } });
  }

  handleCheckClicked = (e) => {
    e.preventDefault();
    this.setState({ newsletterOptIn: !this.state.newsletterOptIn });
  };
  handlePromoCode = async (e) => {
    e.preventDefault();
    this.setState({ loadingPromoCode: true });
    await this.props.handlePromoCodeSubmit(this.state.promoCode);
    return this.setState({ loadingPromoCode: false, promoCodeFocused: false, promoCode: '' });
  };
  enablePromoCode = () => {
    this.setState({ promoCodeFocused: !this.state.promoCodeFocused });
  };
  renderEmailInputArea = () => {
    const { isSignedIn } = this.props.authState;

    if (!isSignedIn) {
      // Return the standard, enabled input to enter email with
      return (
        <Input
          name="bill_to_email"
          autoFocus
          type="email"
          width="100%"
          placeholder="Email"
          label="Email*"
          required
          value={this.state.bill_to_email}
          onChange={(event) => this.setState({ bill_to_email: event.target.value })}
        />
      );
    }

    // User is logged in so display a disabled input box with disclaimer at the bottom to allow user to signout of the current
    // user account and login OR continue as guest.
    return (
      <React.Fragment>
        <Input
          name="bill_to_email"
          readOnly="readonly"
          type="email"
          width="100%"
          placeholder="Email"
          label="Email*"
          required
          value={this.state.bill_to_email}
          style={{ marginBottom: 12 }}
        />
        <UserSignoutTextContainer>
          <TermsText style={{ flex: 1 }}>Is this not you?</TermsText>
          <TermsText style={{ flex: 2 }} link onClick={this.props.handleUserSignoutAndNavigate}>Sign in with a different account or continue as a guest. &gt;</TermsText>
        </UserSignoutTextContainer>
      </React.Fragment>
    );
  };

  renderPayButton(isMobile) {
    const { orderDetails } = this.props;

    const btnContent = this.state.loading ? <LoadingSpinner /> : `PAY ${orderDetails.totalCost}`;

    return (
      <BlueButton
        id="btn-submit"
        type="submit"
        disabled={this.state.loading}
        width={isMobile ? '100%' : '300px'}
      >
        {btnContent}
      </BlueButton>
    );
  }

  renderGiftPromoSection(isEnabled, isMobile) {
    if (!isEnabled) {
      return (
        <Row isMobile={isMobile}>
          <SmallText blue isMobile={isMobile} onClick={this.enablePromoCode}>ENTER PROMO CODE &gt;</SmallText>
          <SmallText blue marginTop isMobile={isMobile} onClick={this.enablePromoCode}>ENTER GIFT CARD &gt;</SmallText>
        </Row>
      )
    }
    else {
      const btnContent = this.state.loadingPromoCode ? <LoadingSpinner /> : `APPLY CODE`;
      return (
        <Column isMobile={isMobile}>
          <Input
            autoFocus
            type="text"
            width="100%"
            label="Please be sure to include any dashes in your code"
            placeholder="Gift Code/Promo Code"
            value={this.state.promoCode}
            style={{ marginBottom: 12 }}
            onChange={(event) => this.setState({ promoCode: event.target.value })}>
          </Input>
          <PromoButtonRow isMobile={isMobile}>
            <BlueButton width={isMobile ? '100%' : undefined} onClick={this.handlePromoCode}>
              {btnContent}
            </BlueButton>
            {
              this.state.loadingPromoCode
                ? null
                : <WhiteButton width={isMobile ? '100%' : undefined} style={{ margin: isMobile ? '10px 0 0 0' : '0 0 0 10px' }} onClick={this.enablePromoCode}>
                  CANCEL
                  </WhiteButton>
            }
          </PromoButtonRow>
        </Column>
      );
    }
  }

  insertHiddenInputFields() {
    const hiddenFields = Object.keys(this.state.signedDataValues).map((field, index) => {
      return (
        <input
          key={index}
          id={field}
          name={field}
          value={this.state.signedDataValues[field]}
          type="hidden" />
      );
    });

    return hiddenFields;
  }

  onCardNumberChange = (event) => {
    // Only allow numbers to be entered
    if (!RegExp('^(?:[0-9]+$|)$').test(event.target.value.slice(this.state.ccNum.length))) {
      return;
    }

    // Validate card number and get card formatting info
    const valid = cardValid.number(event.target.value)

    const stateUpdates = {
      ccNumLengths: valid.card ? valid.card.lengths : DEFAULT_CARD_LENGTHS,
      ccNumGaps: valid.card ? valid.card.gaps : DEFAULT_CARD_GAPS,
      isCCNumInvalid: !valid.isPotentiallyValid,
    }

    // Strip away formatted spaces for easier length validation
    const strippedCCNum = event.target.value.replace(/\s/g, '');
    // If the current cc number length exceeds all expected lengths, prevent any more characters
    if (stateUpdates.ccNumLengths.every(value => strippedCCNum.length > value)) {
      return;
    }
    stateUpdates.ccNum = strippedCCNum;

    if (valid.card) {
      const ccType = CARD_TYPE_MAP[valid.card.type];
      // Record the card type if we have a possible valid card.
      stateUpdates.ccType = ccType;
    }

    this.setState(stateUpdates);
  };

  onCardExpireChange = (event) => {
    const currentVal = event.target.value.replace(/\-/g, '');
    if (!RegExp('^(?:[0-9]+$|)$').test(currentVal)) {
      return;
    }

    if (currentVal.length > 6) {
      return;
    }

    let val = currentVal;
    if (currentVal.length === 1 && parseInt(currentVal, 10) > 1) {
      val = '0' + val;
    }

    const valid = cardValid.expirationDate(val);

    if (val.length > 2) {
      const splitVal = val.split('');
      splitVal.splice(2, 0, '-');
      val = splitVal.join('');
    }

    this.setState({ ccExp: val, isCCExpInvalid: !valid.isPotentiallyValid });
  };

  onCardExpireBlur = (event) => {
    const currentVal = event.target.value.replace(/\-/g, '');
    const {val, isValid} = this.validateCCExpirationDate(currentVal);

    this.setState({ ccExp: val, isCCExpInvalid: !isValid });
  };

  validateCCExpirationDate(currentVal) {
    const valid = cardValid.expirationDate(currentVal);

    let val = currentVal;
    if (valid.month && valid.year) {
      const normalizedYear = valid.year.length > 2 ? valid.year : "20" + valid.year;
      val = valid.month + normalizedYear;
    }

    if (val.length > 2) {
      const splitVal = val.split('');
      splitVal.splice(2, 0, '-');
      val = splitVal.join('');
    }

    return { val, isValid: valid.isValid };
  }

  onCardCVVChange = (event) => {
    if (RegExp('^(?:[0-9]+$|)$').test(event.target.value)) {
      this.setState({ ccCVV: event.target.value });
    }
  }

  renderOverlay () {
    return (
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 4000000,
        backgroundColor: theme.black_30,
        display: this.state.displayOverlayPopup ? 'block' : 'none',
      }}>
        <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{
            width: 640,
            height: 480,
            backgroundColor: 'white',
          }}>
            <iframe id="cybersource_form" name="cybersource_form" style={{
              width: '100%',
              height: '100%',
            }}/>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isMobile, orderDetails, titleId } = this.props;

    let ccForm = (
      <React.Fragment>
        <InputRow>
          <CCInput
            name="card_number"
            type="text"
            placeholder="Visa or Mastercard Number"
            label="Visa or Mastercard Number*"
            required
            value={this.state.ccNum}
            flex={1}
            margin={'0 14px 0 0'}
            isInvalid={this.state.isCCNumInvalid}
            onChange={this.onCardNumberChange}
          />
          <CCInput
            name="card_expiry_date"
            type="text"
            placeholder="MM-YYYY"
            label="Exp Date*"
            required
            value={this.state.ccExp}
            width={96}
            isInvalid={this.state.isCCExpInvalid}
            onChange={this.onCardExpireChange}
            onBlur={this.onCardExpireBlur}
          />
        </InputRow>
        <InputRow>
          <Input
            name="card_cvn"
            type="text"
            placeholder="CVV"
            label="CVV*"
            required
            value={this.state.ccCVV}
            width={79}
            maxLength={4}
            margin={'0 14px 0 0'}
            onChange={this.onCardCVVChange}
          />
        </InputRow>
        <Large>
          {this.renderGiftPromoSection(this.state.promoCodeFocused)}
        </Large>
      </React.Fragment>
    );

    if (!this.state.cardRequired) {
      ccForm = (
        <React.Fragment>
          <InputRow>
            <DisabledInputField
              type="text"
              flex={1}
              padding={'0 14px 0 0'}
              label="Visa or Mastercard Number*"
              placeholder="Visa or Mastercard Number" />
            <DisabledInputField
              type="text"
              width={96}
              label="Exp Date*"
              placeholder="MM/YY" />
          </InputRow>
          <InputRow>
            <DisabledInputField
              type="text"
              width={79}
              padding={'0 14px 0 0'}
              label="CVV*"
              placeholder="CVV" />
          </InputRow>
          <Large>
            {this.renderGiftPromoSection(this.state.promoCodeFocused)}
          </Large>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {/* Overlay for displaying 3d Secure flows */}
        {this.renderOverlay()}
        <StyledForm ref={ref => this.form = ref} onSubmit={this.handleSubmit} isMobile={isMobile} id="payment_confirmation" action="https://testsecureacceptance.cybersource.com/silent/embedded/pay" method="post" target="cybersource_form">
          <PaymentInfoContainer isMobile={isMobile}>
            <PaymentInfoRow isMobile={isMobile}>
              <CheckoutOrderDetails orderDetails={orderDetails} isMobile={isMobile} titleId={titleId} />

              <InputGroup isMobile={isMobile} name="payment-form-group">
                {this.insertHiddenInputFields()}
                <input
                  id="reference_number"
                  name="reference_number"
                  value={this.state.reference_number}
                  type="hidden" />
                <input
                  id="transaction_uuid"
                  name="transaction_uuid"
                  value={this.state.transaction_uuid}
                  type="hidden" />
                <input
                  id="signed_date_time"
                  name="signed_date_time"
                  value={this.state.signed_date_time}
                  type="hidden" />
                <input
                  id="signature"
                  name="signature"
                  value={this.state.signature}
                  type="hidden" />
                {/* Figure out some method for determining the card type. (Some kind of selector?) */}
                <input
                  id="card_type"
                  name="card_type"
                  value={this.state.ccType}
                  type="hidden" />
                {this.renderEmailInputArea()}
                <InputRow>
                  <Input
                    name="bill_to_forename"
                    type="text"
                    placeholder="First Name"
                    label="First Name*"
                    required
                    value={this.state.bill_to_forename}
                    margin={'0 14px 0 0'}
                    flex={1}
                    onChange={(event) => this.setState({ bill_to_forename: event.target.value })}
                    ref={(ref) => this.nameRef = ref ? ref.inputRef : null}
                  />
                  <Input
                    name="bill_to_surname"
                    type="text"
                    placeholder="Last Name"
                    label="Last Name*"
                    required
                    value={this.state.bill_to_surname}
                    flex={1}
                    onChange={(event) => this.setState({ bill_to_surname: event.target.value })}
                  />
                </InputRow>
                {ccForm}
              </InputGroup>
            </PaymentInfoRow>

          </PaymentInfoContainer>

          {/* Terms of service text here */}
          <Large>
            <TermsContainer>
              <TermsText><TermsText link onClick={this.props.linkToTerms}>Dreamscape Terms of Service &gt;</TermsText></TermsText>
            </TermsContainer>
          </Large>
          <Phone>
            {this.renderGiftPromoSection(this.state.promoCodeFocused, true)}
            <TermsContainer isMobile>
              <TermsText link onClick={this.props.linkToTerms}>Dreamscape Terms of Service &gt;</TermsText>
            </TermsContainer>
            <FooterSpacer name="footer-spacer" height={92} />
          </Phone>

          {/* PAYMENT SUBMIT BUTTON */}
          <Large>
            <InputRow>
              {this.renderPayButton(false)}
            </InputRow>
            <FooterSpacer name="footer-spacer" height={57} />
          </Large>

          <Phone>
            <MobileCheckoutFooterBtn>
              {this.renderPayButton(true)}
            </MobileCheckoutFooterBtn>
          </Phone>
        </StyledForm>
      </React.Fragment>
    );
  }
}

export default CybsPaymentForm;
