import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Elements,
} from 'react-stripe-elements';

import { DIApi } from "../util/api";
import { AuthContext } from "../components/context/AuthenticatorContext";
import PaymentForm from '../components/forms/PaymentForm';
import CybsPaymentForm from '../components/forms/CybsPaymentForm';
import { Desktop, Large, Mobile, Phone } from "../components/responsive/Breakpoints";
import { calculatePriceAndFees } from '../util/common';
import { sendAddToCartEvent, sendPurchaseConfirmedEvent } from "../util/analytics";
import { CURRENCY_SYMBOL } from '../util/constants';

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' };
  align-self: center;
  color: ${ props => props.theme.charcoal_90 };
  padding: ${ props => props.mobile ? '30px 48px 0 48px' : '60px 22px 0 22px' };
  text-align: ${ props => props.mobile ? 'center' : undefined };
  letter-spacing: -1px;
`;

// change from 3 -> 18 in order to tolerate a theoretical max latency of 2 minutes
const RETRY_COUNT = 18;
const RETRY_DELAY_MILLIS = 5000;

class CheckoutPaymentConfirm extends Component {
  componentDidMount () {
    document.body.style.background = 'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';
    // This will return the header information back to its default if it has been changed.
    const locationState = this.props.location.state;
    this.props.updateHeaderText({
      handleOnBackClicked: this.props.authState.isSignedIn ? null : () => this.props.history.replace('/checkout/signin', locationState),
    });
  }

  componentWillUnmount () {
    document.body.style.background = 'black';
  }
  generateCodesArray(ticketHold, code){
    let codes = [];
    ticketHold.codes.forEach(appliedCode => {
      codes.push({code: appliedCode.code})
    });
    if(code) {
      codes.push({"code": code});
    }
    return codes;
  }
  handleUserSignoutAndNavigate = async () => {
    await DIApi.userSignOut();
    this.props.authState.handleAuthStateChange('signedOut');
    const locationState = this.props.location.state;
    this.props.history.replace('/checkout/signin', locationState);
  };

  linkToTerms = () => {
    window.open(`${window.location.origin}/terms`);
  };
  handleSubmitCodes = async (code) => {
    const { ticketHold } = this.props.location.state;
    const {orderDetails} = this.props.location.state;
    const {showtime} = this.props.location.state;

    // Filter out potential duplicates. `codeExists` array will contain at least one value if the current code
    // submitted matches one contained in the `ticketHold.codes` array (which has been submitted to the API and validated).
    const codeExists = ticketHold.codes.filter(ticketCode => {
      return ticketCode.code.toUpperCase() === code.toUpperCase();
    });

    // If we have at least one record, we can safely assume this is a code that has already been applied.
    if (codeExists.length) {
      return alert('The code entered has already been applied.');
    }

    // Generate the "new" list of codes to submit to the API
    const codes = this.generateCodesArray(ticketHold, code);

    try {
      const purchaseData = await DIApi.applyGiftCode(ticketHold.partner_site_id, showtime.partner_experience_id, showtime.datetime, orderDetails.quantity, orderDetails.ada_quantity, codes);
      const ticketInfo = calculatePriceAndFees(purchaseData);
      const curSymbol = purchaseData.currency ? CURRENCY_SYMBOL[purchaseData.currency] || '$' : '$';
      orderDetails.totalCost = `${curSymbol}${ticketInfo.totalPrice.toFixed(2)}`;
      orderDetails.total = ticketInfo.totalPrice;
      orderDetails.giftDeduction = ticketInfo.ticketDeductions.gift ? `-${curSymbol}${ticketInfo.ticketDeductions.gift.toFixed(2)}` : null;
      orderDetails.promoDeduction = ticketInfo.ticketDeductions.promo ? `-${curSymbol}${ticketInfo.ticketDeductions.promo.toFixed(2)}` : null;
      orderDetails.ticketFees = ticketInfo.ticketFees;
      ticketHold.codes = purchaseData.codes;
    } catch (error) {
      console.debug('[CheckoutPurchaseConfirm] -> (handleSubmitCodes) error object', error.response);
      alert(error.response.data.message);
    }
  };

  handleSubmitPayment = async ({email, name, cardToken, transRef}) => {
    const { ticketHold } = this.props.location.state;
    const codes = this.generateCodesArray(ticketHold);
    if (!ticketHold) {
      // TODO: Display some kind of error here?
      return;
    }

    const paymentReqBody = {
      email,
      name,
      partner_site_id: ticketHold.partner_site_id,
      partner_order_id: ticketHold.partner_order_id,
      codes,
    };

    if (cardToken){
      paymentReqBody.card_token = cardToken.id;
    }

    if (transRef) {
      paymentReqBody.transaction_reference = transRef;
    }

    this.props.handleProcessingPurchase(true);

    try {
      const purchaseResponseData = await DIApi.purchaseTickets(paymentReqBody);

      // google tag manager
      const productInfo = {
        'id': purchaseResponseData.ticketed_title.title_id,
        'name': purchaseResponseData.ticketed_title.shortname,
        'price': purchaseResponseData.pricing['general-admission'],
        'category': 'Ticket',
        'quantity': this.props.location.state.orderDetails.quantity
      }

      // Send analytics
      sendPurchaseConfirmedEvent({
        productInfo,
        currency: purchaseResponseData.currency,
        titleId: this.props.location.state.adventure.title_id,
        totalPrice: this.props.location.state.totalPrice,
        orderId: this.props.location.state.ticketHold.partner_order_id,
      });

      const checkoutState = {
        ...this.props.location.state,
        purchaseData: purchaseResponseData,
        cardToken,
        customerEmail: email,
      };

      this.props.history.replace('/checkout/receipt', checkoutState);

      return true;
    } catch (error) {
      // Check if the request timed out.
      if (error.response.status === 504) {
        // This should start our order status check
        console.log('[CheckoutPurchaseConfirm] -> (handleSubmitPayment) start order status retry');
        return this.checkOrderStatus(email);
      }

      // There was a problem with the purchase
      this.props.handleProcessingPurchase(false);
      // TODO: Should display some sort of error here
      alert('We couldn\'t process the payment at this time. Please check all fields and try again.');
      return false;
    }
  };

  checkOrderStatus = async (email, attempts = 0) => {
    const { ticketHold } = this.props.location.state;
    const { partner_site_id, partner_order_id } = ticketHold;

    console.log('[CheckoutPurchaseConfirm] -> (checkOrderStatus) attempt:', attempts);
    try {
      const orderStatus = await DIApi.checkOrderStatus(partner_site_id, partner_order_id);

      // If http status is 202, the order status is still pending and we should try again.
      console.log('[CheckoutPurchaseConfirm] -> (checkOrderStatus) orderStatus', orderStatus);
      if (orderStatus.status === 202) {
        const retries = attempts + 1;

        // Check if we have exhausted our retry count
        if (retries >= RETRY_COUNT) {
          // Throw this error which will get locally caught below in the catch block
          throw new Error(`After ${RETRY_COUNT} attempts, we still find the order to be pending`);
        }

        return new Promise((resolve) => {
          setTimeout(async () => {
            return resolve(this.checkOrderStatus(email, retries));
          }, RETRY_DELAY_MILLIS);
        });
      }

      // Order http status was not 202 and must be within the 2xx range so must be a 200 response.
      const purchaseResponseData = orderStatus.data;

      const checkoutState = {
        ...this.props.location.state,
        purchaseData: purchaseResponseData,
        customerEmail: email,
      };

      this.props.history.replace('/checkout/receipt', checkoutState);
      return true;
    } catch (error) {
      // There was a problem with the purchase
      console.log('[CheckoutPurchaseConfirm] -> (checkOrderStatus) error', error);
      this.props.handleProcessingPurchase(false);
      return false;
    }
  };

  renderPaymentForm (isMobile) {
    // Pull out the state object from props.location and rename to checkoutState
    const { state: checkoutState } = this.props.location;
    const { orderDetails, adventure } = checkoutState;
    //TODO: Setting site specific for now but might want to find a more broad definition for when
    // the cybersource payment form should be utilized
    if (adventure.licensed_site.site_id === 'AE000001') {
      return (
        <CybsPaymentForm
          isMobile={isMobile}
          authState={this.props.authState}
          orderDetails={orderDetails}
          handleSubmitPayment={this.handleSubmitPayment}
          handlePromoCodeSubmit={this.handleSubmitCodes}
          checkoutState={checkoutState}
          linkToTerms={this.linkToTerms}
          handleUserSignoutAndNavigate={this.handleUserSignoutAndNavigate}
          titleId={adventure.title_id}
        />
      );
    }

    return (
      <Elements>
        <PaymentForm
          isMobile={isMobile}
          authState={this.props.authState}
          orderDetails={orderDetails}
          handleSubmitPayment={this.handleSubmitPayment}
          handlePromoCodeSubmit={this.handleSubmitCodes}
          checkoutState={checkoutState}
          linkToTerms={this.linkToTerms}
          handleUserSignoutAndNavigate={this.handleUserSignoutAndNavigate}
          titleId={adventure.title_id}
        />
      </Elements>
    );
  }

  render () {
    const pageDetails = {
      title: 'ENTER YOUR PAYMENT INFO',
    };

    return (
      <Page name="checkout-purchase-page">
        <Desktop>
          <SectionTitle>{pageDetails.title}</SectionTitle>
        </Desktop>
        <Mobile>
          <SectionTitle mobile>{pageDetails.title}</SectionTitle>
        </Mobile>

        {/* Desktop and Tablet Devices */}
        <Large>
          {this.renderPaymentForm(false)}
        </Large>

        {/* Mobile Phone size displays */}
        <Phone>
          {this.renderPaymentForm(true)}
        </Phone>
      </Page>
    );
  }
}

const PaymentConfirm = props => (
  <AuthContext.Consumer>
    { authState => <CheckoutPaymentConfirm {...props} authState={authState} /> }
  </AuthContext.Consumer>
);

export default withRouter(PaymentConfirm);
