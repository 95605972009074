import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile } from './responsive/Breakpoints';
import styled from 'styled-components';

const ContainerBound = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  min-height: 485px;
`;

const Container = styled.div`
  background: #fff;
  padding: ${ props => props.mobile ? '36px 21px 49px' : '84px 100px 60px 100px' };
`;

const Header = styled.div`
  padding-bottom: 68px;
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  line-height: 1.09;
  color: ${ props => props.theme.charcoal };
`;

const ContentBlocks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DestinationBlock = styled.div`
  flex: 5;
  padding-right: ${ props => props.mobile ? undefined : '80px' };
`;

const BlockHeader = styled.div`
  font-family: ${ props => props.theme.serif };
  padding-bottom: 18px;
  padding-top: ${ props => props.mobile ? '10px' : '0' };
  font-size: ${ props => props.mobile ? '1.375rem' : '2.25rem' };
  line-height: 1.19;
  color: ${ props => props.theme.charcoal_90 };
`;

const DestinationDescription = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  color: ${ props => props.theme.charcoal_60 };
`;

const TripDetailsBlock = styled.div`
  padding-top: ${ props => props.mobile ? '40px' : undefined };
  flex: 4;
`;

const TripDetailsTextContainer = styled.div`
  padding-bottom: 18px;
`;
const HeaderText = styled.div`
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  font-weight: bold;
  color: ${ props => props.theme.charcoal_90 };
`;

const SubText = styled.div`
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  color: ${ props => props.theme.charcoal_60 };
`;

const PageBreak = styled.div`
  width: 100%;
  margin: 0 auto 18px;
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
`;

const TicketingDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 40px;
`;

const Para = styled.p`
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  color: ${ props => props.theme.charcoal_60 };
  margin: 16px 0 0 0;
`;

class ItineraryBlock extends Component {
  state = {};

  checkForContent = () => {
    return this.props.contentData ? this.props.contentData.itinerary : null;
  };

  checkForSiteContent = () => {
    return this.props.siteCopy ? this.props.siteCopy.ticketingDetails : null;
  };

  renderTripDetails = (itineraryList, mobile) => {
    return itineraryList.map((listItem, idx) => (
      <TripDetailsTextContainer key={idx}>
        <HeaderText mobile={mobile}>{listItem.title}</HeaderText>
        <SubText mobile={mobile}>{listItem.body}</SubText>
      </TripDetailsTextContainer>
    ))
  };

  renderTicketingDetails = (ticketingCopy, mobile) => {
    if (!ticketingCopy) {
      return null;
    }

    const header = ticketingCopy.header ? ticketingCopy.header.toUpperCase() : 'NEW TICKETING APPROACH';
    const lines = ticketingCopy.lines.map((ticketingDetailsLine, index) => (
      <Para key={'tx-detail-' + index} mobile={mobile}>{ticketingDetailsLine}</Para>
    ));

    return (
      <TicketingDetails>
        <PageBreak/>
        <HeaderText mobile={mobile}>{header}</HeaderText>
        {lines}
      </TicketingDetails>
    );
  };

  renderContent(mobile) {
    const adventureContent = this.checkForContent();
    const ticketingDetailsCopy = this.checkForSiteContent();

    const content = (
      <React.Fragment>
        <DestinationBlock mobile={mobile}>
          <PageBreak/>
          <BlockHeader mobile={mobile}>{adventureContent.copy.destination}</BlockHeader>
          <DestinationDescription mobile={mobile}>{adventureContent.copy.description}</DestinationDescription>
          { this.renderTicketingDetails(ticketingDetailsCopy, mobile) }
        </DestinationBlock>
        <TripDetailsBlock mobile={mobile}>
          <PageBreak/>
          <BlockHeader mobile={mobile}>Adventure Details</BlockHeader>
          { this.renderTripDetails(adventureContent.copy.itineraryList, mobile) }
        </TripDetailsBlock>
      </React.Fragment>
    );

    return (
      <Container mobile={mobile}>
        <ContainerBound>
          <Header mobile={mobile}>THE EXPERIENCE</Header>
          <Desktop>
            <ContentBlocks>
              {content}
            </ContentBlocks>
          </Desktop>
          <Mobile>
            {content}
          </Mobile>
        </ContainerBound>
      </Container>
    )
  }

  render () {
    return (
      <div>
        <Desktop>
          {this.renderContent(false)}
        </Desktop>
        <Mobile>
          {this.renderContent(true)}
        </Mobile>
      </div>
    );
  }
}

export default withRouter(ItineraryBlock);
