import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton } from '../components/buttons';
import { AuthContext } from "../components/context/AuthenticatorContext";
import { BasicInputField as Input } from '../components/forms';
import { DIApi } from "../util/api";
import {Large, Phone} from "../components/responsive/Breakpoints";
import LoadingSpinner from "../components/LoadingSpinner";

const Headline = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '1.875' : '2.75' }rem;
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' };
  text-align: center;
  color: ${ props => props.theme.charcoal };
  padding-bottom: ${ props => props.newAccount ? '0' : '16' }px;
  ${ props => !props.newAccount && props.mobile ? 'padding-bottom: 46px;' : '' }
`;

const SubHeadline = styled.div`
  text-align: center;
  max-width: 747px;
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '0.875' : '1' }rem;
  color: ${ props => props.theme.charcoal_60 };
  margin: 0 auto;
  padding-top: ${ props => props.mobile ? '13' : '20' }px;
`;

const CenteredContent = styled.div`
  ${ props => props.mobile ? '' : 'width: min-content; max-width: 430px;' }
  margin: ${ props => props.mobile ? '31px auto' : '46px auto 0' };
  position: relative;
`;

const StyledInput = styled(Input)`
  margin-bottom: 12px;
  width: ${ props => props.mobile ? '100%' : '430px' };
  height: 47px;
  max-height: 47px;
  border-radius: 3px;
  box-sizing: border-box;
  
  &:hover {
    border: 3px solid ${ props => props.theme.ds_bright_blue };
  }
`;

const SmallText = styled.div`
  font-size: 0.8125rem;
  color: ${ props => props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal };
  align-self: flex-end;
  cursor: ${ props => props.blue ? 'pointer' : undefined };
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 40px;
`;

const SignInButton = styled(BlueButton)`
  color: #fff;
  margin: 40px 0 26px;
  border-radius: 3px;
  ${ props => props.mobile ? 'width: 100%;' : '' }
`;

class AccountSignIn extends Component {
  state = {
    email: '',
    password: '',
    signInLoading: false,
  };

  handleInputChange = (element) => {
    const { name, value } = element.target;
    this.setState({
      [name]: value,
    });
  };

  handleUserSignIn = async () => {
    this.setState({signInLoading: true});
    const { email, password } = this.state;

    const signedIn = await DIApi.userSignIn(email.toLowerCase(), password);
    if (!signedIn) {
      alert("Invalid sign-in credentials");
      this.setState({signInLoading: false});
      return;
    }

    this.props.authState.handleAuthStateChange('signedIn');
    this.setState({ email: '', password: '' });

    this.navToAccount();
  };

  navToAccount = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account', locationState);
  };

  navToCreateAccount = () => {
    const locationState = this.props.location.state;
    this.props.history.push('/account/signup', locationState);
  };

  navToForgotPassword = () => {
    this.props.history.push('/account/forgot-password/request');
  };

  componentDidMount () {
    this.props.updateHeaderText({});
  }

  componentDidUpdate () {
    if (this.props.authState.isSignedIn) {
      this.navToAccount();
    }
  }

  render () {
    const signInContent = this.state.signInLoading ? <LoadingSpinner/> : 'SIGN IN';

    let headline = (mobile) => <Headline mobile={mobile}>ACCOUNT<br/>SIGN IN</Headline>;

    if (this.props.location.state && this.props.location.state.newAccount) {
      headline = (mobile) => {
        return (
          <React.Fragment>
            <Headline mobile={mobile} newAccount>WELCOME TO DREAMSCAPE!</Headline>
            <SubHeadline mobile={mobile}>Thanks for creating a Dreamscape Account!<br/><br/>Use it to check in faster, be the first to
              know about the latest adventures, keep track of purchases and get access to special events.</SubHeadline>
          </React.Fragment>
        )
      };
    }

    return (
      <React.Fragment>
        <Large>
          {headline(false)}
          <CenteredContent>
            <StyledInput
              name="email"
              placeholder="Email"
              type="email"
              value={ this.state.email }
              onChange={ this.handleInputChange }
            />
            <StyledInput
              name="password"
              placeholder="Password"
              type="password"
              value={ this.state.password }
              onChange={ this.handleInputChange }
            />
            <SmallText blue style={{float: 'right'}} onClick={ this.navToForgotPassword }>Forgot your password?</SmallText>

            <SignInButton
              invert
              width={430}
              disabled={this.state.signInLoading}
              onClick={ this.handleUserSignIn }>{signInContent}</SignInButton>

            <Row>
              <SmallText>Don't have an account?&nbsp;</SmallText>
              <SmallText blue onClick={ this.navToCreateAccount }>Create an account &gt;</SmallText>
            </Row>
          </CenteredContent>
        </Large>
        <Phone>
          {headline(true)}
          <CenteredContent mobile>
            <StyledInput
              mobile
              width={'100%'}
              name="email"
              placeholder="Email"
              type="email"
              value={ this.state.email }
              onChange={ this.handleInputChange }
            />
            <StyledInput
              mobile
              width={'100%'}
              name="password"
              placeholder="Password"
              type="password"
              value={ this.state.password }
              onChange={ this.handleInputChange }
            />
            <SmallText blue style={{float: 'right'}} onClick={ this.navToForgotPassword }>Forgot your password?</SmallText>

            <SignInButton
              mobile
              invert
              disabled={this.state.signInLoading}
              onClick={ this.handleUserSignIn }>{signInContent}</SignInButton>

            <Row>
              <SmallText>Don't have an account?&nbsp;</SmallText>
              <SmallText blue onClick={ this.navToCreateAccount }>Create an account &gt;</SmallText>
            </Row>
          </CenteredContent>
        </Phone>
      </React.Fragment>
    )
  }
}

const SignIn = props => (
  <AuthContext.Consumer>
    { authState => {
      return (<AccountSignIn {...props} authState={authState} />)
    }}
  </AuthContext.Consumer>
);

export default withRouter(SignIn);
