import React from "react";
// import PropTypes from "prop-types";
import { Motion, spring } from "react-motion";
import Hammer from "react-hammerjs";
import styled, { css } from 'styled-components';
import { NavLink } from "react-router-dom";

import { AuthContext } from "../components/context/AuthenticatorContext";
import theme from "../util/theme";

const MyNavLink = props => <NavLink activeStyle={{ color: theme.ds_bright_blue }} {...props} />;

const Overlay = styled(Hammer).attrs({
  style: props => ({
    opacity: props.x / props.drawerwidth,
    transform: `translate3d(${ props.drawerwidth }px, 0, 0)`,
  }),
})`
  z-index: -2;
  position: fixed;
  width: ${ props => props.clientwidth }px;
  height: 100%;
  background: ${ props => props.overlaycolor };
  top: 0;
  left: 0;
  margin: 0;
`;

const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${ props => props.width}px;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: black;
`;

const DrawerNavBar = styled.div`
  height: ${ props => props.toasterOffset ? 32 + props.toasterOffset : 32 }px;
  padding: 11px;
  flex-direction: column;
  flex-shrink: 0;
`;

const baseLinkStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 19px 0;
  margin: 0 20px;
  border-bottom: 1px solid ${ props => props.theme.white_70 };
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: ${ props => props.theme.white_80 };
  text-decoration: none;
  flex-shrink: 0;
`;

const StyledNavLink = styled(MyNavLink)`
  ${baseLinkStyles}
`;

const StyledExternalLink = styled.a`
  ${baseLinkStyles}
`;

const styles = function (val, props) {
  const {
    zIndex,
    height,
    fadeOut,
    offset
  } = props;

  let width = props.width;
  if (/\D/.test(width)) {
    width = document.body.clientWidth * (width.match(/\d*/) / 100);
  }

  const opacity = (val - offset) / width;
  const stepX = val - width;

  const transform = {
    boxSizing: "content-box",
    pointer: "cursor",
    position: "fixed",
    display: "block",
    zIndex: zIndex,
    width: width,
    maxWidth: width,
    height: height,
    top: 0,
    left: 0,
    margin: 0,
    transform: `translate3d(${stepX}px, 0, 0)`,
    WebkitTransform: `translate3d(${stepX}px, 0, 0)`,
    opacity: fadeOut ? opacity : 1,
    backgroundColor: 'white',
  };

  return { transform };
};

class LeftNavDrawerWithContext extends React.Component {
  static defaultProps = {
    zIndex: 10000,
    noTouchClose: false,
    onChange: () => {},
    overlayColor: "rgba(0, 0, 0, 0.4)",
    config: { stiffness: 350, damping: 40 },
    open: false,
    width: 300,
    height: "100%",
    panTolerance: 50,
    fadeOut: false,
    offset: 0
  };

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    const { open: nextOpen } = nextProps;

    if (nextOpen !== open) {
      if (nextOpen) {
        return this.open();
      }
      return this.close();
    }
  }

  state = {
    currentState: "CLOSED",
    toasterNotif: null,
  };

  componentDidMount() {
    const toasterNotif = document.getElementById('toasterNotification');
    this.setState({ toasterNotif });
  }

  isState = s => s === this.state.currentState;
  isClosed = () => this.isState("CLOSED");
  isOpen = () => this.isState("OPEN");
  isOpening = () => this.isState("IS_OPENING");
  isClosing = () => this.isState("IS_CLOSING");

  close() {
    this.props.onChange(false);
    return this.setState({ currentState: "CLOSED", x: 0 });
  }

  open() {
    this.props.onChange(true);

    return this.setState({ currentState: "OPEN", x: this.calculateWidth() });
  }

  isClosingDirection(direction) {
    return direction === 2;
  }

  closingOrOpening(direction) {
    return this.isClosingDirection(direction) ? "IS_CLOSING" : "IS_OPENING";
  }

  inPanTolerance(deltaX) {
    const { currentState } = this.state;
    const { panTolerance } = this.props;

    return Math.abs(deltaX) <= panTolerance && currentState === "OPEN";
  }

  onPressUp = e => {
    if (this.props.noTouchClose) {
      return;
    }
    // e.preventDefault();
    this.close();
  };

  onPan = e => {
    if (this.isClosed()) {
      return;
    }
    e.preventDefault();

    const { isFinal, pointers, direction, deltaX } = e;
    if (this.inPanTolerance(deltaX)) {
      return;
    }

    if (isFinal) {
      if (this.isOpening()) {
        return this.open();
      }

      if (this.isClosing()) {
        return this.close();
      }

      return;
    }

    const { currentState } = this.state;
    const { clientX } = pointers[0];

    const closingOrOpening = this.closingOrOpening(direction);
    const nextState = {
      PEAK: closingOrOpening,
      IS_OPENING: closingOrOpening,
      IS_CLOSING: closingOrOpening,
      OPEN: "IS_CLOSING",
      CLOSED: "PEAK"
    };

    this.setState({
      currentState: nextState[currentState],
      x: clientX,
    });
  };

  onOverlayTap = e => {
    e.preventDefault();
    if (this.isOpen()) {
      this.close();
    }
  };

  calculateWidth = () => {
    const width = this.props.width;
    return /%/.test(width)
      ? document.body.clientWidth * (width.match(/\d*/) / 100)
      : width;
  };

  getClientWidth () {
    return document.body.clientWidth;
  }

  renderOverlay (stepX) {
    if (this.isClosed()) {
      return;
    }

    return (
      <Overlay
        clientwidth={ this.getClientWidth() }
        overlaycolor={ this.props.overlayColor }
        x={ stepX }
        drawerwidth={ this.calculateWidth() }
        onTap={this.onOverlayTap}
      >
        <span />
      </Overlay>
    );
  }

  render() {
    const toasterOffset = this.state.toasterNotif ? this.state.toasterNotif.offsetHeight : 0;

    return (
      <Motion style={{ myProp: spring(Math.min(this.state.x || 0, this.calculateWidth()), this.props.config) }}>
        {interpolated => {
          const { transform } = styles(interpolated.myProp, this.props);

          return (
            <Hammer
              onPressUp={this.onPressUp}
              onPan={this.onPan}
              direction={Hammer.DIRECTION_HORIZONTAL}
            >
              <div style={transform}>
                <Drawer width={ this.calculateWidth() }>
                  {/* Insert Drawer items here */}
                  <DrawerNavBar toasterOffset={toasterOffset}>{/* Spacer */}</DrawerNavBar>
                  <StyledNavLink to="/" exact onClick={ this.onPressUp }>
                    <span>HOME</span>
                  </StyledNavLink>
                  <StyledNavLink
                    to={{ pathname: '/faq', state: { resetPage: true, fromHealthFooter: false, stamp: Date.now() } }}
                    onClick={ this.onPressUp }
                  >
                    <span>FAQs</span>
                  </StyledNavLink>
                  <StyledExternalLink href="https://dreamscapegeneva.com/" onClick={ this.onPressUp }>
                    <span>GENEVA</span>
                  </StyledExternalLink>
                  <StyledExternalLink href="https://www.dreamscapemena.com/en-sa/" onClick={ this.onPressUp }>
                    <span>RIYADH</span>
                  </StyledExternalLink>
                  <DrawerNavBar>{/* Spacer */}</DrawerNavBar>

                  { this.renderOverlay(interpolated.myProp) }
                </Drawer>
              </div>
            </Hammer>
          );
        }}
      </Motion>
    );
  }
}

const LeftNavDrawer = props => (
  <AuthContext.Consumer>
    { parentState => <LeftNavDrawerWithContext {...props} parentState={parentState}/> }
  </AuthContext.Consumer>
);

export default LeftNavDrawer;
