import React, { Component } from 'react';
import styled from 'styled-components';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Desktop, Mobile } from '../responsive/Breakpoints';
import { WhiteButton } from '../buttons'
import Checkbox from '../forms/Checkbox';
import LocationIcon from '../svgs/LocationIcon';
import CloseIcon from '../svgs/CloseIcon';
import theme from '../../util/theme';
import { GENEVA_LOCATION, RIYADH_LOCATION } from '../../util/constants';

const staticLocationData = [
  RIYADH_LOCATION.site_id,
  GENEVA_LOCATION.site_id,
];

const OverlayBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500000000;
  background-color: ${props => props.theme.white_25};
  backdrop-filter: blur(10px);
`;

const DialogContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LocationDialog = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 594px;
  max-height: 526px;
  padding: 22px;
  border-radius: 6px;
  background-color: ${props => props.theme.charcoal};
`;

const DialogTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0 34px 0;
  letter-spacing: 2px;
`;

const DialogTitle = styled.div`
  flex: 1;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
`;

const DialogCancel = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.white_80};
  font-weight: bold;
  cursor: pointer;
  
  &:hover {
    color: white;
  }
`;

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  border-top: 1px solid ${props => props.theme.white_70};
`;

const DialogFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 41px;
`;

const StyledWhiteButton = styled(WhiteButton)`
  height: 47px;
  
  &:disabled {
    background-color: ${props => props.theme.white_25};
    color: ${props => props.theme.white_25};
    cursor: initial;
    box-shadow: none;
    border: 0;
  }
`;

const SiteItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.white_70};
`;

const SiteItemCol = styled.div`
  display: flex;
  padding: 18px 0;
  margin-right: 31px;
  flex-direction: column;
  flex: 1;
  border-bottom: 1px solid ${props => props.theme.white_70};
`;

const SiteItemName = styled.div`
  letter-spacing: 2px;
  color: ${props => props.theme.white_80};
  font-weight: bold;
  font-size: 0.75rem;
`;

const SiteItemAddress = styled.div`
  font-size: 0.875rem;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  color: ${props => props.theme.white_70};
  z-index: 500000000000;
  transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  top: 150%;
  
  &.open {
    top: 0;
  }
`;

const ModalNavRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 48px;
  padding: 0 22px;
  width: 100%;
  background-color: ${props => props.theme.charcoal};
  align-items: center;
`;

const ModalTitle = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 2px;
  text-align: center;
`;

const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 22px;
`;

const ModalFooter = styled.div`
  display: flex;
  padding: 22px;
  border-top: 1px solid ${props => props.theme.white_80};
  background-color: ${props => props.theme.charcoal};
`;

export default class LocationSelectionDialog extends Component {
  state = {
    tempSelectedSite: {},
    disableSaveBtn: true,
  };

  targetRef = React.createRef();
  targetElement = null;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.show) {
      clearAllBodyScrollLocks();
      const saveState = {};

      if (prevState.tempSelectedSite.site_id !== this.props.selectedSite.site_id) {
        saveState.tempSelectedSite = this.props.selectedSite;
      }

      if (!prevState.disableSaveBtn) {
        saveState.disableSaveBtn = true;
      }

      if (Object.keys(saveState).length) {
        this.setState(saveState);
      }
    } else {
      disableBodyScroll(this.targetElement);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  handleCheckClicked = (selectedSite) => {
    this.setState({
      tempSelectedSite: selectedSite,
      disableSaveBtn: selectedSite.site_id === this.props.selectedSite.site_id,
    });
  };

  renderSites(isMobile) {
    const { sites } = this.props;

    const siteItems = sites.map(site => {
      let siteAddress = `${site.city}, ${site.state}`;
      return (
        <div key={site.site_id}>
          <SiteItemRow>
            <SiteItemCol>
              <SiteItemName>{site.site_legalname.toUpperCase()}</SiteItemName>
              <SiteItemAddress>{siteAddress}</SiteItemAddress>
            </SiteItemCol>

            <Checkbox
              margin={isMobile ? '0' : undefined}
              checked={site.site_id === this.state.tempSelectedSite.site_id}
              onClick={() => this.handleCheckClicked(site)} />
          </SiteItemRow>
        </div>
      );
    });

    return siteItems;
  }

  renderDesktopDialog() {
    if (!this.props.show) {
      return null;
    }

    return (
      <OverlayBackground>
        <DialogContainer>
          <LocationDialog>
            <DialogTitleRow>
              <DialogTitle>SET LOCATION</DialogTitle>
              <DialogCancel onClick={this.props.onDismiss}>CANCEL</DialogCancel>
            </DialogTitleRow>

            <DialogBody ref={this.targetRef}>
              {this.renderSites()}
            </DialogBody>

            <DialogFooter>
              {this.renderDialogFooterBtn()}
            </DialogFooter>
          </LocationDialog>
        </DialogContainer>
      </OverlayBackground>
    );
  }

  renderDialogFooterBtn() {
    if (staticLocationData.includes(this.state.tempSelectedSite.site_id)) {
      return (
        <a href={this.state.tempSelectedSite.site_url_path}>
          <StyledWhiteButton
            disabled={this.state.disableSaveBtn}
          >
            GO
          </StyledWhiteButton>
        </a>
      );
    }

    return (
      <StyledWhiteButton
        disabled={this.state.disableSaveBtn}
        onClick={() => {
          this.props.onDismiss();
          this.props.onSave(this.state.tempSelectedSite);
        }}
      >
        SAVE
      </StyledWhiteButton>
    );
  }

  renderMobileDialogFooterBtn() {
    if (staticLocationData.includes(this.state.tempSelectedSite.site_id)) {
      return (
        <a href={this.state.tempSelectedSite.site_url_path} style={{width: '100%'}}>
          <StyledWhiteButton
            width={'100%'}
            disabled={this.state.disableSaveBtn}
          >
            GO
          </StyledWhiteButton>
        </a>
      );
    }

    return (
      <StyledWhiteButton
        width={'100%'}
        disabled={this.state.disableSaveBtn}
        onClick={() => {
          this.props.onDismiss();
          this.props.onSave(this.state.tempSelectedSite);
        }}
      >
        SAVE
      </StyledWhiteButton>
    );
  }

  renderMobileModal() {
    const className = this.props.show ? 'open' : '';
    return (
      <ModalContainer id="modal-container" className={className}>
        <ModalNavRow>
          <LocationIcon />
          <ModalTitle>SET LOCATION</ModalTitle>
          <CloseIconContainer onClick={this.props.onDismiss}>
            <CloseIcon height={24} color={theme.white_70} />
          </CloseIconContainer>
        </ModalNavRow>

        <ModalBody ref={this.targetRef}>
          {this.renderSites(true)}
        </ModalBody>

        <ModalFooter>
          {this.renderMobileDialogFooterBtn()}
        </ModalFooter>
      </ModalContainer>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Desktop>
          {this.renderDesktopDialog()}
        </Desktop>

        <Mobile>
          {this.renderMobileModal()}
        </Mobile>
      </React.Fragment>
    );
  }
}
