import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const StyledHubspotForm = styled.div`
  padding-top: ${ props => props.mobile ? '0px' : '98px' };
  width: 100%;
  max-width: ${ props => props.mobile ? '538px': undefined };

  & label {
    color: ${ props => props.theme.charcoal_90 };
  }
  
  & .input {
    width: 100%;
    ${ props => props.mobile ? 'display: flex;' : '' }
  }
  
  & .hs-error-msgs {
    margin: 6px 0 0 0;
  }
  
  & .hs_submit {
    ${ props => props.mobile ? 'max-width: 630px; margin: 0 auto;' : '' }
  }
  
  & .hs-form-field label {
    font-size: 0.625rem;
    ${ props => props.dark ? 'color: ' + props.theme.white_80 + ';' : '' }
  }
  
  & .actions {
    ${ props => props.mobile ? 'text-align: center' : '' }
  }
  
  & .hs-form-field input, .hs-form-field select, .hs-form-field textarea {
    appearance: unset;
    box-sizing: border-box;
    width: 100%;
    ${ props => props.mobile ? 'flex-grow: 1;' : '' }
    // ${ props => props.mobile ? '' : 'width: ' + props.widthPercent + 'vw; max-width: ' + props.maxWidth + 'px;' }
    height: 47px;
    border: solid 1px ${ props => props.dark ? props.theme.ds_bright_blue_50 : props.theme.ds_bright_blue };
    padding: 14px;
    font-size: 0.8125rem;
    border-radius: 3px !important;
    transition: all 0.25s;
    background-color: transparent;
    ${ props => props.dark ? 'color: ' + props.theme.white_90 + ';' : '' }
    
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    
    &:hover {
      border: 3px solid ${ props => props.dark ? props.theme.ds_bright_blue_50 : props.theme.ds_bright_blue };
      padding: 12px;
    }
  }
  
  & .hs-form-field textarea {
    height: 110px;
    max-width: 568px;
  }
  
  & .hs-form-field {
    margin-bottom: 6px;
  }
  
  & .hs_submit input {
    appearance: button;
    margin-top: ${ props => props.mobile ? '30' : '15' }px;
    width: ${ props => props.mobile ? '100%' : '243px' };
    padding: 13px 0;
    border: none;
    border-radius: 3px;
    font-size: 0.8125rem;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: ${ props => props.theme.sans_serif };
    background-color: ${ props => props.theme.ds_dusk_blue };
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.25s;
    box-shadow: 0 10px 15px 0 ${ props => props.theme.black_30 };
    color: #FFF;
    
    &:focus {
      border: 1px solid  ${ props => props.theme.ds_bright_blue };
      box-shadow: 0 5px 5px 0 ${ props => props.theme.black_30 };
      color: white;
    }
    
    &:hover {
      box-shadow: 0 5px 5px 0 ${ props => props.theme.black_30 };
      color: ${ props => props.theme.ds_bright_blue };
      background-color: #1e205e
    }
    
    &:active {
      color: ${ props => props.theme.ds_dusk_blue };
      border: none;
      background-color: white;
    }
  }
  
  & .inputs-list {
    list-style: none;
    padding: 0;
  }
  
  & .inputs-list li {
    height: 20px;
    display: flex;
    align-items: center;
  }
  
  & .inputs-list li label {
    display: flex;
    align-items: center;
  }
  
  & .inputs-list li label input {
    margin-right: 10px;
  }
`;

class HubspotForm extends Component {

  componentDidMount () {
    this.setEmailForm();
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.onMessage);
  }

  onMessage = (event) => {
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
      console.log("FORM SUBMITTED");
    }
  };

  setEmailForm = () => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: this.props.portalId,
          formId: this.props.formId,
          target: `#${this.props.id || 'hubspotForm'}`,
        })
      }
    });
    window.addEventListener('message', this.onMessage);
  };

  render () {
    return (
      <StyledHubspotForm maxWidth={this.props.maxWidth}
                         widthPercent={this.props.widthPercent}
                         mobile={this.props.mobile}
                         dark={this.props.dark}
                         id={this.props.id || 'hubspotForm'}/>
    )
  }
}

export default withRouter(HubspotForm);
