import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton, WhiteButton } from '../components/buttons';
import { Large, Phone } from '../components/responsive/Breakpoints';
import AdventureThumbnail from '../components/AdventureThumbnail';
import CheckoutOrderDetails from '../components/CheckoutOrderDetails';

import { calculatePriceAndFees } from '../util/common';
import { CURRENCY_SYMBOL } from '../util/constants';
import {DIApi} from "../util/api";
import debounce from "lodash/debounce";

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  line-height: 1.09;
  align-self: center;
  color: ${ props => props.theme.charcoal_90 };
  padding: ${ props => props.mobile ? '40px 22px 0 22px' : '80px 22px 0 22px' };
  text-align: center;
  letter-spacing: -1px;
`;

const SectionSubTitle = styled.div`
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  align-self: center;
  max-width: ${ props => props.mobile ? '360px' : '695px' };
  padding: 0 22px;
  text-align: center;
  color: ${ props => props.theme.charcoal_60 };
  padding-top: 12px;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${ props => props.mobile ? 'center' : undefined };
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EmailText = styled.span`
  margin: ${ props => props.mobile ? '26px 0 36px 0' : '32px 0' };
  font-family: ${ props => props.theme.sans_serif };
  font-size: 13px;
  color: ${ props => props.theme.charcoal };
  text-align: center;
`;

const MobileButtonFooterSpacer = styled.div`
   height: 91px;
`;

const MobileButtonFooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
`;

const MobileSubmitBtnContainer = styled.div`
  padding: 22px;
  background-color: ${ props => props.theme.white_80 };
  border-top: 1px solid ${ props => props.theme.ds_dusk_blue }
`;

const ReceiptContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin : ${ props => props.isMobile ? '38px 0' : '36px 0 42px 0' };
  max-width: 800px;
  padding: ${ props => props.isMobile ? undefined : '0 22px' };
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const ReceiptDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 800px;
  width: 100%;
  padding: 16px 0 8px 0;
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
  align-items: center;
`;

const ReceiptDetailsRow = styled.div`
  display: flex;
  margin-top: 2px;
`;

const ReceiptDetailsCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ReceiptDetailsLabel = styled.div`
  display: flex;
  width: 147px;
  font-size: 1rem;
  letter-spacing: -0.5px;
  color: ${ props => props.theme.charcoal_60 };
`;

const ReceiptDetailsValue = styled.div`
  display: flex;
  flex: 1;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  margin-left: 12px;
  color: ${ props => props.theme.charcoal_90 };
`;

const OrderCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: ${ props => props.isMobile ? '360px': undefined };
  padding: ${ props => props.isMobile ? '0 22px' : undefined };
  box-sizing: border-box;
`;

const OrderCodeDivider = styled.div`
  display: flex;
  flex: 1;
  height: 1px;
  background-color: ${ props => props.theme.charcoal_25 };
`;

const OrderCodeText = styled.div`
  display: flex;
  font-size: 1.625rem;
  font-weight: bold;
  color: ${ props => props.theme.ds_dusk_blue };
  padding: ${ props => props.isMobile ? '0 6px' : '0 12px' };
`;

const LinkText = styled.span`
  color: ${ props => props.theme.ds_dusk_blue };
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: ${ props => props.theme.ds_dusk_blue };
  cursor: pointer;
  text-decoration: none;
`;

const SpecialInstructionsContainer = styled.div`
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  align-self: center;
  max-width: ${ props => props.mobile ? '360px' : '800px' };
  padding: ${ props => props.mobile ? '22px 22px 0 22px' : '0 0 24px 0' };
  box-sizing: border-box;
`;

const FifteenMinutesText = styled.div`
  padding-bottom: ${ props => props.mobile ? '29' : '52' }px;
  padding-top: ${ props => props.mobile ? '39' : '0' }px;
  font-size: 0.8125rem;
  color: ${ props => props.theme.ds_dusk_blue };
  font-family: ${ props => props.theme.sans_serif };
  font-weight: bold;
  text-align: center;
`;

class CheckoutReceipt extends Component {
  state = {
    orderId: '',
    cardType: '',
    cardLastFour: '',
    total: '',
    shortOrderCode: '',
    customerEmail: '',
    ticketTypes: {},
  };

  async componentDidMount () {
    this.props.clearTimer();
    document.body.style.background = 'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';

    await this.compileInformation();
    this.props.updateHeaderText({
      title: "PURCHASE COMPLETE",
      hideButtons: true,
    });
  }

  componentWillUnmount () {
    document.body.style.background = 'black';
  }

  compileInformation () {
    const {
      purchaseData,
    } = this.props.location.state;

    const {
      partner_short_code :shortOrderCode,
      partner_order_id :orderId,
      transactions,
      email,
    } = purchaseData;

    const { totalPaid } = calculatePriceAndFees(purchaseData);

    const curSymbol = purchaseData.currency ? CURRENCY_SYMBOL[purchaseData.currency] || '$' : '$';
    const total = `${curSymbol}${totalPaid.toFixed(2)}`;

    // TODO: Right now we are only concerned with Credit Card types but will need to expand to show any kind of payment type
    const cc = transactions
      .filter( transaction => transaction.method === 'cc' )
      .reduce( (acc, transaction) => {
        return {
          type: transaction.card_type,
          lastFour: transaction.last_4,
        };
      }, {});

    this.setState({
      orderId,
      siteId: purchaseData.partner_site_id,
      cardType: cc.type?cc.type.toUpperCase():'',
      cardLastFour: cc.lastFour?cc.lastFour: '',
      total,
      shortOrderCode: shortOrderCode,
      customerEmail: email,
    });
  }

  handleReturnBackHome = () => {
    this.props.unblock();
    this.props.history.replace("/");
  };

  handleResendReceiptUnthrottled = () => {
    DIApi.resendReceipt(this.props.location.state.showtime.partner_site_id, this.state.orderId);
  };

  handleResendReceipt = debounce(this.handleResendReceiptUnthrottled, 5000, {
    'leading': true,
    'trailing': false
  });

  renderADARow = () => {
    const { orderDetails } = this.props.location.state;

    if (!orderDetails.ada) {
      return null;
    }

    return (
      <ReceiptDetailsRow>
        <ReceiptDetailsLabel>ACCESSIBLE</ReceiptDetailsLabel>
        <ReceiptDetailsValue>YES</ReceiptDetailsValue>
      </ReceiptDetailsRow>
    );
  };

  handleNavPrintReceipt = () => {
    return window.open(`${window.location.origin}/purchase/${this.state.siteId}/${this.state.orderId}/print-receipt`);
  };

  renderTicketFees = (ticketFees) => {
    if (!ticketFees) {
      return null;
    }

    return ticketFees.map((fee, index) => {
      return (
        <ReceiptDetailsRow key={`fee-type-${index}`}>
          <ReceiptDetailsLabel>{fee.name}</ReceiptDetailsLabel>
          <ReceiptDetailsValue>{fee.amountLabel}</ReceiptDetailsValue>
        </ReceiptDetailsRow>
      );
    });
  };

  render () {
    const pageDetails = {
      title: "YOU'RE READY FOR ADVENTURE",
      subTitle: 'We emailed your ticket details. Please print out your tickets or have your emailed tickets ready on your smartphone when checking in at Dreamscape. Safe travels!',
    };
    const { orderDetails, adventure, locationAssets } = this.props.location.state;

    let specialInstructions = null;
    if (locationAssets && locationAssets.copy && locationAssets.copy.purchaseCompleteSpecialInstructions) {
      specialInstructions = locationAssets.copy.purchaseCompleteSpecialInstructions.map((part, index) => {
        if (typeof part === 'object' && part instanceof Object) {
          const { type, value, display } = part;
          return <StyledLink to={value}>{display}</StyledLink>
        }
        return <span>{part} </span>;
      });
    }

    return (
      <Page>
        <Large>
          <SectionTitle>{pageDetails.title}</SectionTitle>
          <SectionSubTitle>{pageDetails.subTitle}</SectionSubTitle>
        </Large>
        <Phone>
          <SectionTitle mobile>{pageDetails.title}</SectionTitle>
          <SectionSubTitle mobile>{pageDetails.subTitle}</SectionSubTitle>
        </Phone>

        <Large>
          <Container>
            <ReceiptContainer>
              <SpecialInstructionsContainer>{specialInstructions}</SpecialInstructionsContainer>
              <ReceiptDetailsContainer>
                <ReceiptDetailsCol>
                  <ReceiptDetailsRow>
                    <ReceiptDetailsLabel>BOOKING NUMBER</ReceiptDetailsLabel>
                    <ReceiptDetailsValue>{ this.state.shortOrderCode }</ReceiptDetailsValue>
                  </ReceiptDetailsRow>
                  <ReceiptDetailsRow>
                    <ReceiptDetailsLabel>LOCATION</ReceiptDetailsLabel>
                    <ReceiptDetailsValue>{ orderDetails.location }</ReceiptDetailsValue>
                  </ReceiptDetailsRow>
                  <ReceiptDetailsRow>
                    <ReceiptDetailsLabel>TIME</ReceiptDetailsLabel>
                    <ReceiptDetailsValue>{ orderDetails.time }</ReceiptDetailsValue>
                  </ReceiptDetailsRow>
                  <ReceiptDetailsRow>
                    <ReceiptDetailsLabel>TICKETS</ReceiptDetailsLabel>
                    <ReceiptDetailsValue>{ orderDetails.ticketCostBreakdown }</ReceiptDetailsValue>
                  </ReceiptDetailsRow>
                  { this.renderADARow() }
                  { this.renderTicketFees(orderDetails.ticketFees) }
                  {
                    orderDetails.giftDeduction
                      ? <ReceiptDetailsRow>
                          <ReceiptDetailsLabel>GIFT CARD</ReceiptDetailsLabel>
                          <ReceiptDetailsValue>{ orderDetails.giftDeduction }</ReceiptDetailsValue>
                        </ReceiptDetailsRow>
                      : null
                  }

                  {
                    orderDetails.promoDeduction
                      ? <ReceiptDetailsRow>
                          <ReceiptDetailsLabel>PROMO CODE</ReceiptDetailsLabel>
                          <ReceiptDetailsValue>{ orderDetails.promoDeduction }</ReceiptDetailsValue>
                        </ReceiptDetailsRow>
                      : null
                  }
                  <ReceiptDetailsRow>
                    <ReceiptDetailsLabel>TOTAL</ReceiptDetailsLabel>
                    <ReceiptDetailsValue>{ this.state.total }</ReceiptDetailsValue>
                  </ReceiptDetailsRow>
                </ReceiptDetailsCol>

                <AdventureThumbnail ada={orderDetails.ada} titleId={adventure.title_id}/>
              </ReceiptDetailsContainer>
              <OrderCodeContainer>
                <OrderCodeDivider/>
                <OrderCodeText>BOOKING # { this.state.shortOrderCode }</OrderCodeText>
                <OrderCodeDivider/>
              </OrderCodeContainer>
            </ReceiptContainer>

            <FifteenMinutesText>PLEASE ARRIVE 15 MINUTES BEFORE YOUR SCHEDULED<br/>DEPARTURE TIME TO CHECK IN.<br/>LATECOMERS CANNOT BE ACCOMMODATED AFTER SHOWTIME BEGINS.</FifteenMinutesText>

            <ButtonsContainer>
              <WhiteButton width={300} style={{marginRight: '20px'}} onClick={ this.handleNavPrintReceipt }>PRINT MY TICKET</WhiteButton>
              <BlueButton width={300} onClick={ this.handleReturnBackHome }>RETURN TO DREAMSCAPE</BlueButton>
            </ButtonsContainer>
            <EmailText>Need a new confirmation receipt? <LinkText onClick={ this.handleResendReceipt }>Re-send to {this.state.customerEmail}</LinkText></EmailText>

          </Container>
        </Large>

        <Phone>
          <Container mobile>
            <SpecialInstructionsContainer mobile>{specialInstructions}</SpecialInstructionsContainer>
            <ReceiptContainer isMobile>
              <CheckoutOrderDetails isMobile orderDetails={ orderDetails } titleId={adventure.title_id} shortCode={ this.state.shortOrderCode }/>
            </ReceiptContainer>

            <OrderCodeContainer isMobile>
              <OrderCodeDivider/>
              <OrderCodeText isMobile>BOOKING # { this.state.shortOrderCode }</OrderCodeText>
              <OrderCodeDivider/>
            </OrderCodeContainer>

            <FifteenMinutesText mobile>PLEASE ARRIVE 15 MINUTES<br/>BEFORE YOUR SCHEDULED<br/>DEPARTURE TIME TO CHECK IN.<br/>LATECOMERS CANNOT BE<br/>ACCOMMODATED AFTER SHOWTIME BEGINS.</FifteenMinutesText>

            <EmailText mobile>
              Need a new confirmation receipt?
              <br/>
              <LinkText onClick={ this.handleResendReceipt }>Re-send to {this.state.customerEmail}</LinkText>
            </EmailText>
          </Container>
          <MobileButtonFooterSpacer/>
          <MobileButtonFooter>
            <MobileSubmitBtnContainer name="mobile-proceed-btn">
              <BlueButton style={{width: '100%'}} onClick={ this.handleReturnBackHome }>RETURN TO DREAMSCAPE</BlueButton>
            </MobileSubmitBtnContainer>
          </MobileButtonFooter>
        </Phone>
      </Page>
    );
  }
}

export default withRouter(CheckoutReceipt);
