import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {Desktop, Mobile, Large, Phone} from '../../../components/responsive/Breakpoints';
import { BlueButton, BlueOutlineButton } from '../../../components/buttons/index'
import { DIApi } from '../../../util/api';
import AdventureThumbnail from '../../../components/AdventureThumbnail';
import LoadingSpinner from '../../../components/LoadingSpinner';

import checkWhite from '../../../assets/images/check_white_192x192.svg';
import LocationIcon from "../../../components/svgs/LocationIcon";
import { sendAddToCartEvent, sendInitiatePurchaseEvent } from "../../../util/analytics";

const Container = styled.div`
  height: 100%;
  margin: 0 ${ props => props.mobile ? '20px' : ''} auto;
  text-align: center;
`;

const AdventureThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${ props => props.mobile ? '24px 0 18px 0' : '52px 0' };
`;

const SelectNumberHeader = styled.div`
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  line-height: ${ props => props.mobile ? '1.33' : '1.09' };
  font-weight: bold;
  color: ${ props => props.theme.charcoal };
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  color: ${ props => props.theme.charcoal_60 };
`;

const TicketNumberHolder = styled.div`
  vertical-align: middle;
  display: inline-block;
  width: 115px;
  font-family: ${ props => props.theme.serif };
  font-size: 2.25rem;
  line-height: 1.17;
  color: ${ props => props.theme.charcoal_90 };
`;

const SelectionArea = styled.div`
  display: flex;
  ${ props => props.mobile ? '' : 'height: 80px' };
  margin: ${ props => props.mobile ? '44px auto 0' : '36px auto 0 auto' };
  align-items: center;
  justify-content: ${ props => props.mobile ? 'space-between' : 'center' };
  ${ props => props.mobile ? 'max-width: '+props.maxWidth+'px;' : '' }
`;

const SeatContainer = styled.div`
  display: inline-block;
  margin: ${ props => props.mobile ? '0' : '13px' };
  width: 28px;
  height: 28px;
  border: solid 1px ${ props => props.theme.ds_bright_blue };
  cursor: pointer;
  border-radius: 3px;
`;

const Seat = styled.div`
  width: 24px;
  height: 24px;
  margin: 2px;
  background-color: ${ props => props.checked ? props.theme.ds_dusk_blue : 'transparent' };
  border-radius: 3px;
`;

const TakenSeat = styled.div`
  display: inline-block;
  margin: ${ props => props.mobile ? '0' : '13px' };
  width: 28px;
  height: 28px;
  background-color: ${ props => props.theme.charcoal_60 };
  border-radius: 3px;
`;

const ContinueButton = styled(BlueButton)`
  margin-top: ${ props => props.mobile ? '0' : '60px' };
  font-family: ${ props => props.theme.sans_serif };
  width: ${ props => props.mobile ? '100%' : '300px' };
  font-size: 0.8rem;
  letter-spacing: 1.5px;
  
  &:disabled {
    background-color: ${ props => props.theme.charcoal_25 };
    color: ${ props => props.theme.white_25 };
    cursor: initial;
    box-shadow: none;
    border: 0;
  }
`;

const NumberContainer = styled.div`
  margin-top: ${ props => props.mobile ? '18px' : '25px' };
  line-height: 1.17;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlusMinusContainer = styled(BlueOutlineButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
`;

const HorizontalBar = styled.div`
  width: 14px;
  height: 2px;
  background-color: ${ props => props.theme.charcoal_90 };
`;

const VerticalBar = styled.div`
  width: 2px;
  height: 14px;
  background-color: ${ props => props.theme.charcoal_90 };
  position: absolute;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  display: block;
  padding: 2px;
`;

const MobileButtonFooterSpacer = styled.div`
  height: 150px;
`;

const MobileButtonFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
`;

const MobileSubmitBtnContainer = styled.div`
  padding: 18px 22px 12px 22px;
  background-color: ${ props => props.theme.white_80 };
  border-top: 1px solid ${ props => props.theme.ds_dusk_blue }
`;

const LocationSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: ${ props => props.mobile ? '9px' : '22px' };
  font-size: 0.8125rem;
  font-weight: bold;
`;

const ButtonSubText = styled.div`
  text-align: center;
  font-size: 0.8125rem;
  color: ${ props => props.theme.charcoal_60 };
  margin: ${ props => props.mobile ? '11px 0 0 0' : '14px 0' };
`

class CheckoutTicketSelector extends Component {
  state = {
    numTicketsSelected: 1,
    continueLoading: false,
    addedFees: 0,
    feePercent: 0,
    ticketTypes: {},
    estimatedPrice: 0,
    dataLoading: true,
    numOpenSeats: 0,
    purchaseLink: '',
  };

  componentWillUnmount () {
    document.body.style.background = 'black';
  }

  componentDidMount () {
    document.body.style.background = 'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';
    const { shortname, tlc } = this.props.location.state.adventure;
    this.props.updateHeaderText({
      title: `${shortname.toUpperCase()} (${tlc.toUpperCase()})`,
    });
    this.getEstimatedPriceForTicket();
  }

  getEstimatedPriceForTicket = async () => {
    //get estimated price
    const { showtime } = this.props.location.state;

    const event = await DIApi.getEstimatedPriceForAMCExperience(showtime.partner_site_id, showtime.partner_experience_id, showtime.datetime);
    if (event) {
      const pricing = event.pricing[0];
      this.setState({
        estimatedPrice: pricing.price,
        purchaseLink: event.links.deep_link_purchase_url,
        numOpenSeats: event.availability.quantity,
        dataLoading: false,
      });
    }
  };

  decrementTickets = () => {
    let numTickets = 1;
    if (this.state.numTicketsSelected > 1) {
      numTickets = this.state.numTicketsSelected - 1;
    }
    this.setState({numTicketsSelected: numTickets});
  };

  incrementTickets = () => {
    let openSeats = this.state.numOpenSeats;
    let numTickets = openSeats;
    if (this.state.numTicketsSelected < openSeats) {
      numTickets = this.state.numTicketsSelected + 1;
    }

    const numTicketsSelected = numTickets || 1;
    this.setState({ numTicketsSelected });
  };

  selectTickets = (index) => {
    let openSeats = this.state.numOpenSeats;
    if (index >= 1 && index <= openSeats) {
      this.setState({numTicketsSelected: index})
    }
  };

  async handleContinue () {
    this.setState({continueLoading: true});
    const { numTicketsSelected, purchaseLink, estimatedPrice } = this.state;
    const { adventure } = this.props.location.state;
    const link = `${purchaseLink}&qty=${numTicketsSelected}`;

    // Send "Initiate Checkout" analytic event
    // google tag manager
    const productInfo = {
      'id': adventure.title_id,
      'name': adventure.shortname,
      'price': estimatedPrice,
      'category': 'Ticket',
      'quantity': numTicketsSelected,
    };

    const cartEvent = {
      productInfo,
      titleId: adventure.title_id,
      totalPrice: estimatedPrice * numTicketsSelected,
      currency: adventure.licensed_site.currency,
    };

    // First, send add to cart event
    sendAddToCartEvent(cartEvent);
    // Then hit the Initiate Checkout event
    sendInitiatePurchaseEvent(cartEvent);

    return window.location.href = link;
  };

  generateSeats = (mobile) => {
    let maxSeats = this.props.location.state.maxSeats;
    let takenSeats = maxSeats - this.state.numOpenSeats;
    let selectedSeats = takenSeats + this.state.numTicketsSelected;
    const Seats = [];
    for (let i=0; i<maxSeats; i++) {
      if (i < takenSeats) {
        Seats.push(<TakenSeat mobile={mobile} key={i}/>);
      } else if (i < selectedSeats) {
        let img = <Image style={{width: '16px', margin: '0 auto'}} src={checkWhite} alt="Check"/>;
        Seats.push(
          <SeatContainer mobile={mobile} key={i} onClick={this.selectTickets.bind(this, i - takenSeats + 1)}>
            <Seat checked>{img}</Seat>
          </SeatContainer>
        );
      } else {
        Seats.push(
          <SeatContainer mobile={mobile} key={i} onClick={this.selectTickets.bind(this, this.state.numTicketsSelected + i - selectedSeats + 1)}>
            <Seat />
          </SeatContainer>
        );
      }
    }
    const numSeats = Seats.length;
    const numMargins = numSeats-1;
    const selectionMaxWidth = 32*numSeats + 26*numMargins;
    const seatWidth = 32;
    const minMarginBetween = 3;
    const outerMargins = 20;
    const minimumWidthNeeded = seatWidth*numSeats + minMarginBetween*numMargins + outerMargins*2;
    if (window.innerWidth < minimumWidthNeeded) {
      const splitNumber = Math.ceil(Seats.length/2);
      const ratio = (Seats.length - splitNumber)/splitNumber;
      const overflowMaxWidth = (window.innerWidth - outerMargins*2)*ratio;
      return (
        <div>
          <SelectionArea mobile maxWidth={selectionMaxWidth}>{Seats.slice(0, splitNumber)}</SelectionArea>
          <SelectionArea mobile style={{marginTop: '10px'}} maxWidth={overflowMaxWidth}>{Seats.slice(splitNumber)}</SelectionArea>
        </div>
      )
    }
    if (mobile) {
      return <SelectionArea mobile maxWidth={selectionMaxWidth}>{Seats}</SelectionArea>
    } else {
      return <SelectionArea>{Seats}</SelectionArea>
    }
  };

  continueButton = (mobile) => {
    const buttonContent = this.state.continueLoading ? <LoadingSpinner/> : 'NEXT';
    const Continue = (
      <ContinueButton mobile={ mobile }
                      disabled={ this.state.continueLoading || this.state.dataLoading || this.state.numOpenSeats < 1 }
                      onClick={ () => this.handleContinue() }
      >
        { buttonContent }
      </ContinueButton>
    );

    if (mobile) {
      return (
        <MobileButtonFooter>
          <MobileSubmitBtnContainer name="mobile-proceed-btn">
            { Continue }
            <ButtonSubText mobile>(Purchase will be completed on amctheatres.com)</ButtonSubText>
          </MobileSubmitBtnContainer>
        </MobileButtonFooter>
      );
    }

    return Continue;
  };

  renderSelectorContents (isMobile) {
    if (this.state.dataLoading) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}>
          <LoadingSpinner dark />
        </div>
        );
    }

    const SeatList = this.generateSeats(isMobile);
    const { adventure, site } = this.props.location.state;
    const basePrice = this.state.estimatedPrice > 0 ? this.state.estimatedPrice : '';
    const minAge = adventure.licensed_site.min_age;
    const ticketingInfo = isMobile ? <span>General Admission ${basePrice}<br/>(Travelers must be Age {minAge}+)</span> : `General Admission $${basePrice} (Travelers must be Age ${minAge}+)`;
    const selectNumTicketsHeader = isMobile ? <span>SELECT NUMBER<br/>OF TICKETS</span> : 'SELECT NUMBER OF TICKETS'
    const location = site.site_legalname ? `${site.site_legalname}, ${site.city} ${site.state}` : 'NO LOCATION';

    return (
      <React.Fragment>
        <AdventureThumbnailContainer mobile={isMobile}>
          <AdventureThumbnail
            titleId={adventure.title_id}
          />
        </AdventureThumbnailContainer>

        <SelectNumberHeader mobile={isMobile}>{selectNumTicketsHeader}</SelectNumberHeader>
        <SubHeader mobile={isMobile}>{ticketingInfo}</SubHeader>
        <LocationSelectionContainer mobile={isMobile}>
          <span>{location.toUpperCase()}</span>
          <LocationIcon color={'#000000'} />
        </LocationSelectionContainer>

        <NumberContainer mobile={isMobile}>
          <PlusMinusContainer onClick={this.decrementTickets}>
            <HorizontalBar/>
          </PlusMinusContainer>
          <TicketNumberHolder>
            {this.state.numTicketsSelected}
          </TicketNumberHolder>
          <PlusMinusContainer onClick={this.incrementTickets}>
            <HorizontalBar/>
            <VerticalBar/>
          </PlusMinusContainer>
        </NumberContainer>

        {SeatList}

        <Large>
          {this.continueButton(false)}
          <ButtonSubText>(Purchase will be completed on amctheatres.com)</ButtonSubText>
        </Large>
        <Phone>
          <MobileButtonFooterSpacer/>
          {this.continueButton(true)}
        </Phone>
      </React.Fragment>
    );
  }

  renderSelector (mobile) {
    return (
      <Container mobile={mobile}>
        { this.renderSelectorContents(mobile) }
      </Container>
    );
  }

  render () {
    return (
      <React.Fragment>
        <Desktop>
          {this.renderSelector(false)}
        </Desktop>
        <Mobile>
          {this.renderSelector(true)}
        </Mobile>
      </React.Fragment>
    );
  }
}

export default withRouter(CheckoutTicketSelector);

