import React, { Component } from 'react';
import qs from 'querystring';

class CyberSourceAuth extends Component {
  componentDidMount() {
    const query = window.location.search;
    if (!query) {
      return;
    }

    const cybsResponse = qs.parse(query.replace('?', ''));
    window.parent.handlePopupReturn(cybsResponse);
  }

  render() {
    return (
      <section style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
        <div>Cybersource test stuff</div>
        <div>{JSON.stringify(window.location)}</div>
      </section>
    );
  }
}

export default CyberSourceAuth;
