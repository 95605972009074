import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile, Large, Phone } from './responsive/Breakpoints';
import styled from 'styled-components';

import imgDuration from '../assets/images/img-duration.svg';
import imgGear from '../assets/images/img-gear.svg';
import imgHealthSafety from '../assets/images/img-health-safety.png';
import ImgAgeHeight from '../assets/images/img-age-height.svg';

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${ props => props.mobile ? '46px 22px 51px' : '60px 98px 50px' };
  max-width: 1170px;
  margin: 0 auto;
`;

const HeaderBlock = styled.div`
  padding-bottom: ${ props => props.mobile ? '27px' : '36px' };
  font-family: ${ props => props.theme.sans_serif };
  font-size: 1rem;
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' };
  color: ${ props => props.theme.charcoal };
`;

const SubHeaderBlock = styled.div`
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.mobile ? '1.25' : '2.25' }rem;
  line-height: 1.29;
  color: ${ props => props.theme.charcoal_90 };
  max-width: ${ props => props.mobile ? 'unset' : '748px' };
  margin: 0 auto;
`;

const ThingsToKnowTable = styled.table`
  width: 100%;
  text-align: left;
`;

const FlexTr = styled.tr`
  display: flex;
  margin-top: ${ props => props.mobile ? '24' : '16' }px;
`;

const SpacerTd = styled.td`
  width: 70px;
`;

const ThingToKnow = styled.td`
  flex: 1;
`;

const TTKHeader = styled.div`
  margin: 0 0 16px 0;
  padding-top: 18px;
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.mobile ? '1.375' : '2.25' }rem;
  line-height: ${ props => props.mobile ? '1.64' : '1.19' };
  color: ${ props => props.theme.charcoal_90 };
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
`;

const TTKBody = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  color: ${ props => props.theme.charcoal_60 };
  line-height: 1.33;
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  background: url(${props => props.background});
  background-repeat: no-repeat;
  background-position-y: 50%;
`;

class PlanAheadWelcomeBlock extends Component {
  thingToKnowBlock(mobile, header, body, image) {
    return (
      <ThingToKnow key={header}>
        <ImageContainer background={image}/>
        <TTKHeader mobile={mobile}>{header}</TTKHeader>
        <TTKBody mobile={mobile}>{body}</TTKBody>
      </ThingToKnow>
    )
  }

  allocateBlocks(blocks, blocksPerRow, mobile) {
    let blockGroups = blocks;

    if (!mobile) {
      blockGroups = [];
      let temp = [];
      for (let i=0; i<blocks.length; i++) {
        if (temp.length > 0) {
          temp.push(<SpacerTd key={i}/>);
        }
        temp.push(blocks[i]);
        if ((i+1) % blocksPerRow === 0) {
          blockGroups.push(temp);
          temp = [];
        }
        if (i === blocks.length-1 && temp.length > 0) {
          for (let j=temp.length; j<blocksPerRow; j++) {
            temp.push(<SpacerTd/>);
            temp.push(<div style={{flex: '1'}}/>);
          }
          blockGroups.push(temp);
        }
      }
    }

    return blockGroups;
  }

  tableContent (mobile) {
    const blocks = [
      this.thingToKnowBlock(mobile, 'Duration',
        <div>
          <span>Each experience lasts between 10-14 minutes depending on which adventure you choose. Your full journey including check-in, gear up and gear down should take approximately 35 minutes.</span>
          <br/><br/>
          <span><b>Please arrive 15 minutes prior to your departure time to check in. We are not able to bring you into a Dreamscape adventure once it has begun and cannot guarantee that we'll be able to accommodate late arrivals.</b></span>
        </div>, imgDuration),
      this.thingToKnowBlock(mobile, 'Health & Safety',
        <div>
          <span>We thoroughly clean and sanitize all gear and equipment, including headsets, foot and hand trackers, and backpacks before each use.</span>
          <br/><br/>
          <span>In line with government regulations, we continue to follow the various local protocols for mask mandates. Please check with your local Dreamscape for the latest mask policy.</span>
        </div>, imgHealthSafety),
      this.thingToKnowBlock(mobile,'Gear',
        <div>
          <span>A Dreamscape Guide will help you gear up before escorting you to your adventure pod. You'll put on a light backpack, hand trackers, foot trackers and a VR headset.* Our headsets can accommodate most glasses, but if you wear glasses with larger frames, your Dreamscape guide can assist you.</span>
          <br/><br/>
          <span>*To experience free-flying in Dragons Flight Academy, the only wearable gear needed is a VR headset and hand trackers.</span>
        </div>, imgGear),
      this.thingToKnowBlock(mobile,'Age & Height',
        <div>
          <span>For DreamWorks Dragons: Flight Academy, you must be at least 8 years old and at least 44 inches tall. For all other adventures, travelers must be at least 48 inches tall and at least 10 years old.</span>
          <br/><br/>
          <span>Travelers under 13 years old must be accompanied by an adult with a paid ticket and must have their parent or guardian sign their waiver at check-in.</span>
        </div>, ImgAgeHeight),      
    ];

    const blockGroups = this.allocateBlocks(blocks, 2, mobile);

    return (
      <React.Fragment>
        {blockGroups.map((blockGroup, i) => {
          return (
            <FlexTr key={i} mobile={mobile}>
              {blockGroup}
            </FlexTr>
          )
        })}
      </React.Fragment>
    )
  }

  renderWelcomeBlock (mobile) {
    return (
      <React.Fragment>
        <HeaderBlock mobile={mobile}>WHAT TO EXPECT</HeaderBlock>
        <SubHeaderBlock mobile={mobile}>Welcome to Dreamscape. If you've never adventured with us before, here are a few things you might want to know.</SubHeaderBlock>
        <ThingsToKnowTable>
          <tbody>
          {this.tableContent(mobile)}
          </tbody>
        </ThingsToKnowTable>
      </React.Fragment>
    )
  }

  renderContainer (phone) {
    return (
      <TextContainer mobile={phone}>
        <Desktop>
          {this.renderWelcomeBlock(false)}
        </Desktop>
        <Mobile>
          {this.renderWelcomeBlock(true)}
        </Mobile>
      </TextContainer>
    )
  }

  render () {
    return (
      <Container id={'welcome-to-dreamscape'}>
        <Large>
          {this.renderContainer(false)}
        </Large>
        <Phone>
          {this.renderContainer(true)}
        </Phone>
      </Container>
    );
  }
}

export default withRouter(PlanAheadWelcomeBlock);
