import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { scroller } from "react-scroll/modules";
import {Desktop, Mobile} from "./responsive/Breakpoints";
import PlanAheadBGVideo from "../assets/videos/Avatar-Transition.gif";
// import PlanAheadBGVideo from "../assets/videos/Transition_3.gif";
import DownArrowIcon from "./svgs/DownArrowIcon";
import theme from "../util/theme";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";

const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  text-align: center;
  padding: 21px;
`;

const Header = styled.div`
  color: #fff;
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '1.625' : '3.5' }rem;
  ${ props => props.mobile ? 'line-height: 1.31;' : '' }
  font-weight: bold;
  letter-spacing: 2px;
  ${ props => props.mobile ? 'margin-bottom: 15vh' : '' }
  z-index: 5;
`;

const Container = styled.div`
  height: calc(100vh - ${ props => props.toasterHeight }px);
  position: relative;
  background: #000;
`;

const AnchorNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 10;
  pointer-events: none;
`;

const Anchor = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${ props => props.theme.white_25 };
  border-radius: 3px;
  color: white;
  pointer-events: all; 
  cursor: pointer; 
  margin-bottom: ${ props => props.marginTop || '11vh' };
  padding: 10px;
`;

const VideoContainer = styled.div`
  position: absolute;
  overflow: hidden;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  background: url(${PlanAheadBGVideo}) no-repeat;
  background-position: center;
  background-size: cover;
`;

class PlanAheadHeroBlock extends Component {
  scrollPage = (val, offset) => {
    scroller.scrollTo('welcome-to-dreamscape', {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset,
    });
  };

  handleScrollToAdvSection = () => {
    const toasterHeight = this.props.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    this.scrollPage(document.getElementById('plan-ahead-hero').offsetHeight, -document.getElementById('navbar').offsetHeight -toasterHeight);
  };

  render () {
    const toasterHeight = this.props.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    return (
      <Container id="plan-ahead-hero" toasterHeight={toasterHeight}>
        <VideoContainer/>
        <Desktop>
          <ContentContainer mobile={false}>
            <Header mobile={false}>LET'S GET REAL ABOUT VIRTUAL REALITY</Header>
          </ContentContainer>
        </Desktop>
        <Mobile>
          <ContentContainer mobile={true}>
            <Header mobile={true}>LET'S GET REAL ABOUT VIRTUAL REALITY</Header>
          </ContentContainer>
        </Mobile>

        <AnchorNav onClick={ this.handleScrollToAdvSection }>
          <Anchor>
            <DownArrowIcon color={theme.white_80}/>
          </Anchor>
        </AnchorNav>
      </Container>
    )
  }
}

export default withRouter(PlanAheadHeroBlock);
