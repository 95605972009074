import styled from 'styled-components';

import BaseButton from './BaseButton';

const WhiteButton = styled(BaseButton)`
  background-color: white;
  color: ${ props => props.theme.ds_dusk_blue };
  box-shadow: 0 10px 15px 0 ${ props => props.theme.black_30 };
  border: none;
  transition: ${ props => props.theme.transition };
  
  &:focus {
    background-color: white
    border: 1px solid  ${ props => props.theme.ds_dusk_blue };
    box-shadow: 0 5px 5px 0 ${ props => props.theme.black_30 };
  }
  
  &:hover {
    box-shadow: 0 5px 5px 0 ${ props => props.theme.black_30 };
    background-color: white
    border: 3px solid ${ props => props.theme.ds_dusk_blue };
  }
  
  &:active {
    background-color: ${ props => props.theme.ds_dusk_blue };
    color: white;
  }
`;

export default WhiteButton;