import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'moment';

import { BlueButton } from '../components/buttons';
import { BasicInputField as Input } from '../components/forms';
import { DIApi } from '../util/api';
import { AuthContext } from '../components/context/AuthenticatorContext';
import CheckoutOrderDetails from '../components/CheckoutOrderDetails';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import Checkbox from '../components/forms/Checkbox';
import LoadingSpinner from '../components/LoadingSpinner';

import {parsePhoneNumber} from 'libphonenumber-js';
import PhoneInputField from "../components/forms/PhoneInputField";
import { sendCreateAccountEvent } from '../util/analytics';

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${ props => props.mobile ? '20px' : '38px' };
`;

const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${ props => props.mobile ? '0 22px' : undefined };
  max-width: ${ props => props.mobile ? '360px' : '420px' };
  box-sizing: border-box;
  width: 100%;
 `;

const SectionTitle = styled.div`
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' };
  align-self: center;
  color: ${ props => props.theme.charcoal_90 };
  padding: ${ props => props.mobile ? '46px 22px 0 22px' : '60px 22px 0 22px' };
  text-align: ${ props => props.mobile ? 'center' : undefined };
  letter-spacing: -1px;
`;

const SectionSubTitle = styled.div`
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
  align-self: center;
  max-width: ${ props => props.mobile ? '360px' : '428px' };
  padding: 0 22px;
  text-align: center;
  color: ${ props => props.theme.charcoal_60 };
  padding-top: 4px;
  box-sizing: border-box;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: ${ props => props.mobile ? '360px' : '420px' };
  width: 100%;
  margin: 8px 0 40px 0;
`;

const PasswordRulesContainer = styled.div`
  font-size: 0.8125rem;
  font-style: italic;
  color: ${ props => props.theme.charcoal_60 };
  margin: -8px 0 15px 0;
`;

const CheckBoxOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
`;

const CheckBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const CheckBoxRowText = styled.div`
  flex: 1;
  font-size: 0.75rem;
  color: ${ props => props.theme.charcoal_60 };
`;

const LinkText = styled.span`
  color: ${ props => props.theme.ds_dusk_blue };
  cursor: pointer;
`;

const StyledBlueButton = styled(BlueButton)`
  &:disabled {
    background-color: ${ props => props.theme.charcoal_25 };
    color: ${ props => props.theme.white_25 };
    cursor: initial;
    box-shadow: none;
    border: 0;
  }
`;

class CheckoutSignUp extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    tncAndPrivacyAccepted: false,
    newsletterOptIn: false,
    isSigningUp: false,
  };

  componentDidMount () {
    const locationState = this.props.location.state;
    document.body.style.background = 'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';
    // This will return the header information back to its default if it has been changed.
    this.props.updateHeaderText({
      handleOnBackClicked: () => this.props.history.replace('/checkout/signin', locationState),
    });
    this.handleRedirect();
  }

  componentDidUpdate () {
    this.handleRedirect();
  }

  componentWillUnmount () {
    document.body.style.background = 'black';
  }

  handleRedirect () {
    if (this.props.authState.isSignedIn) {
      const locationState = this.props.location.state;
      this.props.history.replace('/checkout/payment', locationState);
    }
  }

  handleInputChange = (element) => {
    const { name, value } = element.target;
    this.setState({
      [name]: value,
    });
  };

  handleUserSignUp = async (e) => {
    e.preventDefault();
    if (!this.state.tncAndPrivacyAccepted) {
      alert("You must read the Terms of Use and the Privacy Policy before signing up.");
      return;
    }
    const { email :emailField, password, confirmPassword, firstName, lastName, dob, phone } = this.state;
    // Take the input data and, if exists, force to lowercase.
    const email = emailField ? emailField.toLowerCase() : null;

    if(firstName.length <= 0){
      alert("First name is required to create an account.");
      return;
    }
    if(lastName.length <= 0){
      alert("Last name is required to create an account.");
      return;
    }

    if(!dob || dob.length <= 0){
      alert("Date of birth is required to create an account.");
      return;
    }
    if (Moment().diff(dob, 'years') < 13) {
      alert("You must be at least 13 years of age to create an account.");
      return;
    }

    if (Moment().diff(dob, 'years') > 100) {
      alert('An invalid date of birth was entered. Please check the date and try again.');
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    if (password.length < 8) {
      alert("Password is too short. Passwords must be at least 8 characters long.");
      return;
    }
    if (password === password.toLowerCase()) {
      alert("Password must contain an uppercase letter.");
      return;
    }
    if (password === password.toUpperCase()) {
      alert("Password must contain a lowercase letter.");
      return;
    }
    if (password.match(/\d+/) === null) {
      alert("Password must contain a number.");
      return;
    }

    this.setState({ isSigningUp: true });
    try {
      const apiArgs = [email, password, firstName, lastName, dob];
      if (phone) {
        const phoneNumber = parsePhoneNumber(phone, 'US');
        if (!phoneNumber.isValid()) {
          throw new Error("Invalid phone number entered.");
        }
        const phoneFormatted = phoneNumber.format('E.164');
        apiArgs.push(phoneFormatted);
      }
      await DIApi.userSignUp(...apiArgs);
      // Send Analytics Event
      sendCreateAccountEvent();
    } catch (e) {
      // TODO: Handle displaying error message here
      if (e.message === "NOT_A_NUMBER" || e.message === "TOO_LONG") {
        alert("You must enter a valid phone number format.");
      } else if (typeof e === 'string') {
        alert(e);
      } else {
        alert(e.message);
      }
      this.setState({ isSigningUp: false });
      return;
    }

    // Automatically "Sign In" the user since we have a successful signup
    const signedIn = await DIApi.userSignIn(email, password);
    if (!signedIn) {
      alert('Could not sign you in. Please enter your credentials on the next page.');
      const locationState = this.props.location.state;
      return this.props.history.replace(`/checkout/signin`, locationState);
    }

    // Notify the "Auth State" that the user has signed in.
    this.setState({ email: '', password: '' });
    this.props.authState.handleAuthStateChange('signedIn');
  };

  handleCheckClicked = (e, checkboxClicked) => {
    e.preventDefault();
    this.setState({
      [checkboxClicked]: !this.state[checkboxClicked],
    });
  };

  renderSignUpButton () {
    const buttonContent = this.state.isSigningUp ? <LoadingSpinner/> : 'CREATE ACCOUNT';

    return (
      <StyledBlueButton width={'100%'} type="submit" disabled={this.state.isSigningUp || !this.state.tncAndPrivacyAccepted}>
        { buttonContent }
      </StyledBlueButton>
    );
  }

  render () {
    const { orderDetails, adventure } = this.props.location.state;
    const pageDetails = {
      title: 'ACCOUNT CREATION',
      subTitle: 'Enter the information below to create your account.',
      submitButtonText: 'CREATE ACCOUNT',
      backButtonText: 'BACK',
      passwordRulesText: 'Use 8 or more characters with an uppercase letter, a lowercase letter, and a number.',
      newsletterEmailText: 'I would like to receive emails about Dreamscape Immersive.',
    }
    return (
      <Page>
        <Desktop>
          <SectionTitle>{pageDetails.title}</SectionTitle>
          <SectionSubTitle>{pageDetails.subTitle}</SectionSubTitle>
        </Desktop>
        <Mobile>
          <SectionTitle mobile>ACCOUNT<br/>CREATION</SectionTitle>
          <SectionSubTitle mobile>{pageDetails.subTitle}</SectionSubTitle>
        </Mobile>

        {/* DESKTOP STYLING */}
        <Desktop>
          <Container>
            <CheckoutOrderDetails orderDetails={orderDetails} titleId={adventure.title_id} />

            <SignupForm onSubmit={ this.handleUserSignUp }>
              <InputRow>
                <StyledInput
                  autoFocus
                  name="firstName"
                  placeholder="First Name"
                  value={ this.state.firstName }
                  onChange={ this.handleInputChange }
                  width={200}
                />
                <StyledInput
                  name="lastName"
                  placeholder="Last Name"
                  value={ this.state.lastName }
                  onChange={ this.handleInputChange }
                  width={200}
                />
              </InputRow>
              <StyledInput
                name="email"
                placeholder="Email"
                type="email"
                value={ this.state.email }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <StyledInput
                name="dob"
                placeholder="Date of Birth"
                type="date"
                value={ this.state.dob }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <PhoneInputField
                width={'100%'}
                placeholder="Phone Number (Optional)"
                style={{marginBottom: '15px'}}
                value={ this.state.phone }
                changeCallback={ (newValue) => this.setState({ phone: newValue }) }/>
              <StyledInput
                name="password"
                placeholder="Password"
                type="password"
                value={ this.state.password }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <PasswordRulesContainer>
                {pageDetails.passwordRulesText}
              </PasswordRulesContainer>
              <StyledInput
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                value={ this.state.confirmPassword }
                onChange={ this.handleInputChange }
                width={'100%'}
              />

              <CheckBoxOptionsContainer>
                <CheckBoxRow>
                  <Checkbox checked={ this.state.tncAndPrivacyAccepted }
                            onClick={ (e) => this.handleCheckClicked(e, 'tncAndPrivacyAccepted') }
                  />
                  <CheckBoxRowText>
                    I Have read and agree to Dreamscape Immersive’s <LinkText onClick={() => this.props.history.push('/terms')}>Terms of Use</LinkText> and <LinkText onClick={() => this.props.history.push('/privacy')}>Privacy Policy</LinkText>.
                  </CheckBoxRowText>
                </CheckBoxRow>

              </CheckBoxOptionsContainer>

              <ButtonGroup>
                { this.renderSignUpButton() }
              </ButtonGroup>

            </SignupForm>
          </Container>
        </Desktop>

        {/* MOBILE STYLING */}
        <Mobile>
          <Container mobile>
            <SignupForm mobile onSubmit={ this.handleUserSignUp }>
              <InputRow>
                <StyledInput
                  autoFocus
                  name="firstName"
                  placeholder="First Name"
                  value={ this.state.firstName }
                  onChange={ this.handleInputChange }
                  width={135}
                />
                <StyledInput
                  name="lastName"
                  placeholder="Last Name"
                  value={ this.state.lastName }
                  onChange={ this.handleInputChange }
                  width={135}
                />
              </InputRow>
              <StyledInput
                name="email"
                placeholder="Email"
                type="email"
                value={ this.state.email }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <StyledInput
                name="dob"
                placeholder="Date of Birth"
                type="date"
                value={ this.state.dob }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <PhoneInputField
                width={'100%'}
                placeholder="Phone Number (Optional)"
                style={{marginBottom: '15px'}}
                value={ this.state.phone }
                changeCallback={ (newValue) => this.setState({ phone: newValue }) }/>
              <StyledInput
                name="password"
                placeholder="Password"
                type="password"
                value={ this.state.password }
                onChange={ this.handleInputChange }
                width={'100%'}
              />
              <PasswordRulesContainer>
                {pageDetails.passwordRulesText}
              </PasswordRulesContainer>
              <StyledInput
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                width={'100%'}
                value={ this.state.confirmPassword }
                onChange={ this.handleInputChange }
              />

              <CheckBoxOptionsContainer>
                <CheckBoxRow>
                  <Checkbox checked={ this.state.tncAndPrivacyAccepted }
                            onClick={ (e) => this.handleCheckClicked(e, 'tncAndPrivacyAccepted') }
                  />
                  <CheckBoxRowText>
                    I Have read and agree to Dreamscape Immersive’s <LinkText onClick={() => this.props.history.push('/terms')}>Terms of Use</LinkText> and <LinkText onClick={() => this.props.history.push('/privacy')}>Privacy Policy</LinkText>.
                  </CheckBoxRowText>
                </CheckBoxRow>

              </CheckBoxOptionsContainer>

              <ButtonGroup mobile>
                { this.renderSignUpButton() }
              </ButtonGroup>

            </SignupForm>
          </Container>
        </Mobile>
      </Page>
    );
  }
}

const SignUp = props => (
  <AuthContext.Consumer>
    { authState => <CheckoutSignUp {...props} authState={authState} /> }
  </AuthContext.Consumer>
);

export default withRouter(SignUp);
