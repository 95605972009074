import { Component } from 'react';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'moment-timezone';

import ADABlock from './ADABlock'
import { SchedulerUndersized, SchedulerNatural } from '../components/responsive/Breakpoints';
import { BlueButton } from '../components/buttons/index';
import { AuthContext } from '../components/context/AuthenticatorContext';

const slotHeightMobile = '80px';
const slotHeightDesktop = '58px';
const slotWidthMobile = '153px';
const slotWidthDesktop = '206px';

const Container = styled.div`
  width: ${ props => props.platform === 'mobile' ? 'unset' : '1170px' };
  margin: ${ props => props.platform === 'mobile' ? '0 auto' : '25px auto 0' };
  font-size: 0.8rem;
  ${ props => props.platform === 'mobile' ? '' : 'padding-bottom: 34px;' }
`;

const Seat = styled.div`
  width: ${ props => props.maxSeats > 8 ? '9' : '18' }px;
  height: 18px;
  background-color: ${ props => {
    if (props.soldOut) {
      return props.theme.white_25;
    }
    
    if (props.taken) {
      return props.theme.charcoal_60;
    }
    
    return props.theme.ds_bright_blue
  }};
  display: inline-block;
  margin: 0 2px;
  border-radius: 2px;
`;

const DesktopList = styled.div`
  padding-left: 4px;
  padding-top: 24px;
  text-align: left;
`;

const MobileGrid = styled.div`
  padding-top: 24px;
  margin: 3px;
  display: grid;
  grid-gap: 6px;
  grid-template-columns: repeat(auto-fill, 153px);
  justify-content: center;
`;

const SoldOutSlot = styled(BlueButton)`
  padding: 0;
  background-color: ${ props => props.private ? props.theme.charcoal : props.theme.charcoal_25 };
  width: ${ props => props.platform === 'mobile' ? slotWidthMobile : slotWidthDesktop };
  height: ${props => props.platform === 'mobile' ? slotHeightMobile : slotHeightDesktop};
  margin: ${ props => props.platform === 'mobile' ? '0' : '0 13px 26px' };
  color: #fff;
  display: inline-block;
  box-shadow: none;
  cursor: unset;
  
  &:active {
    color: white;
  }
  
  &:focus {
    border: none;
    box-shadow: none;
    color: white;
  }
  
  &:hover {
    color: white;
    box-shadow: none;
    background-color: ${ props => props.private ? props.theme.charcoal : props.theme.charcoal_25 };
  }
`;

const UnavailableSlot = styled(BlueButton)`
  padding: 0;
  background-color: transparent;
  width: ${ props => props.platform === 'mobile' ? slotWidthMobile : slotWidthDesktop };
  height: ${props => props.platform === 'mobile' ? slotHeightMobile : slotHeightDesktop};
  margin: ${ props => props.platform === 'mobile' ? '0' : '0 13px 26px' };
  color: #fff;
  display: inline-block;
  box-shadow: none;
  cursor: unset;
  color: ${ props => props.theme.charcoal_25 };
  border: 1px solid ${ props => props.theme.charcoal_25 };
  
  &:active {
    border: 1px solid ${ props => props.theme.charcoal_25 };
  }
  
  &:focus {
    border: 1px solid ${ props => props.theme.charcoal_25 };
    box-shadow: none;
    color: white;
  }
  
  &:hover {
    color: ${ props => props.theme.charcoal_25 };
    border: 1px solid ${ props => props.theme.charcoal_25 };
    box-shadow: none;
    background-color: transparent;
  }
`;

const BlueLine = styled.div`
  width: 8px;
  height: 1px;
  background-color: ${ props => props.theme.ds_bright_blue };
`;

const BlueDot = styled.div`
  width: 2px;
  height: 1px;
  background-color: ${ props => props.theme.ds_bright_blue };
  margin: 2px;
`;

const BlueSpan = styled.span`
  padding: 0 4px;
`;

const SlotLabel = styled.div`
  margin-bottom: 4px;
  color: ${ props => props.soldout ? props.theme.charcoal_60 : undefined };
`;

const PrivateSlotLabel = styled.div`
  margin-bottom: 4px;
  color: ${ props => props.theme.ds_bright_blue };
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TimeSlot = styled(BlueButton)`
  padding: 6px 0;
  width: ${ props => props.platform === 'mobile' ? slotWidthMobile : slotWidthDesktop };
  height: ${props => props.platform === 'mobile' ? slotHeightMobile : slotHeightDesktop};
  margin: ${ props => props.platform === 'mobile' ? '0' : '0 13px 26px' };
  color: #fff;
  display: inline-block;
`;

const NotAvailableContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${ props => props.mobile ? '24px' : undefined };
`;

const NotAvailableArea = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid ${ props => props.theme.charcoal_25 };
  border-radius: 3px;
  max-width: ${ props => props.mobile ? '768px' : '1166px'};
  width: 100%;
  min-height: ${ props => props.mobile ? '233px' : '394px'};
  margin: 0 22px;
  padding: 0 22px;
`;

const CenteredText = styled.div`
  letter-spacing: 1px;
  font-size: ${ props => props.mobile ? '1rem' : '1.625rem'};
  font-weight: bold;
  color: ${ props => props.theme.charcoal_25 };
  margin: 0 auto;
  ${ props => props.mobile ? 'width: 250px;' : '' }
`;

const LinkText = styled(Link)`
  color: ${ props => props.theme.ds_dusk_blue };
  cursor: pointer;
  text-decoration: none;
  opacity: 0.8;
`;

class TimeSelectorWithContext extends Component {
  state = {
    ada: false
  };

  formatTime = (time) => {
    const date = new Moment(time);
    return date.tz(this.props.site.tz).format('h:mmA');
  };

  handleReserveSlot = (time, numOpenSeats, maxSeats, showtime, adventure, site) => {
    const { cms, selectedSite } = this.props.parentState;
    const siteAssets = cms.getSiteAssets(selectedSite.site_id);

    if (site.ticketing_partner_id !== 'xola') {
      return this.props.history.push({
        pathname: '/checkout/amc/tickets',
        state: {
          "time": time,
          "maxSeats": maxSeats,
          "showtime": showtime,
          "adventure": adventure,
          "site": site,
        }
      });
    }

    this.props.history.push({
      pathname: '/checkout/reserve',
      state: {
        "time": time,
        "ada": this.state.ada,
        "numOpenSeats": numOpenSeats,
        "maxSeats": maxSeats,
        "showtime": showtime,
        "adventure": adventure,
        "site": site,
        "locationAssets": siteAssets,
      }
    })
  };

  toggleAda = () => {
    this.setState({'ada': !this.state.ada})
  };

  buildTimeBlocks = (platform) => {
    const selectedDate = Object.keys(this.props.availabilityData)[this.props.selectedDate];
    const selectedDateData = this.props.availabilityData[selectedDate];
    return Object.keys(selectedDateData).map((time, i) => {
      const showtime = selectedDateData[time];
      let maxSeats = this.props.maxSeats;
      let numOpenSeats = showtime.quantity;
      let numTakenSeats = maxSeats - numOpenSeats;
      let numOpenADASeats = showtime.ada_quantity;

      if (this.state.ada) {
        maxSeats -= (showtime.stations_per_ada-1);
        numOpenSeats -= (showtime.stations_per_ada-1);
      }

      if (Moment().isAfter(showtime.datetime)) {
        return (
          <UnavailableSlot platform={platform} key={i} disabled>
            <SlotLabel style={{marginBottom: '3px', marginTop: '3px'}}>{this.formatTime(showtime.datetime)}</SlotLabel>
            <SlotLabel style={{marginTop: '0'}}>Unavailable</SlotLabel>
          </UnavailableSlot>
        )
      }

      if (showtime.private_session) {
        return (
          <SoldOutSlot platform={platform} key={i} private>
            <SlotLabel>
              {this.formatTime(showtime.datetime)}
            </SlotLabel>
            <PrivateSlotLabel>
              <BlueDot/><BlueLine/><BlueSpan>Private Event</BlueSpan><BlueLine/><BlueDot/>
            </PrivateSlotLabel>
          </SoldOutSlot>
        )
      } else if (numOpenSeats === 0 || (this.state.ada && numOpenADASeats === 0)) {
        return (
          <SoldOutSlot platform={platform} key={i} disabled>
            <SlotLabel>
              {this.formatTime(showtime.datetime)}
            </SlotLabel>
            <SlotLabel soldout>
              Sold Out
            </SlotLabel>
          </SoldOutSlot>
        )
      } else {
        return (
          <TimeSlot platform={platform} key={i}
                      onClick={this.handleReserveSlot.bind(this,
                        time,
                        numOpenSeats,
                        maxSeats,
                        showtime,
                        this.props.adventure,
                        this.props.site)
                      }>
            <SlotLabel>{this.formatTime(showtime.datetime)}</SlotLabel>
            {Array.from({length: numTakenSeats}).map((_, i) =>
              <Seat taken key={i} maxSeats={this.props.maxSeats}/>
            )}
            {Array.from({length: numOpenSeats}).map((_, i) =>
              <Seat key={i} maxSeats={this.props.maxSeats}/>
            )}
          </TimeSlot>
        )
      }
    });
  };

  renderTimeSlots(platform) {
    const isMobile = platform === 'mobile';
    const timeSlotsDate = Object.keys(this.props.availabilityData)[this.props.selectedDate];
    const timeSlotsData = this.props.availabilityData[timeSlotsDate];
    const { cms, selectedSite, hasStoredSite } = this.props.parentState;
    const siteAssets = cms.getSiteAssets(selectedSite.site_id);

    let lines = [
      (<CenteredText mobile={isMobile}>TICKETS FOR THIS DATE AREN’T AVAILABLE YET, BUT WILL BE SOON.</CenteredText>),
      (<br/>),
      (<CenteredText mobile={isMobile}>CHECK BACK FOR UPDATES!</CenteredText>)
    ];

    if (siteAssets && siteAssets.copy && siteAssets.copy.ticketingUnavailableLines) {
      lines = siteAssets.copy.ticketingUnavailableLines.map((line, itr) => {
        let finalLine = line;
        if (typeof line === 'object' && line instanceof Array) {
          finalLine = line
            .map((part) => {
              if (typeof part === 'object' && part instanceof Object) {
                const { type, value, display } = part;
                return <span> <LinkText as={'a'} href={value}>{display}</LinkText> </span>
              }
              return <span>{part}</span>;
            });
        }

        return (
          <CenteredText key={`ticket-line-${itr}`} mobile={isMobile}>
            {itr > 0 ? <br/> : undefined}
            {finalLine}
          </CenteredText>
        );
      });
    }

    if (!timeSlotsData || !Object.keys(timeSlotsData).length) {
      return (
        <NotAvailableContainer mobile={isMobile}>
          <NotAvailableArea mobile={isMobile}>
            {lines}
          </NotAvailableArea>
        </NotAvailableContainer>
      )
    }

    const timeBlockList = this.buildTimeBlocks(platform);

    if (isMobile) {
     return (
        <MobileGrid>
          {timeBlockList}
        </MobileGrid>
      );
    }

    return (
      <DesktopList>
        {timeBlockList}
      </DesktopList>
    );
  }

  renderAdaBlock (isMobile) {
    if (this.props.site.ticketing_partner_id !== 'xola') {
      return null;
    }

    return (
      <ADABlock
        mobile={isMobile}
        ada={this.state.ada}
        toggleAda={this.toggleAda}/>
    );
  }

  render () {
    return (
      <React.Fragment>
        <SchedulerNatural>
          <Container platform={'desktop'}>
            { this.renderTimeSlots('desktop') }
            { this.renderAdaBlock(false) }
          </Container>
        </SchedulerNatural>

        <SchedulerUndersized>
          <Container platform={'mobile'}>
            { this.renderAdaBlock(true) }
            { this.renderTimeSlots('mobile') }
          </Container>
        </SchedulerUndersized>
      </React.Fragment>
    )
  }
}

const TimeSelector = props => (
  <AuthContext.Consumer>
    { parentState => <TimeSelectorWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(TimeSelector);
