import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';


import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import { WhiteOutlineButton } from '../components/buttons'
import { AuthContext } from "../components/context/AuthenticatorContext";

import bgImg_x2 from '../assets/images/img-find-us-bg-default@2x.jpg';
// import bgImg_x3 from '../assets/images/img-find-us-bg-default@3x.jpg';
// import bgImgSmall_x2 from '../assets/images/img-find-us-bg-s-default@2x.jpg';
import bgImgSmall_x3 from '../assets/images/img-find-us-bg-s-default@3x.jpg';

import { GENEVA_LOCATION, RIYADH_LOCATION } from '../util/constants';

const staticLocationIds = [
  RIYADH_LOCATION.site_id,
  GENEVA_LOCATION.site_id,
];

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

const FindUsHeroContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${ props => props.isMobile ? '448px' : '656px' };
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
`;

const FindUsTitle = styled.div`
  display: flex;
  font-size: ${ props => props.isMobile ? '1.625rem' : '3.5rem' };
  font-weight: bold;
  letter-spacing: ${ props => props.isMobile ? '-1px' : '2px' };
  line-height: 1.33;
  text-align: center;
  padding: ${ props => props.isMobile ? '0 22px' : '0 60px' };
  max-width: ${ props => props.isMobile ? '340px' : undefined };
  margin-top: ${ props => props.isMobile ? '38px' : undefined };
`;

const FindUsSubTitle = styled.div`
  display: flex;
  font-size: ${ props => props.isMobile ? '0.875rem' : '1rem' };
  line-height: ${ props => props.isMobile ? '1.4' : undefined };
  color: ${ props => props.theme.white_70};
  margin-top: ${ props => props.isMobile ? '12px' : '6px' };
  padding: 0 22px;
  box-sizing: border-box;
  text-align: center;
  max-width: ${ props => props.isMobile ? '460px' : '736px' };
  
  & b {
    color: white;
    font-weight: 500;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center; 
  flex: 1;
  width: 100%;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: ${ props => props.isMobile ? 'column-reverse' : 'row' };
  max-width: 1214px;
  align-items: ${ props => props.isMobile ? 'center' : undefined };
  width: 100%;
  padding: ${ props => props.isMobile ? undefined : '50px 22px' };
  box-sizing: border-box;
`;

const LocationInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: white;
  max-width: ${ props => props.isMobile ? '375px' : undefined };
  border-top: ${ props => props.isMobile ? undefined : '1px solid white' };
  margin-right: ${ props => props.isMobile ? undefined : '133px' };
  padding: ${ props => props.isMobile ? '0 22px' : undefined };
`;

const LocationImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${ props => props.isMobile ? '100%' : '550px' };
`;

const StoreHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${ props => props.isMobile ? '24px 0 30px 0' : '30px 0 47px 0' };
`;

const StoreHoursLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${ props => props.isMobile ? '0.875rem' : '1rem' };
  max-width: 360px;
`;

const TitleText = styled.div`
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.isMobile ? '1.375rem' : '2.25rem' };
  color: ${ props => props.theme.white_90};
  line-height: 1.19;
  margin: ${ props => props.isMobile ? '36px 0 0 0' : '16px 0 0 0' };
`;

const RegularText = styled.div`
  font-size: ${ props => props.isMobile ? '0.875rem' : '1rem' };
  color: ${ props => props.theme.white_70};
  margin: ${ props => props.isMobile ? '0 0 10px 0' : '0 0 4px 0' };
  flex: ${ props => props.flex ? 1 : undefined };
  text-align: ${ props => props.rightAlign ? 'right': undefined };
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

class FindUsWithContext extends Component {
  componentDidMount () {

  }

  renderHeroContainer (isMobile) {
    let background = <BackgroundImage src={bgImg_x2}/>;

    if (isMobile) {
      background = <BackgroundImage src={bgImgSmall_x3}/>
    }

    return (
      <FindUsHeroContainer isMobile={isMobile}>
        {background}
        <FindUsTitle isMobile={isMobile}>
          NEW WORLDS,
          <br/>
          NEW LOCATIONS
        </FindUsTitle>
        <FindUsSubTitle isMobile={isMobile}>
          <span>
            <span>Visit Dreamscape and its VR Adventures in Los Angeles, California; Riyadh, Saudi Arabia and Geneva, Switzerland. Follow @visitdreamscape or @dreamscapeKSA or @dreamscapegeneva and </span>
            <StyledLink to={'/account/signup'}>join the Dreamscape community</StyledLink> for the latest on new openings.
          </span>
        </FindUsSubTitle>
      </FindUsHeroContainer>
    );
  }

  handleNavigation = (path) => {
    return this.props.history.push(path);
  };

  renderStoreHours (hours, isMobile) {
    const storeHourLines = hours.map((storeHourData, index) => {
      return (
        <StoreHoursLineContainer key={`store_hour_${index}`} isMobile={isMobile}>
          <RegularText isMobile={isMobile} flex>{storeHourData.day}</RegularText>
          <RegularText isMobile={isMobile} flex rightAlign>{storeHourData.time}</RegularText>
        </StoreHoursLineContainer>
      );
    });
    return (
      <StoreHoursContainer isMobile={isMobile}>
        <StoreHoursLineContainer isMobile={isMobile} style={{ fontWeight: 'bold', marginBottom: isMobile ? 10 : 0 }}>STORE HOURS</StoreHoursLineContainer>
        { storeHourLines }
      </StoreHoursContainer>
    );
  }

  renderStaticLocation (site, isMobile) {
    const {
      site_legalname,
      street_address,
      phone,
      directions,
      site_url_path,
      hours,
      images,
      site_id,
    } = site;

    const image = isMobile ? images.mobile.x3 : images.desktop.x3;

    return (
      <LocationContainer isMobile={isMobile} key={site_id}>
        <LocationInfoContainer isMobile={isMobile}>
          <TitleText isMobile={isMobile}>{site_legalname}</TitleText>
          <RegularText isMobile={isMobile}>{street_address}</RegularText>
          {phone 
            ? <RegularText as="a" style={{ textDecoration: 'none' }} href={`tel:${phone}`} isMobile={isMobile}>{phone}</RegularText> 
            : null}
          <RegularText isMobile={isMobile}>{directions}</RegularText>
          <a href={site_url_path} style={{ marginTop: isMobile ? 16 : 30, maxWidth: 316 }}>
            <WhiteOutlineButton width={'100%'}>LEARN MORE</WhiteOutlineButton>
          </a>
          {this.renderStoreHours(hours, isMobile)}
        </LocationInfoContainer>
        <LocationImageContainer isMobile={isMobile}>
          <img width={'100%'}
            src={image}
            alt="Find Us BG" />
        </LocationImageContainer>
      </LocationContainer>
    );
  }

  renderLocations (isMobile) {
    const { sites, cms } = this.props.parentState;

    const locations = sites.map(site => {
      if (staticLocationIds.includes(site.site_id)) {
        return this.renderStaticLocation(site, isMobile);
      }

      const siteAssets = cms.getSiteAssets(site.site_id);
      if (!Object.keys(siteAssets).length) {
        return null;
      }

      const copy = siteAssets.copy;
      const imageAssets = siteAssets.images.findus;

      const locationName = copy.display_name;
      let locationAddress = `${site.street_address}, ${site.city}, ${site.state}`;
      if (site.postal_code) {
        locationAddress += ` ${site.postal_code}`;
      }
      const locationPhone = copy.phone;
      const locationDirections = copy.directions;
      const images = isMobile ? imageAssets.mobile.x3 : imageAssets.desktop.x2;
      const navPath = copy.site_url_path;
      const storeHours = copy.hours;

      return (
        <LocationContainer isMobile={isMobile} key={site.site_id}>
          <LocationInfoContainer isMobile={isMobile}>
            <TitleText isMobile={isMobile}>{locationName}</TitleText>
            <RegularText isMobile={isMobile}>{locationAddress}</RegularText>
            <RegularText as="a" style={{ textDecoration: 'none' }} href={`tel:${locationPhone}`} isMobile={isMobile}>{locationPhone}</RegularText>
            <RegularText isMobile={isMobile}>{locationDirections}</RegularText>
            <div style={{ marginTop: isMobile ? 16 : 30, maxWidth: 316 }}>
              <WhiteOutlineButton width={'100%'} onClick={() => this.handleNavigation(navPath)}>LEARN MORE</WhiteOutlineButton>
            </div>
            { this.renderStoreHours(storeHours, isMobile) }
          </LocationInfoContainer>
          <LocationImageContainer isMobile={isMobile}>
            <img width={'100%'}
                 src={images}
                 alt="Find Us BG"/>
          </LocationImageContainer>
        </LocationContainer>
      );
    });
    
    return locations;
  }

  render () {
    return (
      <Container id="find-us-page">
        <Desktop>
          { this.renderHeroContainer() }
          <LocationsContainer>
            { this.renderLocations() }
          </LocationsContainer>
        </Desktop>

        <Mobile>
          { this.renderHeroContainer(true) }
          <LocationsContainer>
            { this.renderLocations(true) }
          </LocationsContainer>
        </Mobile>

      </Container>
    );
  }
}

const FindUs = props => (
  <AuthContext.Consumer>
    { parentState => (<FindUsWithContext {...props} parentState={parentState} />)}
  </AuthContext.Consumer>
);

export default withRouter(FindUs);
