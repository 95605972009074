import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import styled from 'styled-components';
import {scroller} from "react-scroll/modules";
import {AuthContext} from "../components/context/AuthenticatorContext";
import { TOASTER_NOTIFICATION_HEIGHT } from "../util/constants";

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${ props => props.mobile ? '84px 22px' : '164px 20% 86px' };
  max-width: 1170px;
`;

const HeaderBlock = styled.div`
  padding-bottom: ${ props => props.mobile ? '18px' : '40px' };
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '36px' : '44px' };
  font-weight: bold;
  line-height: ${ props => props.mobile ? '1.33' : '1.09' }1.09;
  color: ${ props => props.theme.charcoal };
  text-align: center;
`;

const Body = styled.div`
  text-align: left;
`;

const Header = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: 1rem;
  font-weight: bold;
  ${ props => props.blue && !props.mobile ? 'width: 197px;' : '' }
  padding-right: 10px;
  ${ props => props.blue ? 'text-decoration: underline' : '' }
  color: ${ props => props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal_90 };
  margin: ${ props => props.blue ? '0' : '13' }px 0 0;
  ${ props => props.blue ? 'cursor: pointer;' : '' };
`;

const Text = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: 0.81rem;
  color: ${ props => props.theme.charcoal_60 };
`;

const Contents = styled.table`
  padding: 21px 0 40px;
`;

const ContentsRow = styled.tr`
  vertical-align: top;
`;

const LinkText = styled.span`
  cursor: pointer;
  color: ${ props => props.theme.ds_dusk_blue };
  text-decoration: none;
`;

const StyledUl = styled.ul`
  padding-inline-start: 25px;
`;

const PaddedLi = styled.li`
  padding-bottom: 12px;
  font-size: 0.5rem;
`;

const SizingSpan = styled.span`
  font-size: 0.81rem;
  padding-left: 12px;
  display: block;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const EmailLink = styled.a`
  cursor: pointer;
  color: ${ props => props.theme.ds_dusk_blue };
  text-decoration: none;
`;

const PhoneLink = styled.a`
  cursor: pointer;
  color: ${ props => props.theme.charcoal_60 };
  text-decoration: none;
`;

const InternalLink = styled(Link)`
  cursor: pointer;
  color: ${ props => props.theme.ds_dusk_blue };
  text-decoration: none;
`;

class PrivacyWithContext extends Component {
  componentDidMount () {
    const toasterOffset = this.props.parentState.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    const navbar = document.getElementById('navbar');

    this.setState({ navbar });

    if (this.props.location.state) {
      if (this.props.location.state.willScroll) {
        // If viewing the kiosk, navbar-less, version of the page, default the offset to provide some spacing.
        this.scrollPage(this.props.location.state.scrollId, navbar ? -navbar.offsetHeight -toasterOffset : -30);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      const toasterOffset = this.props.parentState.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
      const { navbar } = this.state;

      if (this.props.location.state) {
        if (this.props.location.state.willScroll) {
          // If viewing the kiosk, navbar-less, version of the page, default the offset to provide some spacing.
          this.scrollPage(this.props.location.state.scrollId, navbar ? -navbar.offsetHeight -toasterOffset : -30);
        }
      }
    }
  }

  scrollPage = (sectionId, offset) => {
    scroller.scrollTo(sectionId, {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset,
    });
  };

  handleScrollToSection = (sectionId) => {
    const toasterOffset = this.props.parentState.hasStoredSite ? 0 : TOASTER_NOTIFICATION_HEIGHT;
    const { navbar } = this.state;
    // If viewing the kiosk, navbar-less, version of the page, default the offset to provide some spacing.
    this.scrollPage(sectionId, navbar ? -navbar.offsetHeight -toasterOffset : -30);
  };

  handleNavigation = (path) => {
    if (this.props.disableLinks) {
      return;
    }
    return this.props.history.push(path);
  };

  renderPrivacyPolicy (mobile) {
    return (
      <Container>
        <TextContainer mobile={mobile}>
          <HeaderBlock mobile={mobile}>DREAMSCAPE PRIVACY POLICY</HeaderBlock>
          <Body>
            <Header>EFFECTIVE DATE: JANUARY 1, 2020</Header>
            <br/>
            <Text>Welcome! Thank you for visiting a Dreamscape Immersive, Inc. ("Dreamscape," "we" or "us") offering. To help inform you, we provide this notice explaining our information practices and the choices you can make about the collection and use of information you submit or we collect through our website&nbsp;
              <InternalLink as={ this.props.disableLinks ? 'a' : undefined } to={ !this.props.disableLinks ? '/' : '' }>www.dreamscapeimmersive.com</InternalLink>,
              our other related mobile websites, mobile applications ("mobile apps"), services, tools, and other applications (collectively, the "Site"). The Site is owned and operated by Dreamscape.</Text>
            <Header>CONTENTS & SUMMARY</Header>
            <Text>
              <p>A table summarizing the contents of this privacy policy ("Privacy Policy") appears below, followed by the policy in full. Should there be any discrepancy between the table and the full Privacy Policy, the language of the full Privacy Policy shall govern.</p>
              <p>
                By using the Site you expressly consent to our collection, use, retention of your personal information and other information as described in this Privacy Policy. Your use of the Site is also governed by the
                <InternalLink as={ this.props.disableLinks ? 'a' : undefined } to={ !this.props.disableLinks ? '/terms' : '' }> Terms of Use.</InternalLink>
              </p>
              <p>We collect the following information when you use the Site.</p>
              <p>(a) Registration and Account Information: When you register an account or create a profile, you may provide personal information such as your name, email address, phone number, password and other relevant information.</p>
              <p>(b) Purchasing Tickets: When you purchase tickets to a Dreamscape virtual reality ("VR") experience, you may provide personal information such as your name, physical address, email address, phone number, financial and payment information, and other relevant information to complete your purchase.</p>
              <p>(c) Checking in and Participating at Dreamscape VR Experience: When you check-in at a Dreamscape VR experience to verify your age and select an avatar, you may provide personal information such as your name, date of birth, email address, and signature, and we may associate an avatar ID and ticket ID with you. We may also record video of your experience.</p>
              <p>(d) Information to Respond to Your Requests: You may give us your personal information so we can respond to your requests, e.g., when you send us questions or sign up for newsletters and other communications.</p>
              <p>(e) Information From Other Sources: We may, on occasion, combine information from outside sources to customize your experience on the Site.</p>
              <p>(f) Content You submit on the Site: We may provide opportunities for you to submit or post comments and suggestions, which may include additional personal information, including about your beliefs.</p>
              <p>(g) Mobile Information: If you access our Site through a mobile device, you may provide information about your device and/or mobile service (e.g., your unique device identifier, your device’s operating system, your network connection or the mobile carrier for your phone).</p>
              <p>(h) Technical usage information: We use tracking technologies such as cookies and other similar technologies to measure usage.</p>
              <p>(i) Social Media information: When you sign in or engage with our Site through third-party social media sites, you agree to give us on-going access to your personal information.</p>
              <p>(j) Video Viewing information: We may collect analytical information about the videos you view on the Site. You consent to our collection of video viewing information on the Site and sharing this information with our affiliates and vendors.</p>
            </Text>
            <Contents>
              <tbody>
              {
                [
                  {
                    title: 'USES AND RETENTION',
                    content: 'We use information collected from you to operate our Site and contact you. Your information is retained for as long as we need it for our business.'
                  },
                  {
                    title: 'YOUR CHOICES',
                    content: 'You can control how and when you want to receive notifications from Dreamscape on the Site or by following instructions within the notices.'
                  },
                  {
                    title: 'DISCLOSURE OF INFORMATION',
                    content: 'We may share your personal information and other information we collect with the following entities: (1) Dreamscape and the rest of our corporate family; (2) our service providers and third parties; (3) other entities with your consent; (4) legal and law enforcement; (5) change of control – new owners; (6) ad networks; (7) companies in the mobile industry; and (8) social networks.'
                  },
                  {
                    title: 'TRACKING TECHNOLOGIES',
                    content: 'We use tracking technologies (including cookies) to recognize you, customize your experience on the Site, and target the content/advertising you see. You consent to our tracking of your video viewing through the Site or third party social media.'
                  },
                  {
                    title: 'ACCESS AND REVIEW OPTIONS',
                    content: (
                      <React.Fragment>
                        <span>You can review and edit your personal information by accessing your account on the Site or by contacting us via email at </span>
                        <EmailLink href={ !this.props.disableLinks ? 'mailto:unsubscribe@dreamscapevr.com' : undefined } style={{wordWrap: 'break-word'}}>unsubscribe&#8203;@dreamscapevr.com</EmailLink><span>.</span>
                      </React.Fragment>
                    )
                  },
                  {
                    title: 'CALIFORNIA PRIVACY RIGHTS AND DISCLOSURES',
                    content: 'If you are a California resident, you may request information about our practices related to collection and disclosure of your personal information.'
                  },
                  {
                    title: 'THIRD PARTIES',
                    content: 'We are not responsible for the privacy practices of third party websites that are linked to our Site.'
                  },
                  {
                    title: 'SECURITY',
                    content: 'We protect your personal information using security measures.'
                  },
                  {
                    title: 'CHILDREN\'S PRIVACY',
                    content: 'The Site is a general audience site and it is not intended for children under the age of 13. Except as described below, we do not knowingly collect personal information via our websites, applications, services, or tools from users in this age group.'
                  },
                  {
                    title: 'INTERNATIONAL TRANSFER',
                    content: 'We store and process your personal information on our computers in the U.S. and elsewhere in the world.'
                  },
                  {
                    title: 'NOTIFICATION REGARDING UPDATES',
                    content: 'You agree that we may notify you about material changes to this Privacy Policy by posting notices on this Site.'
                  },
                  {
                    title: 'CONTACT US',
                    content: (
                      <React.Fragment>
                        <span>For more information about our privacy practices, go to our full Privacy Policy or send an email to </span>
                        <EmailLink href={ !this.props.disableLinks ? 'mailto:privacy@dreamscapevr.com' : undefined } style={{wordWrap: 'break-word'}}>privacy&#8203;@dreamscapevr.com</EmailLink><span>.</span>
                      </React.Fragment>
                    )
                  }
                ].map((row, i) => {
                  const linkDict = {
                    'USES AND RETENTION': 3,
                    'YOUR CHOICES': 4,
                    'DISCLOSURE OF INFORMATION': 5,
                    'TRACKING TECHNOLOGIES': 8,
                    'ACCESS AND REVIEW OPTIONS': 10,
                    'CALIFORNIA PRIVACY RIGHTS AND DISCLOSURES': 11,
                    'THIRD PARTIES': 13,
                    'SECURITY': 14,
                    'CHILDREN\'S PRIVACY': 15,
                    'INTERNATIONAL TRANSFER': 16,
                    'NOTIFICATION REGARDING UPDATES' : 17,
                    'CONTACT US': 18
                  };
                  return (
                    <ContentsRow key={i}>
                      <td><Header blue mobile={mobile} onClick={() => this.handleScrollToSection('section'+(linkDict[row.title]))}>{row.title}</Header></td>
                      <td><Text>{row.content}</Text></td>
                    </ContentsRow>
                  )
              })}
              </tbody>
            </Contents>
          <HeaderBlock mobile={mobile}>FULL PRIVACY POLICY</HeaderBlock>
          {
            [
              {
                title: '1. SCOPE AND CONSENT',
                body: (
                  <React.Fragment>
                    <p>When you use the Site, you are contracting with Dreamscape, and, as applicable, its affiliates, subsidiaries and related entities. If you reside outside of the United States, Dreamscape is your data controller. By using the Site, you agree to our Terms of Use and expressly consent to our collection, use, disclosure, and retention of your personal information as described in this Privacy Policy. If you do not agree and consent, discontinue use of the Site.</p>
                    <p>
                      If you have any questions about our privacy practices, please contact us as described below in
                      <LinkText onClick={() => this.handleScrollToSection('section18')}> Section 18 </LinkText>
                      (Contact Us).
                    </p>
                  </React.Fragment>
                )
              },
              {
                title: '2. INFORMATION WE COLLECT',
                body: (
                  <React.Fragment>
                    <p>(a) Registration and Account Information: You can browse the Site without telling us who you are or revealing identifying information (i.e., name, email address, phone number, and physical contact information) about yourself. If you choose to register for an account with us, we require you to create a username and password. Information is associated with your account/profile when you sign in as a registered user. When you register an account or create a profile, or otherwise engage with our Site, you may provide information such as your name, email address, phone number, password, and other relevant information. We collect your birthdate, username, interests, favorites, demographic information and other profile data that you provide.</p>
                    <p>(b) Purchasing Tickets to a Dreamscape VR Experience: When you purchase tickets to a Dreamscape virtual reality ("VR") experience, you may provide information such as your name, physical address, email address, phone number, date of birth, gender, financial and payment information (such as a credit card number and the security code associated with it), and other relevant information to complete your purchase.</p>
                    <p>(c) Checking in and Participating at a Dreamscape VR Experience: When you check-in at a Dreamscape VR experience to verify your age and select an avatar, you may provide information such as your name, date of birth, email address, gender, signature and other relevant information to complete the check-in process. We may associate an avatar ID and ticket ID with you. When you participate in a Dreamscape VR experience we may record eye movement (from within the VR headset) and video of your experience in order to show you and others what it is like to experience VR and to help us provide a safe and secure experience for you and other participants.</p>
                    <p>
                      (d) Information to Respond to Your Requests: We collect your information so we can respond to your requests, e.g., when you send us questions or sign up for newsletters and other communications. Details of how you can opt-out of receiving any communications from us are detailed in the
                      <LinkText onClick={() => this.handleScrollToSection('section4')}> Section 4 </LinkText>
                      (Your Choices) below.
                    </p>
                    <p>(e) Information From Other Sources: The information we collect may be combined with information from the records of our third party service providers. To the extent we combine information from third party services providers with information we have collected about you through the Site, we will treat the combined information in accordance with this Privacy Policy, plus any additional restrictions imposed by the source of the data. These third party service providers vary over time, but have included: our ticket processor and social media sites (more on this below).</p>
                    <p>(f) Content You Submit on the Site: We may provide opportunities for you to submit or post comments, opinions, reviews or other content. The content you submit or post and recommendations you make may provide additional information to us, including about your beliefs.</p>
                    <p>(g) Device Connectivity and Configuration Data. We automatically collect data about the type of device or browser you use, your device’s operating software, your internet service provider, your device’s regional and language settings, and other similar information. This data also includes IP address, MAC address, device advertising Id (e.g., IDFA or AAID), and other device identifiers.</p>
                    <p>(h) Location Data. We collect data about your device’s location, which can be precise (e.g., latitude/longitude data) or imprecise (e.g., location derived from an IP address or data that indicates a city or postal code level).</p>
                    <p>(i) Mobile Information: We automatically collect additional information if you access our Site through a mobile device (e.g., your unique device identifier, device’s operating system, IP address, mobile browser, mobile carrier, geo-location (GPS, Wi-Fi, or user-entered).</p>
                    <p>
                      (j) Technical Usage Information: When you visit our Site, we automatically collect usage information sent to us by your computer, mobile, or other access device that tells us how you are using the Sites ("usage information"). Usage information includes data about the features you use on the Site, the e-mails and advertisements you view, the products you purchase, your referring and exiting pages, and similar information. We use tracking technologies (including
                      <LinkText onClick={() => this.handleScrollToSection('section18')}> cookies, web beacons, clear gifs, embedded scripts, e-tags, unique device ID</LinkText>
                      ) to collect usage information.
                    </p>
                    <p>We also collect password information from you when you log in, as well as computer and/or connection information. We use software tools to measure and collect session information, including page response times, download errors, time spent on certain pages and page interaction information.</p>
                    <p>
                      To learn more about how we use tracking technologies and cookies, see
                      <LinkText onClick={() => this.handleScrollToSection('section8')}> Section 8 </LinkText>
                      of this Privacy Policy (Tracking Technologies).
                    </p>
                    <p>
                      (k) Social Media Information: We may offer sign-in services that allow you to use third-party login credentials to access the Site. We also may offer services that allow you to share information with third-party social media websites such as Facebook, Pinterest, Instagram, Twitter, YouTube and others. When you link your account or engage with our Site through third-party social media sites, you understand that you are allowing us to have ongoing access to certain information stored on those social media sites (e.g., your public profile, profile photo, friend list, people you follow or who follow you, your email address, birthday, work history, education history, interests, current city, religious and political views, website, personal description, and likes). We may receive certain information about you which is stored on social media sites if other users of those sites give us access to their profiles and you are one of their friends or connections depending upon your settings on those third-party social networking sites. The information we have access to from the third-party social media websites varies by website and is controlled by your privacy settings on that website and by your consent. Likewise, if you choose to link your account or engage with our Site through third-party social media sites, the social media site may receive information about your activities on our Site and be able to associate that information with the information the social media site already has about you. The information that may be passed to analytics providers and advertising partners via cookies and tracking technologies. To learn more about how we use tracking technologies and cookies, see
                      <LinkText onClick={() => this.handleScrollToSection('section8')}> Section 8 </LinkText>
                      of this Privacy Policy (Tracking Technologies (Cookies)). By associating an account managed by a third-party with your Dreamscape account and authorizing us to have access to this information, you agree that we may collect, use, and store information from these websites in accordance with this Privacy Policy.
                    </p>
                    <p>(l) Video Viewing Information: On the Site you may also watch videos about our VR experience. By visiting the Site and watching the video content you agree that we may share your video viewing with, or obtain information about, your video viewing from analytics providers, advertising partners and third-party social media sites for two years or until you withdraw consent.</p>
                    <p>(m) You may choose to voluntarily submit other information to us through the Site that we do not request, and, in such instances, you are solely responsible for such information.</p>
                  </React.Fragment>
                )
              },
              {
                title: '3. USES AND RETENTION',
                body: (
                  <React.Fragment>
                    <p>We collect and use information for our business and commercial purposes described below, and in accordance with the practices detailed in this Privacy Policy:</p>
                    <p>(a) Operate Our Site: We use the information collected to (i) provide our VR experience, products and services to you; (ii) process your orders; (iii) create, maintain and manage your account; (iv) administer our promotions and other offers; (v) develop, maintain, improve, support and customize the Site, our VR experience, promotions, services, and messages to you; (vi) target marketing efforts and conduct research and analysis; (vii) respond to your customer service requests; and (viii) protect the security of your account.</p>
                    <p>(b) Analyze and Aggregate Non-Personal Information: We use aggregate information about our users and non-personal information to analyze the Site and user behavior and prepare aggregated reports.</p>
                    <p>
                      (c) Prevent Fraud: We use information from the Site to prevent, detect, and investigate fraud, security breaches, and potentially prohibited or illegal activities, and enforce our
                      <LinkText onClick={() => this.handleNavigation('/terms')}> Terms of Use.</LinkText>
                    </p>
                    <p>(d) Communicate With You: We use your information to (i) contact you regarding your orders; (ii) contact you about our VR experience, products, services, events, promotions, or other offers; (iii) contact you with technical notices, updates, security alerts, or other information regarding changes to our policies, and support and administrative messages; (iv) respond to your inquiries and comments; (v) comply with your stated communication preferences; and (vi) to provide other services requested by you, as described at the time when we collect the information from you, or to contact you for other purposes authorized by law.</p>
                    <p>(e) Mobile Data: When you access the Site on a mobile device, we may use the information collected for any purpose set forth in this Privacy Policy. For example, we may use this information in connection with your ticket purchase and checking you in at out our VR experience.</p>
                    <p>(f) Other Uses: We may use the information for marketing, recruiting, and investment analysis, and to make decisions about our business and partners. We may also use information about you at your direction, or to fulfil any other purpose disclosed to you and with your consent.</p>
                    <p>
                      We may use information that does not identify you (including information that has been aggregated or de-identified) for any purpose except as prohibited by applicable law. For information on your rights and choices regarding how we use your information, please go to
                      <LinkText onClick={() => this.handleScrollToSection('section4')}> Section 4 </LinkText>
                      (Your Choices) below.
                    </p>
                    <p>We retain information as long as it is necessary and relevant for our operations, and to comply with our legal obligations, resolve disputes, and enforce our agreements. You consent to our retention of your video viewing tracking data for as long as permissible under applicable law – up to one year after you close your account or otherwise withdraw consent for video tracking.</p>
                  </React.Fragment>
                )
              },
              {
                title: '4. YOUR CHOICES',
                body: (
                  <React.Fragment>
                    <p>
                      You can control how and when you want to receive notifications from Dreamscape by following instructions within notices or by contacting us via email at
                      <EmailLink href={ !this.props.disableLinks ? 'mailto:contact@dreamscapevr.com' : undefined }> contact@dreamscapevr.com</EmailLink>
                      . You may also change your notification settings within your account profile. For more information about how to opt-out of marketing communications go to
                      <LinkText onClick={() => this.handleScrollToSection('section7')}> Section 7 </LinkText>
                      (Communication Preferences and Marketing (Opt-out)); for information on your rights and choices regarding Tracking Technologies and advertising go to
                      <LinkText onClick={() => this.handleScrollToSection('section8')}> Sections 8 </LinkText>
                      (Tracking Technologies) and
                      <LinkText onClick={() => this.handleScrollToSection('section9')}> Section 9 </LinkText>
                      (Your Ad Choices); for information about how to change, update your personal information go to
                      <LinkText onClick={() => this.handleScrollToSection('section10')}> Section 10 </LinkText>
                      (Accessing, Reviewing, and Changing Your Personal Information); and if you are a California resident, please go to
                      <LinkText onClick={() => this.handleScrollToSection('section10')}> Section 11 </LinkText>
                      (Additional Disclosures for California Residents) for more information on your rights and choices.
                    </p>
                  </React.Fragment>
                )
              },
              {
                title: '5. DISCLOSURE',
                body: (
                  <React.Fragment>
                    <p>Information collected on the Site may be shared with:</p>
                    <p>(a) The Dreamscape Family: We may share the information we collect about you with our related entities, including our parent and sister companies and their and our affiliates and subsidiaries and partners. The information may be disclosed (i) to provide joint content and our services (e.g., registration, transactions, analytics and customer support); (ii) to help detect and prevent potentially illegal acts, violations of our policies, fraud and/or data security breaches; (iii) to guide decisions about our or third party’s business, assets, operations, rights, products, sites, applications, services, tools, and communications; and (iv) for processing and storage of information. Members of our corporate family will use this information to send you marketing communications and you will have the opportunity to opt-out in email communications.</p>
                    <p>(b) Service Providers and Other Third Parties: We may disclose your information to service providers under contract who help with our business operations (such as, but not limited to, developing, maintaining, improving and supporting our services, the Site, our VR experience, ticket and payment processing, fraud investigations, website hosting, bill collection, promotions and marketing, and site analytics and operations). Service providers are prohibited from using your information for any purpose other than to provide this assistance, although we may permit them to use aggregate information which does not identify you or de-identified data for other purposes.</p>
                    <p>(c) Other Persons with Your Consent: Other third parties to whom you explicitly ask us to share your information or provide consent to share your information.</p>
                    <p>(d) Legal and Law Enforcement: Law enforcement, governmental agencies, or authorized third parties, in response to a verified request relating to a criminal investigation or alleged illegal activity or any other activity that may expose us, you, or any other Dreamscape user to legal liability. In such events, we will only disclose information relevant and necessary to the investigation or inquiry, such as name, city, state, ZIP code, telephone number, email address, user ID history, IP address, fraud complaints, and anything else we may deem relevant to the investigation.</p>
                    <p>(e) Change of Control – New Owners: We may share your information with other business entities, should we plan to merge with or be acquired by that business entity, or during negotiations of any proposed or actual purchase, sale or any other type of acquisition or business combination of all or any portion of our assets, or transfer of all or a portion of our business to another business.</p>
                    <p>
                      (f) Ad Networks and Technology Companies: On the Site, we may work with third parties to serve advertisements, provide us with data collection, reporting, ad response measurement and site analytics and assist with delivery of relevant marketing messages and advertisements. These third parties may view, edit or set their own tracking technologies/cookies. The use of these technologies by these third parties is subject to their own privacy policies and is not covered by this Privacy Policy. They might also obtain information about other applications that you have downloaded to your mobile device, the mobile websites you visit and other information about you or your device in order to analyze and serve anonymous targeted advertising on the Site and elsewhere. For more information about third party ad servers at our Site and your ability to opt-out of targeted advertising from such third parties, please visit
                      <EmailLink target={'_blank'} href={ !this.props.disableLinks ? 'https://youradchoices.com/' : undefined }> Ad Choices </EmailLink>
                      or go to
                      <LinkText onClick={() => this.handleScrollToSection('section9')}> Section 9 </LinkText>
                      (Your Ad Choices).
                    </p>
                    <p>(g) Companies In The Mobile Industry: If you access the Site through a mobile device or app, we may also share your information with carriers, operating systems and platforms.</p>
                    <p>(h) Social Networks: We may disclose information to companies that connect individuals around common interests and facilitate sharing (i.e., Facebook).</p>
                    <p>(i) Business Partners: We may share your information with our business partners in connection with offering you co-branded services, selling or distributing our products, or engaging in joint marketing activities. For example, we may share information about you with our ticket processors for purposes of providing you with customer support.</p>
                    <p>
                      Without limiting the foregoing, in our sole discretion, we may share or disclose aggregated information which does not identify you or de-identified information about you with third parties or affiliates for any purpose except as prohibited by applicable law. For information on your rights and choices regarding how we share your information, please go to
                      <LinkText onClick={() => this.handleScrollToSection('section4')}> Section 4 </LinkText>
                      (Your Choices) above.
                    </p>
                  </React.Fragment>
                )
              },
              {
                title: '6. INFORMATION YOU SHARE ON THE SITE',
                body: (
                  <p>If you post or submit information or content, such as comments, and other postings, or when you interact with our Site through social media sites, plug-ins or other applications, depending upon your privacy settings, this information may become public on the Internet. We cannot prevent further use of this information. Please think carefully before making information public as you are solely responsible for any information you make public. Once you have posted information, you may not be able to edit or delete such information. You can control what data you share through privacy settings available on some social media sites. Please refer to those third party sites’ privacy policies and terms of use to learn more about their privacy practices, which we do not control.</p>
                ),
              },
              {
                title: '7. COMMUNICATION PREFERENCES AND MARKETING (OPT-OUT)',
                body: (
                  <React.Fragment>
                    <p>
                      We do not disclose your personal information to third parties for their marketing purposes without your consent. We may combine your information with information we collect from other companies and use it to improve and personalize the Site and our VR experiences. If you do not wish to receive marketing communications from us, you can indicate your communications preference within the direct communication from us, by contacting us via email at
                      <EmailLink href={ !this.props.disableLinks ? 'mailto:unsubscribe@dreamscapevr.com' : undefined }> unsubscribe@dreamscapevr.com</EmailLink>
                      , or through your account/profile settings. You may not opt-out of administrative emails (for example, emails about your transactions or policy changes) for your registered account.
                    </p>
                  </React.Fragment>
                )
              },
              {
                title: '8. TRACKING TECHNOLOGIES',
                body: (
                  <React.Fragment>
                    <p>When you visit the Site, we and our business partners may use various current and later developed tracking technologies to enhance your online experience or customize our offerings, including the following:</p>
                    <StyledUl>
                      <PaddedLi><SizingSpan>Log Files. A log file is a file that records events that occur in connection with your use of the Site, such as your site use data.</SizingSpan></PaddedLi>
                      <PaddedLi><SizingSpan>Cookies. We use two types of cookies: session cookies and persistent cookies. Temporary cookies last only until you close your browser and make it easier for you to navigate the Site. Persistent cookies remain on your computer until you delete them, and help personalize your experience, remembering your preferences, and supporting security features. Additionally, persistent cookies allow us to bring you advertising both on and off the Site. Persistent cookies may remain on your device for extended periods of time, and generally may be controlled through your browser settings.</SizingSpan></PaddedLi>
                      <PaddedLi><SizingSpan>Pixels. A pixel (also known as a web beacon) is code embedded in a website, video, e-mail, or advertisements that sends information about your use to a server. There are various types of pixels, including image pixels (which are small graphic images) and JavaScript pixels (which contains JavaScript code). When you access a website, video, e-mail, or advertisement that contains a pixel, the pixel may permit us or a third party to drop or read cookies on your browser. Pixels are used in combination with cookies to track activity by a particular browser on a particular device. We may incorporate pixels from third parties that allow us track our conversions, bring you advertising both on and off the Site, and provide you with additional functionality, such as the ability to connect our Site with your social media account. Device Fingerprinting. Device fingerprinting is the process of analyzing and combining sets of information elements from your device’s browser, such as JavaScript objects and installed fonts, in order to create a "fingerprint" of your device and uniquely identify your device and apps.</SizingSpan></PaddedLi>
                      <PaddedLi><SizingSpan>Device Fingerprinting. Device fingerprinting is the process of analyzing and combining sets of information elements from your device’s browser, such as JavaScript objects and installed fonts, in order to create a "fingerprint" of your device and uniquely identify your device and apps.</SizingSpan></PaddedLi>
                      <li style={{fontSize: '0.5rem'}}><SizingSpan>Location-Identifying Technologies. GPS, WiFi, Bluetooth, and other location-aware technologies may be used to collect precise location data when you enable location-based services through your device. Location data may be used for purposes such as verifying your device’s location and delivering or restricting relevant content and advertising based on that location.</SizingSpan></li>
                    </StyledUl>
                    <p>A few additional important things you should know about our use of tracking technologies:</p>
                    <p>We offer certain features that are available only through the use of tracking technologies.</p>
                    <p>We use both session and persistent tracking technologies. You are always free to decline tracking technologies if your browser permits, although doing so may interfere with your use of the Site. Refer to the help section of your browser, browser extensions, or installed applications for instructions on blocking, deleting, or disabling tracking technologies such as cookies.</p>
                    <p>If you are a registered user and you specifically request that our Site remembers your login information, you are allowing us to place a persistent cookie onto your computer.</p>
                    <BoldSpan>Analytics and Interest-Based Advertising</BoldSpan>
                    <p>You may encounter tracking technologies from our third party service providers, that we have allowed on our Site that assist us with various aspects of our web site operations and services, such as Google Analytics and others. We also work with ad serving services, advertisers, and other third parties to serve advertisements on the Site and/or on third party sites and services. These third parties may use Tracking Technologies on our Site and third party services (including in e-mails and advertisements) to track your activities across time and services for purposes of associating the different devices you use, and delivering relevant ads and/or other content to you on the Site and third party services or third party devices after you have left the Site ("Interest-based Advertising").</p>
                    <p>We may serve ads on and through third party services and social networks, such as Facebook and Google, that are targeted to reach people (or people similar to people) who have visited our Site or are identified in one or more of our databases ("Matched Ads"). This is done by us uploading a customer list to the third party service, or incorporating a pixel from the third party service on our Site, and the third party service matching common factors between our data and their data. To opt-out of receiving Matched Ads, please contact the applicable third party service. If we use Facebook Custom Audiences to serve Matched Ads on Facebook services, you should be able to hover over the box in the right corner of the Facebook ad to find out how to opt-out. We are not responsible for any third party service’s failure to comply with your opt-out instructions.</p>
                    <p>You also may encounter tracking technologies from third parties on certain pages of the websites that we do not control and have not authorized. We encourage you to familiarize yourself with and consult their privacy policies and terms of use.</p>
                    <p>We may target and track the videos you view on the Site. You consent to our tracking of your video viewing through the Site or third party social media for up to two years or as permitted by applicable law.</p>
                  </React.Fragment>
                )
              },
              {
                title: '9. YOUR AD CHOICES',
                body: (
                  <React.Fragment>
                    <p>
                      You can opt-out of your data being used by Google Analytics through cookies by visiting
                      <EmailLink target={'_blank'} href={ !this.props.disableLinks ? 'https://tools.google.com/dlpage/gaoptout' : undefined }> https://tools.google.com/dlpage/gaoptout </EmailLink>
                      and downloading the Google Analytics Opt-out Browser Add-on.
                    </p>
                    <p>
                      Some of the third parties that collect information from or about you on the Site in order to provide more relevant advertising to you participate in the Digital Advertising Alliance ("DAA") Self-Regulatory Program for Online Behavioral Advertising. This program offers a centralized location where users can make choices about the use of their information for online behavioral advertising. To learn more about the DAA and your opt-out options for their members, please visit (i) for website opt-out,
                      <EmailLink target={'_blank'} href={ !this.props.disableLinks ? 'http://www.aboutads.info/choices' : undefined }> http://www.aboutads.info/choices</EmailLink>
                      ; and (ii) for mobile app opt-out,
                      <EmailLink target={'_blank'} href={ !this.props.disableLinks ? 'http://www.aboutads.info/appchoices' : undefined }> http://www.aboutads.info/appchoices</EmailLink>
                      . In addition, some of these third parties may be members of the Network Advertising Initiative ("NAI"). To learn more about the NAI and your opt-out options for their members, please visit
                      <EmailLink target={'_blank'} href={ !this.props.disableLinks ? 'http://www.networkadvertising.org/choices/' : undefined }> http://www.networkadvertising.org/choices/</EmailLink>
                      . Please note that if you opt-out of online behavioral advertising using any of these methods, the opt-out will only apply to the specific browser or device from which you opt-out. Further, opting-out only means that the selected members should no longer deliver certain Interest-based Advertising to you, but does not mean you will no longer receive any targeted content and/or ads (e.g., from other ad networks). We are not responsible for effectiveness of, or compliance with, any third-parties’ opt-out options or programs or the accuracy of their statements regarding their programs.
                    </p>
                    <p>You may also limit our use of information collected from or about your mobile device for purposes of serving online behavioral advertising to you by going to your device settings and selecting "Limit Ad Tracking" (for iOS devices) or "Opt out of Interest-Based Ads" (for Android devices).</p>
                  </React.Fragment>
                )
              },
              {
                title: '10. ACCESSING, REVIEWING, AND CHANGING YOUR PERSONAL INFORMATION',
                body: (
                  <p>Registered members can review and change personal information at any time by accessing their accounts on the Site. You should promptly update your personal information if it changes or becomes inaccurate. We may retain some information from closed accounts so that we can comply with law, prevent fraud, assist with investigations, resolve disputes, analyze or troubleshoot programs, enforce our Terms of Use, or take other actions permitted by law. Likewise, if your account or membership is terminated or suspended, we may maintain some information to prevent re-registration.</p>
                ),
              },
              {
                title: '11. ADDITIONAL DISCLOSURES FOR CALIFORNIA RESIDENTS',
                body: (
                  <React.Fragment>
                    <p>
                      (a) These additional disclosures apply only to individuals who reside in California.  The California Consumer Privacy Act of 2018 ("CCPA") provides additional rights to know, delete, and opt-out, and requires businesses collecting or disclosing personal information to provide notices and means to exercise those rights. The words used in this section have the meanings given to them in the CCPA, which may be broader than their common meaning.  For example, the definition of "personal information" under the CCPA includes your name, but also more general information like age.
                    </p>
                    <ol type={'i'}>
                      <li>
                        <BoldSpan>Notice of Collection.</BoldSpan>
                        <p>
                          Although the information we collect is described in greater detail in Section 2
                          <LinkText onClick={() => this.handleScrollToSection('section2')}> (Information We Collect), </LinkText>
                          the categories of personal information that we have collected – as described by the CCPA – in the past 12 months are:
                        </p>
                        <ul type={'disc'}>
                          <li>
                            <BoldSpan>Identifiers, </BoldSpan>
                            including name, username, email address, and IP address.
                          </li>
                          <li>
                            <BoldSpan>Other individual customer records, </BoldSpan>
                            including phone number, billing and shipping address, and credit or debit card information. This category includes personal information protected under pre-existing California law (Cal. Civ. Code 1798.80(e)), and overlaps with other categories listed here.
                          </li>
                          <li>
                            <BoldSpan>Demographics, </BoldSpan>
                            such as your age or gender. This category includes data that may qualify as protected classifications under other California or federal laws.
                          </li>
                          <li>
                            <BoldSpan>Commercial information, </BoldSpan>
                            including ticket purchases to the VR Experience.
                          </li>
                          <li>
                            <BoldSpan>Internet activity, </BoldSpan>
                            including your interactions with our Site, browsing activity, and technical usage information.
                          </li>
                          <li>
                            <BoldSpan>Sensory data, </BoldSpan>
                            including video of your experience.
                          </li>
                          <li>
                            <BoldSpan>Geolocation data, </BoldSpan>
                            including location enabled services such as WiFi and GPS.
                          </li>
                          <li>
                            <BoldSpan>Employment and education data, </BoldSpan>
                            including information you provide when you apply for a job with us.
                          </li>
                          <li>
                            <BoldSpan>Inferences, </BoldSpan>
                            including information about your interests, preferences and beliefs.
                          </li>
                        </ul>
                        <p>
                          For more information on information we collect, including the sources we receive information from, review Section 2
                          <LinkText onClick={() => this.handleScrollToSection('section2')}> (Information We Collect). </LinkText>
                          In general, the sources of these categories of information are: you, either directly from you or from your device when you interact with the Site; from our service providers; and from social media, third party advertising and marketing partners. We collect and use these categories of personal information for the business purposes described in Section 3
                          <LinkText onClick={() => this.handleScrollToSection('section3')}> (Uses and Retention) </LinkText>
                          section, including to provide and manage our Site.
                        </p>
                        <p>
                          Dreamscape does not generally sell information as the term "sell" is traditionally understood. However, if an to the extent "sale" under the CCPA is interpreted to include analytics and interest-based advertising activities such as those disclosed in Section 8
                          <LinkText onClick={() => this.handleScrollToSection('section8')}> (Tracking Technologies) </LinkText>
                          section as a "sale", we will comply with applicable law as to such activity.
                        </p>
                        <p>
                          We disclose the following categories of personal information for commercial purposes: identifiers, demographic information, commercial information, internet activity, geolocation data and inferences. We use and partner with different types of entities to assist with our daily operations and manage our Site. Please review Section 5
                          <LinkText onClick={() => this.handleScrollToSection('section5')}> (Disclosure) </LinkText>
                          for more detail about the parties we have shared information with and the purposes underlying those disclosures.
                        </p>
                      </li>

                      <li>
                        <BoldSpan>Right to Know and Delete. </BoldSpan>
                        <p>California residents have the right to delete the personal information we have collected from you, and the right know certain information about our data practices in the preceding twelve (12) months. In particular, you have the right to request the following from us:</p>
                        <ul type={'disc'}>
                          <li>The categories of personal information we have collected about you;</li>
                          <li>The categories of sources from which the personal information was collected;</li>
                          <li>The categories of personal information about you we disclosed for a business purpose or sold;</li>
                          <li>The categories of third parties to whom the personal information was disclosed for a business purpose or sold;</li>
                          <li>The business or commercial purpose for collecting or selling the personal information; and</li>
                          <li>The specific pieces of personal information we have collected about you.</li>
                        </ul>
                        <p>
                          To exercise any of these rights, please submit a request through our online form available
                          <InternalLink as={ this.props.disableLinks ? 'a' : undefined } to={ !this.props.disableLinks ? '/contact-us' : '' }> here</InternalLink>
                          , call our toll free number at
                          <PhoneLink href={ !this.props.disableLinks ? 'tel:(800) 899-0473' : undefined}> (800) 899-0473, </PhoneLink>
                          or at one of our locations. In the request, please specify which right you are seeking to exercise and the scope of the request. We will confirm receipt of your request within 10 days. We may require specific information from you to help us verify your identity and process your request. If we are unable to verify your identity, we may deny your requests to know or delete.
                        </p>
                        <p>If personal information about you has been processed by us as a service provider on behalf of a customer and you wish to exercise any rights you have with such personal information, please inquire with our customer directly. If you wish to make your request directly to us, please provide the name of our customer on whose behalf we processed your personal information. We will refer your request to that customer, and will support them to the extent required by applicable law in responding to your request.</p>
                      </li>

                      <li>
                        <BoldSpan>Right to Opt-Out. </BoldSpan>
                        <p>
                          To the extent Company sells your personal information as the term “sell” is defined under the California Consumer Privacy Act, you have the right to opt-out of the sale of your personal information by us to third parties at any time. You may submit a request to opt-out through our online form available
                          <InternalLink as={ this.props.disableLinks ? 'a' : undefined } to={ !this.props.disableLinks ? '/contact-us' : '' }> here. </InternalLink>
                          You may also submit a request to opt-out by calling our toll-free number at
                          <PhoneLink href={ !this.props.disableLinks ? 'tel:(800) 899-0473' : undefined}> (800) 899-0473, </PhoneLink>
                          or at one of our locations.
                        </p>
                      </li>

                      <li>
                        <BoldSpan>Authorized Agent. </BoldSpan>
                        <p>You can designate an authorized agent to submit requests on your behalf. However, we will require written proof of the agent’s permission to do so and verification your identity directly.</p>
                      </li>

                      <li>
                        <BoldSpan>Right to Non-Discrimination. </BoldSpan>
                        <p>You have the right not to receive discriminatory treatment by us for the exercise of any your rights.</p>
                      </li>
                    </ol>

                    <p>
                      (b) Shine the Light.  Residents of the State of California may request a list of all third parties to which our online service has disclosed certain personal information (as defined by California law) during the preceding year for those third parties' direct marketing purposes. If you are a California resident and want such a list, please contact us at
                      <EmailLink href={ !this.props.disableLinks ? 'mailto:CaliforniaRequest@dreamscapevr.com' : undefined }> CaliforniaRequest@dreamscapevr.com</EmailLink>
                      . For all requests, you must put the statement "California Shine the Light Request" in the body of your request, as well as your name, street address, city, state, and zip code. In the body of your request, please provide enough information for us to determine if this applies to you. You need to attest to the fact that you are a California resident and provide a current California address for our response. Please note that we will not accept Shine the Light requests via telephone, mail, or by facsimile, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information.
                    </p>
                    <p>(c) How Do We Respond to Do Not Track Signals for California Residents: California Business & Professions Code Section 22575(b)(as amended effective January 1, 2014) provides that California residents are entitled to know how Dreamscape responds to "Do Not Track" browser settings. However, there is no industry consensus as to what site operators should do with regard to these signals. Accordingly, unless and until the law is interpreted to require us to monitor or take action with respect to Do Not Track signals, Dreamscape does not take actions to respond to Do Not Track signals.</p>
                    <p>
                      (d) Any California residents under the age of eighteen (18) who have registered to use the Site and posted content or information on the Site, can request that such information be removed from the Site by contacting us at
                      <EmailLink href={ !this.props.disableLinks ? 'mailto:CaliforniaRequest@dreamscapevr.com' : undefined }> CaliforniaRequest@dreamscapevr.com</EmailLink>
                      . Requests must state that the user personally posted such content or information and detail where the content or information is posted. We will make reasonable good faith efforts to remove the post from prospective public view.
                    </p>
                  </React.Fragment>
                )
              },
              {
                title: '12. ADDITIONAL DISCLOSURES FOR NEVADA RESIDENTS',
                body: (
                  <p>
                    Nevada law (NRS 603A.340) requires each business to establish a designated request address where Nevada consumers may submit requests directing the business not to sell certain kinds of personal information that the business has collected or will collect about the consumer. A sale under Nevada law is the exchange of personal information for monetary consideration by the business to a third party for the third party to license or sell the personal information to other third parties. If you are a Nevada consumer and wish to submit a request relating to our compliance with Nevada law, please contact us as at
                    <EmailLink href={ !this.props.disableLinks ? 'mailto:privacy@dreamscapevr.com' : undefined }> privacy@dreamscapevr.com</EmailLink>.
                  </p>
                )
              },
              {
                title: '13. THIRD PARTIES',
                body: (
                  <p>Our Site may contain links to other third party websites, including social media platforms and the sites of our partners, including our ticketing and payment partners (e.g., xola.com and stripe.com). We are not responsible for the privacy practices or the content of these third party sites.</p>
                ),
              },
              {
                title: '14. SECURITY',
                body: (
                  <p>
                    We store and process your information on our servers located in the United States and around the world. We maintain security measures to protect the security of your personal information against loss, misuse, unauthorized access, disclosure, or alteration. It is your responsibility to make sure that your personal information is accurate. If you believe that your login credentials have been compromised, please make sure to contact us at via the contact information in
                    <LinkText onClick={() => this.handleScrollToSection('section18')}> Section 18 </LinkText>
                    (Contact Us).
                  </p>
                )
              },
              {
                title: '15. CHILDREN\'S PRIVACY',
                body: (
                  <React.Fragment>
                    <p>
                      The Site is a general audience site and it is not intended for children under the age of 13. We do not knowingly collect personal information as defined by the U.S. Children’s Privacy Protection Act ("COPPA") in a manner that is not permitted by COPPA. If you are a parent or guardian and believe Company has collected such information in a manner not permitted by COPPA, please contact us via the email listed in
                      <LinkText onClick={() => this.handleScrollToSection('section18')}> Section 18 </LinkText>
                      (Contact Us), and we will remove such data to the extent required by COPPA.
                    </p>
                    <p>Except as described below, we do not knowingly collect personal information via our websites, applications, services, or tools from users in this age group.  We do not knowingly "sell", as that term is defined under the CCPA, the personal information of minors under 16 years old who are California residents.</p>
                    <p>If your child is under 13 years old and registers online to participate in one of our VR experiences, we may collect from your child your online contact information, name and your child’s name in order to obtain a release and your consent, as required, for the collection, use, or disclosure of your child’s information. We will not collect, use, or disclose any personal information from your child if you do not provide your consent.</p>
                    <p>When a child under 13 arrives to participate in a VR experience, we collect the child’s name, date of birth, gender, avatar ID and ticket ID in order to check the child in and associate that child with a particular avatar during the VR experience. If you do not provide your consent at the VR experience we will delete the child’s information and your online contact information from our records if it differs from the contact information you already provided to us (e.g., to purchase tickets or sign up for a newsletter).</p>
                    <p>
                      If you believe your child has provided personal information to us without your consent, or if you want to review or have us delete the child's personal information, and refuse to permit further collection or use of the child's information, please contact us via the email listed in
                      <LinkText onClick={() => this.handleScrollToSection('section17')}> Section 17 </LinkText>
                      (Contact Us).
                    </p>
                  </React.Fragment>
                )
              },
              {
                title: '16. INTERNATIONAL TRANSFER',
                body: (
                  <p>We are based in the U.S. and information we collect is governed by U.S. law.  If you are accessing the Site from outside of the U.S., please be aware that your personal information may be transferred to, processed, stored, and used in the U.S. and elsewhere where data protection and privacy regulations may not offer the same level of protection as in other parts of the world, such as the European Union. If you use the Site or create an account/profile and provide any information to us, you consent to the collection, use, sharing, storage and/or processing in the United States and other jurisdictions of your personal information and tracking technologies/cookies as described in this Policy.</p>
                ),
              },
              {
                title: '17. NOTIFICATION REGARDING UPDATES',
                body: (
                  <p>From time to time, we may update this Privacy Policy. Any changes will be effective immediately upon posting of the revised Privacy Policy. You agree that we may notify you about material changes in the way we treat personal information by placing a notice on the Site. You should check the Site frequently for updates.</p>
                ),
              },
              {
                title: '18. CONTACT US',
                body: (
                  <React.Fragment>
                    <p>
                      If your questions are not answered online, you may email us at
                      <EmailLink href={ !this.props.disableLinks ? 'mailto:privacy@dreamscapevr.com' : undefined }> privacy@dreamscapevr.com</EmailLink>
                      , or write to us at Dreamscape, Attn: Privacy Questions, 8740 Washington Blvd., Suite B, Culver City, CA 90232.
                    </p>
                    <p>
                      If you have a disability and would like to access this Privacy Policy in an alternative format , please contact us at
                      <EmailLink href={ !this.props.disableLinks ? 'mailto:privacy@dreamscapevr.com' : undefined }> privacy@dreamscapevr.com</EmailLink>
                    </p>
                  </React.Fragment>
                )
              },
              {
                title: '19. DEFINITIONS',
                body: (
                  <React.Fragment>
                    <br/>
                    <BoldSpan>Cookies</BoldSpan>
                    <p>A cookie (sometimes referred to as local storage object or LSO) is a data file placed on a device. Cookies can be created through a variety of web-related protocols and technologies, such as HTTP (sometimes referred to as "browser cookies"), HTML5, or Adobe Flash.</p>
                    <BoldSpan>Web Beacons</BoldSpan>
                    <p>Small graphic images or other web programming code called web beacons (also known as "1×1 GIFs" or "clear GIFs") may be included in our online service’s pages and messages. Web beacons may be invisible to you, but any electronic image or other web programming code inserted into a page or e-mail can act as a web beacon.</p>
                    <BoldSpan>Clear Gifs</BoldSpan>
                    <p>Clear gifs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to HTTP cookies, which are stored on a user's computer hard drive, clear gifs are embedded invisibly on web pages and are about the size of the period at the end of this sentence.</p>
                    <BoldSpan>Embedded Script</BoldSpan>
                    <p>An embedded script is programming code that is designed to collect information about your interactions with the online service, such as the links you click on. The code is temporarily downloaded onto your device from our web server or a third-party service provider, is active only while you are connected to the online service, and is deactivated or deleted thereafter.</p>
                    <BoldSpan>ETag, or entity tag</BoldSpan>
                    <p>A feature of the cache in browsers, an ETag is an opaque identifier assigned by a web server to a specific version of a resource found at a URL. If the resource content at that URL ever changes, a new and different ETag is assigned. Used in this manner ETags are a form of device identifier. ETag tracking may generate unique tracking values even where the consumer blocks HTTP, Flash, and/or HTML5 cookies.</p>
                    <BoldSpan>Unique Device ID</BoldSpan>
                    <p>The unique series of numbers and letters assigned to your device.</p>
                  </React.Fragment>
                )
              }
            ].map((row, i) => {
            return (
              <React.Fragment key={i}>
                <Header id={"section"+(i+1)} style={{paddingBottom: '0'}}>{row.title}</Header>
                <Text>{row.body}</Text>
              </React.Fragment>
            )
          })}
          </Body>
        </TextContainer>
      </Container>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Desktop>
          {this.renderPrivacyPolicy(false)}
        </Desktop>
        <Mobile>
          {this.renderPrivacyPolicy(true)}
        </Mobile>
      </React.Fragment>
    );
  }
}

const Privacy = props => (
  <AuthContext.Consumer>
    { parentState => <PrivacyWithContext {...props} parentState={parentState} /> }
  </AuthContext.Consumer>
);

export default withRouter(Privacy);
