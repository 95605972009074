import Home from '../pages/Home';
import Adventures from '../pages/Adventures';
import SingleAdventure from '../pages/SingleAdventure';
import LocationLanding from '../pages/LocationLanding';
import PlanAhead from '../pages/PlanAhead';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import FindUs from '../pages/FindUs';
import AboutUs from '../pages/AboutUs';
import Careers from '../pages/Careers';
import ContactUs from '../pages/ContactUs';
import Faq from '../pages/FAQ';
import PressKit from '../pages/PressKit';

export const HOME = Home;
export const ADVENTURES = Adventures;
export const SINGLE_ADVENTURE = SingleAdventure;
export const LOCATION_LANDING = LocationLanding;
export const PLAN_AHEAD = PlanAhead;
export const TERMS = Terms;
export const PRIVACY = Privacy;
export const FIND_US = FindUs;
export const ABOUT_US = AboutUs;
export const CAREERS = Careers;
export const CONTACT_US = ContactUs;
export const PRESS_KIT = PressKit;
export const FAQ = Faq;
