import React, { Component } from 'react';
import Slider from "react-slick";
import styled from "styled-components";
import {Desktop, Mobile} from "./responsive/Breakpoints";
import rightArrow from "../assets/images/btn-right-arrow.svg";
import leftArrow from "../assets/images/btn-left-arrow.svg";
import btnClose from "../assets/images/btn-close.svg";

const Darkener = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9);
  z-index: 3;
  
  & .slick-slider, .slick-list, .slick-track, .slick-slide div {
    height: 100% !important;
  }
  
  & .slick-prev {
    left: ${ props => props.mobile ? '2.25' : '7.65' }%;
    z-index: 1;
  }
  
  & .slick-next {
    right: ${ props => props.mobile ? '2.25' : '7.65' }%;
    z-index: 1;
  }
`;

const NextArrow = styled.div`
  background: url(${rightArrow});
  height: 37px;
  width: 37px;
  
  :before {
    display: none;
  }
  
  &:hover {
    background: url(${rightArrow});
  }
`;

const PrevArrow = styled.div`
  background: url(${leftArrow});
  height: 37px;
  width: 37px;
  
  :before {
    display: none;
  }
  
  &:hover {
    background: url(${leftArrow});
  }
`;

const ExitButton = styled.div`
  background: url(${btnClose});
  position: absolute;
  height: 37px;
  width: 37px;
  right: ${ props => props.mobile ? '2.25' : '7.65' }%;
  top: 12%;
  z-index: 1;
  cursor: pointer;
`;

export default class Overlay extends Component {
  componentDidUpdate () {
    this.slider.slickGoTo(this.props.startingIndex, true);
  }

  stopAll = () => {
    for (let i=0; i<this.props.videos.length; i++) {
      if (this.props.videos[i].video) {
        this.props.videos[i].video.pause();
      }
    }
  };

  handleExitClicked = (event) => {
    if (event.target.classList.contains('slick-arrow')) {
      return;
    }
    this.stopAll();
    this.props.tearDown();
  };

  renderOverlay (mobile) {
    const settings = {
      beforeChange: this.stopAll,
      touchMove: false,
      infinite: false,
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>
    };

    return (
      <Darkener key={this.props.title_id} mobile={mobile} style={{display: this.props.display ? 'block' : 'none'}} onClick={this.handleExitClicked}>
        <ExitButton mobile={mobile}/>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {this.props.items}
        </Slider>
      </Darkener>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Desktop>
          {this.renderOverlay(false)}
        </Desktop>
        <Mobile>
          {this.renderOverlay(true)}
        </Mobile>
      </React.Fragment>
    )
  }
}
