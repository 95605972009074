import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Large, Phone } from '../responsive/Breakpoints';
import styled from 'styled-components';
import BlueButton from "../buttons/BlueButton";

const Container = styled.div`
  background: #fff;
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${ props => props.mobile ? '52px 22px 47px' : '80px 98px 128px' };
  max-width: 1170px;
  margin: 0 auto;
`;

const ThingsToKnowTable = styled.table`
  width: 100%;
  text-align: left;
`;

const FlexTr = styled.tr`
  display: flex;
`;

const SpacerTd = styled.td`
  width: 70px;
`;

const ThingToKnow = styled.td`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
`;

const TTKHeader = styled.div`
  margin: ${ props => props.mobile ? '8px 0 14px' : '11px 0 23px' };
  font-family: ${ props => props.theme.serif };
  font-size: ${ props => props.mobile ? '1.375' : '2.25' }rem;
  line-height: ${ props => props.mobile ? '1.29' : '1.19' };
  color: ${ props => props.theme.charcoal_90 };
`;

const TTKBody = styled.div`
  font-family: ${ props => props.theme.sans_serif };
  font-size: ${ props => props.mobile ? '0.875' : '1' }rem;
  color: ${ props => props.theme.charcoal_60 };
  min-height: 72px;
  margin-bottom: 40px;
`;

const FlexButtonContainer = styled.div`
  flex: 1;
  display: flex;
`;

const TTKButton = styled(BlueButton)`
  align-self: flex-end;
  ${ props => props.mobile ? '' : 'max-width: 310px;' }
`;

const UnstyledLinkText = styled(Link)`
  color: ${ props => props.theme.ds_dusk_blue };
  cursor: pointer;
  text-decoration: none;
`;
class AboutUsLinkBlock extends Component {
  thingToKnowBlock(mobile, header, body, buttonContent, buttonOnClick) {
    return (
      <ThingToKnow key={header}>
        <TTKHeader mobile={mobile}>{header}</TTKHeader>
        <TTKBody mobile={mobile}>{body}</TTKBody>
        <FlexButtonContainer>
          <TTKButton width={'100%'} mobile={mobile} onClick={buttonOnClick}>{buttonContent}</TTKButton>
        </FlexButtonContainer>
      </ThingToKnow>
    )
  }

  allocateBlocks(blocks, blocksPerRow, mobile) {
    let blockGroups = blocks;

    if (!mobile) {
      blockGroups = [];
      let temp = [];
      for (let i=0; i<blocks.length; i++) {
        if (temp.length > 0) {
          temp.push(<SpacerTd key={i}/>);
        }
        temp.push(blocks[i]);
        if ((i+1) % blocksPerRow === 0) {
          blockGroups.push(temp);
          temp = [];
        }
        if (i === blocks.length-1 && temp.length > 0) {
          for (let j=temp.length; j<blocksPerRow; j++) {
            temp.push(<SpacerTd/>);
            temp.push(<div style={{flex: '1'}}/>);
          }
          blockGroups.push(temp);
        }
      }
    }

    return blockGroups;
  }

  tableContent (mobile) {
    const blocks = [
      this.thingToKnowBlock(mobile,
        'Where\'s Dreamscape Located?',
        (<span>Dreamscape currently has three locations across the globe with more coming soon! Please visit our <UnstyledLinkText to={'/find-us'}>Locations</UnstyledLinkText> page for more information and details.</span>),
        'FIND US',
        () => this.props.history.push('/find-us')),
      this.thingToKnowBlock(mobile,
        'Careers',
        'If you’re ready to make an adventurous career move to our LA office or retail venues, Dreamscape wants to hear from you.',
        'VIEW JOB OPENINGS',
        () => window.location.assign('https://dreamscape-immersive.breezy.hr')),
      this.thingToKnowBlock(mobile,
        'Press',
        'New and exciting things are happening all the time at Dreamscape. Find our latest press releases and contact info here.',
        'SEE WHAT PEOPLE SAY',
        () => this.props.history.push('/press-kit')),
      this.thingToKnowBlock(mobile,
        'Contact Us',
        'Got a question, or just want to know more about Dreamscape? Find our contact info and FAQs here.',
        'GET SUPPORT',
        () => this.props.history.push('/contact-us'))
    ];

    const blockGroups = this.allocateBlocks(blocks, 2, mobile);

    return (
      <React.Fragment>
        {blockGroups.map((blockGroup, i) => {
          let style = {};
          if (i > 0) {
            style = {marginTop: mobile ? '66px' : '79px'};
          }
          return (
            <FlexTr key={i} mobile={mobile} style={style}>
              {blockGroup}
            </FlexTr>
          )
        })}
      </React.Fragment>
    )
  }

  renderLinkBlock (mobile) {
    return (
      <Container>
        <TextContainer mobile={mobile}>
          <ThingsToKnowTable>
            <tbody>
            {this.tableContent(mobile)}
            </tbody>
          </ThingsToKnowTable>
        </TextContainer>
      </Container>
    )
  }

  render () {
    return (
      <React.Fragment>
        <Large>
          {this.renderLinkBlock(false)}
        </Large>
        <Phone>
          {this.renderLinkBlock(true)}
        </Phone>
      </React.Fragment>
    );
  }
}

export default withRouter(AboutUsLinkBlock);
