import React from 'react';
import theme from '../../util/theme';

export default (props) => {
  let color = theme.white_70;

  if (props.active) {
    color = theme.ds_bright_blue;
  } else if (props.color) {
    color = props.color;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 18">
      <path fill={color} fillOpacity=".7" fillRule="evenodd" d="M11.803 15.676a1 1 0 0 1-1.606 0C6.732 11.006 5 7.69 5 5.728 5 2.564 7.686 0 11 0s6 2.564 6 5.728c0 1.962-1.732 5.278-5.197 9.948zM11 7.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
    </svg>
  );
};
