import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Tablet, Phone } from "../components/responsive/Breakpoints";
import styled from 'styled-components';
import HubspotForm from '../components/hubspot/LocationLandingHubspotForm';

import imgBenefit from '../assets/images/img-benefit-4.svg';

const Container = styled.div`
  width: 100%;
  background-color: white;
`;

const Body = styled.div`
  width: 100%;
  max-width: 1270px;
  box-sizing: border-box;
  margin: 0 auto;
  color: ${ props => props.theme.charcoal_60 };
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${ props => props.mobile ? '38px 22px' : '97px 50px' };
`;

const HeroText = styled.h1`
  width: 100%;
  text-align: ${ props => props.mobile ? 'center' : 'left' };
  font-size: ${ props => props.mobile ? '1.625rem' : '2.75rem' };
  line-height: 1.09;
  color: ${ props => props.theme.charcoal };
  margin: 0;
  margin-bottom: 10px;
`;

const BulletContainer = styled.div`
  display: flex;
  flex-direction: ${ props => props.mobile ? 'column' : 'row'};
  justify-content: space-between;
  width: 100%;
  ${ props => props.mobile ? 'margin: 30px 0 18px 0;' : 'margin: 40px 0;' }
`;

const Bullet = styled.div`
  font-size: 1rem;
  margin: 0;
  ${ props => props.mobile ? 'margin-bottom: 15px;' : '' }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DescriptionText = styled.p`
  width: 100%;
  text-align: ${ props => props.mobile ? 'center' : 'left' };
  margin: 0;
  font-size: ${ props => props.mobile ? '0.875rem' : '1rem' };
`;

const FormBlock = styled.div`
  border-top: 1px solid ${ props => props.theme.charcoal_25 };
  display: flex;
  flex-direction: ${ props => (props.mobile || props.tablet) ? 'column': 'row' };
  justify-content: space-between;
  width: 100%;
  padding-top: 50px;
`;

const BookingInfo = styled.div`
  color: black;
  width: ${ props => (props.mobile || props.tablet) ? '100%' : '400px' };
  ${ props => {
    if (props.tablet) {
      return (
        `display: flex;
        flex-direction: row;
        justify-content: space-between;`
      )
    }
  }}
  ${ props => props.desktop ? 'margin-right: 18px;' : ''}

  & div.booking-block {
    width: calc(50% - 9px);
  }
`;

const InfoHeader = styled.h3`
  font-size: 1em;
  color: ${ props => props.theme.charcoal_90 };
  margin: 0;
`;

const InfoCopy = styled.p`
  margin: 0;
  margin-bottom: 30px;
  color: ${ props => props.theme.charcoal_60 };
  #email {
    color: ${ props => props.theme.ds_dusk_blue };
    text-decoration: none;
  }
`;

const data = {
  bullets: [
    'Corporate Entertaining',
    'Team Building',
    'Birthday Parties',
    'Special Occasions',
  ],
};

class PrivateEvents extends Component {
  renderBullets = (isMobile) => {
    return data.bullets.map((bullet, index) => {
      return (
        <Bullet key={index} mobile={isMobile}>
          {/* The span helps the browser factor in the img's width correctly on safari */}
          <span>
            <img style={{ height: '35px', marginRight: '28px' }} src={imgBenefit} alt={'Bullet point'} />
          </span>
          {bullet}
        </Bullet>
      )
    });
  };

  render () {
    const { phone, email, displayName } = this.props.locationData.copy;

    const heroTextTitle = 'BOOK A PRIVATE EVENT';
    const descLine1 = `Interested in hosting your next private event at Dreamscape ${displayName}?`;
    const descLine2 = 'Please provide a few details in the form below and we\'ll get back to you shortly!';
    const thingsToKnowTitle = 'THINGS YOU SHOULD KNOW';
    const thingsToKnowCopy = <span>Private event bookings require a 2-hour minimum and are available Monday through Thursday between 9am and 11pm, and Friday through Sunday between 9am and 5pm. Event capacity varies based on location. For special requests, please email <a id={'email'} href={`mailto:${email}`}>{email}</a></span>;
    const eligibilityTitle = 'INDIVIDUAL ELIGIBILITY';
    const eligibilityCopy = 'All travelers must be at least 48 inches tall and 10+ years old. Travelers under age 13 must be accompanied by an adult with a paid ticket to sign the Dreamscape waiver on child\'s behalf.'
    
    return (
      <Container id={"location-private-events"}>
        <Desktop>
          <Body>
            <HeroText>{heroTextTitle}</HeroText>
            <DescriptionText>
              {descLine1}
              <br />
              {descLine2}
            </DescriptionText>
            <BulletContainer>
              {this.renderBullets()}
            </BulletContainer>
            <FormBlock>
              <BookingInfo desktop={true}>
                <InfoHeader>{thingsToKnowTitle}</InfoHeader>
                <InfoCopy>{thingsToKnowCopy}</InfoCopy>
                <InfoHeader>{eligibilityTitle}</InfoHeader>
                <InfoCopy>{eligibilityCopy}</InfoCopy>
              </BookingInfo>
              <HubspotForm
                id={'bookEvent'}
                portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                formId={process.env.REACT_APP_HUBSPOT_PRIVATE_EVENTS_FORM_ID} />
            </FormBlock>
          </Body>
        </Desktop>

        <Tablet>
          <Body>
            <HeroText>{heroTextTitle}</HeroText>
            <DescriptionText>
              {descLine1}
              <br />
              {descLine2}
            </DescriptionText>
            <BulletContainer>
              {this.renderBullets()}
            </BulletContainer>
            <FormBlock tablet={true}>
              <BookingInfo tablet={true}>
                <div className={'booking-block'}>
                  <InfoHeader>{thingsToKnowTitle}</InfoHeader>
                  <InfoCopy>{thingsToKnowCopy}</InfoCopy>
                </div>
                <div className={'booking-block'}>
                  <InfoHeader>{eligibilityTitle}</InfoHeader>
                  <InfoCopy>{eligibilityCopy}</InfoCopy>
                </div>
              </BookingInfo>
              <HubspotForm
                tablet={true}
                id={'bookEvent'}
                portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                formId={process.env.REACT_APP_HUBSPOT_PRIVATE_EVENTS_FORM_ID} />
            </FormBlock>
          </Body>
        </Tablet>

        <Phone>
          <Body mobile={true}>
            <HeroText mobile={true}>{heroTextTitle}</HeroText>
            <DescriptionText mobile={true}>
              {descLine1} {descLine2}
            </DescriptionText>
            <BulletContainer mobile={true}>
              {this.renderBullets(true)}
            </BulletContainer>
            <FormBlock mobile={true}>
              <BookingInfo mobile={true}>
                <InfoHeader>{thingsToKnowTitle}</InfoHeader>
                <InfoCopy>{thingsToKnowCopy}</InfoCopy>
                <InfoHeader>{eligibilityTitle}</InfoHeader>
                <InfoCopy>{eligibilityCopy}</InfoCopy>
              </BookingInfo>
              <HubspotForm
                mobile={true}
                id={'bookEvent'}
                portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                formId={process.env.REACT_APP_HUBSPOT_PRIVATE_EVENTS_FORM_ID} />
            </FormBlock>
          </Body>
        </Phone>
      </Container>
    );
  }
}

export default withRouter(PrivateEvents);
