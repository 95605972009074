import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="126" height="150" viewBox="0 0 117 140">
    <g fill="none" fillRule="evenodd">
      <rect width="114" height="137" x="1.5" y="1.5" stroke="#2E3191" strokeWidth="3" rx="5"/>
      <g fill="#2E3191">
        <path d="M46 32.172L68.042 10.13l2.828 2.828L48.828 35z"/>
        <path d="M55 10h16v4H55z"/>
        <path d="M67 26V10h4v16z"/>
      </g>
      <text fill="#1B1B1B" fillOpacity=".9" fontFamily="Brother-1816" fontSize="16" fontWeight="bold">
        <tspan x="26" y="127">THE BLU</tspan>
      </text>
      <text fill="#1B1B1B" fontFamily="Brother-1816-Bold, Brother-1816" fontSize="45" fontWeight="bold" letterSpacing="-1">
        <tspan x="16" y="91">BL</tspan> <tspan x="68.45" y="91">U</tspan>
      </text>
      <path fill="#1B1B1B" fillOpacity=".25" fillRule="nonzero" d="M6 106v-1h105v1zM6 44v-1h105v1z"/>
    </g>
  </svg>
);
