import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { Large, Phone } from '../components/responsive/Breakpoints';

import { AuthContext } from "./context/AuthenticatorContext";

const MainFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${ props => props.theme.charcoal };
  color: ${ props => props.theme.white_80 };
  font-family: ${ props => props.theme.sans_serif };
  font-size: 0.8rem;
  padding: ${ props => props.mobile ? '24px 0px' : '16px 0px' };
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Copyright = styled.div`
  margin: auto;
  padding: ${props => props.mobile ? '20px 22px 0' : '12px 22px 0'};
  color: rgba(255,255,255,0.5);
  font-size: ${ props => props.mobile ? '0.5625rem' : '0.6875rem' };
  text-align: center;
`;

const PrivacyTermsArea = styled.div`
  padding-top: 10px;
`;

const PrivacyTermsLink = styled(Link)`
  margin: auto 10px;
  cursor: pointer;
  color: ${ props => props.theme.white_80 };
  text-decoration: none;
`;

const PrivacyTermsDivider = styled.span`
  margin: auto 10px;
`;

const CopyrightDiv = (props) => (
  <Column {...props}>
    <Copyright mobile={props.mobile}>
      Copyright © 2019 - 2024 Dreamscape Immersive. All rights reserved. <br/>
      DreamWorks Dragons © 2019 - 2024 DreamWorks Animation LLC. All rights reserved.<br/>
      MIB™: First Assignment © 2024 CTMG, Inc. All Rights Reserved. <br/>
      Copyright © 2021 – 2024 Audemars Piguet. All rights reserved.
    </Copyright>
    <PrivacyTermsArea>
      <PrivacyTermsLink to={'/privacy'}> Privacy&nbsp;Policy</PrivacyTermsLink>
      <PrivacyTermsDivider>/</PrivacyTermsDivider>
      <PrivacyTermsLink to={'/terms'}> Terms&nbsp;&&nbsp;Conditions</PrivacyTermsLink>
      <PrivacyTermsDivider>/</PrivacyTermsDivider>
      <PrivacyTermsLink
        to={{
          pathname: '/privacy',
          state: { willScroll: true, scrollId: 'section11' }
        }}> California&nbsp;Privacy&nbsp;Rights</PrivacyTermsLink>
      <PrivacyTermsDivider>/</PrivacyTermsDivider>
      <PrivacyTermsLink
        style={{ marginRight: 0 }}
        to={{
          pathname: '/privacy',
          state: { willScroll: true, scrollId: 'section9' }
        }}> Ad&nbsp;Choices</PrivacyTermsLink>
    </PrivacyTermsArea>
  </Column>
);

class FooterWithContext extends Component {
  state = {
    isDrawerOpen: false,
  };

  handleNavigation = (path, opts) => {
    return this.props.history.push(path, opts);
  };

  render () {
    return (
      <React.Fragment>
        <Large>
          <MainFooter>
            <Row>
              <CopyrightDiv navigate={this.handleNavigation}/>
            </Row>
          </MainFooter>
        </Large>

        <Phone>
          <MainFooter mobile={true}>
            <Row style={{ textAlign: 'center' }}>
              <CopyrightDiv mobile navigate={this.handleNavigation}/>
            </Row>
          </MainFooter>
        </Phone>

      </React.Fragment>
    );
  }
}

const Footer = props => (
  <AuthContext.Consumer>
    { parentState => {
      return (<FooterWithContext {...props} parentState={parentState} />);
    }}
  </AuthContext.Consumer>
);

export default withRouter(Footer);
